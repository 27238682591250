import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingScreenComponent } from 'core/loadingScreen/loading-screen.component';
import { LoadingScreenService } from 'core/loadingScreen/loading-screen.service';

@NgModule({
    declarations: [LoadingScreenComponent],
    imports: [CommonModule],
    providers: [LoadingScreenService]
})
export class LoadingScreenModule {}
