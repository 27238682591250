import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TextService } from 'core/text.service';

import { TooltipData, TooltipPortalData, TooltipPosition } from 'shared/tooltip/tooltip.model';
import { TooltipService } from 'shared/tooltip/tooltip.service';

@Directive({
    selector: '[textOverflowTooltip]'
})
export class TextOverflowTooltipDirective implements OnInit, OnDestroy {
    @Input('textOverflowTooltip') content: string | TooltipData;
    @Input() tooltipPosition: TooltipPosition = TooltipPosition.right;

    private isOpened: boolean;
    private isMouseInside: boolean;
    private closeSubscription: Subscription = Subscription.EMPTY;

    constructor(private tooltipService: TooltipService, private elementRef: ElementRef, private textService: TextService) {}

    @HostListener('mouseenter') onMouseEnter() {
        this.isMouseInside = true;

        setTimeout(() => {
            if (this.isMouseInside && this.content && this.isContentOverflowing()) {
                this.openTooltip();
            }
        }, 100);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.isMouseInside = false;

        setTimeout(() => {
            if (this.isOpened && !this.tooltipService.componentInstance.isMouseInside) {
                this.closeTooltip();
            }
        }, 0);
    }

    @HostListener('click') onMouseClick() {
        if (this.isOpened && this.isMouseInside) {
            this.closeTooltip();
        }
    }

    ngOnInit() {
        this.elementRef.nativeElement.classList.add('text-ellipsis');
    }

    ngOnDestroy() {
        if (this.isOpened) {
            this.tooltipService.close();
        }

        this.closeSubscription.unsubscribe();
    }

    isContentOverflowing(): boolean {
        const elementToCompare = this.elementRef.nativeElement;

        if (!elementToCompare) {
            return false;
        }

        if (this.isElementChildrenOverflown(elementToCompare)) {
            return true;
        }

        return this.isOverflown(elementToCompare);
    }

    isElementChildrenOverflown(element: HTMLElement): boolean {
        if (!element.children) {
            return false;
        }

        for (let i = 0; i < element.children.length; i++) {
            if (this.isOverflown(element.children[i])) {
                return true;
            }

            if (this.isElementChildrenOverflown(<HTMLElement>element.children[i])) {
                return true;
            }
        }
    }

    private buildTooltipPortableData(): TooltipPortalData {
        const componentData: TooltipPortalData = {
            elementRef: this.elementRef,
            position: this.tooltipPosition,
            content: null
        };

        if (this.isTooltipData(this.content)) {
            if (this.content.inputs !== undefined) {
                componentData.inputs = this.content.inputs;
            }

            if (this.content.position !== undefined) {
                componentData.position = this.content.position;
            }

            if (this.content.contentPadding !== undefined) {
                componentData.contentPadding = this.content.contentPadding;
            }

            if (this.content.useAppText) {
                componentData.content = this.textService.getText(this.content.content as string);
            } else {
                componentData.content = this.content.content;
            }

            if (this.content.contentMaxWidth !== undefined) {
                componentData.contentMaxWidth = this.content.contentMaxWidth;
            }
        } else {
            componentData.content = this.content;
        }

        return componentData;
    }

    private isTooltipData(content: string | TooltipData): content is TooltipData {
        return typeof content !== 'string';
    }

    private openTooltip() {
        this.tooltipService.open(this.buildTooltipPortableData());
        this.isOpened = true;
    }

    private closeTooltip() {
        this.tooltipService.close();
        this.isOpened = false;
    }

    private isOverflown(element: any): boolean {
        return element.scrollWidth > (element as HTMLElement).offsetWidth;
    }
}
