import { Injectable } from '@angular/core';

@Injectable()
export class LayoutService {
    getElementOffsetTop(elementID: string, offSetTopAdjustment?: number): number {
        let offsetTop = 0;
        let element = document.getElementById(elementID);

        while (element.offsetParent) {
            offsetTop += element.offsetTop;
            element = <HTMLElement>element.offsetParent;
        }

        offsetTop -= this.getHeaderMenuHeight();
        offsetTop -= this.getNavigationMenuHeight();

        if (offSetTopAdjustment) {
            offsetTop += offSetTopAdjustment;
        }

        return offsetTop;
    }

    getFooterPixelsOnScreen(): number {
        let footerPixelsOnScreen =
            document.querySelector('.app-footer').clientHeight +
            window.pageYOffset +
            window.innerHeight -
            document.querySelector('.app-inner-container').clientHeight;

        footerPixelsOnScreen = footerPixelsOnScreen > 1 ? footerPixelsOnScreen : 0;

        let checkoutBars = document.querySelectorAll('.checkout-bar');
        checkoutBars = [].slice.call(checkoutBars);

        checkoutBars.forEach(checkoutBar => {
            const checkoutBarHeight = checkoutBar.clientHeight;
            footerPixelsOnScreen = footerPixelsOnScreen < 1 ? checkoutBarHeight : footerPixelsOnScreen + checkoutBarHeight;
        });

        return footerPixelsOnScreen;
    }

    getAppBodyHeight(): number {
        return window.innerHeight - this.getHeaderMenuHeight() - this.getNavigationMenuHeight() - this.getFooterPixelsOnScreen();
    }

    getHeaderMenuHeight(): number {
        return document.getElementById('header-menu').clientHeight;
    }

    private getNavigationMenuHeight(): number {
        const navigationMenu = document.getElementById('navigation-menu');

        if (navigationMenu) {
            return navigationMenu.clientHeight;
        }

        return 0;
    }
}
