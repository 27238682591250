import { OverlayModule as CdkOverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InternalSharedModule } from 'shared/internal-shared.module';
import { MenuComponent } from 'shared/menu/menu.component';
import { MenuDirective } from 'shared/menu/menu.directive';
import { MenuService } from 'shared/menu/menu.service';

@NgModule({
    declarations: [MenuComponent, MenuDirective],
    imports: [CdkOverlayModule, CommonModule, InternalSharedModule],
    exports: [MenuDirective]
})
export class MenuModule {
    static rootProviders() {
        return [MenuService];
    }
}
