import { ElementRef } from '@angular/core';

export interface MenuPortalData {
    elementRef: ElementRef;
    position: MenuPosition;
    menuItems: MenuItem[];
}

export interface MenuItem {
    type: MenuItemType.action | MenuItemType.listItem;
    action?: MenuAction;
    listItem?: MenuListItem;
}

export interface MenuAction {
    label: string;
    iconID: string;
    actionID: string;
}

export interface MenuListItem {
    header: string;
    actions?: MenuAction[];
}

export enum MenuPosition {
    left = 1,
    right = 2,
    top = 3,
    bottom = 4
}

export enum MenuItemType {
    action = 'action',
    listItem = 'listItem'
}
