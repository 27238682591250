import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileDropDirective } from 'shared/file-upload/file-drop.directive';
import { FileUploadComponent } from 'shared/file-upload/file-upload.component';
import { FileUploadService } from 'shared/file-upload/file-upload.service';
import { InternalSharedModule } from 'shared/internal-shared.module';

@NgModule({
    declarations: [FileDropDirective, FileUploadComponent],
    exports: [FileDropDirective, FileUploadComponent],
    imports: [InternalSharedModule, CommonModule]
})
export class FileUploadModule {
    static rootProviders() {
        return [FileUploadService];
    }
}
