import { Overlay, OverlayConfig as CdkOverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { concatMap, filter, map, mergeMap, skipWhile, take } from 'rxjs/operators';

import { Guid } from 'core/extensions/guid-extension';
import { SchemeContentFile } from 'core/models/configuration.model';
import { OverlayPriorityHelper } from 'core/overlay-priority.helper';

import { DialogContainerComponent } from 'shared/overlay/dialog-container.component';
import {
    DialogConfig,
    IOverlayBase,
    IOverlayContent,
    OverlayConfig,
    OverlayPosition,
    OverlayTextDataToken,
    TextContentClickEvent
} from 'shared/overlay/overlay.model';
import { OverlayContainerComponent } from 'shared/overlay/overlay-container.component';
import { OverlayTextContentComponent } from 'shared/overlay/overlay-text-content.component';
import { VideoOverlayComponent } from 'shared/video/video-overlay/video-overlay.component';

@Injectable()
export class OverlayService {
    private lastFocusedElement: HTMLElement;
    private containerInstances: { [id: string]: IOverlayBase } = {};
    private onOverlayDisposed = new Subject<void>();

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private router: Router,
        private overlayPriorityHelper: OverlayPriorityHelper,
        @Inject('fromRoot') private fromRoot: boolean
    ) {}

    open(component: ComponentType<IOverlayContent>, componentData = {}, config: OverlayConfig = {}) {
        const containerPortal = new ComponentPortal(OverlayContainerComponent);
        const componentPortal = new ComponentPortal(component);
        this.openOverlay(config, containerPortal, componentPortal, componentData).subscribe(() => {
            if (config.closeCallback) {
                config.closeCallback();
            }
        });
    }

    openVideoOverlay(internalVideoFile: SchemeContentFile, header: string) {
        this.open(
            VideoOverlayComponent,
            { internalVideoFile: internalVideoFile, videoHeader: header },
            {
                position: OverlayPosition.Centre,
                noOverlayPadding: true
            }
        );
    }

    openTextContent(text: string, clickEvents: TextContentClickEvent[] = [], config: OverlayConfig = {}) {
        const containerPortal = new ComponentPortal(OverlayContainerComponent);

        const portalInjector = Injector.create({
            providers: [{ provide: OverlayTextDataToken, useValue: { text, clickEvents } }],
            parent: this.injector
        });
        const componentPortal = new ComponentPortal(OverlayTextContentComponent, null, portalInjector);

        this.openOverlay(config, containerPortal, componentPortal, {}).subscribe();
    }

    openDialog<T>(
        component: ComponentType<IOverlayContent>,
        componentData,
        actions: { text: string; value: T; class?: string }[],
        ariaLabel: string,
        headerText = '',
        showIcon: boolean = true,
        hideCross: boolean = false,
        closeOnNavigation: boolean = true
    ) {
        const config: DialogConfig<T> = {
            keepExistingOverlay: true,
            preventCloseOnClickBackDrop: true,
            actions,
            ariaLabel,
            headerText,
            showIcon,
            hideCross
        };

        const containerPortal = new ComponentPortal<DialogContainerComponent<T>>(DialogContainerComponent);
        const componentPortal = new ComponentPortal(component);

        return this.openOverlay<T>(config, containerPortal, componentPortal, componentData, closeOnNavigation);
    }

    openDialogTextContent<T>(
        text: string,
        clickEvents: TextContentClickEvent[],
        actions: { text: string; value: T }[],
        ariaLabel: string,
        headerText = '',
        showIcon: boolean = true,
        hideCross: boolean = false
    ) {
        const config: DialogConfig<T> = {
            keepExistingOverlay: true,
            preventCloseOnClickBackDrop: true,
            actions,
            ariaLabel,
            headerText,
            showIcon,
            hideCross
        };

        const containerPortal = new ComponentPortal<DialogContainerComponent<T>>(DialogContainerComponent);

        const portalInjector = Injector.create({
            providers: [{ provide: OverlayTextDataToken, useValue: { text, clickEvents } }],
            parent: this.injector
        });
        const componentPortal = new ComponentPortal(OverlayTextContentComponent, null, portalInjector);

        return this.openOverlay<T>(config, containerPortal, componentPortal, {});
    }

    closeOverlay(overlayID: string) {
        this.closeOverlayInternal(overlayID);
    }

    private closeOverlayInternal(overlayID: string) {
        if (this.containerInstances[overlayID]) {
            this.containerInstances[overlayID].closeOverlay();
            delete this.containerInstances[overlayID];

            this.overlayPriorityHelper.closeOverlay(this.fromRoot);
        }
    }

    private openOverlay<T>(
        config: OverlayConfig,
        containerPortal: ComponentPortal<IOverlayBase>,
        componentPortal: ComponentPortal<IOverlayContent>,
        componentData,
        closeOnNavigation?: boolean
    ) {
        return this.overlayPriorityHelper.openOverlay(this.fromRoot).pipe(
            mergeMap(() => {
                if (document.activeElement != null) {
                    this.lastFocusedElement = <HTMLElement>document.activeElement;
                } else {
                    this.lastFocusedElement = document.body;
                }

                const closeObs = config.keepExistingOverlay ? of(true) : this.closeAllOverlays();

                const overlayObs = new Observable<T>(obs => {
                    const overlayConfig = new CdkOverlayConfig({
                        hasBackdrop: true,
                        scrollStrategy: this.overlay.scrollStrategies.block(),
                        positionStrategy: this.overlay.position().global(),
                        backdropClass: 'overlay-backdrop-custom',
                        disposeOnNavigation: true
                    });

                    const overlayID = config.overlayID ? config.overlayID : Guid.newGuid();
                    const overlayRef = this.overlay.create(overlayConfig);
                    const disposeOverlay = new EventEmitter<T>();

                    disposeOverlay.pipe(take(1)).subscribe(val => {
                        overlayRef.dispose();
                        disposeOverlay.complete();
                        this.lastFocusedElement.focus();
                        delete this.containerInstances[overlayID];
                        this.onOverlayDisposed.next();

                        obs.next(val);
                        obs.complete();

                        this.overlayPriorityHelper.closeOverlay(this.fromRoot);
                    });

                    if (closeOnNavigation) {
                        this.router.events
                            .pipe(
                                filter((event: RouterEvent) => event instanceof NavigationStart),
                                take(1)
                            )
                            .subscribe(() => {
                                this.closeOverlayInternal(overlayID);
                            });
                    }

                    setTimeout(() => {
                        //TODO: Angular CDK Overlay - Remove after Angular bug is fixed https://gsd.tobdarwin.com/browse/GSD-28976
                        const { instance } = overlayRef.attach(containerPortal);
                        Object.assign(instance, config, { disposeOverlay });

                        this.containerInstances[overlayID] = instance;
                        this.attachComponentPortal(instance, componentPortal, componentData);

                        if (!config.preventCloseOnClickBackDrop) {
                            overlayRef.backdropClick().subscribe(() => {
                                /*
                                Before disposing the overlay, the sliding-out animation needs to be triggered first. 
                                Hence, the base component closeOverlay is called here.
                            */
                                this.closeOverlayInternal(overlayID);
                            });
                        }
                    });
                });

                return closeObs.pipe(concatMap(() => overlayObs));
            })
        );
    }

    private attachComponentPortal(portalInstance: IOverlayBase, componentPortal: ComponentPortal<IOverlayContent>, componentData) {
        const { instance } = portalInstance.portalOutlet.attach(componentPortal);
        Object.assign(instance, componentData, { close: portalInstance.close });
    }

    private closeAllOverlays() {
        const keys = Object.keys(this.containerInstances);
        if (keys.length === 0) {
            return of(true);
        }

        keys.forEach(overlayID => this.containerInstances[overlayID].closeOverlay());

        return this.onOverlayDisposed.pipe(
            skipWhile(() => Object.keys(this.containerInstances).length > 0),
            take(1),
            map(() => true)
        );
    }
}
