import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationService {
    baseUrl: string;
    loginUrl: string;
    webUrl: string;

    constructor() {
        this.baseUrl = document.getElementsByTagName('base')[0].href;
        this.loginUrl = this.baseUrl + 'login';
        this.webUrl = this.baseUrl + 'dist';
    }
}
