import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ApplicationInsightsService } from 'core/application-insights.service';
import { PendoService } from 'core/pendo.service';

@Injectable()
export class AnalyticsService {
    private isRegistered: boolean;

    constructor(private router: Router, private applicationInsightsService: ApplicationInsightsService, private pendoService: PendoService) {}

    register() {
        if (this.isRegistered) {
            return;
        }

        this.pendoService.init();
        this.setupApplicationInsightsRouterSubscription();

        this.isRegistered = true;
    }

    private setupApplicationInsightsRouterSubscription() {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.applicationInsightsService.trackPageView();
            }
        });
    }
}
