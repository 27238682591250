import { QuickLink } from 'core/models/configuration.model';
import { SystemIcon } from 'core/models/system-icon.model';

export interface MenuItem {
    displayName: string;
    routerLink: string;
    iconName: string;
    pendingCount?: number;
}

export interface MenuItems {
    profileMenuItems: MenuItem[];
    navigationItems: MenuItem[];
    selectionMenuItems: MenuItem[];
    helpMenuItems: MenuItem[];
    managerMenuItems: MenuItem[];
    applicationFormMenuItems: MenuItem[];
}

export class NavMenuItem {
    id?: string = null;
    path?: string = null;
    active?: boolean = false;
    expanded?: boolean = false;
    hasApproval?: boolean = false;
    iconName?: string;
    quickLink?: QuickLink;
    hasDivider?: boolean = false;
    tooltip?: string;
    type: NavMenuType = NavMenuType.Link;
    count: number = 0;
    constructor(public displayText: string, public children: NavMenuItem[] = []) {}
}

export class MegaNavMenuItem extends NavMenuItem {
    groupConfigID: string = '';
    headerTitle?: string = '';
}

export class CartConfig {
    tempSelectionsCount: number = 0;
    isUpdateNotification: boolean = false;
    displayText: string;
    systemIcon: SystemIcon;
    isDiscontinue: boolean = false;
    isPostSelectionNextStep: boolean = false;
    showMenu: boolean = false;
    useOptOutText: boolean = false;
}

export enum NavMenuType {
    None = 0,
    Link = 1,
    QuickLinkInternal = 2,
    QuickLinkExternal = 3,
    QuickLinkSso = 4,
    QuickLinkDocument = 5,
    Action = 6,
    MoreLink = 7
}

export enum MenuType {
    None = 0,
    HelpAndSupport = 1,
    Profile = 2,
    QuickLink = 3
}
