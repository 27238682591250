import { Component, ContentChildren, QueryList } from '@angular/core';

import { ScrollService } from 'core/scroll.service';

import { AccordionItemComponent } from 'shared/components/accordion/accordion-item.component';

@Component({
    selector: 'accordion',
    templateUrl: 'accordion.component.html'
})
export class AccordionComponent {
    @ContentChildren(AccordionItemComponent) items: QueryList<AccordionItemComponent>;

    constructor(private scrollService: ScrollService) {}

    scrollToItem(targetItemID: string, expand: boolean) {
        const targetItem = this.items.find(item => item.id === targetItemID);
        targetItem.expanded = targetItem.expanded || expand;

        this.scrollService.scrollToElementID(targetItemID);
    }
}
