module.exports = function (urls) {
    var fontRegular = new FontFace('scheme_custom_font', 'url(' + urls[0] + ')', {
        style: 'normal',
        weight: 'normal'
    });

    var fontBold = new FontFace('scheme_custom_font', 'url(' + urls[1] + ')', {
        style: 'normal',
        weight: 'bold'
    });

    var fontItalic = new FontFace('scheme_custom_font', 'url(' + urls[2] + ')', {
        style: 'italic',
        weight: 'normal'
    });

    var fontBoldItalic = new FontFace('scheme_custom_font', 'url(' + urls[3] + ')', {
        style: 'italic',
        weight: 'bold'
    });

    document.fonts.add(fontRegular);
    document.fonts.add(fontBold);
    document.fonts.add(fontItalic);
    document.fonts.add(fontBoldItalic);

    fontRegular.load();
    fontBold.load();
    fontItalic.load();
    fontBoldItalic.load();
};
