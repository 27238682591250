import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ConfigurationService } from 'core/configuration.service';
import { EmployeeService } from 'core/employee.service';
import { AggregatedLanguage } from 'core/models/configuration.model';
import { SecurityService } from 'core/security.service';
import { SessionStorageService } from 'core/session-storage.service';
import { WindowService } from 'core/window.service';

@Component({
    selector: 'language-select',
    templateUrl: 'language-select.component.html'
})
export class LanguageSelectComponent implements OnInit {
    @ViewChild('languageSelectElement', { static: true }) languageSelectEl: ElementRef;

    @Input() autoFillContainer: boolean;

    supportedLanguages: AggregatedLanguage[];
    selectedLanguage: AggregatedLanguage;
    initAccessibilityFocus: boolean;

    constructor(
        private securityService: SecurityService,
        private windowService: WindowService,
        private configurationService: ConfigurationService,
        private sessionStorageService: SessionStorageService,
        private employeeService: EmployeeService
    ) {}

    ngOnInit() {
        this.supportedLanguages = this.configurationService.aggregatedLanguages;
        this.selectedLanguage = this.configurationService.selectedLanguage;

        this.initAccessibilityFocus = this.supportedLanguages.length > 1;
    }

    changeLanguage(currentValue: number) {
        const selectedLanguageID = currentValue;

        if (this.selectedLanguage.id === selectedLanguageID) {
            return;
        }

        this.selectedLanguage = this.supportedLanguages.find(sl => sl.id === selectedLanguageID);

        this.sessionStorageService.setItem('selectedLanguage', this.selectedLanguage);

        if (this.securityService.isLoggedIn()) {
            this.employeeService.updateSelectedLanguage(this.selectedLanguage.id).subscribe(() => {
                this.securityService.removeAccessToken();
                this.windowService.reloadPage();
            });
        } else {
            this.windowService.reloadPage();
        }
    }
}
