import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';

@Injectable()
export class TextService {
    rewardCentreText: RewardCentreText;

    constructor(private httpClient: ApiHttpClient) {}

    loadText(): Observable<boolean> {
        return this.httpClient.get<RewardCentreText>('/Configuration/LoadText').pipe(
            map(resp => {
                this.rewardCentreText = resp;
                return true;
            })
        );
    }

    getText(textKey: string, replacements?: (string | number)[]) {
        let value: string = 'key_' + textKey + '_missing';

        if (this.rewardCentreText.text[textKey]) {
            value = this.rewardCentreText.text[textKey];
        }

        if (replacements && replacements.length > 0) {
            replacements.forEach((repText, i) => {
                if (repText !== null) {
                    value = value.replace(`{${i}}`, repText.toString());
                }
            });
        }

        return value;
    }
}

export interface RewardCentreText {
    languageID: number;
    text: KeyValuePair[];
}

export interface KeyValuePair {
    key: string;
    value: string;
}
