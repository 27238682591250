import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'trustHtml' })
export class TrustHtmlPipe implements PipeTransform {
    constructor(private sanitizationService: DomSanitizer) {}

    transform(value: string): SafeHtml {
        return this.sanitizationService.bypassSecurityTrustHtml(value);
    }
}
