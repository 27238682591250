import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';

import { TooltipPosition } from 'shared/tooltip/tooltip.model';

@Component({
    selector: 'tooltip',
    templateUrl: './tooltip.component.html'
})
export class TooltipComponent implements AfterViewInit {
    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

    @Output() closeTooltip = new EventEmitter<void>();

    content: string = '';
    isMouseInside: boolean;
    styleClass: string;
    private _position: TooltipPosition;

    constructor(private changeDetectorRef: ChangeDetectorRef, private elementRef: ElementRef) {}

    @HostListener('mouseenter') onMouseEnter() {
        this.isMouseInside = true;
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.isMouseInside = false;
        this.hideTooltip();
    }

    ngAfterViewInit() {
        if (this.content !== '') {
            ((this.elementRef.nativeElement.firstElementChild as HTMLElement).firstElementChild as HTMLElement).setAttribute('tabIndex', '0');
        }

        (this.elementRef.nativeElement.firstElementChild as HTMLElement).focus();
    }

    set position(value: TooltipPosition) {
        this._position = value;
        this.setStyleClass();
        this.changeDetectorRef.detectChanges();
    }

    get position(): TooltipPosition {
        return this._position;
    }

    private hideTooltip() {
        this.closeTooltip.emit();
    }

    private setStyleClass() {
        switch (this.position) {
            case TooltipPosition.right:
                this.styleClass = 'arrow-right';
                break;
            case TooltipPosition.left:
                this.styleClass = 'arrow-left';
                break;
            case TooltipPosition.bottom:
                this.styleClass = 'arrow-bottom';
                break;
            case TooltipPosition.top:
                this.styleClass = 'arrow-top';
                break;
        }
    }
}
