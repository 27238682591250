import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApplicationService } from 'core/application.service';
import { CookieConsentService } from 'core/cookie-consent.service';
import { CustomDateTimeFormatOptions, DayFormatOptions, MonthFormatOptions, YearFormatOptions } from 'core/models/custom-date-time-format-options.model';
import { PolicyService } from 'core/policy.service';

@Component({
    selector: 'footer-details',
    templateUrl: 'footer.component.html',
    styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit, OnDestroy {
    darwinLogoUrl: string;
    currentYear: Date = new Date();
    dateFormatOptions: CustomDateTimeFormatOptions = {
        dayFormatOptions: DayFormatOptions.None,
        monthFormatOptions: MonthFormatOptions.None,
        yearFormatOptions: YearFormatOptions.Numeric
    };
    displayTrustArcComponents: boolean;

    private cookieConsentSubscription: Subscription = Subscription.EMPTY;

    constructor(private applicationService: ApplicationService, private policyService: PolicyService, private cookieConsentService: CookieConsentService) {}

    ngOnInit() {
        this.darwinLogoUrl = `${this.applicationService.baseUrl}dist/content/DarwinLogo.png`;

        this.cookieConsentSubscription = this.cookieConsentService.trustArcStateObservable.subscribe((state: boolean) => {
            this.displayTrustArcComponents = state;
        });
    }

    ngOnDestroy() {
        this.cookieConsentSubscription.unsubscribe();
    }

    showTermsAndConditions() {
        this.policyService.showTermsAndConditions();
    }

    showPrivacyPolicy() {
        this.policyService.showPrivacyPolicy();
    }

    showCookiesPolicy() {
        this.policyService.showCookiesPolicy();
    }

    showSecurityPolicy() {
        this.policyService.showSecurityPolicy();
    }
}
