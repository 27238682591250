import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { FileUploadModule } from 'shared/file-upload/file-upload.module';
import { InternalSharedModule } from 'shared/internal-shared.module';
import { MenuModule } from 'shared/menu/menu.module';
import { OverlayModule } from 'shared/overlay/overlay.module';
import { PdfViewerModule } from 'shared/pdf-viewer/pdf-viewer.module';
import { TableModule } from 'shared/table/table.module';

@NgModule({
    exports: [InternalSharedModule, MenuModule, OverlayModule, TableModule, FileUploadModule, PdfViewerModule],
    imports: [CommonModule, InternalSharedModule, MenuModule, OverlayModule, TableModule, FileUploadModule, PdfViewerModule]
})
export class SharedModule {
    static forRoot(fromRoot: boolean = false): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                OverlayModule.rootProviders(fromRoot),
                TableModule.rootProviders(),
                FileUploadModule.rootProviders(),
                PdfViewerModule.rootProviders(),
                MenuModule.rootProviders()
            ]
        };
    }
}
