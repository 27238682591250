import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ConfigurationService } from 'core/configuration.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';
import { SelectionBasic } from 'core/selection.model';

@Injectable()
export class TestingModeService {
    specifiedCurrentPeriodDate$: Observable<Date>;
    specifiedCurrentPeriodDate: Date;
    minDate: Date;
    private specifiedCurrentPeriodDateSubject: Subject<Date> = new Subject();

    constructor(private environmentConfigurationDataService: EnvironmentConfigurationDataService, private configurationService: ConfigurationService) {
        this.specifiedCurrentPeriodDate$ = this.specifiedCurrentPeriodDateSubject.asObservable();
    }

    get testingModeFeatureEnabled(): boolean {
        return (
            this.environmentConfigurationDataService.environmentConfigurationData.rewardCentreTestingEnabled &&
            this.configurationService.configuration.pageConfig.benefitManager.isEnabled
        );
    }

    get isInTestingMode(): boolean {
        return this.testingModeFeatureEnabled && !!this.specifiedCurrentPeriodDate;
    }

    setSpecifiedCurrentPeriodDate(specifiedCurrentPeriodDate: Date) {
        this.specifiedCurrentPeriodDate = specifiedCurrentPeriodDate;
        this.specifiedCurrentPeriodDateSubject.next(specifiedCurrentPeriodDate);
    }

    setMinDate(selection: SelectionBasic) {
        if (!this.minDate) {
            this.minDate =
                selection.benefitSummaries?.length > 0
                    ? selection.benefitSummaries.find(x => x.effectivePeriodStartDate).effectivePeriodStartDate
                    : new Date(Date.now());
        }
    }
}
