import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationService } from 'core/configuration.service';
import { LandingPage } from 'core/models/configuration.model';

@Component({
    selector: 'landing-page',
    template: ' '
})
export class LandingPageComponent implements OnInit {
    constructor(private configurationService: ConfigurationService, private router: Router) {}

    ngOnInit(): void {
        let nav = '';

        switch (this.configurationService.configuration.options.landingPage) {
            case LandingPage.Selection:
                nav = 'selection';
                break;
            case LandingPage.Reimbursements:
                nav = 'reimbursement';
                break;
            case LandingPage.TotalRewardStatement:
                nav = 'engager';
                break;
            default:
                nav = 'home';
                break;
        }

        this.configurationService.checkWelcomeOverlay();
        this.router.navigate([`/${nav}`]);
    }
}
