import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, share } from 'rxjs/operators';

import { ApplicationService } from 'core/application.service';
import { AccessTokenReturn, ErrorMessage, Token } from 'core/models/security.model';
import { TokenService } from 'core/token.service';

export interface IHttpClientOptions {
    headers: HttpHeaders;
    params: HttpParams;
}

export interface IHttpParamOptions {
    key: string;
    value: any;
}

export interface FileUploadModel {
    [prop: string]: string | File | Blob;
}

export enum ProgressState {
    None = 0,
    Started = 1,
    InProgress = 2,
    Completed = 3
}

export interface RequestProgressState {
    state: ProgressState;
    percentage: number;
}

@Injectable()
export class ApiHttpClient {
    accessToken: Token;

    private apiBaseUrl: string;
    private webUrl: string;
    private refreshObs$: Observable<boolean>;

    constructor(private http: HttpClient, private tokenService: TokenService, applicationService: ApplicationService) {
        this.apiBaseUrl = applicationService.baseUrl + 'api';
        this.webUrl = applicationService.webUrl;
    }

    unsecurePost<T>(url: string, model: any): Observable<T> {
        return this.http.post<T>(this.apiUrl(url), model, this.httpOptions());
    }

    unsecureGet<T>(url: string, params?: IHttpParamOptions[]): Observable<T> {
        const options = this.httpOptions();
        if (params) {
            options.params = new HttpParams();
            params.forEach(p => {
                options.params = options.params.append(p.key, p.value);
            });
        }

        return this.http.get<T>(this.apiUrl(url), options);
    }

    post<T>(url: string, model: any, params?: IHttpParamOptions[]): Observable<T> {
        return this.checkToken().pipe(
            mergeMap(() => {
                return this.http.post<T>(this.apiUrl(url), model, this.getSecureHttpOptionsWithParams(params));
            })
        );
    }

    uploadFile(url: string, model: FileUploadModel, reportProgress: boolean = false) {
        return this.checkToken().pipe(
            mergeMap(() => {
                const formData: FormData = new FormData();
                Object.keys(model).forEach(key => {
                    formData.append(key, model[key]);
                });

                const options = <IHttpClientOptions>{
                    headers: new HttpHeaders({
                        'X-Requested-With': 'XMLHttpRequest',
                        ...this.tokenHeader()
                    })
                };

                if (reportProgress) {
                    Object.assign(options, { reportProgress: true, observe: 'events' });
                }

                return this.http.post(this.apiUrl(url), formData, options);
            }),
            map((evt: HttpEvent<ArrayBuffer>) => {
                if (!reportProgress) {
                    return {
                        state: ProgressState.Completed,
                        percentage: 100
                    };
                }

                switch (evt.type) {
                    case HttpEventType.Sent:
                        return {
                            state: ProgressState.Started,
                            percentage: 0
                        };
                    case HttpEventType.UploadProgress:
                        return {
                            state: ProgressState.InProgress,
                            percentage: evt.total ? Math.round((100 * evt.loaded) / evt.total) : 0
                        };
                    case HttpEventType.Response:
                        return {
                            state: ProgressState.Completed,
                            percentage: 100
                        };
                    default:
                        return {
                            percentage: 0,
                            state: ProgressState.None
                        };
                }
            })
        );
    }

    downloadFile(url: string, model: any, fileName: string): Observable<Blob> {
        function getContentType() {
            const ext = fileName.split('.').pop().toLowerCase();
            switch (ext) {
                case 'png':
                    return 'image/png';
                case 'jpg':
                case 'jpeg':
                    return 'image/jpeg';
                case 'gif':
                    return 'image/gif';
                case 'mp4':
                    return 'video/mp4';
                case 'pdf':
                    return 'application/pdf';
                default:
                    return '';
            }
        }

        return this.checkToken().pipe(
            mergeMap(() => {
                const headers = new HttpHeaders({
                    ...this.jsonHeaders(),
                    ...this.tokenHeader()
                });

                return this.http
                    .post(this.apiUrl(url), model, { headers, observe: 'response', responseType: 'blob' })
                    .pipe(map(response => new Blob([response.body], { type: getContentType() })));
            })
        );
    }

    downloadFileAndOpenLink(url: string, model: any, fileName: string): Observable<Blob> {
        return this.downloadFile(url, model, fileName).pipe(
            map(fileBlob => {
                const link: HTMLAnchorElement = document.createElement('a');
                link.href = window.URL.createObjectURL(fileBlob);
                document.body.appendChild(link);
                link['download'] = fileName!;
                link.click();
                document.body.removeChild(link);

                return fileBlob;
            })
        );
    }

    get<T>(url: string, params?: IHttpParamOptions[], dontRefreshIfExpired: boolean = false): Observable<T> {
        return this.checkToken(dontRefreshIfExpired).pipe(
            mergeMap(() => {
                return this.http.get<T>(this.apiUrl(url), this.getSecureHttpOptionsWithParams(params));
            })
        );
    }

    getOptionalSecure<T>(url: string): Observable<T> {
        const options = this.secureHttpOptions();

        return this.http.get<T>(this.apiUrl(url), options);
    }

    getApiResource(url: string): Observable<HttpResponse<string>> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/css',
            'X-Requested-With': 'XMLHttpRequest'
        });

        return this.http.get(this.apiUrl(url), { headers: headers, observe: 'response', responseType: 'text' });
    }

    secureGetApiResource(url: string): Observable<HttpResponse<string>> {
        return this.checkToken().pipe(
            mergeMap(() => {
                const options = this.secureHttpOptions();
                options.headers.append('Content-Type', 'application/css');
                options.headers.append('X-Requested-With', 'XMLHttpRequest');

                return this.http.get(this.apiUrl(url), { headers: options.headers, observe: 'response', responseType: 'text' });
            })
        );
    }

    getWebResource(url: string): Observable<HttpResponse<string>> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/text',
            'X-Requested-With': 'XMLHttpRequest'
        });

        return this.http.get(this.webUrl + url, { headers: headers, observe: 'response', responseType: 'text' });
    }

    /**
     * Downloads an image from the server
     *
     * Please use URL.createObjectURL(`blobObject`) to create a URL representing the image.
     * When the image is no longer used in the UI, please make sure to remove it by calling URL.revokeObjectURL(`urlPreviouslyCreated`).
     *
     * @param {string} imageUrl the image Url
     * @return an `Observable` of the Blob object
     */
    getWebMediaFile(fileUrl: string): Observable<Blob> {
        if (!fileUrl.startsWith('/ContentC/')) {
            return this.checkToken().pipe(mergeMap(() => this.getFile(fileUrl)));
        } else {
            return this.getFile(fileUrl);
        }
    }

    getFromThirdParty(url: string, responseType?: 'json'): Observable<Response>;
    getFromThirdParty(url: string, responseType: 'text'): Observable<string>;
    getFromThirdParty(url: string, responseType: 'text' | 'json') {
        if (responseType === 'text') {
            const headers = new HttpHeaders({
                'Content-Type': 'application/text',
                'X-Requested-With': 'XMLHttpRequest'
            });

            return this.http.get(url, { headers: headers, responseType: 'text' });
        } else {
            return this.http.get<Response>(url);
        }
    }

    private getFile(fileUrl: string): Observable<Blob> {
        const headers = new HttpHeaders({
            ...{
                'X-Requested-With': 'XMLHttpRequest'
            },
            ...this.tokenHeader()
        });

        const url = /^https?:\/\//.test(fileUrl) ? fileUrl : this.apiUrl(fileUrl);

        return <Observable<Blob>>this.http.get(url, { headers, observe: 'response', responseType: 'blob' }).pipe(
            map(response => response.body),
            catchError(() => of())
        );
    }

    private checkToken(dontRefreshIfExpired: boolean = false): Observable<boolean> {
        this.accessToken = this.tokenService.getAccessToken();

        if (!this.accessToken) {
            if (dontRefreshIfExpired) {
                return throwError(ErrorMessage.AccessTokenExpired);
            }

            if (this.refreshObs$) {
                return this.refreshObs$;
            }
            this.refreshObs$ = this.unsecurePost<AccessTokenReturn>('/Security/Refresh', this.tokenService.getRefreshToken())
                .pipe(
                    map(data => {
                        this.tokenService.saveTokens(data.accessToken, data.refreshToken);
                        this.accessToken = this.tokenService.getAccessToken();
                        this.refreshObs$ = null;
                        return true;
                    })
                )
                .pipe(share());
            return this.refreshObs$;
        }

        return of(true);
    }

    private httpOptions() {
        const options = <IHttpClientOptions>{
            headers: new HttpHeaders({
                ...this.jsonHeaders()
            })
        };

        return options;
    }

    private secureHttpOptions() {
        const options = <IHttpClientOptions>{
            headers: new HttpHeaders({
                ...this.jsonHeaders(),
                ...this.tokenHeader()
            })
        };

        return options;
    }

    private jsonHeaders() {
        return {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        };
    }

    private tokenHeader() {
        if (this.accessToken) {
            return { Authorization: `Bearer ${this.accessToken.value}` };
        }
        return {};
    }

    private apiUrl(url: string): string {
        return `${this.apiBaseUrl}${url}`;
    }

    private getSecureHttpOptionsWithParams(params?: IHttpParamOptions[]) {
        const options = this.secureHttpOptions();

        if (params) {
            options.params = new HttpParams();
            params.forEach(p => {
                options.params = options.params.append(p.key, p.value);
            });
        }

        return options;
    }
}
