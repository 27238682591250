<div class="outer-container" #outerContainer>
    <div *ngIf="showMenu" class="navigation-menu" [class.inverted]="invertedNavigationBarColor">
        <div [ngStyle]="{ 'max-height': maxHeight ? maxHeight + 'px' : 'unset', 'overflow-y': overflowStyle }">
            <h2 *ngIf="title" class="menu-title heading-18-b">
                {{ title }}
            </h2>
            <ul id="{{ id }}" [attr.aria-label]="title">
                <ng-container *ngFor="let navMenuItem of navMenuItems; index as i">
                    <li
                        class="menu-item"
                        [attr.aria-current]="navMenuItem.active"
                        [class.has-icon]="navMenuItem.iconName"
                        [class.divider]="navMenuItem.hasDivider">
                        <a
                            class="link"
                            href="javascript:void(0);"
                            (click)="triggerMenuAction(navMenuItem)"
                            [tooltip]="navMenuItem?.tooltip"
                            [tooltipPosition]="TooltipPosition.top">
                            <span class="icon-container" *ngIf="navMenuItem.iconName">
                                <icon iconId="{{ navMenuItem.iconName }}" class="icon-md"></icon>
                            </span>
                            <p [ngClass]="navMenuItem.active ? 'label-14-b' : 'label-14-r'">{{ navMenuItem.displayText }}</p>
                            <div class="count-container">
                                <span *ngIf="navMenuItem.count > 0" class="count-menu label-11-r">
                                    {{ navMenuItem.count }}
                                </span>
                            </div>
                        </a>
                    </li>
                </ng-container>
                <ng-container [ngTemplateOutlet]="itemTemplateRef"> </ng-container>
            </ul>
        </div>
    </div>
</div>
