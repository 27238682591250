export enum WeekDayFormatOptions {
    None = '',
    Short = 'short',
    Long = 'long'
}

export enum DayFormatOptions {
    None = '',
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum MonthFormatOptions {
    None = '',
    Numeric = 'numeric',
    TwoDigit = '2-digit',
    Short = 'short',
    Long = 'long'
}

export enum YearFormatOptions {
    None = '',
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum SecondFormatOptions {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum MinuteFormatOptions {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum HourFormatOptions {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export interface CustomDateTimeFormatOptions {
    weekdayFormatOptions?: WeekDayFormatOptions;
    dayFormatOptions?: DayFormatOptions;
    monthFormatOptions?: MonthFormatOptions;
    yearFormatOptions?: YearFormatOptions;
    secondFormatOptions?: SecondFormatOptions;
    minuteFormatOptions?: MinuteFormatOptions;
    hourFormatOptions?: HourFormatOptions;
}
