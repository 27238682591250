import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiHttpClient } from 'core/api-http-client';
import { Guid } from 'core/extensions/guid-extension';
import { SecurityService } from 'core/security.service';
import { WindowService } from 'core/window.service';

import { ContentOverlayComponent } from 'shared/components/content-overlay/content-overlay.component';
import {
    CardActionLinkType,
    ContentDocumentNode,
    ContentLinkNode,
    ContentLinkType,
    ContentMultiContentNode,
    ContentNodeItemType,
    ContentVideoNode,
    IContentNode
} from 'shared/components/content-renderer/content-renderer.model';
import { OverlayService } from 'shared/overlay/overlay.service';

@Injectable()
export class ContentService {
    private overlayID: string;

    constructor(
        private router: Router,
        private windowService: WindowService,
        private securityService: SecurityService,
        private overlayService: OverlayService,
        private httpClient: ApiHttpClient
    ) {}

    setIconIdForClickableCards(contentNode: IContentNode, ariaLabel): void {
        if (contentNode.nodeItemType === ContentNodeItemType.Link) {
            const linkContentNode = contentNode as ContentLinkNode;
            linkContentNode._iconId = this.getContentIconId(linkContentNode.contentLinkType);
            linkContentNode._clickable = true;
            linkContentNode._ariaLabel = ariaLabel;
            linkContentNode._actionLinkText =
                linkContentNode.contentLinkType === ContentLinkType.RedirectInternal ? 'Global_Action_FindOutMore' : 'Global_Action_VisitSite';
        } else if (contentNode.nodeItemType === ContentNodeItemType.Document) {
            const documentContentNode = contentNode as ContentDocumentNode;
            documentContentNode._iconId = 'download';
            documentContentNode._clickable = true;
            documentContentNode._ariaLabel = ariaLabel;
            documentContentNode._actionLinkText = 'Global_Action_DownloadDocumentName';
        } else if (contentNode.nodeItemType === ContentNodeItemType.MultiContent) {
            const multiContentNode = contentNode as ContentMultiContentNode;
            multiContentNode._iconId = 'bring-to-front';
            multiContentNode._clickable = true;
            multiContentNode._ariaLabel = ariaLabel;
            multiContentNode._actionLinkText =
                multiContentNode.actionLinkType === CardActionLinkType.ReadMore ? 'Global_Action_ReadMore' : 'HubCard_Action_Read';

            multiContentNode.contentNodes.forEach(contentItem => {
                if (contentItem.nodeItemType === ContentNodeItemType.Link) {
                    const contentLinkItem = contentItem as ContentLinkNode;
                    contentLinkItem._iconId = this.getContentIconId(contentLinkItem.contentLinkType);
                }
            });
        } else if (contentNode.nodeItemType === ContentNodeItemType.Video) {
            const multiContentNode = contentNode as ContentMultiContentNode;
            multiContentNode._iconId = 'play-video';
            multiContentNode._ariaLabel = ariaLabel;
            multiContentNode._clickable = true;
        }
    }

    displayInformation(contentNode: IContentNode, ariaLabel?: string): void {
        if (contentNode.nodeItemType == ContentNodeItemType.Link) {
            const linkNode: ContentLinkNode = contentNode as ContentLinkNode;
            switch (linkNode.contentLinkType) {
                case ContentLinkType.RedirectInternal:
                    if (this.overlayID) {
                        this.overlayService.closeOverlay(this.overlayID);
                    }

                    this.router.navigate([linkNode.url]);
                    break;
                case ContentLinkType.RedirectExternal:
                    this.windowService.navigateTo(linkNode.url, linkNode.openInNewWindow);
                    break;
                case ContentLinkType.OutboundSso:
                    this.securityService.outboundSso(linkNode.serviceProviderID);
                    break;
            }
        } else if (contentNode.nodeItemType == ContentNodeItemType.MultiContent) {
            this.overlayID = Guid.newGuid();
            this.overlayService.open(ContentOverlayComponent, { content: contentNode }, { overlayID: this.overlayID, ariaLabel: ariaLabel });
        } else if (contentNode.nodeItemType === ContentNodeItemType.Document) {
            const contentDoc = contentNode as ContentDocumentNode;
            this.onFileDownload(contentDoc.file.filePath, contentDoc.fileName);
        } else if (contentNode.nodeItemType === ContentNodeItemType.Video) {
            const videoContentNode = contentNode as ContentVideoNode;
            this.overlayService.openVideoOverlay(videoContentNode.file, videoContentNode.fileName);
        }
    }

    private onFileDownload(filePath: string, fileName: string) {
        this.httpClient.getWebMediaFile(filePath).subscribe(response => {
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            document.body.appendChild(link);
            link['download'] = fileName!;
            link.click();
            document.body.removeChild(link);
        });
    }

    private getContentIconId(contentLinkType: ContentLinkType): string {
        if (contentLinkType === ContentLinkType.RedirectExternal || contentLinkType === ContentLinkType.OutboundSso) {
            return 'external-redirect';
        }

        return 'right';
    }
}
