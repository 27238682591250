import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ApiHttpClient } from 'core/api-http-client';
import { Account } from 'core/models/reimbursement.model';

@Injectable()
export class FundingAccountService {
    constructor(private httpClient: ApiHttpClient, private router: Router) {}

    getFundingAccount(accountID: string): Observable<Account[]> {
        if (this.router.url.startsWith('/reimbursement')) {
            return this.getFundingAccountForReimbursement(accountID);
        } else {
            return this.getFundingAccountForHome(accountID);
        }
    }

    private getFundingAccountForHome(accountID: string): Observable<Account[]> {
        return this.httpClient.get<Account[]>('/FundingAccount/GetFundingAccountForHome', [{ key: 'fundingAccountID', value: accountID }]);
    }

    private getFundingAccountForReimbursement(accountID: string): Observable<Account[]> {
        return this.httpClient.get<Account[]>('/FundingAccount/GetFundingAccountForReimbursement', [{ key: 'fundingAccountID', value: accountID }]);
    }
}
