import { ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { TooltipComponent } from 'shared/tooltip/tooltip.component';
import { TooltipPortalData, TooltipPosition } from 'shared/tooltip/tooltip.model';

@Injectable()
export class TooltipService {
    overlayRef: OverlayRef;
    componentInstance: TooltipComponent;

    constructor(private overlay: Overlay) {}

    open(portalData: TooltipPortalData) {
        this.close();

        this.overlayRef = this.overlay.create({
            hasBackdrop: false,
            positionStrategy: this.overlay.position().flexibleConnectedTo(portalData.elementRef),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });

        this.tooltipPosition(portalData.position).positionChanges.subscribe(value => {
            if (value.connectionPair.originY === 'bottom') {
                portalInstance.position = TooltipPosition.bottom;
            } else if (value.connectionPair.originY === 'top') {
                portalInstance.position = TooltipPosition.top;
            } else if (value.connectionPair.originX === 'start') {
                portalInstance.position = TooltipPosition.right;
            } else if (value.connectionPair.originX === 'end') {
                portalInstance.position = TooltipPosition.left;
            }
        });

        const { instance: portalInstance } = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
        Object.assign(portalInstance);

        if (typeof portalData.content !== 'string') {
            const { instance: componentInstance } = portalInstance.portalOutlet.attach(new ComponentPortal(portalData.content));
            Object.assign(componentInstance, portalData.inputs);
        } else {
            portalInstance.content = portalData.content;
        }

        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.dispose();
        });

        portalInstance.closeTooltip.subscribe(() => {
            this.close();
        });

        this.componentInstance = portalInstance;
    }

    close() {
        if (this.overlayRef && this.overlayRef.overlayElement) {
            this.overlayRef.dispose();
        }
    }

    private tooltipPosition(position: TooltipPosition): FlexibleConnectedPositionStrategy {
        const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

        const connectedPositions: ConnectedPosition[] = this.getConnectedPositions(position);

        positionStrategy.withPositions(connectedPositions).withViewportMargin(8);

        return positionStrategy;
    }

    private getConnectedPositions(position: TooltipPosition): ConnectedPosition[] {
        const positions: ConnectedPosition[] = [];

        switch (position) {
            case TooltipPosition.left:
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                break;
            case TooltipPosition.right:
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                break;
            case TooltipPosition.top:
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                break;
            case TooltipPosition.bottom:
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                break;
        }

        return positions;
    }
}
