<div
    id="navigation-menu"
    role="navigation"
    class="desktop-menu-container"
    [ngClass]="{ inverted: invertedNavigationBarColor }"
    [attr.aria-label]="'Header_NavigationMenu' | appText">
    <ul class="nav-items" #navItems>
        <li *ngFor="let item of mainItems" class="nav-item main-item" [routerLinkActive]="['active-link']">
            <a href="javascript:void(0)" [routerLink]="[item.routerLink]" [routerLinkActive]="['label-14-b']">
                {{ item.displayName }}
            </a>
        </li>
        <li
            id="mega-menu-items-nav-menu"
            *ngIf="showMoreMenuItem"
            class="nav-item mega-menu-items"
            [class.active]="isMoreItemSelected"
            (focusout)="hideMegaMenu($event)"
            (mouseleave)="hideMegaMenu($event)">
            <a
                href="javascript:void(0)"
                (click)="isMegaMenuOpen = !isMegaMenuOpen"
                (mouseenter)="openMegaMenu()"
                id="mega-menu-items-button"
                aria-controls="mega-menu"
                [attr.aria-haspopup]="true"
                [attr.aria-expanded]="isMegaMenuOpen">
                {{ 'Global_More' | appText }}
                <icon iconId="down" class="icon-xxs down-icon"></icon>
            </a>
            <ng-container *ngIf="isMegaMenuLoaded">
                <mega-menu
                    [(showMenu)]="isMegaMenuOpen"
                    [bannerUrl]="bannerUrl"
                    [invertedNavigationBarColor]="invertedNavigationBarColor"
                    [quickLinkGroups]="navigationMenu.quickLinkGroups"
                    [moreMenuItems]="moreMenuItems"></mega-menu>
            </ng-container>
        </li>
    </ul>
</div>
