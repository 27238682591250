<div tabindex="0" class="menu" focus-trap>
    <ng-container *ngFor="let menuItem of menuItems">
        <div class="menu-action-container" *ngIf="menuItem.type === 'action'">
            <button class="btn-transparent btn-icon-left" (click)="executeFunction(menuItem.action)">
                <icon *ngIf="menuItem.action.iconID" class="icon-md" iconId="{{ menuItem.action.iconID }}"></icon>
                <p class="paragraph-16-r">{{ menuItem.action.label }}</p>
            </button>
        </div>
        <ng-container *ngIf="menuItem.type === 'listItem'">
            <div tabindex="0" class="menu-list-item-header label-14-r">{{ menuItem.listItem.header }}</div>
            <div class="quick-action-container" *ngFor="let action of menuItem.listItem.actions">
                <button class="btn-transparent btn-icon-left" (click)="executeFunction(action)">
                    <icon *ngIf="action.iconID" class="icon-md" iconId="{{ action.iconID }}"></icon>
                    <p class="paragraph-16-r">{{ action.label }}</p>
                </button>
            </div>
        </ng-container>
    </ng-container>
</div>
