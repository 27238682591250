import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApplicationGuard } from 'core/application.guard';
import { ApplicationLoadResolver } from 'core/Application.resolver';
import { CoreModule } from 'core/core.module';
import { LayoutModule } from 'core/layout/layout.module';

import { SharedModule } from 'shared/shared.module';

import { AppComponent } from 'darwin/rc/app-component';
import { AppRoutingModule } from 'darwin/rc/app-routing-module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode'
        }),
        HttpClientModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        LayoutModule,
        HammerModule
    ],
    bootstrap: [AppComponent],
    providers: [{ provide: 'BASE_URL', useFactory: getBaseUrl }, ApplicationGuard, ApplicationLoadResolver]
})
export class AppModule {}

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}
