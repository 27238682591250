import { ConnectedPosition, FlexibleConnectedPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { race } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuComponent } from 'shared/menu/menu.component';
import { MenuPortalData, MenuPosition } from 'shared/menu/menu.model';

@Injectable()
export class MenuService {
    overlayRef: OverlayRef;

    constructor(private overlay: Overlay) {}

    open(portalData: MenuPortalData) {
        this.close();

        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: this.overlay.position().flexibleConnectedTo(portalData.elementRef),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });

        this.menuPosition(portalData.position);

        const { instance: portalInstance } = this.overlayRef.attach(new ComponentPortal(MenuComponent));
        portalInstance.menuItems = portalData.menuItems;

        return race([this.overlayRef.backdropClick(), portalInstance.closeMenu]).pipe(
            map(res => {
                this.close();

                if (typeof res === 'string') {
                    return res;
                } else {
                    return '';
                }
            })
        );
    }

    close() {
        if (this.overlayRef && this.overlayRef.overlayElement) {
            this.overlayRef.dispose();
        }
    }

    private menuPosition(position: MenuPosition): void {
        const positionStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

        const connectedPositions: ConnectedPosition[] = this.getConnectedPositions(position);

        positionStrategy.withPositions(connectedPositions).withViewportMargin(8);
    }

    private getConnectedPositions(position: MenuPosition): ConnectedPosition[] {
        const positions: ConnectedPosition[] = [];

        switch (position) {
            case MenuPosition.left:
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                break;
            case MenuPosition.right:
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                break;
            case MenuPosition.top:
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                break;
            case MenuPosition.bottom:
                positions.push({ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' } as ConnectedPosition);
                positions.push({ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' } as ConnectedPosition);
                positions.push({ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' } as ConnectedPosition);
                positions.push({ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' } as ConnectedPosition);
                break;
        }

        return positions;
    }
}
