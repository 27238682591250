import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApplicationService } from 'core/application.service';
import { ConfigurationService } from 'core/configuration.service';
import { CookieConsentService } from 'core/cookie-consent.service';
import { EmployeeService } from 'core/employee.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';
import { AggregatedLanguage } from 'core/models/configuration.model';
import { AdminDetail, EmployeeBasic } from 'core/models/employee.model';
import { SecurityService } from 'core/security.service';

@Injectable()
export class PendoService implements OnDestroy {
    private cookieConsentSubscriptions: Subscription = Subscription.EMPTY;
    private allowAnalyticsCookies = false;
    private isPendoReady = false;

    private get pendo() {
        return (window as any).pendo;
    }

    constructor(
        private configurationService: ConfigurationService,
        private environmentConfigurationDataService: EnvironmentConfigurationDataService,
        private employeeService: EmployeeService,
        private cookieConsentService: CookieConsentService,
        private applicationService: ApplicationService,
        private securityService: SecurityService
    ) {}

    init() {
        if (!this.environmentConfigurationDataService.environmentConfigurationData.pendoID) {
            return;
        }

        this.initCookieConsentSubscriptions();
    }

    ngOnDestroy() {
        this.cookieConsentSubscriptions.unsubscribe();
    }

    trackExternalLink(url, currentPagePath) {
        if (this.eventTrackingDisabled()) {
            return;
        }

        const pendoVisitor = this.visitor();

        this.pendo.track('externalLink', <pendoExternalLink>{
            url: url,
            currentPagePath: currentPagePath,
            id: pendoVisitor.id,
            schemeID: pendoVisitor.schemeID,
            organisationID: pendoVisitor.organisationID,
            languageID: pendoVisitor.languageID,
            administratorID: pendoVisitor.administratorID,
            environmentName: pendoVisitor.environmentName
        });
    }

    trackCardClick(cardType: string, cardName: string, cardLinkType: string, currentPagePath: string, url?: string) {
        if (this.eventTrackingDisabled()) {
            return;
        }

        const pendoVisitor = this.visitor();

        this.pendo.track('cardClick', <pendoCardClick>{
            id: pendoVisitor.id,
            schemeID: pendoVisitor.schemeID,
            organisationID: pendoVisitor.organisationID,
            countryID: pendoVisitor.countryID,
            languageID: pendoVisitor.languageID,
            administratorID: pendoVisitor.administratorID,
            currentPagePath: currentPagePath,
            cardName: cardName,
            cardType: cardType,
            cardLinkType: cardLinkType,
            url: url,
            environmentName: pendoVisitor.environmentName
        });
    }

    private initCookieConsentSubscriptions() {
        this.cookieConsentSubscriptions = this.cookieConsentService.allowAnalyticObservable.subscribe((state: boolean) => {
            this.allowAnalyticsCookies = state;

            if (state && !this.isPendoReady) {
                this.initialisePendo();
            } else if (!state && this.isPendoReady) {
                this.reloadPage();
            }
        });
    }

    private initialisePendo() {
        const pendoScript: Function = window['pendoScript'];

        pendoScript(this.environmentConfigurationDataService.environmentConfigurationData.pendoID);

        const pendoVisitor = this.visitor();

        this.pendo.initialize({
            visitor: pendoVisitor,
            disableCookies: true,
            disablePersistence: true,
            events: {
                ready: () => {
                    this.isPendoReady = true;
                }
            }
        });
    }

    private reloadPage() {
        this.securityService.removeAccessToken();
        window.location.reload();
    }

    private eventTrackingDisabled() {
        return !this.isPendoReady || !this.allowAnalyticsCookies;
    }

    private visitor(): pendoVisitorBase {
        const proxyAdmin: AdminDetail = this.employeeService.proxyAdmin;
        const selectedLanguage: AggregatedLanguage = this.configurationService.selectedLanguage;
        const employee: EmployeeBasic = this.employeeService.employee;
        const environmentName: string = this.environmentConfigurationDataService.environmentConfigurationData.environmentName;

        return <pendoVisitorBase>{
            id: employee.id,
            schemeID: employee.schemeID,
            countryID: employee.countryID,
            organisationID: employee.organisationID,
            languageID: selectedLanguage.id,
            administratorID: proxyAdmin?.adminID,
            environmentName: environmentName
        };
    }
}

interface pendoVisitorBase {
    id: string;
    schemeID: string;
    countryID: string;
    organisationID: string;
    languageID: number;
    administratorID?: string;
    environmentName: string;
}

interface pendoExternalLink extends pendoVisitorBase {
    url: string;
    currentPagePath: string;
}

interface pendoCardClick extends pendoVisitorBase {
    currentPagePath: string;
    cardName: string;
    cardType: string;
    cardLinkType: string;
    url: string;
}
