export interface MessageBase {
    title?: Title;
    property?: string;
    messageType: ValidationRuleType;
    replacements?: ValidationErrorReplacement[];
    effectiveDate?: Date;
    calculationID?: string;
}

export class ValidationMessage implements MessageBase {
    title?: Title;
    property?: string;
    messageType: ValidationRuleType;
    replacements?: ValidationErrorReplacement[];
    effectiveDate?: Date;
    calculationID?: string;
    message: string;
}

export class Message implements MessageBase {
    title?: Title;
    property?: string;
    messageType: ValidationRuleType;
    replacements?: ValidationErrorReplacement[];
    effectiveDate?: Date;
    calculationID?: string;
    text: string;
}

export enum ValidationRuleType {
    error = 1,
    warning = 2,
    information = 3,
    success = 4
}

export class ValidationErrorReplacement {
    key: string;
    value: any;
}

export interface Title {
    key: string;
    replacements: string[];
}

export enum IconSize {
    Lg = 'icon-lg',
    Md = 'icon-md'
}
