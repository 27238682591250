import { Injectable } from '@angular/core';

import { EmitEvent, EventService, EventType } from 'core/event.service';
import { SessionStorageService } from 'core/session-storage.service';
import { WindowService } from 'core/window.service';

@Injectable()
export class PrivacyService {
    inPrivateModeFlag: boolean = true;
    isMobileDevice: boolean;

    constructor(private windowService: WindowService, private sessionStorageService: SessionStorageService, private eventService: EventService) {}

    init(defaultPrivacyValue: boolean) {
        this.isMobileDevice = this.windowService.isMobileDevice();

        const privacyValue = this.isMobileDevice ? false : defaultPrivacyValue;

        this.setupPrivacyForDevice(false);
        this.setPrivacy(privacyValue);

        this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe(() => {
            this.isMobileDevice = this.windowService.isMobileDevice();
            this.setupPrivacyForDevice(true);
        });
    }

    setPrivacy(value: boolean) {
        if (this.inPrivateModeFlag !== value) {
            this.inPrivateModeFlag = value;

            this.eventService.triggerEvent(new EmitEvent(EventType.PrivacyToggle, this.inPrivateModeFlag));

            if (!this.isMobileDevice) {
                this.sessionStorageService.setItem('inPrivateMode', value);
            }
        }
    }

    private setupPrivacyForDevice(triggerEvent: boolean) {
        let flag = this.inPrivateModeFlag;

        if (!this.isMobileDevice) {
            const sessionStorageItem = this.sessionStorageService.getItem('inPrivateMode');

            if (sessionStorageItem !== null) {
                flag = sessionStorageItem;
            }
        } else {
            flag = false;
        }

        if (triggerEvent) {
            this.setPrivacy(flag);
        }
    }
}
