<div class="menu-container" [ngClass]="{ inverted: invertedNavigationBarColor }" [class.show]="showMenu">
    <nav role="navigation" class="nav" *ngIf="isLoaded">
        <ul id="mobile-tablet-menu" [attr.aria-label]="'Global_Menu_Label' | appText" class="menu-items overlay">
            <li class="employee-name heading-18-b">
                <span class="main-text">{{ employee | formatName }}</span>
            </li>
            <li *ngFor="let mainMenuItem of mainMenuItems" role="menuitem" class="menu-item" [ngClass]="{ 'menu-item-expandable': !mainMenuItem.path }">
                <ng-container *ngIf="mainMenuItem.path; else noPath">
                    <a
                        class="main-menu-item"
                        (click)="triggerLinkAction(mainMenuItem)"
                        ariaCurrentWhenActive="page"
                        [routerLinkActive]="'label-14-b'"
                        [routerLink]="mainMenuItem.path"
                        href="javascript:void(0)">
                        <div>
                            <span>{{ mainMenuItem.displayText }}</span>
                        </div>
                    </a>
                </ng-container>
                <ng-template #noPath>
                    <div class="divider"></div>
                    <div
                        role="button"
                        tabindex="0"
                        class="main-menu-item"
                        [ngClass]="{
                            'label-14-b': mainMenuItem.active,
                            'label-14-r': !mainMenuItem.active
                        }"
                        (click)="mainMenuItem.active = !mainMenuItem.active"
                        (keydown.enter)="mainMenuItem.active = !mainMenuItem.active"
                        aria-haspopup="true"
                        [attr.aria-expanded]="mainMenuItem.active">
                        <div>
                            <span>{{ mainMenuItem.displayText }}</span>
                        </div>
                        <span *ngIf="mainMenuItem.hasApproval && mainMenuItem.count > 0" class="task-to-complete-count-menu label-11-r">
                            {{ mainMenuItem.count }}
                        </span>
                        <span [@rotateBlob]="!mainMenuItem.active" class="expand-collapse-icon">
                            <icon class="icon-md" iconId="right"></icon>
                        </span>
                    </div>
                    <ul
                        class="sub-menu-items fade-in"
                        [id]="mainMenuItem.displayText"
                        [@expandCollapse]="mainMenuItem.active"
                        [ngClass]="{ expanded: mainMenuItem.active }">
                        <li *ngFor="let subMenuItem of mainMenuItem.children" class="sub-menu-item" role="menuitem">
                            <div class="sub-menu-item-parent-link">
                                <div class="sub-menu-container" (click)="mainMenuItem.active = true" aria-haspopup="false" tabindex="-1">
                                    <a
                                        class="sub-menu-link"
                                        [class.link]="subMenuItem.type === NavMenuType.Link"
                                        (click)="triggerLinkAction(subMenuItem)"
                                        [routerLinkActive]="'label-14-b'"
                                        [routerLink]="subMenuItem.path"
                                        [routerLinkActiveOptions]="{
                                            exact: !subMenuItem.path?.startsWith('selection') && !subMenuItem.path?.startsWith('reimbursement')
                                        }"
                                        ariaCurrentWhenActive="page"
                                        href="javascript:void(0)">
                                        <ng-container *ngIf="subMenuItem.type === NavMenuType.QuickLinkExternal || subMenuItem.iconName">
                                            <favicon
                                                [siteUrl]="subMenuItem.path"
                                                [overrideIcon]="subMenuItem.iconName"
                                                [outline]="subMenuItem.type !== NavMenuType.Link"
                                                [inverted]="invertedNavigationBarColor"></favicon>
                                        </ng-container>
                                        <div class="title-description-and-right-icon">
                                            <div class="title-and-icon">
                                                <span class="text" [class.label-14-b]="subMenuItem.active"> {{ subMenuItem.displayText }}</span
                                                ><span class="icon">
                                                    &nbsp;<icon
                                                        class="menu-icon link-icon {{ iconNameList[subMenuItem.type][0] }} {{
                                                            iconNameList[subMenuItem.type][1]
                                                        }}"
                                                        [class.brand-colour]="subMenuItem.type !== NavMenuType.Link"
                                                        iconId="{{ iconNameList[subMenuItem.type][1] }}"></icon>
                                                </span>
                                            </div>
                                            <div class="description paragraph-12-r" *ngIf="subMenuItem.tooltip?.length > 0">
                                                {{ subMenuItem.tooltip }}
                                            </div>
                                        </div>
                                    </a>
                                    <span
                                        *ngIf="subMenuItem.hasApproval && totalPendingCountForManagerApproval > 0"
                                        class="task-to-complete-count-submenu label-11-r">
                                        {{ totalPendingCountForManagerApproval }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ng-template>
            </li>
            <li role="none" class="menu-item">
                <div class="divider"></div>
            </li>
            <li class="menu-item language-and-log-out" role="menuitem">
                <div class="language-container" *ngIf="hasMultiLanguage">
                    <darwin-select
                        id="languageDropDown"
                        class="language-dropdown"
                        [iconId]="'globe-vector'"
                        (currentValueChange)="changeLanguage($event)"
                        [contextType]="invertedNavigationBarColor ? contextType.MenuInverted : contextType.Menu">
                        <darwin-select-option
                            *ngFor="let supportedLanguage of supportedLanguages"
                            [contextType]="invertedNavigationBarColor ? contextType.MenuInverted : contextType.Menu"
                            [value]="supportedLanguage.id"
                            [selected]="supportedLanguage.id === selectedLanguage.id">
                            {{ supportedLanguage.displayText }}
                        </darwin-select-option>
                    </darwin-select>
                </div>
                <div class="main-menu-item">
                    <button class="btn-primary logout" (click)="logout()" appText="Global_Action_Logout"></button>
                </div>
            </li>
        </ul>
    </nav>
</div>
