import { MonetaryValue, MonetaryValueType, RecurringPeriodValue } from 'core/models/core.model';

export class MonetaryValueExtension {
    static subtract(minuend: MonetaryValue, subtrahend: MonetaryValue): MonetaryValue {
        const hasPendingApproval: boolean = (minuend && !!minuend.pendingApprovalValue) || (subtrahend && !!subtrahend.pendingApprovalValue);

        return <MonetaryValue>{
            annualActualCurrentValue: (minuend ? minuend.annualActualCurrentValue : 0) - (subtrahend ? subtrahend.annualActualCurrentValue : 0),
            annualActualValue: (minuend ? minuend.annualActualValue : 0) - (subtrahend ? subtrahend.annualActualValue : 0),
            schemePeriodCurrentForecast: (minuend ? minuend.schemePeriodCurrentForecast : 0) - (subtrahend ? subtrahend.schemePeriodCurrentForecast : 0),
            schemePeriodForecast: (minuend ? minuend.schemePeriodForecast : 0) - (subtrahend ? subtrahend.schemePeriodForecast : 0),
            periodValue: (minuend ? minuend.periodValue : 0) - (subtrahend ? subtrahend.periodValue : 0),
            periodActualValue: (minuend ? minuend.periodActualValue : 0) - (subtrahend ? subtrahend.periodActualValue : 0),
            schemePeriodToDate: (minuend ? minuend.schemePeriodToDate : 0) - (subtrahend ? subtrahend.schemePeriodToDate : 0),
            pendingApprovalValue: hasPendingApproval
                ? this.subtract(
                      minuend ? (minuend.pendingApprovalValue as MonetaryValue) : null,
                      subtrahend ? (subtrahend.pendingApprovalValue as MonetaryValue) : null
                  )
                : null
        };
    }

    static add(valueA: MonetaryValue, valueB: MonetaryValue): MonetaryValue {
        const hasPendingApproval: boolean = (valueA && !!valueA.pendingApprovalValue) || (valueB && !!valueB.pendingApprovalValue);

        return <MonetaryValue>{
            annualActualCurrentValue: (valueA ? valueA.annualActualCurrentValue : 0) + (valueB ? valueB.annualActualCurrentValue : 0),
            annualActualValue: (valueA ? valueA.annualActualValue : 0) + (valueB ? valueB.annualActualValue : 0),
            schemePeriodCurrentForecast: (valueA ? valueA.schemePeriodCurrentForecast : 0) + (valueB ? valueB.schemePeriodCurrentForecast : 0),
            schemePeriodForecast: (valueA ? valueA.schemePeriodForecast : 0) + (valueB ? valueB.schemePeriodForecast : 0),
            periodValue: (valueA ? valueA.periodValue : 0) + (valueB ? valueB.periodValue : 0),
            periodActualValue: (valueA ? valueA.periodActualValue : 0) + (valueB ? valueB.periodActualValue : 0),
            schemePeriodToDate: (valueA ? valueA.schemePeriodToDate : 0) + (valueB ? valueB.schemePeriodToDate : 0),
            pendingApprovalValue: hasPendingApproval
                ? this.add(valueA ? (valueA.pendingApprovalValue as MonetaryValue) : null, valueB ? (valueB.pendingApprovalValue as MonetaryValue) : null)
                : null
        };
    }

    static hasValue(value: MonetaryValue) {
        return (
            value.annualActualCurrentValue !== 0 ||
            value.annualActualValue !== 0 ||
            value.schemePeriodCurrentForecast !== 0 ||
            value.schemePeriodForecast !== 0 ||
            value.periodValue !== 0 ||
            value.periodActualValue !== 0
        );
    }

    static hasValueWithPendingApprovalStatus(value: MonetaryValue, usePendingApproval: boolean) {
        const effectiveMonetaryValue: MonetaryValue = this.getMonetaryValueByPendingApprovalStatus(value, usePendingApproval);

        if (!effectiveMonetaryValue) {
            return false;
        }

        return (
            effectiveMonetaryValue.annualActualCurrentValue !== 0 ||
            effectiveMonetaryValue.annualActualValue !== 0 ||
            effectiveMonetaryValue.schemePeriodCurrentForecast !== 0 ||
            effectiveMonetaryValue.schemePeriodForecast !== 0 ||
            effectiveMonetaryValue.schemePeriodToDate !== 0 ||
            effectiveMonetaryValue.periodValue !== 0 ||
            effectiveMonetaryValue.periodActualValue !== 0 ||
            (effectiveMonetaryValue.recurringPeriodValues && effectiveMonetaryValue.recurringPeriodValues.length > 0)
        );
    }

    static isPositive(value: MonetaryValue) {
        return (
            value.annualActualCurrentValue >= 0 &&
            value.annualActualValue >= 0 &&
            value.schemePeriodCurrentForecast >= 0 &&
            value.schemePeriodForecast >= 0 &&
            value.periodValue >= 0 &&
            value.periodActualValue >= 0
        );
    }

    static getRecurringPeriodValue(
        monetaryValue: MonetaryValue,
        monetaryValueType: MonetaryValueType,
        customPeriodID: string,
        usePendingApproval: boolean = false
    ): number {
        if (!monetaryValue || !monetaryValue.recurringPeriodValues || monetaryValue.recurringPeriodValues.length == 0) {
            return 0;
        }

        const recurringPeriodValues: RecurringPeriodValue = this.getMonetaryValueByPendingApprovalStatus(
            monetaryValue,
            usePendingApproval
        ).recurringPeriodValues.find(x => x.id === customPeriodID);

        if (recurringPeriodValues) {
            switch (monetaryValueType) {
                case MonetaryValueType.SchemePeriodForecast:
                    return recurringPeriodValues.forecast;
                case MonetaryValueType.SchemePeriodCurrentForecast:
                    return recurringPeriodValues.currentForecast;
                case MonetaryValueType.SchemePeriodToDate:
                    return recurringPeriodValues.valueToDate;
            }
        }

        return 0;
    }

    static getMonetaryValueByType(monetaryValue: MonetaryValue, monetaryValueType: MonetaryValueType, usePendingApproval: boolean = false): number {
        if (!monetaryValue) {
            return 0;
        }

        const effectiveMonetaryValue: MonetaryValue = this.getMonetaryValueByPendingApprovalStatus(monetaryValue, usePendingApproval);

        switch (monetaryValueType) {
            case MonetaryValueType.PeriodValue:
                return effectiveMonetaryValue.periodValue;
            case MonetaryValueType.PeriodActualValue:
                return effectiveMonetaryValue.periodActualValue;
            case MonetaryValueType.AnnualActualValue:
                return effectiveMonetaryValue.annualActualValue;
            case MonetaryValueType.AnnualActualCurrentValue:
                return effectiveMonetaryValue.annualActualCurrentValue;
            case MonetaryValueType.SchemePeriodToDate:
                return effectiveMonetaryValue.schemePeriodToDate;
            case MonetaryValueType.SchemePeriodForecast:
                return effectiveMonetaryValue.schemePeriodForecast;
            case MonetaryValueType.SchemePeriodCurrentForecast:
                return effectiveMonetaryValue.schemePeriodCurrentForecast;
        }
    }

    static getMonetaryValueByPendingApprovalStatus(monetaryValue: MonetaryValue, usePendingApproval: boolean = false): MonetaryValue {
        if (usePendingApproval && monetaryValue && monetaryValue.pendingApprovalValue) {
            return monetaryValue.pendingApprovalValue as MonetaryValue;
        }

        return monetaryValue;
    }

    static convertToPositive(value: MonetaryValue): MonetaryValue {
        return <MonetaryValue>{
            annualActualCurrentValue: Math.abs(value.annualActualCurrentValue),
            annualActualValue: Math.abs(value.annualActualValue),
            schemePeriodCurrentForecast: Math.abs(value.schemePeriodCurrentForecast),
            schemePeriodForecast: Math.abs(value.schemePeriodForecast),
            periodValue: Math.abs(value.periodValue),
            periodActualValue: Math.abs(value.periodActualValue),
            schemePeriodToDate: Math.abs(value.schemePeriodToDate),
            pendingApprovalValue: value.pendingApprovalValue ? this.convertToPositive(value.pendingApprovalValue) : null
        };
    }

    static getMonetaryValueEmpty(): MonetaryValue {
        return <MonetaryValue>{
            annualActualCurrentValue: 0,
            annualActualValue: 0,
            schemePeriodCurrentForecast: 0,
            schemePeriodForecast: 0,
            periodValue: 0,
            periodActualValue: 0,
            schemePeriodToDate: 0
        };
    }

    static getRecurringPeriodValueBySchemePeriod(monetaryValue: MonetaryValue, schemePeriodID: string): number {
        if (!monetaryValue.recurringPeriodValues || monetaryValue.recurringPeriodValues.length === 0) {
            return 0;
        }

        const recurringPriodValue = monetaryValue.recurringPeriodValues.find(x => x.id == schemePeriodID);

        if (recurringPriodValue) {
            return recurringPriodValue.forecast;
        }

        return 0;
    }
}
