import { Component, EventEmitter, Input } from '@angular/core';

import { ContentNodeItemType, IContentNodeImpl } from 'shared/components/content-renderer/content-renderer.model';
import { IOverlayContent } from 'shared/overlay/overlay.model';

@Component({
    selector: 'content-overlay',
    templateUrl: './content-overlay.component.html'
})
export class ContentOverlayComponent implements IOverlayContent {
    @Input() content: IContentNodeImpl;

    close: EventEmitter<void>;

    contentNodeItemType = ContentNodeItemType;

    closeOverlay() {
        this.close.emit();
    }
}
