import { Injectable } from '@angular/core';
import { AsyncSubject, forkJoin, Observable, of } from 'rxjs';

import { ApiHttpClient, FileUploadModel, ProgressState } from 'core/api-http-client';
import { DependantFileForUpload, DependantFileUpload, DependantFileUploadData, FileStatus } from 'core/models/profile.model';

@Injectable()
export class DependantEvidenceService {
    dependantEvidenceFileUploadData: DependantFileUploadData = {};

    constructor(private httpClient: ApiHttpClient) {}

    getDependantEvidenceFileUploads() {
        const dependantFileUploads: DependantFileUpload[] = [];

        for (const key of Object.keys(this.dependantEvidenceFileUploadData)) {
            const fileToUpload = this.dependantEvidenceFileUploadData[key];

            const file = {
                fileID: fileToUpload.fileID,
                fileName: fileToUpload.fileName,
                documentTypeID: fileToUpload.documentTypeID,
                evidenceID: fileToUpload.evidenceID,
                isDeleted: fileToUpload.status === FileStatus.Deleted,
                submitDateTime: fileToUpload.submitDateTime,
                adjudicationStatus: fileToUpload.adjudicationStatus
            };

            dependantFileUploads.push(file);
        }

        return dependantFileUploads;
    }

    uploadDependantEvidences(dependantID: string): Observable<boolean[]> {
        const uploadDependantEvidenceObservables = [of(true)];
        Object.keys(this.dependantEvidenceFileUploadData).forEach(fileID => {
            if (this.dependantEvidenceFileUploadData[fileID].status === FileStatus.New) {
                uploadDependantEvidenceObservables.push(this.uploadDependantEvidence(fileID, dependantID));
            }
        });

        return forkJoin(uploadDependantEvidenceObservables);
    }

    uploadTemporaryDependantEvidences(dependantID: string): Observable<boolean[]> {
        const uploadDependantEvidenceObservables = [of(true)];
        Object.keys(this.dependantEvidenceFileUploadData).forEach(fileID => {
            if (this.dependantEvidenceFileUploadData[fileID].status === FileStatus.New) {
                uploadDependantEvidenceObservables.push(this.uploadTemporaryDependantEvidence(fileID, dependantID));
            }
        });

        return forkJoin(uploadDependantEvidenceObservables);
    }

    updateDependantEvidenceFileData(dependantFileUploads: DependantFileUpload[]) {
        if (!dependantFileUploads) {
            this.dependantEvidenceFileUploadData = {};
        }

        for (const key of Object.keys(this.dependantEvidenceFileUploadData)) {
            const fileToUpload = this.dependantEvidenceFileUploadData[key];

            if (dependantFileUploads && !dependantFileUploads.find(x => x.fileID === fileToUpload.fileID)) {
                this.dependantEvidenceFileUploadData[key].status = FileStatus.Deleted;
            }
        }

        (dependantFileUploads || []).forEach(doc => {
            this.dependantEvidenceFileUploadData[doc.fileID] = <DependantFileForUpload>{
                fileID: doc.fileID,
                fileName: doc.fileName,
                documentTypeID: doc.documentTypeID,
                evidenceID: doc.evidenceID,
                status: FileStatus.Existing,
                submitDateTime: doc.submitDateTime,
                adjudicationStatus: doc.adjudicationStatus
            };
        });
    }

    downloadEvidence(dependantID: string, fileID: string, fileName: string) {
        return this.httpClient.downloadFile('/Profile/DownloadEvidence', { fileId: fileID, dependantID: dependantID }, fileName);
    }

    downloadTemporaryEvidence(dependantID: string, fileID: string, fileName: string) {
        return this.httpClient.downloadFile('/Profile/DownloadTemporaryEvidence', { fileId: fileID, fileName: fileName, dependantID: dependantID }, fileName);
    }

    createFileDownloadLink(fileName: string, fileBlob: Blob) {
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(fileBlob);
        document.body.appendChild(link);
        link['download'] = fileName!;
        link.click();
        document.body.removeChild(link);
    }

    private uploadDependantEvidence(fileID: string, dependantID: string): Observable<boolean> {
        const uploadDependantEvidenceSubject = new AsyncSubject<boolean>();

        const model: FileUploadModel = {
            dependantID,
            fileID,
            file: this.dependantEvidenceFileUploadData[fileID].rawFile
        };

        this.httpClient.uploadFile('/Profile/UploadDependantEvidenceFile', model, true).subscribe(val => {
            if (val.state === ProgressState.Completed) {
                uploadDependantEvidenceSubject.next(true);
                uploadDependantEvidenceSubject.complete();
            }
        });

        return uploadDependantEvidenceSubject.asObservable();
    }

    private uploadTemporaryDependantEvidence(fileID: string, dependantID: string): Observable<boolean> {
        const uploadDependantEvidenceSubject = new AsyncSubject<boolean>();

        const model: FileUploadModel = {
            dependantID,
            fileID,
            file: this.dependantEvidenceFileUploadData[fileID].rawFile
        };

        this.httpClient.uploadFile('/Profile/UploadTemporaryDependantEvidenceFile', model, true).subscribe(val => {
            if (val.state === ProgressState.Completed) {
                uploadDependantEvidenceSubject.next(true);
                uploadDependantEvidenceSubject.complete();
            }
        });

        return uploadDependantEvidenceSubject.asObservable();
    }
}
