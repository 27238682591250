import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from 'core/format.service';
import { CustomDateTimeFormatOptions } from 'core/models/custom-date-time-format-options.model';

@Pipe({
    name: 'formatDate',
    pure: false
})
export class FormatDatePipe implements PipeTransform {
    constructor(private formatService: FormatService) {}

    transform(value: Date | string, dayFormatOptions?: CustomDateTimeFormatOptions): string {
        if (value) {
            return this.formatService.formatDate(value, dayFormatOptions);
        }

        return '-';
    }
}
