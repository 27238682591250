export enum DataType {
    none = 0,
    numeric = 1,
    text = 2,
    date = 3,
    boolean = 4,
    list = 5,
    listArray = 15,
    intArray = 16,
    decimalArray = 17
}
