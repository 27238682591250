import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { ApiHttpClient } from 'core/api-http-client';
import { SchemeContentFile } from 'core/models/configuration.model';

@Component({
    selector: 'video-component',
    templateUrl: 'video.component.html',
    styleUrls: ['video.component.less']
})
export class VideoComponent implements OnInit, OnDestroy {
    @Input() internalVideoFile: SchemeContentFile;
    @Input() autoplay: boolean = false;

    safeSourceUrl: SafeResourceUrl;

    private blobUrl: string;
    private getWebMediaFileSubscription: Subscription = Subscription.EMPTY;

    constructor(private sanitizer: DomSanitizer, private apiHttpClient: ApiHttpClient) {}

    ngOnInit() {
        if (this.internalVideoFile) {
            this.getWebMediaFileSubscription = this.apiHttpClient.getWebMediaFile(this.internalVideoFile.filePath).subscribe(response => {
                const url = URL.createObjectURL(response);
                this.blobUrl = url;
                this.safeSourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            });
        }
    }

    ngOnDestroy() {
        URL.revokeObjectURL(this.blobUrl);
        this.getWebMediaFileSubscription.unsubscribe();
    }
}
