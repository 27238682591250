import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';

import { IOverlayContent, OverlayTextData, OverlayTextDataToken } from 'shared/overlay/overlay.model';

@Component({
    selector: 'overlay-text-content',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayTextContentComponent implements IOverlayContent, OnInit {
    @HostBinding('class.external-html-container') get htmlContainer() {
        return true;
    }
    close: EventEmitter<void>;

    constructor(private renderer: Renderer2, private elementRef: ElementRef, @Inject(OverlayTextDataToken) private data: OverlayTextData) {}

    @HostListener('click', ['$event.target'])
    onClickEvent(elm: HTMLElement) {
        const clickEvent = this.data.clickEvents.find(x => x.identifier === elm.getAttribute('data-identifier'));
        if (clickEvent) {
            clickEvent.observer.next();
        }
    }

    ngOnInit() {
        function htmlDecode(input) {
            const el = document.createElement('div');
            return input.replace(/\&[#0-9a-z]+;/gi, enc => {
                el.innerHTML = enc;
                return el.innerText;
            });
        }

        let htmlText = htmlDecode(this.data.text);
        this.data.clickEvents.forEach(evt => {
            htmlText = htmlText.replace(evt.identifier, `<a href="javascript:void(0)" data-identifier="${evt.identifier}">${evt.textReplacement}</a>`);
        });

        const doc = document.createElement('div');
        doc.innerHTML = htmlText;

        __ngRendererProjectNodesHelper(this.renderer, this.elementRef.nativeElement, [doc]);
    }
}
type AnyDuringRendererMigration = any;

function __ngRendererProjectNodesHelper(renderer: AnyDuringRendererMigration, parent: AnyDuringRendererMigration, nodes: AnyDuringRendererMigration) {
    for (let i = 0; i < nodes.length; i++) {
        renderer.appendChild(parent, nodes[i]);
    }
}
