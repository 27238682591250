import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ConfigurationService } from 'core/configuration.service';
import { EventService, EventType } from 'core/event.service';
import { FormatService } from 'core/format.service';
import { SessionStorageService } from 'core/session-storage.service';

@Component({
    selector: 'privacy-toggle-content',
    templateUrl: './privacy-toggle-content.component.html',
    styleUrls: ['./privacy-toggle-content.component.less']
})
export class PrivacyToggleCotentComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('privacyToggle') privacyToggle: ElementRef;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    formGroup: FormGroup;
    inPrivateMode: boolean = true;
    currencySymbol: string;
    amountText: string = '****';
    useBrandColour: boolean = true;

    private timeOutAction: any;
    private closePrivacyToggleContent: boolean = false;
    private eventSubscription: Subscription = Subscription.EMPTY;

    constructor(
        private formatService: FormatService,
        private configurationService: ConfigurationService,
        private sessionStorageService: SessionStorageService,
        private eventService: EventService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.currencySymbol = this.formatService.getDefaultCurrency().currencySymbol;

        this.initForm();
    }

    ngAfterViewInit(): void {
        this.startAnimation();

        this.eventSubscription = this.eventService.subscribeToEvent(EventType.PrivacyToggle).subscribe(() => {
            if (this.closePrivacyToggleContent) {
                this.sessionStorageService.setItem('privacyToggleSeen', true);
                this.close.emit();
            }

            this.closePrivacyToggleContent = true;
        });
    }

    ngOnDestroy() {
        if (this.timeOutAction != null) {
            clearTimeout(this.timeOutAction);
        }

        this.eventSubscription.unsubscribe();
    }

    submit() {
        const hidePrivacyToggle = this.formGroup.get('hidePrivacyToggle').value;

        if (hidePrivacyToggle) {
            this.configurationService.setPrivacyToggle().subscribe();
        }

        this.sessionStorageService.setItem('privacyToggleSeen', hidePrivacyToggle);

        this.close.emit();
    }

    private initForm() {
        this.formGroup = this.fb.group({
            hidePrivacyToggle: [false]
        });
    }

    private startAnimation() {
        this.timeOutAction = setTimeout(() => {
            this.showToggle(true);

            setTimeout(() => {
                this.setToggleStatus(false, 500);

                setTimeout(() => {
                    this.showToggle(false);

                    setTimeout(() => {
                        this.setToggleStatus(true, 900);
                        this.startAnimation();
                    }, 500);
                }, 1000);
            }, 500);
        }, 300);
    }

    private setToggleStatus(flag: boolean, time: number) {
        this.inPrivateMode = flag;
        setTimeout(() => {
            this.amountText = flag ? '****' : '10,000.00';
        }, time / 2);
    }

    private showToggle(flag: boolean) {
        if (flag) {
            this.amountText = '****';
        }

        this.privacyToggle.nativeElement.style.opacity = flag ? '1' : '0';
    }
}
