import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { LayoutService } from 'core/layout/layout.service';
import { WindowService } from 'core/window.service';

@Injectable()
export class ScrollService {
    private scrollTop: number;

    constructor(private windowService: WindowService, private layoutService: LayoutService) {}

    scrollToElementID(elementID: string, offSetTopAdjustment?: number, onScrollFinishCallback?: Function) {
        const offsetTop = this.layoutService.getElementOffsetTop(elementID, offSetTopAdjustment);

        this.scrollToPosition(offsetTop, onScrollFinishCallback);
    }

    scrollIntoViewByElementID(elementID: string) {
        document.getElementById(elementID).scrollIntoView();
    }

    scrollToTop() {
        const setFocus = () => {
            const skipToContentSectionElement = <HTMLElement>document.getElementById('ghost-element');
            skipToContentSectionElement.focus();
        };

        this.scrollToPosition(0, setFocus);
    }

    getScrollEvent(): Observable<Event> {
        return fromEvent(this.windowService.getWindow(), 'scroll').pipe(throttleTime(20));
    }

    getHeaderMenuHeight(): number {
        return this.layoutService.getHeaderMenuHeight();
    }

    scrollToPosition(targetY: number, onScrollFinishCallback?: Function) {
        const timeOut = 20;
        const scrollMargin = 32;
        let nextTarget;
        const multiplier = window.pageYOffset <= targetY ? 1 : -1;
        let speed = 0;
        let isFinalAdjustment = false;
        let currY;
        const initialY = window.pageYOffset;

        requestAnimationFrame(step);
        function step() {
            setTimeout(function () {
                nextTarget = (nextTarget || window.pageYOffset) + multiplier * (scrollMargin + 40 * speed);

                let compare = multiplier === 1 ? nextTarget < targetY : nextTarget > targetY;
                if (!compare) {
                    compare = isFinalAdjustment = true;
                    nextTarget = targetY;
                }
                if (currY !== window.pageYOffset) {
                    currY = window.pageYOffset;
                    window.scrollTo(0, nextTarget);
                    if (Math.floor(nextTarget) > Math.ceil(window.pageYOffset)) {
                        isFinalAdjustment = true;
                    }
                    const y = ((currY - initialY) / (targetY - initialY)) * Math.PI;
                    speed = Math.sin(y);
                    if (!isFinalAdjustment) {
                        requestAnimationFrame(step);
                    } else {
                        if (onScrollFinishCallback) {
                            onScrollFinishCallback();
                        }
                    }
                }
            }, timeOut);
        }
    }

    private enableSafariOverscrollPrevention() {
        this.scrollTop = document.body.scrollTop;

        document.documentElement.style.height = '100%';
        document.documentElement.style.overflowY = 'hidden';

        document.body.style.height = '100%';
        document.body.style.overflowY = 'hidden';
        document.body.scrollTop = this.scrollTop;
    }

    private disableSafariOverscrollPrevention() {
        document.documentElement.style.height = 'auto';
        document.documentElement.style.overflowY = 'auto';

        document.body.style.height = 'auto';
        document.body.style.overflowY = 'auto';
        document.body.scrollTop = this.scrollTop;
    }
}
