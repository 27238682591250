<div class="privacy-toggle-container" [ngClass]="{ 'privacy-toggle-container-reverse': showValueTextBellow }">
    <label for="privacy-toggle" class="privacy-label label-14-r" [ngClass]="{ 'brand-colour': useBrandColour }">
        {{ labelText | appText }}
    </label>
    <toggle
        class="privacy-toggle"
        id="privacy-toggle"
        (toggleFlag)="togglePrivateMode(!$event)"
        [isActive]="!inPrivateMode"
        [brandColour]="useBrandColour"
        [showToolTip]="false"
        [size]="toggleSize"
        label="{{ 'Engager_Accessibility_TogglePrivacyMode' | appText }}">
        <off-label [class.label-11-r]="toggleSize === 'medium'"
            ><span class="slashed">{{ currencySymbol }}</span></off-label
        >
        <on-label [class.label-11-r]="toggleSize === 'medium'">
            {{ currencySymbol }}
        </on-label>
    </toggle>
</div>
