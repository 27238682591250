<div
    [attr.aria-label]="ariaLabel"
    class="overlay-container"
    [ngClass]="{ checkoutBarOverlay: checkoutBarOverlay }"
    role="dialog"
    (keydown.esc)="closeOverlay()"
    [@flyInOut]="animate"
    (@flyInOut.done)="onAnimationDone()"
    focus-trap>
    <div class="overlay-banner"></div>
    <button #closeIcon [attr.aria-label]="'Global_Action_Close' | appText" class="close-icon btn-close" (click)="closeOverlay()">
        <icon iconId="cross"></icon>
    </button>
    <div class="overlay-content" [ngClass]="{ 'padding-none': noOverlayPadding, 'overlay-auto-width': autoWidth, checkoutBarOverlay: checkoutBarOverlay }">
        <div [cdkPortalOutlet]></div>
    </div>
</div>
