import { MonetaryValue } from 'core/models/core.model';
import { CarryoverAccount, ExtensionAccount } from 'core/models/reimbursement.model';

export interface EmployeeFundingAccount {
    fundingAccountID: string;
    schemePeriodID: string;
    name: string;
    startDate: string;
    endDate: string;
    credit: MonetaryValue;
    preResidueBalance: MonetaryValue;
    benefitBalance: MonetaryValue;
    transactionSummaries: EmployeeFundingAccountTransactionSummary[];
    totalTargetDebits: { [id: string]: MonetaryValue };
    totalTargetCredits: { [id: string]: MonetaryValue };
    totalTypeValues: Map<FundingAccountTransactionType, MonetaryValue>;
    daysUntilEndDate: number;
    carryover: CarryoverAccount;
    expiredCarryover: CarryoverAccount;
    extension: ExtensionAccount;
    isAssigneeFundingAccount: boolean;
    assigneeSubLedgers: AssigneeSubLedgers[];
    assigneeName?: string;
    assigneeID?: string;
    assigneeLedgers: AssigneeSubLedgers[];
}

export interface AssigneeSubLedgers {
    id: string;
    assigneeID: string;
    isAllowed: boolean;
    totalBalance: number;
    totalAllowanceToDate: number;
    remainingBalance: number;
    credit: MonetaryValue;
    carryover: CarryoverAccount;
    expiredCarryover: CarryoverAccount;
    extension: ExtensionAccount;
    totalTargetDebits: { [id: string]: MonetaryValue };
    totalTargetCredits: { [id: string]: MonetaryValue };
    totalTypeValues: Map<FundingAccountTransactionType, MonetaryValue>;
    preResidueBalance: MonetaryValue;
    firstName: string;
    lastName: string;
    displayName: string;
}

export interface TransactionSummary {
    transactionID: string;
    targetID: string;
    assigneeID: string;
    description: string;
    credit: MonetaryValue;
    debit: MonetaryValue;
    _totalDebit: number;
    _totalCredit: number;
}

export enum FundingAccountTransactionType {
    Credit_CreditRule = 0,
    Debit_BenefitEmployeePreTaxCost_ToBenefit = 1,
    Debit_ResidueToPay_ToPay = 2,
    Debit_ResidueToBenefit_ToBenefit = 3,
    Debit_ResidueToCarryOver = 4,
    Credit_CarryOver = 5,
    Debit_ResidueToForfeit = 6,
    Credit_FundingRule = 7,
    Credit_BenefitReturnToFunding_FromBenefit = 8,
    AdjustmentCredit = 9,
    AdjustmentDebit = 10,
    ExtensionForfeit = 11,
    Debit_Claim = 12,
    Credit_ClaimCredit = 13,
    Credit_Clawback_FromPay = 14,
    Debit_FundingRule = 15,
    Debit_ResidueToFundingAccount_ToFundingAccount = 16,
    Credit_ResidueToFundingAccount_FromFundingAccount = 17,
    Debit_ClosureForfeit = 18,
    MicroDebit_Claim_ToAssignee = 19,
    MicroCredit_Claim_FromAssignee = 20,
    Credit_Clawback_FromEmployer = 21,
    Debit_BenefitEmployeePreTaxCostReclaim_ToBenefit = 22
}

export interface EmployeeFundingAccountTransactionSummary extends TransactionSummary {
    type: FundingAccountTransactionType;
    isHistoricalClaim: boolean;
    isAssigneeAccountDeducted?: boolean;
}

export interface FundingAccountBreakdown {
    totalTypeCreditRule: MonetaryValue;
    totalTakenFromClaims: number;
    totalDeductions: number;
    otherDeductions: number;
    totalClaimDebit: number;
    showExtension: boolean;
    totalFundingAmount: number;
    carryoverInitialBalance: number;
    preResidueSchemePeriodForecast: number;
    creditSchemePeriodForecast: number;
    preResidueBalance: number;
    hasPendingApproval: boolean;
    showFundingAccountHeader: boolean;
    showExpiryMessage: boolean;
    summariesForBenefitsCredited: EmployeeFundingAccountTransactionSummary[];
    summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[];
    summariesForClaimsToShow: EmployeeFundingAccountTransactionSummary[];
    fundingAccount: EmployeeFundingAccount;
    currentClaimDeduction?: number;
    assigneeName?: string;
}
