import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ApplicationGuard } from 'core/application.guard';
import { ApplicationLoadResolver } from 'core/Application.resolver';
import { ErrorComponent } from 'core/error/error.component';
import { LandingPageComponent } from 'core/layout/landing-page.component';
import { LayoutComponent } from 'core/layout/layout.component';
import { SecureGuard } from 'core/secure-guard';

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'dna', loadChildren: () => import('DNA/dna.module').then(m => m.DnaModule) },
    { path: 'error', component: ErrorComponent },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [SecureGuard, ApplicationGuard],
        resolve: [ApplicationLoadResolver],
        children: [
            { path: '', component: LandingPageComponent, pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('home/home.module').then(m => m.HomeModule) },
            { path: 'selection', loadChildren: () => import('selection/selection.module').then(m => m.SelectionModule) },
            { path: 'selectionv2', redirectTo: 'selection' },
            { path: 'engager', loadChildren: () => import('engager/engager.module').then(m => m.EngagerModule) },
            { path: 'profile', loadChildren: () => import('profile/profile.module').then(m => m.ProfileModule) },
            { path: 'reimbursement', loadChildren: () => import('reimbursement/reimbursement.module').then(m => m.ReimbursementModule) },
            { path: 'hub', loadChildren: () => import('hub/hub.module').then(m => m.HubModule) },
            { path: 'help', loadChildren: () => import('help/help.module').then(m => m.HelpModule) },
            { path: 'manager', loadChildren: () => import('manager/manager.module').then(m => m.ManagerModule) },
            { path: '**', redirectTo: '' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
