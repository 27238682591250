export enum SystemIcon {
    None = 0,
    Bike = 1,
    Discounts = 2,
    Dependants = 3,
    UsefulTools = 4,
    Benefits = 5,
    SpendingAccounts = 6,
    LifeEventChanges = 7,
    Policy = 8,
    Sickness = 9,
    Star = 10,
    Travel = 11,
    FAQs = 12,
    BenefitsModeller = 13,
    PensionsModeller = 14,
    RiskProfiler = 15,
    Print = 16,
    Call = 17,
    TotalRewardStatement = 18,
    BenefitInformation = 19,
    BrokenFile = 20,
    Globe = 21,
    Headphones = 22,
    Smile = 23,
    University = 24,
    People = 25,
    GroupFavoriteHeart = 26,
    Graduate = 27,
    ShoppingCartFull = 28,
    Safe = 29,
    BankNotes = 30,
    MoneyBagDollar = 31,
    PiggyBank = 32,
    CoinBankNote = 33,
    HouseLock = 34,
    Beach = 35,
    AirplaneDeparture = 36,
    FoodDomeServing = 37,
    WeightLifting = 38,
    Dumbbell = 39,
    Stethoscope = 40,
    Saline = 41,
    Medicine = 42,
    MedicalNotepad = 43,
    HeartBeat = 44,
    Dentist = 45,
    DentalShield = 46,
    Ambulance = 47,
    GlassesRound = 48,
    MaskDouble = 49,
    HeartCare = 50,
    Car = 51,
    PoundSign = 52,
    BookFavoriteHeart = 53,
    BookContact = 54,
    BubbleChatQuestion = 55,
    Wallet = 56,
    CustomIcon = 999
}
