import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EmitEvent, EventService, EventType } from 'core/event.service';
import { DisplaySize } from 'core/models/display.model';
import { PendoService } from 'core/pendo.service';

@Injectable()
export class WindowService implements OnDestroy {
    private currentDisplaySize: DisplaySize;
    private isSafari: boolean;
    private isChrome: boolean;
    private isIE: boolean;
    private isFirefox: boolean;
    private mobileMaxSize: number = 720;
    private tabletMaxSize: number = 1080;
    private windowResizeSubscription: Subscription;

    constructor(private ngZone: NgZone, private eventService: EventService, private pendoService: PendoService) {}

    ngOnDestroy(): void {
        if (this.windowResizeSubscription) {
            this.windowResizeSubscription.unsubscribe();
        }
    }

    init() {
        this.currentDisplaySize = this.displaySize();
        this.eventService.triggerEvent(new EmitEvent(EventType.DisplayChange, this.currentDisplaySize));

        this.setupDisplaySizeEvent();
    }

    getWindow() {
        return window;
    }

    getAppProperty(propName: string) {
        return window[propName];
    }

    setAppProperty(propName: string, value: string) {
        window[propName] = value;
    }

    navigateTo(url: string, newWindow: boolean = false) {
        this.pendoService.trackExternalLink(url, window.location.pathname);

        if (newWindow) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }
    }

    isWebkitBrowser() {
        return this.browserIsChrome() || this.browserIsSafari() || this.browserIsFirefox();
    }

    reloadPage() {
        this.getWindow().location.reload();
    }

    setPageTitle(textKey: string) {
        window.document.title = textKey;
    }

    browserIsSafari(): boolean {
        if (this.isSafari === undefined) {
            this.isSafari = /Safari/.test(window.navigator.userAgent) && /Apple Computer/.test(window.navigator.vendor);
        }

        return this.isSafari;
    }

    browserIsIE(): boolean {
        if (this.isIE === undefined) {
            this.isIE = /Trident/.test(window.navigator.userAgent) || /MSIE/.test(window.navigator.userAgent);
        }

        return this.isIE;
    }

    isMobileDevice() {
        return this.currentDisplaySize === DisplaySize.mobile;
    }

    isTabletDevice() {
        return this.currentDisplaySize === DisplaySize.tablet;
    }

    isDesktopDevice() {
        return this.currentDisplaySize === DisplaySize.desktop;
    }

    isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    private browserIsChrome() {
        if (this.isChrome === undefined) {
            this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        }

        return this.isChrome;
    }

    private browserIsFirefox() {
        this.isFirefox = false;

        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            this.isFirefox = true;

            return this.isFirefox;
        }
    }

    private setupDisplaySizeEvent() {
        this.ngZone.runOutsideAngular(() => {
            this.windowResizeSubscription = fromEvent(window, 'resize')
                .pipe(debounceTime(50))
                .subscribe(() => {
                    const newDisplaySize = this.displaySize();

                    if (newDisplaySize !== this.currentDisplaySize) {
                        this.ngZone.run(() => {
                            this.currentDisplaySize = newDisplaySize;
                            this.eventService.triggerEvent(new EmitEvent(EventType.DisplayChange, newDisplaySize));
                        });
                    }
                });
        });
    }

    private displaySize(): DisplaySize {
        if (window.innerWidth <= this.mobileMaxSize || window.innerHeight <= 480) {
            return DisplaySize.mobile;
        } else if (window.innerWidth <= this.tabletMaxSize && window.innerWidth > this.mobileMaxSize) {
            return DisplaySize.tablet;
        }

        return DisplaySize.desktop;
    }
}
