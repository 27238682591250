import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Criterion, PasswordCriteria, PasswordRequirements } from 'core/models/security.model';
import { ValidationMessage } from 'core/models/validation.model';
import { TextService } from 'core/text.service';

@Injectable()
export class PasswordValidationService {
    passwordCriteria: Criterion[] = PasswordCriteria;
    parentFormSubscription: Subscription = Subscription.EMPTY;
    passwordMinimumLength: number = 8;
    doesPasswordMeetMinLength: boolean;
    defaultMaxPasswordLength: number = 150;
    maxNumberOfConsecutiveRepeatedChars: number = 2;
    maxNumberOfCharsInSequence: number = 2;
    validatorNames = {
        required: 'required',
        minlength: 'minlength',
        maxlength: 'maxlength',
        passwordComposition: 'passwordComposition',
        passwordsDontMatch: 'passwordsDontMatch',
        containsTooManyConsecutiveRepeatingChars: 'containsTooManyConsecutiveRepeatingChars',
        containsTooManyCharsInSequence: 'containsTooManyCharsInSequence'
    };
    characterSequences = {
        alphabet: 'abcdefghijklmnopqrstuvwxyz',
        numbers: '01234567890',
        alphabetReversed: 'zyxwvutsrqponmlkjihgfedcba',
        numbersReversed: '09876543210'
    };

    constructor(private textService: TextService) {}

    init(passwordRequirements: PasswordRequirements) {
        this.passwordMinimumLength = passwordRequirements.minimumLength;
    }

    newPasswordValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean | null } => {
            const errors: { [key: string]: boolean } = {};

            if (control.value) {
                if (!this.doesPasswordSatisfyMinLength(control.value)) {
                    errors[this.validatorNames.minlength] = true;
                }

                if (!this.isValidComposition(control.value)) {
                    errors[this.validatorNames.passwordComposition] = true;
                }

                if (this.containsTooManyRepeatingChars(control.value)) {
                    errors[this.validatorNames.containsTooManyConsecutiveRepeatingChars] = true;
                }

                if (this.containsTooManyCharsInSequence(control.value)) {
                    errors[this.validatorNames.containsTooManyCharsInSequence] = true;
                }
            } else {
                this.passwordCriteria.forEach(item => {
                    item.isValid = false;
                });

                this.doesPasswordMeetMinLength = false;
            }

            return !!Object.keys(errors).length ? errors : null;
        };
    }

    confirmPasswordValidator(password: any, confirmPassword: any): { [key: string]: boolean | null } {
        if (password === confirmPassword) {
            return null;
        }
        return { [this.validatorNames.passwordsDontMatch]: true };
    }

    initFormErrorMessages() {
        const passwordLabel: string = this.textService.getText('Login_Password');

        return {
            [this.validatorNames.required]: this.textService.getText('Global_Validation_RequiredFieldError'),
            [this.validatorNames.minlength]: this.textService.getText('Global_Validation_MinLengthFieldError', [this.passwordMinimumLength]),
            [this.validatorNames.maxlength]: this.textService.getText('Global_Validation_MaxLengthFieldError', [this.defaultMaxPasswordLength]),
            [this.validatorNames.passwordComposition]: this.textService.getText('PasswordInvalidComposition'),
            [this.validatorNames.passwordsDontMatch]: this.textService.getText('PasswordAndConfirmPasswordDontMatch'),
            [this.validatorNames.containsTooManyConsecutiveRepeatingChars]: this.textService.getText('AuthenticationMechanismHasTooManyRepeatedChars', [
                passwordLabel
            ]),
            [this.validatorNames.containsTooManyCharsInSequence]: this.textService.getText('AuthenticationMechanismInvalidIncludesSequence', [passwordLabel])
        };
    }

    getFormErrors(
        errors: { [property: string]: string[] },
        formControlNames: { [key: string]: string },
        parentForm: FormGroup,
        formErrorMessages: { [validatorName: string]: string }
    ): { [property: string]: string[] } {
        Object.keys(formControlNames).forEach(formControlName => {
            errors[formControlName] = [];

            Object.keys(this.validatorNames).forEach(validatorName => {
                const control = parentForm.controls[formControlName];

                if (control && control.hasError(validatorName)) {
                    errors[formControlName].push(formErrorMessages[validatorName]);
                }
            });
        });

        return errors;
    }

    groupErrors(validationMessages: ValidationMessage[], formControlNames: { [key: string]: string }): { [property: string]: string[] } {
        const errors: { [property: string]: string[] } = {};

        validationMessages.forEach(validationMessage => {
            let property: string = validationMessage.property;

            if (!Object.keys(formControlNames).some(controlName => controlName === property)) {
                property = 'other';
            }

            errors[property] = [];

            const replacements = validationMessage.replacements ? validationMessage.replacements.map(replacements => replacements.value) : null;

            const message = this.textService.getText(validationMessage.message, replacements);

            errors[property].push(message);
        });

        return errors;
    }

    private containsTooManyCharsInSequence(password: string): boolean {
        const minSubstringLength: number = this.maxNumberOfCharsInSequence + 1;

        return Object.keys(this.characterSequences).some(charSequenceName => {
            let startIndex: number = 0;

            const hasMoreSubstringsToValidate = () => {
                return startIndex + minSubstringLength <= password.length;
            };

            while (hasMoreSubstringsToValidate()) {
                const substring: string = password.toLowerCase().substring(startIndex, startIndex + minSubstringLength);

                if (this.characterSequences[charSequenceName].includes(substring)) {
                    return true;
                }

                ++startIndex;
            }

            return false;
        });
    }

    private containsTooManyRepeatingChars(password: string): boolean {
        let currentCharCount: number = 0;
        let currentChar: string = '';

        return password
            .toLowerCase()
            .split('')
            .some(character => {
                if (currentChar === character) {
                    if (++currentCharCount > this.maxNumberOfConsecutiveRepeatedChars) {
                        return true;
                    }
                } else {
                    currentCharCount = 1;
                    currentChar = character;
                }

                return false;
            });
    }

    private doesPasswordSatisfyMinLength(password): boolean {
        this.doesPasswordMeetMinLength = password.length >= this.passwordMinimumLength;

        return this.doesPasswordMeetMinLength;
    }

    private isValidComposition(password: string): boolean {
        this.passwordCriteria.forEach(item => {
            item.isValid = item.regex.test(password);
        });

        return this.passwordCriteria.filter(criteria => criteria.isValid).length >= 3;
    }
}
