import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';

import { CostPeriodService } from 'core/cost-period.service';
import { MonetaryValueExtension } from 'core/extensions/monetary-value.extension';
import { MonetaryValue, MonetaryValueType } from 'core/models/core.model';

@Pipe({
    name: 'costPeriod',
    pure: false
})
export class CostPeriodPipe implements PipeTransform, OnDestroy {
    private subscription: Subscription;
    private monetaryValueType: MonetaryValueType;

    constructor(private costService: CostPeriodService) {}

    transform(monetaryValue: MonetaryValue, usePendingApproval: boolean = false): number {
        if (!this.subscription) {
            this.subscription = this.costService.monetaryValueType$.subscribe((monetaryValueType: MonetaryValueType) => {
                this.monetaryValueType = monetaryValueType;
            });
        }

        return MonetaryValueExtension.getMonetaryValueByType(monetaryValue, this.monetaryValueType, usePendingApproval);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
