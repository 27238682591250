import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

import { QuickLinkGroup } from 'core/models/configuration.model';
import { MegaNavMenuItem, NavMenuItem, NavMenuType } from 'core/models/navigation.model';
import { NavigationService } from 'core/navigation.service';
import { TextService } from 'core/text.service';
import { WindowService } from 'core/window.service';

import { expandCollapse } from 'shared/animations/expand-collapse.animation';

@Component({
    selector: 'mega-menu',
    templateUrl: 'mega-menu.component.html',
    styleUrls: ['mega-menu.component.less'],
    animations: [expandCollapse]
})
export class MegaMenuComponent implements OnInit, OnDestroy {
    @Input() quickLinkGroups: QuickLinkGroup[];
    @Input() moreMenuItems: NavMenuItem[];
    @Input() showMenu: boolean;
    @Input() invertedNavigationBarColor: boolean;
    @Input() bannerUrl: string;
    @Output() showMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    categories: string[] = [];
    groupedNavMenuItems: MegaNavMenuItem[][] = [];
    iconNameList: Map<number, string> = new Map<number, string>();

    showGroupHeaderTitle: boolean = true;
    isStacked: boolean = false;
    maxNumberOfColumnsForScreenSize: number = 0;
    menuWidth: string;
    menuItemWidth: string;

    NavMenuType = NavMenuType;

    megaMenuGroups: QuickLinkGroup[];

    isLoaded: boolean = false;

    private megaNavMenuItems: MegaNavMenuItem[] = [];
    private customPageCategoryId: string = 'custom-pages';

    private isTouchDevice: boolean = false;
    private windowResizeSubscription: Subscription = Subscription.EMPTY;

    private minScreenSizeFor5Columns: number = 1280;
    private menuLeftAndRightMarginTotal: number = 75;
    private minMenuItemMargin: number = 24;
    private maxNumberOfColumnsAllowed: number = 5;
    private minColumnWidth: number = 241;

    private window;

    constructor(
        private windowService: WindowService,
        private changeDetectorRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private textService: TextService
    ) {}

    ngOnInit(): void {
        this.window = this.windowService.getWindow();

        this.isTouchDevice = this.windowService.isTouchDevice();

        this.iconNameList = this.navigationService.getIconNameAndSizeList();

        this.loadNavigationMenuItems();

        this.windowResizeSubscription = fromEvent(this.window, 'resize')
            .pipe(debounceTime(200))
            .subscribe(() => {
                this.showMenu = false;

                this.showMenuChange.emit(false);

                this.isTouchDevice = this.windowService.isTouchDevice();

                this.loadNavigationMenuItems();

                this.changeDetectorRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this.windowResizeSubscription.unsubscribe();
    }

    openMenu() {
        if (this.isTouchDevice) {
            return;
        }

        this.showMenu = true;
    }

    closeMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent?.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.showMenu = false;
    }

    triggerMenuAction(menuItem: MegaNavMenuItem) {
        this.navigationService.triggerLinkAction(menuItem);
        this.showMenuChange.emit(false);
    }

    private createMenuItemByGroup() {
        let groupItemIndex: number = 0;

        this.megaNavMenuItems = [];
        this.categories = [];

        for (const quickLinkGroup of this.megaMenuGroups) {
            groupItemIndex = 0;

            this.categories.push(quickLinkGroup.groupConfigID);

            const quickLinks = quickLinkGroup.quickLinks.filter(x => x != null);

            for (const quickLink of quickLinks) {
                groupItemIndex++;

                const navMenuItem = this.navigationService.convertQuickLinkToNavMenuItems('', [quickLink]).children[0] as MegaNavMenuItem;

                navMenuItem.groupConfigID = quickLinkGroup.groupConfigID;

                if (groupItemIndex === 1) {
                    navMenuItem.headerTitle = quickLinkGroup.name;
                }

                this.megaNavMenuItems.push(navMenuItem);
            }
        }

        this.showGroupHeaderTitle = groupItemIndex > 0;
    }

    private addMoreNavMenuItems() {
        const moreMenuItems = this.moreMenuItems as MegaNavMenuItem[];

        const globalPages = this.textService.getText('Global_Pages');

        if (moreMenuItems?.length > 0) {
            moreMenuItems.forEach((moreMenuItem, index) => {
                if (index === 0) {
                    moreMenuItem.headerTitle = globalPages;
                }

                moreMenuItem.groupConfigID = this.customPageCategoryId;

                this.megaNavMenuItems.splice(index, 0, moreMenuItem);
            });

            this.categories.splice(0, 0, this.customPageCategoryId);

            this.megaMenuGroups.splice(0, 0, {
                groupConfigID: this.customPageCategoryId,
                name: globalPages,
                quickLinks: []
            });
        }
    }

    private getMegaNavMenuItemByCategories(): MegaNavMenuItem[][] {
        const navMenuItemsByCategory: MegaNavMenuItem[][] = [];

        this.megaMenuGroups.forEach(quickLink => {
            const menu: MegaNavMenuItem[] = [];

            for (let index = 0; index < this.megaNavMenuItems.length; index++) {
                if (this.megaNavMenuItems[index].groupConfigID === quickLink.groupConfigID) {
                    menu.push(this.megaNavMenuItems[index]);
                }
            }

            if (menu.length) {
                navMenuItemsByCategory.push(menu);
            }
        });

        return navMenuItemsByCategory;
    }

    private loadNavigationMenuItems() {
        this.megaMenuGroups = this.quickLinkGroups?.length > 0 ? [...this.quickLinkGroups.filter(group => group.quickLinks?.length > 0)] : [];

        this.createMenuItemByGroup();

        this.addMoreNavMenuItems();

        this.calculateScreenSizeAndColumnWidth();

        this.groupedNavMenuItems = this.getMegaNavMenuItemByCategories();

        this.isLoaded = true;
    }

    private calculateScreenSizeAndColumnWidth() {
        const maxScreenSize =
            this.window.innerWidth > this.minScreenSizeFor5Columns ? this.minScreenSizeFor5Columns : this.window.innerWidth - this.menuLeftAndRightMarginTotal;

        this.maxNumberOfColumnsForScreenSize = Number(Math.floor(maxScreenSize / this.minColumnWidth).toFixed(5));

        this.maxNumberOfColumnsForScreenSize =
            this.megaMenuGroups.length < this.maxNumberOfColumnsForScreenSize ? this.megaMenuGroups.length : this.maxNumberOfColumnsForScreenSize;

        const numberOfColumnWhenOverflowStackedIsTriggered = this.megaMenuGroups.length > this.maxNumberOfColumnsForScreenSize;

        this.isStacked = this.maxNumberOfColumnsForScreenSize < this.maxNumberOfColumnsAllowed && numberOfColumnWhenOverflowStackedIsTriggered;

        if (this.isStacked) {
            const width = Number(Math.floor(this.window.innerWidth / this.maxNumberOfColumnsForScreenSize - this.minMenuItemMargin).toFixed(5));
            this.menuItemWidth = `${width}px`;
        } else {
            this.menuItemWidth = `${this.minColumnWidth}px`;
        }

        this.menuWidth = 'min-content';

        if (this.megaMenuGroups.length > 3 && this.isStacked) {
            this.menuWidth = '100%';
        }
    }
}
