import { CdkPortalOutlet } from '@angular/cdk/portal';
import { EventEmitter, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export interface IOverlayContent {
    close: EventEmitter<void>;
}

export interface IOverlayBase<T = any> extends InternalOverlayConfig {
    disposeOverlay: EventEmitter<T>;
    close: EventEmitter<void>;
    portalOutlet: CdkPortalOutlet;
    closeOverlay: () => void;
}

export type OverlayConfig = Partial<
    InternalOverlayConfig & {
        overlayID: string;
        preventCloseOnClickBackDrop: boolean;
        position: OverlayPosition;
        keepExistingOverlay: boolean;
        closeCallback: () => void;
        noOverlayPadding: boolean;
        autoWidth: boolean;
        checkoutBarOverlay: boolean;
    }
>;

type InternalOverlayConfig = {
    ariaLabel: string;
};

export type DialogConfig<T> = OverlayConfig & {
    actions: { text: string; value: T; class?: string }[];
    headerText: string;
    ariaLabel: string;
    showIcon: boolean;
    hideCross: boolean;
};

export const enum OverlayPosition {
    Right = 1,
    Centre = 2
}

export const OverlayTextDataToken = new InjectionToken<OverlayTextData>('OverlayTextData');

export interface OverlayTextData {
    text: string;
    clickEvents: TextContentClickEvent[];
}

export interface TextContentClickEvent {
    identifier: string;
    textReplacement: string;
    observer: Subject<void>;
}

export const DialogDataToken = new InjectionToken<DialogData>('DialogData');

export interface DialogData {
    text: string;
    actions: { text: string; value: any }[];
}
