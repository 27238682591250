<fallback-error *ngIf="showFallbackErrorComponent; else errorTemplate"></fallback-error>
<ng-template #errorTemplate>
    <div class="error-container">
        <div class="header">
            <div *ngIf="hasBanner" class="banner">
                <a *ngIf="!isMobileDevice" [routerLink]="['/']">
                    <progressive-image [imageUrl]="bannerUrl" altText=""></progressive-image>
                </a>
                <a *ngIf="isMobileDevice" [routerLink]="['/']">
                    <progressive-image [imageUrl]="logoUrl" [altText]=""></progressive-image>
                </a>
            </div>
        </div>
        <div class="error-wrapper">
            <div class="error-text-container">
                <h1 class="error-text-title heading-22-b" appText="Error_Text_Title"></h1>
                <p class="error-text-heading paragraph-16-r" appText="Error_Text_Message"></p>
                <a role="link" href="javascript:void(0)" class="go-back" (click)="goBack()">
                    <div class="btn-link-icon">
                        <icon class="icon-xxs" iconId="left"></icon>
                        <span appText="Global_Action_GoBack"></span>
                    </div>
                </a>
                <button class="btn-primary" (click)="goToHomePage()">{{ 'Error_Text_Button' | appText }}</button>
            </div>
        </div>
        <div class="footer">
            <div class="darwin-logo">
                <img alt="" [src]="darwinLogoUrl" />
            </div>
        </div>
    </div>
</ng-template>
