import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { TextService } from 'core/text.service';

import { OverlayPosition, TextContentClickEvent } from 'shared/overlay/overlay.model';
import { OverlayService } from 'shared/overlay/overlay.service';

@Injectable()
export class PolicyService {
    constructor(private textService: TextService, private overlayService: OverlayService) {}

    showTermsAndConditions(keepExistingOverlay = false) {
        const text = this.textService.getText('Global_TermsAndConditions_Description');

        const obs = new Subject<void>();
        obs.subscribe(() => {
            this.showPrivacyPolicy(keepExistingOverlay);
        });

        const clickEvents: TextContentClickEvent[] = [
            {
                identifier: '[PrivacyPolicy]',
                textReplacement: this.textService.getText('Global_PrivacyPolicy'),
                observer: obs
            }
        ];

        this.overlayService.openTextContent(text, clickEvents, { position: OverlayPosition.Centre, keepExistingOverlay });
    }

    showPrivacyPolicy(keepExistingOverlay = false) {
        const text = this.textService.getText('Global_PrivacyPolicy_Description');

        const obs = new Subject<void>();
        obs.subscribe(() => {
            this.showTermsAndConditions(keepExistingOverlay);
        });

        const clickEvents: TextContentClickEvent[] = [
            {
                identifier: '[TermsAndConditions]',
                textReplacement: this.textService.getText('Global_TermsAndConditions'),
                observer: obs
            }
        ];

        this.overlayService.openTextContent(text, clickEvents, { position: OverlayPosition.Centre, keepExistingOverlay });
    }

    showCookiesPolicy(keepExistingOverlay = false) {
        const text = this.textService.getText('Global_CookiesPolicy_Description');

        const obs = new Subject<void>();
        obs.subscribe(() => {
            this.showCookiesPolicy(keepExistingOverlay);
        });

        const clickEvents: TextContentClickEvent[] = [
            {
                identifier: '[CookiesPolicy]',
                textReplacement: this.textService.getText('Global_CookiesPolicy'),
                observer: obs
            }
        ];

        this.overlayService.openTextContent(text, clickEvents, { position: OverlayPosition.Centre, keepExistingOverlay });
    }

    showSecurityPolicy(keepExistingOverlay = false) {
        const text = this.textService.getText('Global_SecurityPolicy_Description');
        this.overlayService.openTextContent(text, [], { position: OverlayPosition.Centre, keepExistingOverlay });
    }
}
