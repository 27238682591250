import { Injectable } from '@angular/core';

@Injectable()
export class OverlayAccordionHelper {
    collapseAccordionsExceptActive(accordions: any[], activeAccordionIndex: number) {
        accordions.forEach(function (value, index) {
            value.expanded = index === activeAccordionIndex;
        });
    }
}
