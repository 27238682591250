import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ApiHttpClient } from 'core/api-http-client';
import { EmployeeManagerStatsCount } from 'core/models/line-manager.model';

@Injectable()
export class ApprovalService {
    claimApprovalStatistics: EmployeeManagerStatsCount;
    pendingActivityRequestCount: number;
    managerApprovalCounterUpdate$: Observable<void>;
    managerApprovalAdjudicated$: Observable<void>;

    private managerApprovalSubject: Subject<void> = new Subject();
    private managerApprovalRefreshSubject: Subject<void> = new Subject();

    constructor(private httpClient: ApiHttpClient) {
        this.managerApprovalCounterUpdate$ = this.managerApprovalSubject.asObservable();
        this.managerApprovalAdjudicated$ = this.managerApprovalRefreshSubject.asObservable();
    }

    detailManagerClaimsStatistics(): Observable<EmployeeManagerStatsCount> {
        return this.httpClient.get<EmployeeManagerStatsCount>('/EmployeeLineManager/DetailManagerClaimsStatistics');
    }

    countManagerEffectiveReceivedPendingRequest(): Observable<number> {
        return this.httpClient.get<number>('/EmployeeLineManager/CountManagerEffectiveReceivedPendingRequest');
    }

    notifyClaimAdjudicated() {
        this.managerApprovalRefreshSubject.next();
    }

    setApprovalStatistics(stats: EmployeeManagerStatsCount) {
        this.claimApprovalStatistics = stats;

        this.managerApprovalSubject.next();
    }

    setPendingRequestCount(requests: number) {
        this.pendingActivityRequestCount = requests;

        this.managerApprovalSubject.next();
    }

    getTotalPendingCount() {
        return (this.claimApprovalStatistics.pendingManagerCount || 0) + (this.pendingActivityRequestCount || 0);
    }
}
