import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { EventService, EventType } from 'core/event.service';
import { LayoutService } from 'core/layout/layout.service';
import { ScrollService } from 'core/scroll.service';

import { fadeInOut } from 'shared/animations/fade-in-out.animation';

@Component({
    selector: 'scrollToTopArrow',
    templateUrl: 'scroll-to-top-arrow.component.html',
    styleUrls: ['scroll-to-top-arrow.component.less'],
    animations: [fadeInOut]
})
export class ScrollToTopArrowComponent implements OnInit, OnDestroy {
    showButton: boolean = false;
    buttonMarginBottom: number = 0;

    private scrollSubscription: Subscription = Subscription.EMPTY;
    private resizeSubscription: Subscription = Subscription.EMPTY;

    constructor(private scrollService: ScrollService, private layoutService: LayoutService, private eventService: EventService) {}

    ngOnInit() {
        this.scrollSubscription = this.scrollService.getScrollEvent().subscribe(() => this.showScrollToTopButton());
        this.resizeSubscription = this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe(() => this.showScrollToTopButton());
    }

    ngOnDestroy() {
        this.scrollSubscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

    scrollToTop() {
        this.scrollService.scrollToTop();
    }

    private showScrollToTopButton() {
        const topMenuComponentHeight = this.layoutService.getHeaderMenuHeight();
        this.showButton = window.pageYOffset >= topMenuComponentHeight;

        const footerPixelsOnScreen = this.layoutService.getFooterPixelsOnScreen();

        if (this.buttonMarginBottom != footerPixelsOnScreen) {
            this.buttonMarginBottom = footerPixelsOnScreen;
        }
    }
}
