import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SecurityService } from 'core/security.service';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
    constructor(private securityService: SecurityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403) {
                    this.securityService.logout();
                    return of<HttpEvent<HttpErrorResponse>>();
                }

                return throwError(error);
            })
        );
    }
}
