import { Injectable } from '@angular/core';

import { ConfigurationService } from 'core/configuration.service';
import { AccountSettingsState } from 'core/models/configuration.model';
import { MenuItem, MenuItems } from 'core/models/navigation.model';
import { TextService } from 'core/text.service';

@Injectable()
export class MenuNavigationService {
    menuItems: MenuItems;

    constructor(private configurationService: ConfigurationService, private textService: TextService) {
        this.menuItems = <MenuItems>{
            profileMenuItems: [],
            navigationItems: [],
            selectionMenuItems: [],
            helpMenuItems: [],
            managerMenuItems: [],
            applicationFormMenuItems: []
        };

        if (this.configurationService.configuration.pageConfig.home.isEnabled) {
            this.menuItems.navigationItems.push(<MenuItem>{
                displayName: textService.getText('Nav_Menu_Home'),
                routerLink: '/home'
            });
        }

        if (this.configurationService.configuration.pageConfig.benefitManager.isEnabled) {
            this.menuItems.navigationItems.push(<MenuItem>{
                displayName: textService.getText('Global_Benefits'),
                routerLink: '/selection'
            });

            this.menuItems.selectionMenuItems.push(<MenuItem>{
                displayName: textService.getText('Global_Benefits'),
                routerLink: '/selection/overview'
            });

            this.menuItems.selectionMenuItems.push(<MenuItem>{
                displayName: textService.getText('BenefitSelection_PostSelection_Title'),
                routerLink: '/selection/post-selection'
            });
        }

        if (this.configurationService.configuration.pageConfig.engager.isEnabled) {
            this.menuItems.navigationItems.push(<MenuItem>{
                displayName: textService.getText('Global_TotalRewardStatement'),
                routerLink: '/engager'
            });
        }

        if (this.configurationService.configuration.pageConfig.reimbursement.isEnabled) {
            this.menuItems.navigationItems.push(<MenuItem>{
                displayName: textService.getText('Global_Reimbursement'),
                routerLink: '/reimbursement/home'
            });
        }

        if (this.configurationService.configuration.pageConfig.profile.isEnabled) {
            this.menuItems.profileMenuItems.push(<MenuItem>{
                displayName: this.getProfileDisplayName(),
                routerLink: '/profile',
                iconName: 'user-2'
            });

            if (this.configurationService.configuration.pageConfig.profile.dependantsPageEnabled) {
                this.menuItems.profileMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Global_Dependants_Details'),
                    routerLink: '/profile/dependants',
                    iconName: 'user-group'
                });
            }

            if (this.configurationService.configuration.pageConfig.profile.beneficiaryPageEnabled) {
                this.menuItems.profileMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Global_Beneficiaries'),
                    routerLink: '/profile/beneficiaries',
                    iconName: 'person-cash'
                });
            }

            if (this.configurationService.configuration.pageConfig.profile.lifeEventsPageEnabled) {
                this.menuItems.profileMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Global_LifeEvents'),
                    routerLink: '/profile/lifeEvents',
                    iconName: 'employee-badge'
                });
            }

            if (this.configurationService.configuration.pageConfig.profile.agreementsAndCorrespondencePageEnabled) {
                this.menuItems.profileMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Profile_AgreementsAndCorrespondence'),
                    routerLink: '/profile/agreements',
                    iconName: 'file-checklist-quill'
                });
            }
        }

        if (this.configurationService.configuration.pageConfig.help.isEnabled) {
            if (this.configurationService.configuration.pageConfig.help.benefitInformationPageEnabled) {
                this.menuItems.helpMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Global_BenefitInformation'),
                    routerLink: '/help/benefit-information',
                    iconName: 'book-heart'
                });
            }

            if (this.configurationService.configuration.pageConfig.help.faqsPageEnabled) {
                this.menuItems.helpMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Global_Faqs'),
                    routerLink: '/help/faqs',
                    iconName: 'bubble-chat-question'
                });
            }

            if (this.configurationService.configuration.pageConfig.help.contactUsPageEnabled) {
                this.menuItems.helpMenuItems.push(<MenuItem>{
                    displayName: textService.getText('Global_ContactUs'),
                    routerLink: '/help/contact-us',
                    iconName: 'phone-book'
                });
            }
        }

        this.menuItems.managerMenuItems.push(<MenuItem>{
            displayName: textService.getText('EmployeeLineManager_NavTab_ManagerApproval'),
            routerLink: '/manager/approval',
            iconName: 'tools-hammer'
        });

        this.menuItems.managerMenuItems.push(<MenuItem>{
            displayName: textService.getText('EmployeeLineManager_NavTab_ManagerTeam'),
            routerLink: '/manager/team'
        });

        this.configurationService.configuration.navigationMenu.hubPageNavigationDetails.forEach(page => {
            if (page.displayInMenu) {
                this.menuItems.navigationItems.push(<MenuItem>{
                    displayName: page.displayName,
                    routerLink: `/hub/${page.id}`
                });
            }
        });

        this.menuItems.applicationFormMenuItems.push(<MenuItem>{
            displayName: textService.getText('Reimbursement_ClaimList_Label_Claims'),
            routerLink: '/reimbursement/home'
        });

        this.configurationService.configuration.pageConfig.applicationForm.applicationTypeLinks.forEach(link => {
            this.menuItems.applicationFormMenuItems.push(<MenuItem>{
                displayName: link.displayName,
                routerLink: `/reimbursement/application-type/${link.id}`
            });
        });
    }

    private getProfileDisplayName(): string {
        if (this.configurationService.configuration.options.accountSettingsState == AccountSettingsState.Hidden) {
            return this.textService.getText('Global_Your_Profile');
        }

        return this.textService.getText('Global_Profile_And_Account_Settings');
    }
}
