import { CallToAction, SchemeContentFile } from 'core/models/configuration.model';

export class EmbeddedContent {
    title: string;
    subtitle: string;
    description: string;
    ariaLabel: string;
    contentNode: IContentNodeImpl;
    tag: string;
}

export type IContentNodeImpl = ContentHeaderNode &
    ContentTextNode &
    ContentImageNode &
    ContentVideoNode &
    ContentLinkNode &
    ContentDocumentNode &
    ContentExpanderNode &
    ContentExpanderBlockNode &
    ContentTextBlockNode &
    ContentMultiContentNode;

export interface IContentNode {
    nodeItemType: ContentNodeItemType;
    _clickable: boolean;
    _actionLinkText?: string;
    _ariaLabel?: string;
    _redirectAndVideo?: boolean;
}

export enum ContentNodeItemType {
    None = 0,
    Header = 1,
    Text = 2,
    Image = 3,
    Expander = 4,
    Link = 5,
    Document = 6,
    MultiContent = 7,
    Video = 8,
    TextBlock = 100
}

export enum ContentLinkType {
    None = 0,
    RedirectInternal = 1,
    RedirectExternal = 2,
    OutboundSso = 3
}

export enum CardActionLinkType {
    None = 0,
    MinuteRead = 1,
    ReadMore = 2
}

export interface Content {
    languageID: number;
    contentNode: IContentNodeImpl;
}

export interface ContentHeaderNode extends IContentNode {
    headerText: string;
}

export interface ContentTextNode extends IContentNode {
    text: string;
}

export interface ContentImageNode extends IContentNode {
    fileName: string;
    file: SchemeContentFile;
}

export interface ContentVideoNode extends IContentNode {
    fileName: string;
    file: SchemeContentFile;
    _iconId: string;
}

export interface ContentLinkNode extends IContentNode {
    url: string;
    text: string;
    openInNewWindow: boolean;
    serviceProviderID?: any;
    contentLinkType: ContentLinkType;
    ariaLabel: string;
    _iconId: string;
    _id: string;
}

export interface ContentDocumentNode extends IContentNode {
    fileName: string;
    file: SchemeContentFile;
    ariaLabel: string;
    _iconId: string;
    _id: string;
}

export interface ContentExpanderNode extends IContentNode {
    expanderHeader: string;
    isExpanded: boolean;
    text: string;
}

export interface ContentExpanderBlockNode extends IContentNode {
    expanderHeader: string;
    isExpanded: boolean;
    contentNodes: IContentNodeImpl[];
}

export interface ContentTextBlockNode extends IContentNode {
    contentNodes: IContentNodeImpl[];
}

export interface ContentMultiContentNode extends IContentNode {
    contentNodes: IContentNodeImpl[];
    callToAction: CallToAction;
    ariaLabel: string;
    _iconId: string;
    actionLinkType: CardActionLinkType;
}
