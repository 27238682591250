import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'privacy-toggle-popup',
    templateUrl: './privacy-toggle-popup.component.html',
    styleUrls: ['./privacy-toggle-popup.component.less']
})
export class PrivacyTogglePopupComponent implements AfterViewInit {
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    isVisible: boolean = false;

    ngAfterViewInit() {
        setTimeout(() => {
            this.isVisible = true;
        }, 1000);
    }

    onClose() {
        this.close.emit();
    }
}
