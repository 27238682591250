import { AfterViewInit, Component, ElementRef, EventEmitter, Output } from '@angular/core';

import { MenuAction, MenuItem } from 'shared/menu/menu.model';

@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less']
})
export class MenuComponent implements AfterViewInit {
    @Output() closeMenu = new EventEmitter<string>();

    menuItems: MenuItem[];

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit() {
        (this.elementRef.nativeElement.firstElementChild as HTMLElement).focus();
    }

    executeFunction(action: MenuAction): void {
        this.closeMenu.emit(action.actionID);
    }
}
