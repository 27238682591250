<ng-template #template>
    <h3 id="{{ id }}" class="accordion-item heading-22-b">
        <button
            id="{{ 'acc-' + id }}"
            class="btn-transparent header-container"
            [disabled]="disabled"
            (click)="toggleItem()"
            [attr.aria-controls]="'content-' + id"
            [attr.aria-expanded]="expanded">
            <span class="header">
                <span class="header-text" role="heading">
                    <ng-content select=".accordion-item-title"></ng-content>
                </span>
                <span [@rotateBlob]="!expanded" class="expand-collapse-container" aria-hidden="true">
                    <icon class="icon-xxs expand-collapse-icon" iconId="right"></icon>
                </span>
            </span>
            <span role="heading" class="header-value">
                <ng-content select=".accordion-item-title-value"></ng-content>
            </span>
        </button>
    </h3>
    <div
        class="content-container"
        id="{{ 'content-' + id }}"
        role="region"
        tabindex="0"
        [attr.aria-labelledby]="'acc-' + id"
        [attr.aria-hidden]="!expanded"
        [@expandCollapse]="expanded"
        (@expandCollapse.done)="onExpandCollapseDone()">
        <ng-content select=".accordion-item-content"></ng-content>
    </div>
</ng-template>
