import { Injectable } from '@angular/core';

import { Token } from 'core/models/security.model';
import { SessionStorageKey as GlobalSessionStorageKey, SessionStorageService } from 'core/session-storage.service';

const enum SessionStorageKey {
    accessToken = 'rcToken',
    refreshToken = 'rcRefreshToken'
}

@Injectable()
export class TokenService {
    constructor(private sessionStorageService: SessionStorageService) {}

    saveTokens(accessToken: Token, refreshToken: Token) {
        const sessionAccessToken: Token = { ...accessToken, _browserExpiry: this.calculateBrowserExpiry(accessToken) };
        this.sessionStorageService.setItem(SessionStorageKey.accessToken, sessionAccessToken);

        const sessionRefreshToken: Token = { ...refreshToken, _browserExpiry: this.calculateBrowserExpiry(refreshToken) };
        this.sessionStorageService.setItem(SessionStorageKey.refreshToken, sessionRefreshToken);
    }

    getAccessToken(): Token | null {
        const token: Token = this.sessionStorageService.getItem(SessionStorageKey.accessToken);
        return this.validate(token);
    }

    saveMfaTokens(mfaAccessToken: Token) {
        if (!mfaAccessToken) {
            return;
        }

        const sessionAccessToken: Token = { ...mfaAccessToken, _browserExpiry: this.calculateBrowserExpiry(mfaAccessToken) };
        this.sessionStorageService.setItem(GlobalSessionStorageKey.mfaAccessToken, sessionAccessToken);
    }

    getMfaAccessToken(): Token | null {
        const token: Token = this.sessionStorageService.getItem(GlobalSessionStorageKey.mfaAccessToken);
        return this.validate(token);
    }

    removeMfaAccessToken() {
        this.sessionStorageService.removeItem(GlobalSessionStorageKey.mfaAccessToken);
    }

    getRefreshToken(): Token | null {
        const token: Token = this.sessionStorageService.getItem(SessionStorageKey.refreshToken);
        return this.validate(token);
    }

    removeAccessToken() {
        this.sessionStorageService.removeItem(SessionStorageKey.accessToken);
    }

    private calculateBrowserExpiry(token: Token): string {
        const browserDateTime = new Date();
        const serverDateTimeInLocal = new Date(token.created);
        const diff = browserDateTime.getTime() - serverDateTimeInLocal.getTime();

        const browserExpiryTimeValue = new Date(token.expiry).getTime() + diff;

        return new Date(browserExpiryTimeValue).toISOString();
    }

    private validate(token: Token): Token | null {
        if (token && new Date(token._browserExpiry) > new Date()) {
            return token;
        }

        return null;
    }
}
