import { Injectable } from '@angular/core';

@Injectable()
export class AccessibilityService {
    constructor() {}

    findFirstTabbableElement(element: HTMLElement) {
        const elementChildren = element.children;
        for (let i = 0; i < elementChildren.length; i++) {
            const elementChild = <HTMLElement>elementChildren[i];

            if (this.isTabbable(elementChild)) {
                return elementChild;
            }

            if (elementChild.children && elementChild.children.length > 0) {
                const ret = this.findFirstTabbableElement(elementChild);
                if (ret) {
                    return ret;
                }
            }
        }

        return undefined;
    }

    private isTabbable(element: HTMLElement) {
        const tabIndex = this.getElementTabIndex(element),
            isTabIndexNan = isNaN(tabIndex);
        return (isTabIndexNan || tabIndex >= 0) && this.focusable(element);
    }

    private getElementTabIndex(element: HTMLElement): number {
        const nodeName = element.nodeName.toLowerCase();
        if (/^(input|select|textarea|button|object|a|area)$/.test(nodeName)) {
            return element.tabIndex;
        }

        const tabindex = element.getAttribute('tabIndex');
        return tabindex !== null ? Number(tabindex) : -1;
    }

    private focusable(element: HTMLElement) {
        let map, mapName, img;
        const nodeName = element.nodeName.toLowerCase(),
            isTabIndexNotNan = !isNaN(this.getElementTabIndex(element));

        if ('area' === nodeName) {
            map = element.parentNode;
            if (element.tagName !== 'A' || map.nodeName.toLowerCase() !== 'map') {
                return false;
            }
            img = element.querySelector('img[usemap=#' + mapName + ']')[0];
            return !!img && this.visible(img);
        }

        if ('a' === nodeName) {
            if (element.tagName !== 'A') {
                return false;
            }

            const hasHrefAttribute = element.hasAttribute('href');
            const tabIndex = element.getAttribute('tabIndex');
            const hasFocusableTabIndex = tabIndex !== null && Number(tabIndex) >= 0;

            return (hasHrefAttribute || hasFocusableTabIndex) && this.visible(element);
        }

        return this.visible(element)
            ? /^(input|select|textarea|button|object)$/.test(nodeName)
                ? element.getAttribute('disabled') === null
                : isTabIndexNotNan
            : false;
    }

    private visible(element: HTMLElement) {
        return (
            element.offsetHeight !== 0 &&
            element.offsetWidth !== 0 &&
            element.style.visibility !== 'hidden' &&
            element.getAttribute('visibility') !== 'hidden' &&
            !element.hidden &&
            window.getComputedStyle(element).visibility !== 'hidden'
        );
    }
}
