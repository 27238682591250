import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import { FormatService } from 'core/format.service';
import { DataType } from 'core/models/data-type.model';
import { EmployeeLifeEvent } from 'core/models/life-event.model';
import {
    AdditionalDependantEvidence,
    BeneficiariesLayoutDetail,
    BeneficiaryDetail,
    BeneficiaryModel,
    DependantDetail,
    DependantFileUpload,
    DependantsDetail,
    ListItem,
    ProfileCard,
    ProfileField,
    ProfileFieldValue,
    ProfileGroup,
    ProfileUpdateResult
} from 'core/models/profile.model';
import { ValidationMessage } from 'core/models/validation.model';
import { AssigneeType } from 'core/selection.model';
import { TextService } from 'core/text.service';

import { IContentNodeImpl } from 'shared/components/content-renderer/content-renderer.model';

@Injectable()
export class CoreProfileService {
    dependantsDetail: DependantsDetail;
    beneficiariesLayoutDetail: BeneficiariesLayoutDetail;
    dependantInformationMessage: IContentNodeImpl;
    beneficiaryInformationMessage: IContentNodeImpl;
    beneficiaryForAdd: BeneficiaryDetail;

    validateFieldsSubject = new Subject<string[]>();

    private allProfileCardsFields: ProfileField[];
    private deleteLockedBeneficiaryIDs: string[];

    constructor(private httpClient: ApiHttpClient, private textService: TextService, private formatService: FormatService) {}

    loadDependants(): Observable<DependantsDetail> {
        return this.httpClient.get<DependantsDetail>('/Profile/LoadDependants').pipe(
            map(dependantsDetail => {
                this.dependantInformationMessage = dependantsDetail.informationMessage;
                this.dependantsDetail = dependantsDetail;
                this.attachDependantsInformationForProfile(dependantsDetail.dependants);
                this.attachDependantsInformationForProfile(dependantsDetail.pendingDependants);

                this.attachFieldsInformation(this.dependantsDetail.dependantForAdd.fields);
                this.groupDependantFieldsForProfile(this.dependantsDetail.dependantForAdd);

                return dependantsDetail;
            })
        );
    }

    loadDependantsLayout(): Observable<ProfileGroup[]> {
        return this.httpClient.get<ProfileGroup[]>('/Profile/GetDependantsLayoutDetail').pipe(
            map(profileGroups => {
                this.initDependantDetails();

                this.dependantsDetail.groups = profileGroups;
                return profileGroups;
            })
        );
    }

    getBeneficiary(beneficiaryID: string, dependantID: string, assigneeType: AssigneeType): Observable<BeneficiaryDetail> {
        return this.httpClient
            .get<BeneficiaryDetail>('/Profile/GetBeneficiary', [
                { key: 'beneficiaryID', value: beneficiaryID },
                { key: 'dependantID', value: dependantID },
                { key: 'assigneeType', value: assigneeType }
            ])
            .pipe(
                map(ret => {
                    this.attachBeneficiariesInformation(assigneeType, beneficiaryID, ret);
                    return ret;
                })
            );
    }

    loadLayout(): Observable<BeneficiariesLayoutDetail> {
        return this.httpClient.get<BeneficiariesLayoutDetail>('/Profile/GetBeneficiariesLayoutDetail').pipe(
            map(data => {
                this.beneficiaryInformationMessage = data.informationMessage;
                this.beneficiariesLayoutDetail = data;
                return data;
            })
        );
    }

    loadBeneficiariesPage(): Observable<BeneficiaryModel> {
        return this.httpClient.get<BeneficiaryModel>('/Profile/LoadBeneficiariesPage').pipe(
            map(data => {
                this.deleteLockedBeneficiaryIDs = data.deleteLockedBeneficiaryIDs;

                this.beneficiaryForAdd = data.beneficiaryForAdd;

                this.attachFieldsInformation(this.beneficiaryForAdd.fields);
                this.attachBeneficiariesCardsInformation(AssigneeType.employeeBeneficiary, this.beneficiaryForAdd);

                return data;
            })
        );
    }

    refreshProfileCardsFields(updatedFields: { [id: string]: any }, currentCard: ProfileCard, fieldIDsFilter: string[] = null): Observable<ProfileField[]> {
        return this.httpClient.post<{ [id: string]: any }>('/Profile/Refresh', { updatedFields }).pipe(
            map((refreshedFields: ProfileField[]) => {
                this.attachFieldsInformation(refreshedFields);
                const newFields = [];

                let filteredRefreshedFields: ProfileField[] = [];

                if (fieldIDsFilter) {
                    refreshedFields.forEach(field => {
                        if (fieldIDsFilter.find(fieldID => fieldID == field.id)) {
                            filteredRefreshedFields.push(field);
                        }
                    });
                } else {
                    filteredRefreshedFields = refreshedFields;
                }

                filteredRefreshedFields.forEach(field => {
                    if (!this.allProfileCardsFields.some(profileCardField => profileCardField.id == field.id)) {
                        if (!currentCard.groups.some(group => group.fields.some(x => x == field.id))) {
                            newFields.push(field);
                        }
                    }
                });

                this.setAllProfileCardsFields(filteredRefreshedFields);

                return newFields;
            })
        );
    }

    getAllProfileCardsFields(): ProfileField[] {
        return this.allProfileCardsFields;
    }

    setAllProfileCardsFields(fields: ProfileField[]) {
        this.allProfileCardsFields = fields;
    }

    update(fields: { [id: string]: any }): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Profile/Update', fields);
    }

    updateDependant(
        dependantID: string,
        updatedFields: { [id: string]: any },
        dependantFileUploads: DependantFileUpload[],
        additionalDependantEvidence: AdditionalDependantEvidence
    ): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Profile/UpdateDependant', {
            id: dependantID,
            updatedFields,
            dependantFileUploads,
            additionalDependantEvidence
        });
    }

    updateBeneficiary(
        beneficiaryID: string,
        updatedFields: { [id: string]: any },
        assigneeType: AssigneeType,
        dependantFileUploads: DependantFileUpload[]
    ): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Profile/UpdateBeneficiary', {
            id: beneficiaryID,
            updatedFields: updatedFields,
            dependantFileUploads: dependantFileUploads,
            assigneeType: assigneeType
        });
    }

    validateProfile(updatedFields: { [id: string]: any }, employeeLifeEvent: EmployeeLifeEvent = undefined) {
        return this.httpClient.post<{ [id: string]: any }>('/Profile/Validate', { updatedFields: updatedFields, employeeLifeEvent: employeeLifeEvent });
    }

    validateDependant(
        dependantID: string,
        updatedFields: { [id: string]: any },
        dependantFileUploads: DependantFileUpload[],
        employeeLifeEvent: EmployeeLifeEvent,
        additionalDependantEvidence: AdditionalDependantEvidence = null
    ): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Profile/ValidateDependant', {
            id: dependantID,
            updatedFields: updatedFields,
            dependantFileUploads: dependantFileUploads,
            employeeLifeEvent: employeeLifeEvent,
            additionalDependantEvidence: additionalDependantEvidence
        });
    }

    validateBeneficiary(beneficiaryID: string, updatedFields: { [id: string]: any }): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Profile/ValidateBeneficiary', {
            id: beneficiaryID,
            updatedFields: updatedFields
        });
    }

    getFormField(field: ProfileField, isReadonly: boolean = false): FormControl {
        return new FormControl(
            { value: field.value !== undefined ? field.value : '', disabled: !field.editable || isReadonly },
            this.getFormFieldValidators(field)
        );
    }

    refreshFormFieldValidators(formField: AbstractControl, field: ProfileField) {
        formField.clearValidators();
        formField.setValidators(this.getFormFieldValidators(field));
    }

    getFieldValidationMessages(field: ProfileField, fieldValidationMessages: ValidationMessage[]) {
        if (fieldValidationMessages === undefined) {
            return null;
        }

        const validationMessages: string[] = [];

        fieldValidationMessages.forEach(validationMessage => {
            let message: string;

            message = this.textService.getText(validationMessage.message);

            if (message === 'key_' + validationMessage.message + '_missing') {
                message = validationMessage.message;
            }

            if (validationMessage.replacements && validationMessage.replacements.length) {
                validationMessage.replacements.forEach(replacement => {
                    let formattedValue: any = replacement.value;

                    if (field.dataType === DataType.date) {
                        formattedValue = this.formatService.formatDate(new Date(replacement.value));
                    } else if (field.dataType === DataType.numeric) {
                        formattedValue = this.formatService.formatNumber(Number(replacement.value));
                    }

                    message = message.replace(`{${replacement.key}}`, formattedValue);
                });
            }

            if (validationMessage.property) {
                message = `${validationMessage.property}: ${message}`;
            }

            validationMessages.push(message);
        });

        return validationMessages;
    }

    attachFieldsInformation(fields: ProfileField[]) {
        fields.forEach(field => {
            if (field.value && field.dataType === DataType.list) {
                const listItem: ListItem = field.listItems.find(item => item.id === field.value.toString());

                if (listItem) {
                    field._listItemName = listItem.name;
                }
            }
        });
    }

    isPreSelectionFieldInvalid(field): boolean {
        return field.mandatory && (field.value === null || field.value === '');
    }

    validateFields(fieldIds: string[]): void {
        this.validateFieldsSubject.next(fieldIds);
    }

    refreshDependant(dependantID: string, updatedFields: { [id: string]: any }, employeeLifeEvent: EmployeeLifeEvent): Observable<DependantDetail> {
        return this.httpClient.post<DependantDetail>('/Profile/RefreshDependant', {
            id: dependantID,
            updatedFields: updatedFields,
            employeeLifeEvent: employeeLifeEvent
        });
    }

    refreshBeneficiary(beneficiaryID: string, updatedFields: { [id: string]: any }, assigneeType: AssigneeType): Observable<BeneficiaryDetail> {
        return this.httpClient.post<BeneficiaryDetail>('/Profile/RefreshBeneficiary', {
            id: beneficiaryID,
            updatedFields: updatedFields,
            assigneeType: assigneeType
        });
    }

    groupDependantFieldsForPreSelection(dependant: DependantDetail) {
        this.groupDependantFields(dependant, null, true);
    }

    groupDependantFieldsForProfile(dependant: DependantDetail) {
        this.groupDependantFields(dependant, this.dependantsDetail.groups, false);
    }

    attachBeneficiariesCardsInformation(assigneType: AssigneeType, beneficiary: BeneficiaryDetail) {
        const beneficiaryGroups: ProfileGroup[] = [];
        const fieldsConfiguredInLayout = [];
        let profileGroup = [];
        let index: number = 0;

        if (assigneType === AssigneeType.employeeBeneficiary) {
            profileGroup = this.beneficiariesLayoutDetail.beneficiaryGroups;
        } else {
            profileGroup = this.beneficiariesLayoutDetail.dependantGroups;
        }

        profileGroup.forEach(group => {
            fieldsConfiguredInLayout.push(...group.fields);
            index++;

            beneficiaryGroups.push(<ProfileGroup>{
                displayName: group.displayName,
                _fields: this.getGroupFields(group.fields, beneficiary.fields),
                _id: this.generateProfileGroupID(index, group._id)
            });
        });

        const fieldsNotAddedToGroups = beneficiary.fields.filter(field => !fieldsConfiguredInLayout.some(fieldId => fieldId === field.id));

        if (fieldsNotAddedToGroups.length > 0) {
            beneficiaryGroups.push(<ProfileGroup>{
                displayName: this.textService.getText('Profile_Card_FurtherInformation'),
                _fields: fieldsNotAddedToGroups,
                _id: 'accordion-group-further-info'
            });
        }

        if (beneficiary.dependantEvidences && beneficiary.dependantEvidences.length > 0) {
            beneficiaryGroups.push(<ProfileGroup>{
                displayName: this.textService.getText('DependantEvidence_DependantsEvidence'),
                _isDependantEvidenceGroup: true,
                _id: 'accordion-group-dependant-evidence'
            });
        }

        beneficiary._groups = beneficiaryGroups;
        beneficiary._hasLayoutGroups = fieldsConfiguredInLayout.length > 0;
    }

    getGroupFields(fieldIDs: any[], fields: ProfileField[]): ProfileField[] {
        const groupFields = <ProfileField[]>[];

        fieldIDs.forEach(fieldID => {
            const fieldDetails = fields.find(field => field.id === fieldID);

            if (fieldDetails !== undefined) {
                groupFields.push(fieldDetails);
            }
        });

        return groupFields;
    }

    hasPendingChanges(fields: ProfileField[], pendingFieldValues: { [id: string]: ProfileFieldValue[] }) {
        if (!pendingFieldValues) {
            return false;
        }

        return fields.some(field => pendingFieldValues[field.id] && pendingFieldValues[field.id].length);
    }

    deleteDependant(dependantID: string): any {
        return this.httpClient.get<string>('/Profile/DeleteDependant', [{ key: 'dependantID', value: dependantID }]);
    }

    deleteBeneficiary(beneficiaryID: string): any {
        return this.httpClient.get<string>('/Profile/DeleteBeneficiary', [{ key: 'beneficiaryID', value: beneficiaryID }]);
    }

    loadDependantForAdd(): Observable<any> {
        const sources = [];

        sources.push(this.httpClient.get<DependantDetail>('/Profile/GetDependantForAdd'));

        if (!this.dependantsDetail || !this.dependantsDetail.groups) {
            sources.push(this.loadDependantsLayout());
        }

        return forkJoin(...sources).pipe(
            tap(ret => {
                this.initDependantDetails();

                this.dependantsDetail.dependantForAdd = ret[0];

                this.attachFieldsInformation(this.dependantsDetail.dependantForAdd.fields);
                this.groupDependantFieldsForProfile(this.dependantsDetail.dependantForAdd);
            })
        );
    }

    loadBeneficiaryForAdd(): Observable<any> {
        const sources = [];

        sources.push(this.httpClient.get<BeneficiaryDetail>('/Profile/GetBeneficiaryForAdd'));

        if (this.beneficiariesLayoutDetail === undefined) {
            sources.push(this.loadLayout());
        }

        return forkJoin(...sources).pipe(
            tap(ret => {
                this.beneficiaryForAdd = ret[0];

                this.attachFieldsInformation(this.beneficiaryForAdd.fields);
                this.attachBeneficiariesCardsInformation(AssigneeType.employeeBeneficiary, this.beneficiaryForAdd);
            })
        );
    }

    attachDependantsInformationForPreSelection(dependantsDetail: DependantDetail[]) {
        dependantsDetail.forEach(dependant => {
            this.attachDependantFields(dependant);
            this.groupDependantFieldsForPreSelection(dependant);
        });
    }

    attachDependantsInformationForProfile(dependantsDetail: DependantDetail[]) {
        dependantsDetail.forEach(dependant => {
            this.attachDependantFields(dependant);
            this.groupDependantFieldsForProfile(dependant);
        });
    }

    attachDependantFields(dependant: DependantDetail) {
        this.attachFieldsInformation(dependant.fields);
        this.attachPendingFieldValuesInformation(dependant.pendingLifeEventFieldValues, dependant.fields);
        dependant._isEditable = dependant.fields.some(field => field.editable);
    }

    initDependantDetails() {
        if (!this.dependantsDetail) {
            this.dependantsDetail = <DependantsDetail>{
                dependants: [],
                groups: []
            };
        }
    }

    attachPendingFieldValuesInformation(
        pendingLifeEventFieldValues: { [id: string]: ProfileFieldValue[] },
        fields: ProfileField[]
    ): { [id: string]: ProfileFieldValue[] } {
        if (!pendingLifeEventFieldValues) {
            return;
        }

        fields.forEach(field => {
            if (pendingLifeEventFieldValues[field.id] && pendingLifeEventFieldValues[field.id].length) {
                pendingLifeEventFieldValues[field.id].forEach(fieldValue => {
                    if (field.dataType === DataType.list && field.listItems && fieldValue.value) {
                        const listItem = field.listItems.find(li => li.id === fieldValue.value);
                        fieldValue._listItemName = listItem ? listItem.name : '';
                    }
                });
            }
        });
    }

    private groupDependantFields(dependant: DependantDetail, configuredDependantGroups: ProfileGroup[], excludeDependantEvidence: boolean) {
        const dependantGroups: ProfileGroup[] = [];
        const fieldsConfiguredInLayout = [];
        let index: number = 0;

        if (configuredDependantGroups) {
            configuredDependantGroups.forEach(group => {
                fieldsConfiguredInLayout.push(...group.fields);
                index++;

                const groupFields = this.getGroupFields(group.fields, dependant.fields);

                dependantGroups.push(<ProfileGroup>{
                    displayName: group.displayName,
                    _fields: groupFields,
                    _hasPendingChanges: this.hasPendingChanges(groupFields, dependant.pendingLifeEventFieldValues),
                    _id: this.generateProfileGroupID(index, group._id)
                });
            });
        }

        const fieldsNotAddedToGroups = dependant.fields.filter(field => !fieldsConfiguredInLayout.some(fieldId => fieldId === field.id));

        if (fieldsNotAddedToGroups.length > 0) {
            dependantGroups.push(<ProfileGroup>{
                displayName: this.textService.getText('Profile_Card_FurtherInformation'),
                _fields: fieldsNotAddedToGroups,
                _hasPendingChanges: this.hasPendingChanges(fieldsNotAddedToGroups, dependant.pendingLifeEventFieldValues),
                _id: 'accordion-group-further-info'
            });
        }

        if (!excludeDependantEvidence && dependant.dependantEvidences && dependant.dependantEvidences.length > 0) {
            dependantGroups.push(<ProfileGroup>{
                displayName: this.textService.getText('DependantEvidence_DependantsEvidence'),
                _isDependantEvidenceGroup: true,
                _id: 'accordion-group-dependant-evidence'
            });
        }

        dependant._groups = dependantGroups;
        dependant._hasLayoutGroups = fieldsConfiguredInLayout.length > 0;
    }

    private attachBeneficiariesInformation(assigneeType: AssigneeType, beneficiaryID: string, beneficiary: BeneficiaryDetail) {
        this.attachFieldsInformation(beneficiary.fields);
        this.attachPendingFieldValuesInformation(beneficiary.pendingLifeEventFieldValues, beneficiary.fields);

        this.attachBeneficiariesCardsInformation(assigneeType, beneficiary);
        beneficiary._deleteLocked = this.deleteLockedBeneficiaryIDs?.findIndex(x => x === beneficiaryID) >= 0;
    }

    private getFormFieldValidators(field: ProfileField): ValidatorFn[] {
        const validators: ValidatorFn[] = [];

        if (field.mandatory) {
            validators.push(Validators.required);
        }

        if (field.minLength !== null) {
            validators.push(Validators.minLength(field.minLength));
        }

        if (field.maxLength !== null) {
            validators.push(Validators.maxLength(field.maxLength));
        }

        if (field.validationMask !== null) {
            validators.push(Validators.pattern(field.validationMask));
        }

        if (field.minValue !== null) {
            validators.push(Validators.min(field.minValue));
        }

        if (field.maxValue !== null) {
            validators.push(Validators.max(field.maxValue));
        }

        return validators;
    }

    private generateProfileGroupID(index: number, profileGroupItem: string): string {
        const accordionGroup: string = '';

        if (!profileGroupItem) {
            profileGroupItem = accordionGroup;
        }

        const incrementProfileGroupID = String(profileGroupItem.replace('', 'accordion-group-')) + index;
        return incrementProfileGroupID;
    }
}
