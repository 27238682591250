import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import { AdminDetail, EmployeeBasic, EmployeeDetail } from 'core/models/employee.model';

@Injectable()
export class EmployeeService {
    employee: EmployeeBasic;
    proxyAdmin: AdminDetail;

    constructor(private httpClient: ApiHttpClient) {}

    load(): Observable<boolean> {
        return this.httpClient.get<EmployeeDetail>('/Employee/BasicDetails').pipe(
            map(employeeDetail => {
                this.employee = employeeDetail.employee;
                this.proxyAdmin = employeeDetail.adminDetail;
                return true;
            })
        );
    }

    updateSelectedLanguage(selectedLanguageId: number): Observable<void> {
        return this.httpClient.post('/Employee/UpdateSelectedLanguage', selectedLanguageId);
    }
}
