import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subject, Subscription, throttle } from 'rxjs';

@Directive({
    selector: 'button'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
    @Input() throttleTime = 1000;
    @Output() throttleClick = new EventEmitter<void>();

    private clicks = new Subject<void>();
    private subscription: Subscription;

    constructor() {}

    ngOnInit(): void {
        this.subscription = this.clicks.pipe(throttle(() => interval(this.throttleTime))).subscribe(() => this.throttleClick.emit());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @HostListener('click')
    clickEvent() {
        this.clicks.next();
    }
}
