import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { ApiHttpClient } from 'core/api-http-client';

@Component({
    selector: 'progressive-image',
    templateUrl: 'progressive-image.component.html',
    styleUrls: ['progressive-image.component.less']
})
export class ProgressiveImageComponent implements AfterViewInit, OnDestroy {
    @Input() imageUrl: string;
    @Input() thumbnailImageUrl: string;
    @Input() hasNoBackgroundColor: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() altText?: string;

    @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    private image: HTMLImageElement;
    private imageContainer: HTMLElement;
    private loaded: boolean = false;

    private blobUrls: string[] = [];

    constructor(private apiHttpClient: ApiHttpClient, private elementRef: ElementRef) {}

    ngOnDestroy() {
        this.blobUrls.forEach(url => {
            URL.revokeObjectURL(url);
        });
    }

    ngAfterViewInit() {
        const nativeElement: HTMLElement = this.elementRef.nativeElement;

        this.imageContainer = <HTMLElement>nativeElement.getElementsByClassName('progressive-image').item(0);
        this.image = <HTMLImageElement>nativeElement.getElementsByClassName('image').item(0);

        if (this.width) {
            this.image.style.width = `${this.width}px`;
        }

        if (this.height) {
            this.image.style.height = `${this.height}px`;
        }

        if (this.thumbnailImageUrl) {
            this.apiHttpClient.getWebMediaFile(this.thumbnailImageUrl).subscribe(response => {
                if (!this.loaded) {
                    const url = URL.createObjectURL(response);
                    this.blobUrls.push(url);
                    this.image.src = url;
                    this.image.classList.add('loaded');
                    this.image.classList.add('image-blur');
                    this.image.onload = () => {
                        this.finishedLoading.emit();
                    };
                }
            });
        } else {
            if (!this.hasNoBackgroundColor) {
                this.imageContainer.classList.add('background-colour');
            }
        }

        this.apiHttpClient.getWebMediaFile(this.imageUrl).subscribe(response => this.setImageProperties(response));
    }

    private setImageProperties(response: Blob) {
        const url = URL.createObjectURL(response);
        this.blobUrls.push(url);
        this.image.src = url;
        this.imageContainer.classList.remove('background-colour');
        this.image.classList.remove('image-blur');
        this.image.classList.add('img');
        this.image.onload = () => {
            this.finishedLoading.emit();
        };
        this.loaded = true;
    }
}
