import { Component, ContentChildren, QueryList } from '@angular/core';

import { ScrollService } from 'core/scroll.service';

import { AccordionTransparentItemComponent } from 'shared/components/accordion-transparent/accordion-transparent-item.component';

@Component({
    selector: 'accordion-transparent',
    templateUrl: 'accordion-transparent.component.html'
})
export class AccordionTransparentComponent {
    @ContentChildren(AccordionTransparentItemComponent) items: QueryList<AccordionTransparentItemComponent>;

    constructor(private scrollService: ScrollService) {}

    scrollToItem(targetItemID: string, expand: boolean) {
        const targetItem = this.items.find(item => item.id === targetItemID);
        targetItem.expanded = targetItem.expanded || expand;

        this.scrollService.scrollToElementID(targetItemID);
    }
}
