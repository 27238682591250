import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { SecurityService } from 'core/security.service';

@Injectable()
export class SecureGuard implements CanActivate, CanActivateChild {
    constructor(private securityService: SecurityService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateInternal();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateInternal();
    }

    private canActivateInternal(): boolean {
        if (this.securityService.isLoggedIn()) {
            if (!this.securityService.rewardCentreEnabled()) {
                this.router.navigate(['/login/disabled']);
                return false;
            }

            return true;
        } else {
            this.securityService.logout();
            return false;
        }
    }
}
