import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import { SecurityService } from 'core/security.service';

@Injectable()
export class DynamicStyleLoaderService {
    private cssVariables: [string];

    constructor(private httpClient: ApiHttpClient, private securityService: SecurityService) {}

    run(): Observable<boolean> {
        if ((window as any).CSS && (window as any).CSS.supports && (window as any).CSS.supports('(--test: red)')) {
            return this.loadStylingVariablesCSS().pipe(
                map(text => {
                    this.addStyle(text, -1);

                    return true;
                })
            );
        } else {
            return this.runReplace();
        }
    }

    private loadStylingVariablesCSS(): Observable<string> {
        if (this.securityService.isLoggedIn()) {
            return this.httpClient.secureGetApiResource('/Configuration/EmployeeSpecificStyling').pipe(map(a => a.body));
        } else {
            return this.httpClient.getApiResource('/Configuration/Styling').pipe(map(a => a.body));
        }
    }

    private loadCSSFile(url: string): Observable<string> {
        return this.httpClient.getFromThirdParty(url, 'text');
    }

    private runReplace(): Observable<boolean> {
        console.log('replacing css variables');

        return this.loadStylingVariablesCSS().pipe(
            map(cssVariablesText => {
                return this.getCSSVars(cssVariablesText);
            }),
            concatMap(cssVariables => {
                this.cssVariables = cssVariables;
                return forkJoin(this.generateUpdatedCSSRulesFromInlineStyles(cssVariables), this.generateUpdatedCSSRulesFromLinkedFiles(cssVariables));
            }),
            map(() => {
                const observer: MutationObserver = new MutationObserver((mutationsList: MutationRecord[], observer: MutationObserver) => {
                    for (const el of mutationsList) {
                        // added styles
                        if (
                            el.type === 'childList' &&
                            el.addedNodes.length === 1 &&
                            el.addedNodes[0].nodeName === 'STYLE' &&
                            !(<HTMLStyleElement>el.addedNodes[0]).id.startsWith('dynamicStyle')
                        ) {
                            const htmlStyleElement: HTMLStyleElement = <HTMLStyleElement>el.addedNodes[0];
                            const updatedCssRules = this.generateUpdatedStyleRule(this.cssVariables, htmlStyleElement.textContent);
                            const styleHash = this.getHashCode(htmlStyleElement.textContent);
                            this.addDynamicStyle(updatedCssRules, styleHash);
                        }

                        //removed styles
                        if (
                            el.type === 'childList' &&
                            el.removedNodes.length === 1 &&
                            el.removedNodes[0].nodeName === 'STYLE' &&
                            !(<HTMLStyleElement>el.removedNodes[0]).id.startsWith('dynamicStyle')
                        ) {
                            const htmlStyleElement: HTMLStyleElement = <HTMLStyleElement>el.removedNodes[0];
                            const styleHash = this.getHashCode(htmlStyleElement.textContent);
                            const element = document.getElementById('dynamicStyle_' + styleHash);
                            if (element) {
                                element.parentNode.removeChild(element);
                            }
                        }
                    }
                });
                observer.observe(document.head, { attributes: false, childList: true, subtree: false });
            }),
            map(x => true)
        );
    }

    private getHashCode(value: string): number {
        let hash = 0;
        if (value.length === 0) {
            return hash;
        }
        for (let i = 0; i < value.length; i++) {
            const char = value.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    }

    private addDynamicStyle(cssText: string, hash: number) {
        const styleId = 'dynamicStyle_' + hash;
        if (document.getElementById(styleId)) {
            return;
        }
        const style = document.createElement('style');
        style.type = 'text/css';
        style.textContent = cssText;

        style.id = styleId;

        document.getElementsByTagName('head')[0].appendChild(style);
    }

    private addStyle(cssText: string, id: number) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = cssText;

        if (id !== -1) {
            style.id = 'dynamic_style_' + id;
        }

        document.getElementsByTagName('head')[0].appendChild(style);
    }

    private getCSSVars(cssText: string): [string] {
        //search CSS for OUR style of variable declaration e.g. --client-colour-1: #FFFFFF;
        //follow this syntax when delcaring a variable or you may have to change this regex
        const match = cssText.replace(/\s/g, '').match(/--[a-z-\d]*:\s?#[0-9a-f]*;/gi);
        const vars = <[string]>{};
        for (let i = 0; i < match.length; i++) {
            const v = match[i].split(':');
            //add the var to the vars object so it is a property of that object
            //e.g.
            vars[v[0]] = v[1];
        }

        return vars;
    }

    private generateUpdatedCSSRulesFromLinkedFiles(cssVars: [string]): Observable<boolean> {
        //find all linked style sheets
        const linkedStyles = document.querySelectorAll('link[rel="stylesheet"]');

        return from(linkedStyles).pipe(
            concatMap(linkedStyle => this.loadCSSFile((<HTMLLinkElement>linkedStyle).href)),
            map((cssText, i) => {
                const stylesToReplace = this.generateUpdatedCSSRules(cssVars, cssText);

                if (stylesToReplace.length > 0) {
                    this.addStyle(stylesToReplace.join('\r\n'), i);
                }

                return true;
            })
        );
    }

    private generateUpdatedCSSRulesFromInlineStyles(cssVars: [string]): Observable<boolean> {
        let stylesToReplace = [];
        const styleBlocks = document.querySelectorAll('style');

        for (let i = 0; i < styleBlocks.length; i++) {
            stylesToReplace = stylesToReplace.concat(this.generateUpdatedCSSRules(cssVars, styleBlocks[i].innerHTML));
        }

        this.addStyle(stylesToReplace.join('\r\n'), 0);

        return of(true);
    }

    private generateUpdatedCSSRules(cssVars: [string], style: string): Array<string> {
        const stylesToReplace: string[] = [];

        //find all css rules in the style sheet
        //a rule is defined by starting with a . having a name and an opening/closing curly brace
        //OR a rule is defined by starting with @media having a name and an opening/closing curly brace - this regex misses the last } on @media matches
        //e.g. .logon-colour { background-colour: red }
        //this regex matches multi lines as well
        const cssMatches = style.match(/(\.|@media)[\s\S]*?{[\s\S]*?}/gi);

        if (cssMatches != null) {
            for (let matchIndex = 0; matchIndex < cssMatches.length; matchIndex++) {
                //check for all variable declarations in the css rule
                //this will match OUR style of variable declaration usage
                //e.g. var(--client-colour-1);
                let styleToReplace = cssMatches[matchIndex];
                let replace = false;

                let varMatch = styleToReplace.match(/var\((--[a-z-\d]*)\)/i);
                while (varMatch) {
                    replace = true;

                    if (styleToReplace.startsWith('@media')) {
                        //replace that variable with the actual colour from the variables we loaded in the "getCSSVars" function
                        //have to add in an extra } because of the way the regex matches the @media queries
                        styleToReplace = styleToReplace.replace(varMatch[0], cssVars[varMatch[1]]) + '\r\n}';
                    } else {
                        //replace that variable with the actual colour from the variables we loaded in the "getCSSVars" function
                        styleToReplace = styleToReplace.replace(varMatch[0], cssVars[varMatch[1]]);
                    }

                    varMatch = styleToReplace.match(/var\((--[a-z-\d]*)\)/i);
                }

                if (replace) {
                    stylesToReplace.push(styleToReplace);
                }
            }
        }

        return stylesToReplace;
    }

    private generateUpdatedStyleRule(cssVars: [string], styleContent: string): string {
        let replacedStyleContent = styleContent;
        Object.keys(cssVars).forEach(cssVar => {
            const cssVarValue: string = cssVars[cssVar];
            const pattern = 'var\\(' + cssVar + '\\)(\\s*)(!important|)(;|)';
            const regex = new RegExp(pattern, 'gm');
            const substitution = cssVarValue.replace(';', '') + '$1$2$3';
            replacedStyleContent = replacedStyleContent.replace(regex, substitution);
        });
        return replacedStyleContent;
    }
}
