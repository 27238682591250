import { Injectable } from '@angular/core';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, skipWhile, switchMap } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';

const pollingInterval = 5000;

@Injectable()
export class ThirdPartyLockService {
    thirdPartyLockSession$: Observable<boolean>;

    private thirdPartyLockSessionSubject = new Subject<boolean>();
    private isLockActiveSubscription = Subscription.EMPTY;

    constructor(private httpClient: ApiHttpClient) {
        this.thirdPartyLockSession$ = this.thirdPartyLockSessionSubject.asObservable();
    }

    init() {
        this.isThirdPartyLockActive().subscribe(res => {
            if (res) {
                this.startSessionPolling();
            }
        });
    }

    startSessionPolling() {
        this.isLockActiveSubscription.unsubscribe();

        this.isLockActiveSubscription = this.checkThirdPartySessionState();
    }

    private checkThirdPartySessionState() {
        return interval(pollingInterval)
            .pipe(
                switchMap(() => {
                    return this.isThirdPartyLockActive().pipe(catchError(() => of(true)));
                }),
                skipWhile(val => val)
            )
            .subscribe(res => {
                this.isLockActiveSubscription.unsubscribe();
                this.thirdPartyLockSessionSubject.next(res);
            });
    }

    private isThirdPartyLockActive(): Observable<boolean> {
        return this.httpClient.get<boolean>('/Selection/IsThirdPartyLockActive', [], true);
    }
}
