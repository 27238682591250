import { animate, AUTO_STYLE, keyframes, state, style, transition, trigger } from '@angular/animations';

export const expandCollapse = trigger('expandCollapse', [
    state('false', style({ overflow: 'hidden', height: '0px', visibility: 'hidden' })),
    state('true', style({ overflow: AUTO_STYLE, height: AUTO_STYLE, visibility: 'visible' })),
    transition('1 => 0', [
        animate('250ms ease-in-out', keyframes([style({ overflow: 'hidden', height: '0px', visibility: 'hidden', animationPlayState: 'running' })]))
    ]),
    transition('0 => 1', [
        animate('500ms ease-in-out', keyframes([style({ height: AUTO_STYLE, visibility: 'visible', overflow: AUTO_STYLE, animationPlayState: 'paused' })]))
    ])
]);
