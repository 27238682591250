import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ErrorComponent } from 'core/error/error.component';
import { FallbackErrorComponent } from 'core/error/fallback-error.component';

import { SharedModule } from 'shared/shared.module';

@NgModule({
    declarations: [ErrorComponent, FallbackErrorComponent],
    imports: [RouterModule, SharedModule, CommonModule]
})
export class ErrorModule {}
