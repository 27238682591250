import { MonetaryValue } from 'core/models/core.model';
import { DataType } from 'core/models/data-type.model';
import { SystemIcon } from 'core/models/system-icon.model';
import { Message } from 'core/models/validation.model';

import { EmbeddedContent } from 'shared/components/content-renderer/content-renderer.model';
import { TooltipData } from 'shared/tooltip/tooltip.model';

/**************************Selection***************************/

export interface SelectionBasic {
    selectionPeriods: SelectionPeriod[];
    selectableBenefits: SelectionBenefit[];
    postSelectionModels: PostSelectionModel[];
    benefitSummaries: BenefitSummaries[];
}

export interface SelectionPeriod {
    id: string;
    startDate: Date;
    endDate: Date;
    description: string;
    daysUntilClose: number;
    effectiveDate: Date;
    type: SelectionPeriodType;
    hasSelections: boolean;
    _descriptionWithDaysLeft: string;
}

export enum SelectionPeriodType {
    open = 0,
    benefitSpecific = 1,
    lifeEventSpecific = 2
}

export interface SelectionBenefit {
    id: string;
    selectionBenefitEffectives: SelectionBenefitEffective[];
}

export interface SelectionBenefitEffective {
    inTemporarySelection: boolean;
}

export interface BenefitSummaries {
    effectivePeriodStartDate: Date;
}

export interface EffectivePeriod {
    effectivePeriodStartDate: Date;
    effectivePeriodEndDate: Date;
}

export interface BenefitSummary extends EffectivePeriod {
    hasBenefitChanges: boolean;
    totalEmployeeCredit: MonetaryValue;
    totalPreTaxPayCredit: MonetaryValue;
    totalPostTaxPayCredit: MonetaryValue;
    totalFundingAccountCredit: MonetaryValue;
    totalEmployerDebit: MonetaryValue;
    totalFundingAccountBalance: MonetaryValue;
    totalFundingAccountDebit: MonetaryValue;
    _postSelectionAgreements: PostSelectionAgreement[];
    _costs: MonetaryValue;
    _isPassedBackCredit: boolean;
    _hasCostDifference: boolean;
    _isPositiveFlag: boolean;
    _currentlyActive: MonetaryValue;
    _showInCostSummary: boolean;
    _totalEmployeeCreditAbsoluteValue: MonetaryValue;
    _costDifferenceTooltipData: TooltipData;
}

/************************Post Selection************************/

export interface PostSelectionAgreement {
    id: string;
    benefitIDs: string[];
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    displayName: string;
    shortText: string;
}

export interface PostSelectionConfig {
    cards: PostSelectionCardConfig[];
    pageContent: EmbeddedContent;
}

export interface PostSelectionCardConfig {
    id: string;
    displayConfig: PostSelectionCardDisplayConfig[];
    cardContent: PostSelectionCardContent;
    rowConfig: PostSelectionRowConfig[];
    messages: PostSelectionCardMessage[];
    includeTotalRow: boolean;
    totalCell1: PostSelectionTotalCellConfig;
    totalCell2: PostSelectionTotalCellConfig;
    _showColumn1: boolean;
    _showColumn2: boolean;
}

export interface PostSelectionTotalCellConfig {
    maxCalculationID?: string;
    itemFormatType: PostSelectionItemFormatType;
    _max: number;
    _total: number;
    _formattedTotal: number | string;
    _difference: string | number;
    _showInMobileView: boolean;
}

export interface PostSelectionCardDisplayConfig {
    itemID?: string;
    width: DisplayWidth;
    itemType: ItemType;
    header: string;
    displayAsInput: boolean;
    tooltip: string;
    itemFormatType: PostSelectionItemFormatType;
    _item: any;
    _itemForMessage: Message[];
}

export interface PostSelectionRowConfig {
    header: string;
    tooltip: string;
    cell1: PostSelectionCellConfig;
    cell2: PostSelectionCellConfig;
}

export interface PostSelectionCellConfig {
    itemID?: string;
    itemType: ItemType;
    displayAsInput: boolean;
    itemFormatType: PostSelectionItemFormatType;
    _item: any;
}

export interface PostSelectionCardMessage {
    messageID: string;
    type: PostSelectionCardMessageType;
    _message: Message;
}

export enum PostSelectionItemFormatType {
    none = 0,
    number = 1,
    currency = 2
}

export enum DisplayWidth {
    none = 0,
    half = 2,
    full = 4
}

export enum ItemType {
    none = 0,
    field = 1,
    calculation = 2,
    validationMessage = 3,
    lineBreak = 4,
    message = 5
}

export enum PostSelectionCardMessageType {
    none = 0,
    validationMessage = 1,
    message = 2
}

export interface PostSelectionCardContent {
    id: string;
    header: string;
    content: EmbeddedContent;
    tableHeader: string;
    columnHeader1: string;
    columnHeader2: string;
}

export interface PostSelectionModel {
    effectiveDate: Date;
    effectivePeriodStartDate: Date;
    effectivePeriodEndDate: Date;
    editable: boolean;
    hasChanges: boolean;
    isEnabled: boolean;
    employeeFields: PostSelectionEmployeeField[];
    messages: Message[];
    isValid: boolean;
    inTemporarySelection: boolean;
    calculatedValues: { [id: string]: any };
    _postSelectionConfiguration: PostSelectionConfig;
}

export interface PostSelectionEmployeeField {
    id: string;
    name: string;
    label: string;
    decimalIncrement?: number;
    dataType: DataType;
    editable: boolean;
    mandatory: boolean;
    reset: boolean;
    minLength: number;
    maxLength: number;
    minValue: any;
    maxValue: any;
    validationMask: string;
    listItems: PostSelectionEmployeeFieldListItem[];
    value: any;
    _validationMessages: string[];
    _listItemName: string;
    originalValue: any;
}

export interface PostSelectionEmployeeFieldListItem {
    value: any;
    displayText: string;
}

/****************General (needs classification)****************/

export interface ProviderIntegrationMessage {
    id: string;
    benefitID: string;
    selectionPeriodID: string;
    selectionEffectiveDate: Date;
    isThirdParty: boolean;
}

export interface CartPanelDisplayInfo {
    displayName: string;
    systemIcon: SystemIcon;
    hasPostSelectionModels: boolean;
}

export enum AssigneeType {
    employee = 1,
    employeeDependant = 2,
    employeeBeneficiary = 3
}

/**************************************************************/
