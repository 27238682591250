import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { ApprovalService } from 'core/approval.service';
import { ConfigurationService } from 'core/configuration.service';
import { EmployeeService } from 'core/employee.service';
import { EventService, EventType } from 'core/event.service';
import { MenuNavigationService } from 'core/layout/core/menu-navigation.service';
import { AggregatedLanguage } from 'core/models/configuration.model';
import { EmployeeBasic } from 'core/models/employee.model';
import { EmployeeManagerStatsCount } from 'core/models/line-manager.model';
import { NavMenuItem, NavMenuType } from 'core/models/navigation.model';
import { NavigationService } from 'core/navigation.service';
import { SecurityService } from 'core/security.service';
import { SessionStorageService } from 'core/session-storage.service';
import { WindowService } from 'core/window.service';

import { expandCollapse } from 'shared/animations/expand-collapse.animation';
import { AnimationState } from 'shared/animations/overlay.animation';
import { rotateBlob } from 'shared/animations/rotate-blob.animation';
import { SelectContextType } from 'shared/components/darwin-select/darwin-select.model';

@Component({
    selector: 'full-width-menu',
    templateUrl: 'full-width-menu.component.html',
    styleUrls: ['full-width-menu.component.less'],
    animations: [expandCollapse, rotateBlob]
})
export class FullWidthMenuComponent implements OnInit, OnChanges, OnDestroy {
    @Input() showMenu: boolean;
    @Output() showMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    employee: EmployeeBasic;
    mainMenuItems: NavMenuItem[] = [];
    NavMenuType = NavMenuType;
    isLoaded: boolean = false;
    totalPendingCountForManagerApproval: number;
    selectedLanguage: AggregatedLanguage;
    supportedLanguages: AggregatedLanguage[];
    hasMultiLanguage: boolean;
    invertedNavigationBarColor: boolean;
    contextType = SelectContextType;
    iconNameList: Map<number, string> = new Map<number, string>();

    animate = AnimationState.rightOut;

    private inAnimationState: AnimationState = AnimationState.rightIn;
    private outAnimationState: AnimationState = AnimationState.rightOut;

    private subscriptions: Subscription[] = [];

    constructor(
        private employeeService: EmployeeService,
        private windowService: WindowService,
        private configurationService: ConfigurationService,
        private navigationService: NavigationService,
        private menuNavigationService: MenuNavigationService,
        private eventService: EventService,
        private coreApprovalService: ApprovalService,
        private securityService: SecurityService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.employee = this.employeeService.employee;

        this.invertedNavigationBarColor = this.configurationService.configuration.options.invertedNavigationBarColor;

        this.supportedLanguages = this.configurationService.aggregatedLanguages;
        this.selectedLanguage = this.configurationService.selectedLanguage;
        this.hasMultiLanguage = this.supportedLanguages.length > 1;

        this.iconNameList = this.navigationService.getIconNameAndSizeList();

        this.initMenuItems();

        this.isLoaded = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const toggleMenuChange = changes['showMenu'];

        if (this.animate === this.inAnimationState || !toggleMenuChange.currentValue) {
            this.animate = this.outAnimationState;
        } else {
            this.animate = this.inAnimationState;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
        this.showMenu = false;
    }

    triggerLinkAction(navMenuItem: NavMenuItem) {
        this.animate = this.outAnimationState;

        this.showMenuChange.emit(false);

        this.navigationService.setActiveGroup(navMenuItem.path, this.mainMenuItems);

        this.navigationService.triggerLinkAction(navMenuItem);
    }

    changeLanguage(languageId: number) {
        if (this.selectedLanguage.id === languageId) {
            return;
        }

        this.sessionStorageService.setItem(
            'selectedLanguage',
            this.supportedLanguages.find(language => language.id === languageId)
        );

        this.employeeService.updateSelectedLanguage(languageId as number).subscribe(() => {
            this.securityService.removeAccessToken();
            this.windowService.reloadPage();
        });
    }

    logout(): void {
        this.securityService.logout();
    }

    private initMenuItems(): void {
        const hasManagerApproval = this.configurationService.configuration.pageConfig.lineManager.isEnabled;

        const quickLinkGroups = this.configurationService.configuration.navigationMenu.quickLinkGroups;

        if (hasManagerApproval) {
            this.subscriptions.push(this.eventService.subscribeToEvent(EventType.ManagerApproval).subscribe(() => this.updateAllCounters()));

            this.subscriptions.push(
                this.coreApprovalService.managerApprovalCounterUpdate$.subscribe(() => {
                    this.totalPendingCountForManagerApproval = this.coreApprovalService.getTotalPendingCount();

                    this.mainMenuItems.forEach(x => {
                        if (x.id === 'Profile') {
                            x.count = this.totalPendingCountForManagerApproval;
                            return;
                        }
                    });
                })
            );
        }

        this.mainMenuItems = this.navigationService.buildMenu(
            this.menuNavigationService.menuItems,
            hasManagerApproval,
            this.totalPendingCountForManagerApproval,
            [],
            quickLinkGroups
        );

        this.navigationService.setActiveGroup(this.router.url, this.mainMenuItems);
    }

    private updateAllCounters(): void {
        forkJoin([this.coreApprovalService.detailManagerClaimsStatistics(), this.coreApprovalService.countManagerEffectiveReceivedPendingRequest()]).subscribe(
            ([approvalStats, pendingRequestCount]: [EmployeeManagerStatsCount, number]) => {
                this.coreApprovalService.setApprovalStatistics(approvalStats);
                this.coreApprovalService.setPendingRequestCount(pendingRequestCount);
            }
        );
    }
}
