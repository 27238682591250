import { Highlightable } from '@angular/cdk/a11y';
import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';

import { Option, SelectContextType } from 'shared/components/darwin-select/darwin-select.model';

@Component({
    selector: 'darwin-select-option',
    templateUrl: 'darwin-select-option.component.html',
    styleUrls: ['./darwin-select-option.component.less']
})
export class DarwinSelectOptionComponent implements AfterViewChecked, Highlightable {
    @ViewChild('template', { static: true }) template: TemplateRef<any>;
    @ViewChild('optionElement', { static: false }) optionEl: ElementRef<HTMLElement>;

    @Input() value: any;
    @Input() selected: boolean;
    @Input() contextType: SelectContextType = SelectContextType.Default;

    selectionFocus: boolean;
    onSelectOption: EventEmitter<Option> = new EventEmitter();
    onInitOption: EventEmitter<Option> = new EventEmitter();
    onSelectedOption: EventEmitter<Option> = new EventEmitter();
    isActive: boolean = false;
    activeDescendantElementId: string;

    private initialised: boolean = false;

    constructor() {}

    ngAfterViewChecked() {
        if (this.initialised || !this.optionEl) {
            return;
        }

        this.initialised = true;

        if (this.selected) {
            this.onSelectedOption.emit(<Option>{
                value: this.value,
                text: this.optionEl.nativeElement.innerText,
                contextType: this.contextType
            });
        }

        if (this.selectionFocus) {
            this.onInitOption.emit(<Option>{
                text: this.optionEl.nativeElement.innerText,
                contextType: this.contextType
            });
        }
    }

    selectOption() {
        this.onSelectOption.emit(<Option>{
            value: this.value,
            text: this.optionEl.nativeElement.innerText,
            contextType: this.contextType
        });
    }

    setActiveStyles() {
        this.isActive = true;
        this.optionEl.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    setInactiveStyles() {
        this.isActive = false;
    }
}
