<div class="favicon-container" [class.inverted]="inverted" [class.outline]="outline">
    <span class="img-container">
        <img
            *ngIf="!isFaviconFallbackIcon && !overrideIcon"
            alt="favicon"
            src="{{ faviconApiBaseUrl + siteUrl + faviconApiParams }}"
            (error)="imageError(siteUrl)"
            (load)="imageLoad($event, siteUrl)" />
        <icon *ngIf="isFaviconFallbackIcon" class="icon-sm" iconId="{{ fallbackIcon }}"></icon>
        <icon *ngIf="overrideIcon" class="icon-sm" iconId="{{ overrideIcon }}"></icon>
    </span>
</div>
