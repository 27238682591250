import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';

@Injectable()
export class ApplicationInsightsService {
    private appInsights: ApplicationInsights;

    constructor(private environmentConfigurationDataService: EnvironmentConfigurationDataService) {}

    register() {
        if (this.appInsights) {
            return;
        }

        const { rewardCentreApplicationInsightsID } = this.environmentConfigurationDataService.environmentConfigurationData;

        if (!rewardCentreApplicationInsightsID) {
            return;
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: rewardCentreApplicationInsightsID,
                isCookieUseDisabled: true,
                loggingLevelTelemetry: 0,
                enableAjaxPerfTracking: true
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(envelope => {
            envelope.tags['ai.cloud.role'] = 'Darwin Web';
            envelope.tags['ai.cloud.roleInstance'] = 'Reward Centre';
        });

        this.trackPageViewInternal();
    }

    trackPageView() {
        if (!this.appInsights) {
            return;
        }

        this.trackPageViewInternal();
    }

    logException(exception: Error) {
        if (!this.appInsights) {
            return of(true);
        }

        this.appInsights.trackException({ exception: exception });
        this.appInsights.flush();

        return of(true).pipe(delay(500)); //short delay to ensure the request is sent before redirected to error page
    }

    private trackPageViewInternal() {
        this.appInsights.trackPageView({ name: location.origin + location.pathname + location.hash });
    }
}
