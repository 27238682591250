import { ValidationMessage } from 'core/models/validation.model';

import { CountryFlag } from 'shared/components/country-flag/country-flag.model';

export interface Criterion {
    descriptionTextKey: string;
    isValid: boolean;
    regex: RegExp;
}

export const PasswordCriteria: Criterion[] = [
    {
        descriptionTextKey: 'Login_PasswordReset_EnglishUpperCaseLettersCriteria',
        isValid: false,
        regex: /(?=.*[A-Z])/
    },
    {
        descriptionTextKey: 'Login_PasswordReset_EnglishLowerCaseLettersCriteria',
        isValid: false,
        regex: /(?=.*[a-z])/
    },
    {
        descriptionTextKey: 'Login_PasswordReset_WesternizedArabicNumeralsCriteria',
        isValid: false,
        regex: /(?=.*\d)/
    },
    {
        descriptionTextKey: 'Login_PasswordReset_SpecialCharactersCriteria',
        isValid: false,
        regex: /(?=.*[^\da-zA-Z])/
    }
];

export interface EmployeeSsoLogin {
    employeeID: string;
    token: string;
}

export interface LoginModel {
    username: string;
    password: string;
    selectedLanguage: number;
    deviceRequest: DeviceRequest;
}

export interface DeviceTokenData {
    deviceTokenFso?: string;
}

export interface DeviceRequest extends DeviceTokenData {
    devicePrint?: string;
}

export enum LoginStep {
    None = 0,
    CollectChallenge = 3,
    SelectChallenge = 4,
    ProvideChallenege = 5,
    Support = 6
}

export const mfaSteps = {
    [LoginStep.CollectChallenge]: 'collect-challenge',
    [LoginStep.SelectChallenge]: 'select-challenge-mode',
    [LoginStep.ProvideChallenege]: 'provide-challenge-response',
    [LoginStep.Support]: 'support'
};

export interface MfaToken extends Token {
    loginStep: LoginStep;
}

export interface MfaTokenInfo {
    mfaToken?: MfaToken;
}

export interface AccessTokenReturn extends DeviceTokenData, MfaTokenInfo {
    loginResult: LoginResult;
    rewardCentreAccessEnabled: boolean;
    logoutUrl?: string;
    accessToken?: Token;
    refreshToken?: Token;
    errorID?: string;
    mfaMessage: string;
    passwordRequirements: PasswordRequirements;
    errors: ValidationMessage[];
    ssoSchemeSelections?: SsoSchemeSelection[];
}

export interface SsoSchemeSelection {
    employeeID: string;
    schemeName: string;
    countryFlag: CountryFlag;
}

export interface AccountSettings {
    username: string;
    passwordRequirements: PasswordRequirements;
}

export interface PasswordRequirements {
    minimumLength: number;
}

export interface ChangePasswordCredentials {
    username: string;
    currentPassword: string;
    passwordRequirements: PasswordRequirements;
}

export interface Token {
    created: string;
    expiry: string;
    value: string;
    _browserExpiry: string;
}

export interface Sso {
    parameters: SsoParameters;
}

export interface SsoParameters {
    [id: string]: string;
    idp: string;
    page: string;
    action: ActionName;
}

export interface SsoDetails {
    logoutUrl: string;
}

export enum SsoDeepLink {
    BenefitSelection = 1,
    Profile = 2,
    LifeEvent = 3,
    TotalRewardStatement = 4,
    Reimbursement = 5
}

export enum ActionName {
    SAML2 = 'SAML2',
    OpenIDStart = 'OpenIDStart',
    OpenIDComplete = 'OpenIDComplete'
}

export interface AuthResult {
    status: AuthStatus;
    navigateTo?: string;
    ssoSchemeSelections?: SsoSchemeSelection[];
    ssoValidEmployeeIDsToken?: string;
}

export interface RedirectUrl {
    url: string;
    success: boolean;
    errorID: string;
}

export interface SsoOutboundRequest {
    tokenName: string;
    tokenValue: string;
    relayStateName: string;
    relayStateURL: string;
    url: string;
    errors: ValidationError[];
    errorID: string;
    messageID: string;
    benefitID: string;
}

export interface EmployeeSelfRegistrationRequest {
    nationalIdentifier: string;
    dateOfBirth: string;
    employeeNumber: string;
    externalSchemeIdentifier: string;
    employeeEmailAddress: string;
    selectedLanguageID?: number;
}

export interface ValidationError {
    message: string;
    property: string;
    validationKey: string;
    replacements: ValidationErrorReplacement[];
}

export class ValidationErrorReplacement {
    key: string;
    value: any;
}

export enum ErrorMessage {
    AccessTokenExpired = 'Access token has expired.'
}

export const enum AuthStatus {
    Fail = 0,
    Success = 1,
    Redirect = 2,
    SchemeSelection = 3
}

export const enum LoginResult {
    None = 0,
    Success = 1,
    Fail = 2,
    PasswordExpired = 3,
    TempPasswordExpired = 4,
    Leaver = 5,
    TempPassword = 6,
    SchemeSelection = 7,
    CollectChallenge = 9,
    SelectChallenge = 10,
    Support = 11,
    MfaFail = 12,
    ProvideChallenege = 13,
    MfaUnauthorized = 14,
    MfaError = 15
}

export const mfaLoginResult = [
    LoginResult.CollectChallenge,
    LoginResult.SelectChallenge,
    LoginResult.Support,
    LoginResult.MfaFail,
    LoginResult.ProvideChallenege,
    LoginResult.MfaUnauthorized,
    LoginResult.MfaError
];