import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import { CookieConsentService } from 'core/cookie-consent.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';
import { IconProviderService } from 'core/icon-provider.service';
import { EnvironmentConfigurationData } from 'core/models/environment-configuration-data.model';
import usabilla from 'core/scripts/usabilla.js';
import { SecurityService } from 'core/security.service';
import { WindowService } from 'core/window.service';

@Injectable()
export class FeedbackService implements OnDestroy {
    private allowFunctionalCookies: boolean = false;
    private cookieConsentSubscription: Subscription = Subscription.EMPTY;

    constructor(
        private windowService: WindowService,
        private iconProvider: IconProviderService,
        private environmentConfigurationDataService: EnvironmentConfigurationDataService,
        private httpClient: ApiHttpClient,
        private securityService: SecurityService,
        private cookieConsentService: CookieConsentService
    ) {}

    init() {
        this.initCookieConsentSubscription();
    }

    ngOnDestroy() {
        if (this.cookieConsentSubscription) {
            this.cookieConsentSubscription.unsubscribe();
        }
    }

    private initCookieConsentSubscription() {
        this.cookieConsentSubscription = this.cookieConsentService.allowFunctionalObservable.subscribe(state => {
            this.allowFunctionalCookies = state;
            this.setFeedback();
        });
    }

    private setFeedback() {
        if (!this.allowFunctionalCookies) {
            this.disable();
            return;
        }

        const envConfigData: EnvironmentConfigurationData = this.environmentConfigurationDataService.environmentConfigurationData;

        if (envConfigData.usabillaButtonRewardCentreID) {
            let feedbackSvgElement: SVGElement = this.iconProvider.getIcon('feedback-desktop');

            if (this.windowService.isMobileDevice()) {
                feedbackSvgElement = this.iconProvider.getIcon('feedback-mobile');
            }

            if (this.windowService.isTabletDevice()) {
                feedbackSvgElement = this.iconProvider.getIcon('feedback-tablet');
            }

            usabilla(envConfigData.usabillaButtonRewardCentreID, feedbackSvgElement);

            if (this.securityService.isLoggedIn()) {
                this.httpClient
                    .get<UsabillaData>('/Feedback/LoadFeedbackData')
                    .pipe(
                        map(data => {
                            this.setFeedbackData(data);
                        })
                    )
                    .subscribe();
            }
        }
    }

    private disable() {
        if (window['usabilla_live']) {
            window['usabilla_live'] = undefined;
            this.removeFeedbackButton();
        }
    }

    private setFeedbackData(customData: object) {
        if (window['usabilla_live']) {
            window['usabilla_live']('data', { custom: customData });
        }
    }

    private removeFeedbackButton() {
        const element = document.getElementById('usbl-integrated-button');
        if (element) {
            element.parentNode.removeChild(element);
        }
    }
}

interface UsabillaData {
    OrganisationID: string;
    OrganisationName: string;
    SchemeID: string;
    SchemeName: string;
    CountryName: string;
}
