<div class="cart-menu" aria-describedby="dialogDescription">
    <drop-down-menu
        [(showMenu)]="showMenu"
        id="drop-down-cart-menu"
        buttonId="header-menu-item-language"
        menuEventId="drop-down-cart-menu"
        [invertedNavigationBarColor]="invertedNavigationBarColor"
        [navMenuItems]="cartNavMenuItems">
        <ng-template #itemTemplate>
            <div [ngClass]="invertedNavigationBarColor ? 'menu-inverted' : 'menu'">
                <div *ngIf="!isUpdateNotification; else updateNotification" class="cart-panel">
                    <div class="top-bar">&nbsp;</div>
                    <div>
                        <h2 class="header-text heading-18-b" appText="BenefitSelection_EditBenefit_InYourCart"></h2>
                    </div>
                    <div id="dialogTitle" class="cart-header">
                        <div class="icon-container" *ngIf="!hasTempSelections">
                            <icon
                                class="header-icon icon-xxxxl"
                                [ngClass]="invertedNavigationBarColor ? 'grey' : 'brand-450-fill'"
                                iconId="empty-cart-2"></icon>
                        </div>
                    </div>
                    <div id="dialogDescription" class="cart-body" [class.no-items-in-cart]="!hasTempSelections">
                        <p class="label-14-r">
                            {{ (hasTempSelections ? 'BenefitSelection_Label_HasTemporarySelection' : 'BenefitSelection_Label_NoBenefitInCart') | appText }}
                        </p>
                    </div>
                    <div class="checkout-button" *ngIf="hasTempSelections">
                        <button #checkoutButtonElement class="btn-primary" [class.btn-wide]="isMobileDevice" (click)="navigateToConfirmation()">
                            <span class="sentence-case">
                                {{ (isPostSelectionNextStep ? 'BenefitSelection_GoToTasks' : 'BenefitSelection_GoToCheckout') | appText }}
                            </span>
                        </button>
                    </div>
                </div>
                <ng-template #updateNotification>
                    <div class="cart-panel update-notification" [class.discontinue]="isDiscontinue">
                        <button #closeButton [attr.aria-label]="'Global_Action_Close' | appText" class="close-icon btn-close" (click)="closeMenu($event)">
                            <icon iconId="cross"></icon>
                        </button>
                        <div class="top-bar">&nbsp;</div>
                        <div id="dialogTitle" class="cart-header">
                            <h2 class="header-text-small heading-18-b" appText="BenefitSelection_SelectionAddedToCart"></h2>
                        </div>
                        <div id="dialogDescription" class="cart-body">
                            <div class="benefit-name-container">
                                <icon
                                    *ngIf="systemIcon"
                                    [class.menu-stroke]="!invertedNavigationBarColor"
                                    [iconKey]="systemIcon"
                                    class="update-toaster-system-icon icon-lg"></icon>
                                <p class="benefit-name label-14-b">{{ displayText }}</p>
                                <div class="discontinue-message" *ngIf="isDiscontinue">
                                    <validation-message
                                        [displayValidationErrors]="true"
                                        [iconSize]="discontinueIconSize"
                                        [iconIdOverride]="'circle-xmark'"
                                        [validationMessages]="validationMessages"
                                        [allowedValidationRuleTypes]="discontinueValidationTypes">
                                    </validation-message>
                                </div>
                            </div>
                        </div>
                        <div class="checkout-button">
                            <button #checkoutButtonElement class="btn-icon-right btn-primary-inverse btn-wide" (click)="navigateToConfirmation()">
                                <span class="sentence-case">
                                    {{ (isPostSelectionNextStep ? 'BenefitSelection_GoToTasks' : 'BenefitSelection_GoToCheckout') | appText }}
                                </span>
                                <icon class="size-xxxs icon-xxxs" iconId="right"></icon>
                            </button>
                            <button class="btn-primary btn-icon-left btn-wide" (click)="navigateToItem('/overview')">
                                <icon class="size-xxxs icon-xxxs" iconId="left"></icon>
                                <span class="sentence-case" appText="BenefitSelection_BackToOverview"></span>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </drop-down-menu>
</div>
