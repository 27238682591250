import { Component, Input, OnInit } from '@angular/core';

import { FormatService } from 'core/format.service';
import { IconSize, MessageBase, ValidationRuleType } from 'core/models/validation.model';

@Component({
    selector: 'validation-message',
    templateUrl: 'validation-message.component.html'
})
export class ValidationMessageComponent implements OnInit {
    @Input() validationMessages: MessageBase[];
    @Input() displayValidationErrors: boolean = true;
    @Input() allowedValidationRuleTypes: ValidationRuleType[] = [];
    @Input() hidePaddingTop: boolean;
    @Input() iconIdOverride: string = null;
    @Input() iconSize: IconSize = IconSize.Lg;
    @Input() sortByMessageType: boolean = true;

    iconIds: Map<number, string> = new Map<number, string>();
    messageType = ValidationRuleType;

    constructor(private formatService: FormatService) {}

    ngOnInit() {
        this.iconIds.set(ValidationRuleType.warning, 'warning-notification');
        this.iconIds.set(ValidationRuleType.error, 'error-notification');
        this.iconIds.set(ValidationRuleType.success, 'success-notification');
        this.iconIds.set(ValidationRuleType.information, 'information-notification');

        if (!this.validationMessages) {
            return;
        }

        if (this.sortByMessageType) {
            this.validationMessages = this.validationMessages.sort((a, b) => {
                return a.messageType - b.messageType;
            });
        }

        if (this.allowedValidationRuleTypes.length > 0) {
            this.validationMessages = this.validationMessages.filter(message =>
                this.allowedValidationRuleTypes.some(ruleType => ruleType === message.messageType)
            );
        }
    }

    formatMessage(messageBase: MessageBase) {
        return this.formatService.formatMessage(messageBase);
    }
}
