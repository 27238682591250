import { OverlayModule as CdkOverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TextOverflowTooltipDirective } from 'shared/tooltip/text-overflow-tooltip.directive';
import { TooltipComponent } from 'shared/tooltip/tooltip.component';
import { ToolTipDirective } from 'shared/tooltip/tooltip.directive';
import { TooltipService } from 'shared/tooltip/tooltip.service';

@NgModule({
    declarations: [TooltipComponent, ToolTipDirective, TextOverflowTooltipDirective],
    exports: [ToolTipDirective, TextOverflowTooltipDirective],
    imports: [CommonModule, CdkOverlayModule, PortalModule]
})
export class TooltipModule {
    static rootProviders() {
        return [TooltipService];
    }
}
