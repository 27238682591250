import { Component, Input } from '@angular/core';

@Component({
    selector: 'favicon',
    templateUrl: 'favicon.component.html',
    styleUrls: ['favicon.component.less']
})
export class FaviconComponent {
    @Input() siteUrl: string;
    @Input() inverted: boolean = false;
    @Input() fallbackIcon: string = 'globe-vector';
    @Input() overrideIcon: string;
    @Input() outline: boolean = true;

    isFaviconFallbackIcon: boolean = false;
    faviconApiBaseUrl: string = 'https://www.google.com/s2/favicons?domain=';
    faviconApiParams: string = '&sz=96';

    imageLoad(event: Event, path: string) {
        const faviconWidth = (event.target as HTMLImageElement).naturalWidth;

        const sizeOfGoogleDefaultFallbackGlobeImage = 16;

        if (faviconWidth === sizeOfGoogleDefaultFallbackGlobeImage) {
            this.isFaviconFallbackIcon = true;
        }
    }

    imageError(path: string) {
        this.isFaviconFallbackIcon = true;
    }
}
