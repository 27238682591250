import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';

import { ConfigurationService } from 'core/configuration.service';
import { EventService, EventType } from 'core/event.service';
import { DisplaySize } from 'core/models/display.model';
import { CartConfig, NavMenuItem } from 'core/models/navigation.model';
import { SystemIcon } from 'core/models/system-icon.model';
import { IconSize, Message, ValidationRuleType } from 'core/models/validation.model';
import { SelectionService as CoreSelectionService } from 'core/selection.service';
import { TextService } from 'core/text.service';
import { WindowService } from 'core/window.service';

@Component({
    selector: 'cart-menu',
    templateUrl: 'cart-menu.component.html',
    styleUrls: ['cart-menu.component.less']
})
export class CartMenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('checkoutButtonElement') checkoutButtonEl: ElementRef;
    @ViewChild('closeButton') closeButtonEl: ElementRef;

    @Input() showMenu: boolean = false;

    @Output() tempSelectionsCount = new EventEmitter<number>();
    @Output() showMenuChange = new EventEmitter<boolean>();

    hasTempSelections: boolean = false;
    isUpdateNotification: boolean = false;
    isDiscontinue: boolean = false;
    isPostSelectionNextStep = false;
    isMobileDevice: boolean = false;
    isToasterOpen: boolean = true;
    displayText: string;
    systemIcon: SystemIcon;
    cartNavMenuItems: NavMenuItem[] = [];
    invertedNavigationBarColor: boolean = false;
    validationMessages: Message[];
    discontinueIconSize: IconSize = IconSize.Lg;
    discontinueValidationTypes: ValidationRuleType[];

    private useOptOutText: boolean = false;
    private windowResizeSubscription: Subscription = Subscription.EMPTY;
    private openMenuSubscription: Subscription = Subscription.EMPTY;
    private selectionUrl = 'selection';

    constructor(
        private router: Router,
        private eventService: EventService,
        private coreSelectionService: CoreSelectionService,
        private windowService: WindowService,
        private configurationService: ConfigurationService,
        private textService: TextService
    ) {
        router.events.subscribe(event => {
            if (event instanceof RoutesRecognized || NavigationStart) {
                this.showMenu = false;
                this.isUpdateNotification = false;
            }
        });
    }

    ngOnInit() {
        this.invertedNavigationBarColor = this.configurationService.configuration.options.invertedNavigationBarColor;

        this.openMenuSubscription = this.eventService.subscribeToEvent(EventType.UpdateCart).subscribe((cartConfig: CartConfig) => {
            this.isUpdateNotification = cartConfig?.isUpdateNotification;
            this.displayText = cartConfig?.displayText;
            this.systemIcon = cartConfig?.systemIcon;
            this.isDiscontinue = cartConfig?.isDiscontinue;
            this.isPostSelectionNextStep = cartConfig?.isPostSelectionNextStep;
            this.useOptOutText = cartConfig?.useOptOutText;
            this.tempSelectionsCount.emit(cartConfig?.tempSelectionsCount);

            this.toggleMenuVisibility(cartConfig?.showMenu);

            this.coreSelectionService.getTempSelectionsCountAndSetHasPostSelection().subscribe(count => {
                this.tempSelectionsCount.emit(count);

                this.hasTempSelections = count > 0;

                if (!this.hasTempSelections) {
                    this.isDiscontinue = false;
                    this.isUpdateNotification = false;
                }
            });
        });

        this.isMobileDevice = this.windowService.isMobileDevice();

        this.windowResizeSubscription = this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe((displaySize: DisplaySize) => {
            this.isMobileDevice = displaySize === DisplaySize.mobile;
        });

        this.initErrorMessage();
    }

    ngAfterViewInit() {
        if (this.isUpdateNotification && this.closeButtonEl) {
            this.closeButtonEl.nativeElement.focus();
        } else if (this.hasTempSelections && this.checkoutButtonEl) {
            this.checkoutButtonEl.nativeElement.focus();
        }
    }

    navigateToItem(item: string) {
        const itemToNavigateTo = this.selectionUrl + item;

        this.router.navigate([itemToNavigateTo]);

        this.toggleMenuVisibility(false);
    }

    navigateToConfirmation() {
        const itemToNavigateTo = this.selectionUrl + (this.isPostSelectionNextStep ? '/post-selection' : '/confirmation');

        this.router.navigate([itemToNavigateTo]);

        this.toggleMenuVisibility(false);
    }

    closeMenu(event) {
        if (event.currentTarget.contains(event.relatedTarget)) {
            return;
        }

        this.toggleMenuVisibility(false);
    }

    ngOnDestroy() {
        this.windowResizeSubscription.unsubscribe();
        this.openMenuSubscription.unsubscribe();
    }

    private toggleMenuVisibility(visibility: boolean) {
        this.showMenu = visibility;
        this.showMenuChange.emit(this.showMenu);
    }

    private initErrorMessage() {
        this.validationMessages = [
            <Message>{
                messageType: ValidationRuleType.error,
                text: this.useOptOutText
                    ? this.textService.getText('BenefitSelection_Dialog_OptOutBenefit')
                    : this.textService.getText('BenefitSelection_Dialog_DiscontiueBenefit')
            }
        ];

        this.discontinueValidationTypes = [ValidationRuleType.error];
    }
}
