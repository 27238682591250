<footer role="contentinfo" class="footer-component">
    <div id="consent-banner" *ngIf="displayTrustArcComponents"></div>
    <div class="grid">
        <div class="footer-copyright label-14-r" appText="Global_Copyright" [replacements]="[currentYear | formatDate: dateFormatOptions]"></div>
        <div class="footer-links">
            <button id="footer-privacystatement-link" class="label-14-r" (click)="showPrivacyPolicy()" appText="Global_PrivacyPolicy"></button>
            <button id="footer-termsandconditions-link" class="label-14-r" (click)="showTermsAndConditions()" appText="Global_TermsAndConditions"></button>
            <div id="teconsent" class="label-14-r" *ngIf="displayTrustArcComponents" role="button"></div>
            <button
                id="footer-cookiespolicy-link"
                class="label-14-r"
                *ngIf="!displayTrustArcComponents"
                (click)="showCookiesPolicy()"
                appText="Global_CookiesPolicy"></button>
            <button id="footer-security-link" class="label-14-r" appText="Global_Security" (click)="showSecurityPolicy()"></button>
        </div>
        <div class="footer-image">
            <div>
                <img alt="" [src]="darwinLogoUrl" />
            </div>
        </div>
    </div>
</footer>
