import { Component, EventEmitter, Input } from '@angular/core';

import { SchemeContentFile } from 'core/models/configuration.model';

@Component({
    selector: 'video-overlay',
    templateUrl: 'video-overlay.component.html',
    styleUrls: ['video-overlay.component.less']
})
export class VideoOverlayComponent {
    @Input() videoHeader: string;
    @Input() internalVideoFile: SchemeContentFile;

    close: EventEmitter<void>;

    constructor() {}

    hideOverlay() {
        this.close.emit();
    }
}
