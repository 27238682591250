import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InternalSharedModule } from 'shared/internal-shared.module';
import { PdfViewerComponent } from 'shared/pdf-viewer/pdf-viewer.component';
import { PdfViewerService } from 'shared/pdf-viewer/pdf-viewer.service';

@NgModule({
    declarations: [PdfViewerComponent],
    exports: [PdfViewerComponent],
    imports: [InternalSharedModule, CommonModule]
})
export class PdfViewerModule {
    static rootProviders() {
        return [PdfViewerService];
    }
}
