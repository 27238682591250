import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, map, Observable, ReplaySubject, Subscription } from 'rxjs';

import { ApplicationService } from 'core/application.service';
import { ConfigurationService } from 'core/configuration.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';

declare let truste: any;
const scriptUrl = 'https://consent.trustarc.com/v2/notice/';

@Injectable()
export class CookieConsentService implements OnDestroy {
    domainUrl: string;
    cookiesCategoriesStatus: CookieCategoriesStatus;
    allowFunctionalObservable: Observable<boolean>;
    allowAnalyticObservable: Observable<boolean>;
    allowAdvertisingObservable: Observable<boolean>;
    allowOthersObservable: Observable<boolean>;
    trustArcStateObservable: Observable<boolean>;

    private isEventActive: boolean = false;
    private isScriptLoading: boolean = false;
    private subscription: Subscription;
    private allowFunctionalSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private allowAnalyticSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private allowAdvertisingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private allowOthersSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private trustArcStateSubject: ReplaySubject<boolean> = new ReplaySubject();

    constructor(
        private applicationService: ApplicationService,
        private configurationService: ConfigurationService,
        private environmentConfigurationDataService: EnvironmentConfigurationDataService
    ) {
        this.allowFunctionalObservable = this.allowFunctionalSubject.asObservable();
        this.allowAnalyticObservable = this.allowAnalyticSubject.asObservable();
        this.allowAdvertisingObservable = this.allowAdvertisingSubject.asObservable();
        this.allowOthersObservable = this.allowOthersSubject.asObservable();
        this.trustArcStateObservable = this.trustArcStateSubject.asObservable();
    }

    init() {
        if (!this.environmentConfigurationDataService.environmentConfigurationData.trustArcCMID) {
            this.useDefaultCookieSettings();
            return;
        }

        if (this.isScriptLoading) {
            return;
        }

        this.domainUrl = this.applicationService.baseUrl;

        this.loadScript().subscribe(loaded => {
            this.trustArcStateSubject.next(loaded);

            if (loaded) {
                this.loadConsent().subscribe();
            } else {
                this.setDefaultBehaviorForCookies(false);
            }

            this.isScriptLoading = false;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private useDefaultCookieSettings() {
        this.setDefaultBehaviorForCookies(true);
        this.trustArcStateSubject.next(false);
    }

    private loadScript(): Observable<boolean> {
        return new Observable(observer => {
            this.removeDuplicateScriptLoad();
            this.isScriptLoading = true;

            const id = 'trust-arc-script';
            const trustArcCMID = this.environmentConfigurationDataService.environmentConfigurationData.trustArcCMID;
            const selectedLanguage = this.configurationService.selectedLanguage.defaultCulture;
            const script = document.createElement('script');

            script.type = 'text/javascript';
            script.src = scriptUrl + trustArcCMID + '&locale=' + selectedLanguage;
            script.setAttribute('id', id);
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);

            script.onload = () => {
                observer.next(true);
                observer.complete();
            };

            script.onerror = () => {
                observer.next(false);
                observer.complete();
            };
        });
    }

    private setDefaultBehaviorForCookies(defaultState: boolean) {
        this.allowFunctionalSubject.next(defaultState);
        this.allowAnalyticSubject.next(defaultState);
        this.allowAdvertisingSubject.next(defaultState);
        this.allowOthersSubject.next(defaultState);
    }

    private removeDuplicateScriptLoad() {
        const element = document.getElementById('trust-arc-script');
        if (element) {
            element.parentNode.removeChild(element);
        }
    }

    private loadConsent = (): Observable<void> => {
        const data = this.getGDPRConsentDecision();

        return data.pipe(
            map((configCookie: GDPRConsentDecisionData) => {
                this.setCookies(configCookie.consentDecision);

                this.allowFunctionalSubject.next(this.cookiesCategoriesStatus.functional);
                this.allowAnalyticSubject.next(this.cookiesCategoriesStatus.analytic);
                this.allowAdvertisingSubject.next(this.cookiesCategoriesStatus.advertising);
                this.allowOthersSubject.next(this.cookiesCategoriesStatus.others);

                this.setEventHandlerForConsent();

                return;
            })
        );
    };

    private getGDPRConsentDecision(): Observable<GDPRConsentDecisionData> {
        return new Observable(observer => {
            if (typeof truste !== 'undefined' && truste !== null && truste && truste.cma) {
                const data = truste?.cma.callApi('getGDPRConsentDecision', this.domainUrl);
                if (data && !data.error) {
                    observer.next(data);
                    observer.complete();
                }
            } else {
                this.useDefaultCookieSettings();
            }
        });
    }

    private setCookies(consentDecision: number[]) {
        this.cookiesCategoriesStatus = { required: true, functional: false, analytic: false, advertising: false, others: false } as CookieCategoriesStatus;

        if (consentDecision.includes(2)) {
            this.cookiesCategoriesStatus.functional = true;
        }

        if (consentDecision.includes(3)) {
            this.cookiesCategoriesStatus.analytic = true;
        }

        if (consentDecision.includes(4)) {
            this.cookiesCategoriesStatus.advertising = true;
        }

        if (consentDecision.includes(5)) {
            this.cookiesCategoriesStatus.others = true;
        }
    }

    private setEventHandlerForConsent() {
        if (this.isEventActive) {
            return;
        }

        this.subscription = fromEvent(window, 'message').subscribe(this.consentMessageHandler);
        this.isEventActive = true;
    }

    private consentMessageHandler = (e): any => {
        try {
            if (!e.data) {
                return;
            }
            const eventData = JSON.parse(e.data);

            if (eventData.source === 'preference_manager' && eventData.message === 'submit_preferences' && eventData.data) {
                this.loadConsent().subscribe();
            }
        } catch (error) {}
    };
}

interface GDPRConsentDecisionData {
    consentDecision: number[];
    source: string;
}

interface CookieCategoriesStatus {
    required: boolean;
    functional: boolean;
    analytic: boolean;
    advertising: boolean;
    others: boolean;
}
