import { ComponentType } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { TextService } from 'core/text.service';

import { KeyCodes } from 'shared/shared.model';
import { TooltipData, TooltipPortalData, TooltipPosition } from 'shared/tooltip/tooltip.model';
import { TooltipService } from 'shared/tooltip/tooltip.service';

@Directive({
    selector: '[tooltip]'
})
export class ToolTipDirective implements OnInit, OnDestroy {
    @Input('tooltip') content: string | TooltipData | ComponentType<{}>;
    @Input() tooltipPosition: TooltipPosition;
    @Input() isToolTipTargetFocusable: boolean = true;

    private isOpened: boolean;

    constructor(private tooltipService: TooltipService, private elementRef: ElementRef, private textService: TextService) {}

    @HostListener('mouseenter') onMouseEnter() {
        setTimeout(() => {
            const tooltipPortalData = this.buildTooltipPortalData();
            this.openTooltip(tooltipPortalData);
        }, 10);
    }

    @HostListener('click') onClick() {
        const tooltipPortalData = this.buildTooltipPortalData();

        if (!this.isTextContent(tooltipPortalData)) {
            this.openTooltip(tooltipPortalData);
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        setTimeout(() => {
            if (this.isOpened && !this.tooltipService.componentInstance.isMouseInside) {
                this.closeTooltip();
            }
        }, 0);
    }

    @HostListener('document:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (this.isOpened && event.keyCode === KeyCodes.Escape) {
            this.closeTooltip();
            this.elementRef.nativeElement.focus();
        }
    }

    ngOnInit() {
        if (this.content) {
            this.elementRef.nativeElement.classList.add('tooltip-target');
        }

        if (this.isToolTipTargetFocusable) {
            this.elementRef.nativeElement.setAttribute('tabIndex', '0');
        }

        if (!this.elementRef.nativeElement.hasAttribute('aria-label')) {
            this.elementRef.nativeElement.setAttribute('aria-label', this.textService.getText('Global_Tooltip'));
        }

        if (this.elementRef.nativeElement.tagName === 'icon') {
            this.elementRef.nativeElement.setAtribute('role', 'img');
        }
    }

    ngOnDestroy() {
        if (this.isOpened) {
            this.tooltipService.close();
        }
    }

    private buildTooltipPortalData(): TooltipPortalData {
        if (!this.content) {
            return null;
        }

        const componentData: TooltipPortalData = {
            elementRef: this.elementRef,
            position: this.tooltipPosition || TooltipPosition.bottom,
            content: null
        };

        if (typeof this.content === 'string') {
            componentData.content = this.content;
        } else {
            if (this.isTooltipData(this.content)) {
                componentData.content = this.content.content;
                componentData.inputs = this.content.inputs;
            } else {
                componentData.content = this.content;
            }
        }
        return componentData;
    }

    private isTooltipData(content: TooltipData | ComponentType<{}>): content is TooltipData {
        return (<TooltipData>content).inputs !== undefined;
    }

    private openTooltip(tooltipPortalData: TooltipPortalData) {
        if (!tooltipPortalData) {
            return;
        }

        if (this.isTextContent(tooltipPortalData)) {
            this.elementRef.nativeElement.setAttribute('aria-label', tooltipPortalData.content);
        }

        this.tooltipService.open(tooltipPortalData);
        this.isOpened = true;
    }

    private closeTooltip() {
        this.tooltipService.close();
        this.isOpened = false;
    }

    private isTextContent(tooltipPortalData: TooltipPortalData) {
        if (!tooltipPortalData) {
            return;
        }

        if (typeof tooltipPortalData.content === 'string') {
            return true;
        }

        return false;
    }
}
