import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

import { ConfigurationService } from 'core/configuration.service';
import { MenuNavigationService } from 'core/layout/core/menu-navigation.service';
import { NavigationMenu } from 'core/models/configuration.model';
import { MenuItem, MenuItems, NavMenuItem } from 'core/models/navigation.model';
import { NavigationService } from 'core/navigation.service';
import { WindowService } from 'core/window.service';

@Component({
    selector: 'desktop-menu',
    templateUrl: './desktop-menu.component.html',
    styleUrls: ['./desktop-menu.component.less']
})
export class DesktopMenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('navItems') navItems: ElementRef;

    mainItems: MenuItem[];
    moreMenuItems: NavMenuItem[] = [];
    mainMenuItems: MenuItems;
    quickLinksMenuItems: NavMenuItem[] = [];
    navigationMenu: NavigationMenu;
    isMoreItemsMenuOpen: boolean = false;
    isQuickLinksMenuOpen: boolean = false;
    isMegaMenuOpen: boolean = false;
    invertedNavigationBarColor: boolean = false;
    isMoreItemSelected: boolean = false;
    quickLinksMenuTitle: string;
    isMegaMenuLoaded: boolean = false;
    hasBannerLogo: boolean = false;
    bannerUrl: string;
    showMoreMenuItem: boolean = true;

    private windowResizeSubscription: Subscription = Subscription.EMPTY;
    private megaMenuControlWidth: number = 0;
    private isTouchDevice: boolean = false;
    private items: NavigationItem[] = [];

    constructor(
        private windowService: WindowService,
        private router: Router,
        private configurationService: ConfigurationService,
        private navigationService: NavigationService,
        private elementRef: ElementRef,
        private cdRef: ChangeDetectorRef,
        private menuNavigationService: MenuNavigationService
    ) {}

    ngOnInit() {
        this.isTouchDevice = this.windowService.isTouchDevice();

        this.mainMenuItems = this.menuNavigationService.menuItems;
        this.mainItems = [...this.mainMenuItems.navigationItems].filter(x => x.displayName !== null && x.displayName !== '');

        this.mainMenuItems.navigationItems = [...this.mainItems];

        this.invertedNavigationBarColor = this.configurationService.configuration.options.invertedNavigationBarColor;

        this.hasBannerLogo = !!this.configurationService.configuration.options.banner;

        if (this.hasBannerLogo) {
            this.bannerUrl = this.configurationService.configuration.options.banner.filePath;
        }

        this.navigationMenu = this.configurationService.configuration.navigationMenu;

        this.router.events.subscribe((val: any) => {
            this.onRouterEvent(val);
        });
    }

    ngAfterViewInit() {
        this.megaMenuControlWidth = this.getElementWidth(<HTMLElement>this.navItems.nativeElement.querySelector('.mega-menu-items'));

        this.setNavigationItems();
        this.organiseNavItems();
        this.windowResizeSubscription = fromEvent(window, 'resize')
            .pipe(debounceTime(50))
            .subscribe(() => {
                this.organiseNavItems();
            });

        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        this.windowResizeSubscription.unsubscribe();
    }

    openQuickLinksMenu() {
        if (this.isTouchDevice) {
            return;
        }

        this.isQuickLinksMenuOpen = true;
    }

    hideQuickLinksMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.isQuickLinksMenuOpen = false;
    }

    openMoreItemsMenu() {
        if (this.isTouchDevice) {
            return;
        }

        this.isMoreItemsMenuOpen = true;
    }

    hideMoreItemsMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.isMoreItemsMenuOpen = false;
    }

    openMegaMenu() {
        if (this.isTouchDevice) {
            return;
        }

        this.isMegaMenuOpen = true;
    }

    hideMegaMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.isMegaMenuOpen = false;
    }

    private onRouterEvent(val: any): void {
        if (val instanceof NavigationEnd) {
            this.isMoreItemsMenuOpen = false;

            this.isQuickLinksMenuOpen = false;

            this.isMegaMenuOpen = false;
        }

        this.setActiveMenu();
    }

    private setActiveMenu() {
        this.isMoreItemSelected = false;

        const megaMenuItems = this.moreMenuItems.concat(this.quickLinksMenuItems);

        megaMenuItems?.forEach(item => {
            if (item && !this.isMoreItemSelected) {
                if (this.router.url.includes(item.path)) {
                    this.isMoreItemSelected = true;
                } else {
                    this.isMoreItemSelected = false;
                }
            }
        });
    }

    private setNavigationItems() {
        const children = Array.from(this.navItems.nativeElement.querySelectorAll('.nav-item.main-item')) as HTMLElement[];
        children.forEach((item: HTMLElement, index: number) => {
            this.items.push({
                index: index,
                width: this.getElementWidth(item)
            });
        });
    }

    private organiseNavItems() {
        const maxWidth = this.getElementWidth(<HTMLElement>this.elementRef.nativeElement.firstChild);

        let currentWidth: number = 0;
        let navItemIndex: number = 0;

        const hasQuickLinks: boolean = this.configurationService.configuration.navigationMenu?.quickLinkGroups?.length > 0;

        while (currentWidth < maxWidth) {
            if (navItemIndex >= this.mainMenuItems.navigationItems.length) {
                break;
            }

            const nextIterationWidth: number = currentWidth + this.items[navItemIndex]?.width;

            if (nextIterationWidth > maxWidth) {
                break;
            }

            const remainingNumberOfItems: number = this.mainMenuItems.navigationItems.length - (navItemIndex + 1);
            const checkForNextIteration: boolean = hasQuickLinks || remainingNumberOfItems > 0;

            if (checkForNextIteration && nextIterationWidth + this.megaMenuControlWidth > maxWidth) {
                break;
            }

            currentWidth += this.items[navItemIndex]?.width;
            navItemIndex++;
        }

        this.mainItems = this.mainMenuItems.navigationItems.slice(0, navItemIndex);
        const moreItems = this.mainMenuItems.navigationItems.slice(navItemIndex, this.mainMenuItems.navigationItems.length);
        this.moreMenuItems = this.navigationService.convertToNavMenuItems(moreItems) ?? [];

        this.showMoreMenuItem = hasQuickLinks || this.moreMenuItems?.length > 0;

        this.isMegaMenuLoaded = true;
    }

    private getElementWidth(el: HTMLElement): number {
        if (!el) {
            return 0;
        }

        return el.getBoundingClientRect().width;
    }
}

interface NavigationItem {
    index: number;
    width: number;
}
