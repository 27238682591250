import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { FormatService } from 'core/format.service';
import { MonetaryValueType } from 'core/models/core.model';
import { CustomDateTimeFormatOptions, MonthFormatOptions, YearFormatOptions } from 'core/models/custom-date-time-format-options.model';
import { BenefitSummary } from 'core/selection.model';
import { SessionStorageService } from 'core/session-storage.service';

@Injectable()
export class CostPeriodService {
    monetaryValueType$: Observable<MonetaryValueType>;
    selectedBenefitSummary$: Observable<BenefitSummary>;

    private monetaryValueType = new BehaviorSubject<MonetaryValueType>(MonetaryValueType.PeriodActualValue);
    private selectedBenefitSummary = new ReplaySubject<BenefitSummary>();
    private monetaryValueTypeKey: string = 'monetaryValueTypeSelected';

    constructor(private formatService: FormatService, private sessionStorageService: SessionStorageService) {
        this.monetaryValueType$ = this.monetaryValueType.asObservable();
        this.selectedBenefitSummary$ = this.selectedBenefitSummary.asObservable();
    }

    changeMonetaryValueType(monetaryValueType: MonetaryValueType) {
        this.sessionStorageService.setItem(this.monetaryValueTypeKey, monetaryValueType);
        this.monetaryValueType.next(monetaryValueType);
    }

    changeEffectiveDate(benefitSummary: BenefitSummary) {
        this.selectedBenefitSummary.next(benefitSummary);
    }

    getSchemePeriodLabels(start: string, end: string): [string, string] {
        const startFormatOptions: CustomDateTimeFormatOptions = {
            monthFormatOptions: MonthFormatOptions.Short
        };

        if (this.formatService.parseDateUTC(start).year === this.formatService.parseDateUTC(end).year) {
            startFormatOptions.yearFormatOptions = YearFormatOptions.None;
        }

        return [
            this.formatService.formatDate(start, startFormatOptions),
            this.formatService.formatDate(end, {
                monthFormatOptions: MonthFormatOptions.Short
            })
        ];
    }

    getSchemePeriodLabelsByDates(start: Date, end: Date): [string, string] {
        const startFormatOptions: CustomDateTimeFormatOptions = {
            monthFormatOptions: MonthFormatOptions.Short
        };

        if (this.formatService.parseDateUTC(start).year === this.formatService.parseDateUTC(end).year) {
            startFormatOptions.yearFormatOptions = YearFormatOptions.None;
        }

        return [
            this.formatService.formatDate(start, startFormatOptions),
            this.formatService.formatDate(end, {
                monthFormatOptions: MonthFormatOptions.Short
            })
        ];
    }
}
