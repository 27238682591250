<div
    #containerFocus
    tabindex="-1"
    [attr.aria-label]="ariaLabel"
    class="dialog-container"
    role="dialog"
    (keydown.esc)="closeOverlay()"
    [@flyInOut]="animate"
    (@flyInOut.done)="onAnimationDone()"
    focus-trap>
    <div class="dialog-banner"></div>
    <button *ngIf="!hideCross" #closeIcon [attr.aria-label]="'Global_Action_Close' | appText" class="close-icon btn-close" (click)="closeOverlay()">
        <icon iconId="cross"></icon>
    </button>
    <div class="dialog-content">
        <div *ngIf="headerText" class="dialog-title" aria-labelledby="dialog-title">
            <icon class="warning-icon icon-lg" iconId="warning-notification-filled" *ngIf="showIcon"></icon>
            <h3 id="dialog-title" class="title-message heading-22-b">{{ headerText }}</h3>
        </div>
        <div class="dialog-message-container">
            <div class="dialog-message" [cdkPortalOutlet]></div>
        </div>
        <div class="dialog-responses" *ngIf="actions">
            <button
                class="response"
                *ngFor="let action of actions; let last = last"
                [ngClass]="action.class ? action.class : last ? 'btn-primary' : 'btn-default'"
                (click)="selectAction(action.value)">
                <span>{{ action.text }}</span>
            </button>
        </div>
    </div>
</div>
