export interface SimpleMonetaryValue {
    annualActualCurrentValue: number;
    annualActualValue: number;
    schemePeriodCurrentForecast: number;
    schemePeriodForecast: number;
    schemePeriodToDate: number;
    periodValue: number;
    periodActualValue: number;
}

export interface MonetaryValue extends SimpleMonetaryValue {
    pendingApprovalValue?: SimpleMonetaryValue;
    recurringPeriodValues?: RecurringPeriodValue[];
}

export interface CurrencyConversionInformation {
    currencyID: string;
    name?: string;
    effectiveToSchemeExchangeRateValue?: number;
    decimalPlaces: number;
}

export interface SelectionBenefitAssigneeCurrencyConversionModel {
    employeePreTaxCost: MonetaryValue;
    employeePostTaxCost: MonetaryValue;
    employerCost: MonetaryValue;
    totalCost: MonetaryValue;
    totalEmployeeCost: MonetaryValue;
    totalReturnToFunding: MonetaryValue;
    employeePreTaxPayCost: MonetaryValue;
    employeeFundingCost: MonetaryValue;
}

export interface SelectionBenefitCostElementCurrencyConversionModel {
    employerCost: MonetaryValue;
    employeePreTaxCost: MonetaryValue;
    employeePostTaxCost: MonetaryValue;
    totalCost: MonetaryValue;
    totalEmployeeCost: MonetaryValue;
    employeeFundingCost: MonetaryValue;
    employeePreTaxPayCost: MonetaryValue;
    currencyConversionInformation: CurrencyConversionInformation;
}

export interface SelectionBenefitTaxElementCurrencyConversionModel {
    employerCost: MonetaryValue;
    employeeCost: MonetaryValue;
    totalCost: MonetaryValue;
}

export interface RecurringPeriodValue {
    id: string;
    forecast: number;
    currentForecast: number;
    valueToDate: number;
}

export enum MonetaryValueType {
    None = 0,
    SchemePeriodForecast = 2,
    SchemePeriodToDate = 3,
    PeriodValue = 4,
    PeriodActualValue = 5,
    AnnualActualValue = 6,
    AnnualActualCurrentValue = 7,
    SchemePeriodCurrentForecast = 8
}

export enum AdjudicationStatus {
    None = 0,
    PendingApproval = 1,
    Approved = 2,
    ApprovedUntil = 3,
    PartiallyApproved = 4,
    Declined = 5,
    RequestForInformation = 6,
    Accepted = 7,
    Expired = 8,
    Discarded = 9
}

export enum DependantEvidenceStatus {
    None = 0,
    PendingApproval = 1,
    Required = 2,
    Approved = 3
}
