import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { expandCollapse } from 'shared/animations/expand-collapse.animation';
import { rotateBlob } from 'shared/animations/rotate-blob.animation';

@Component({
    selector: 'accordion-item',
    templateUrl: 'accordion-item.component.html',
    styleUrls: ['./accordion-item.component.less'],
    animations: [expandCollapse, rotateBlob]
})
export class AccordionItemComponent {
    @Input() id: string;
    @Input() colour: string;
    @Input() expanded: boolean;
    @Input() disabled: boolean;
    @Output() expandCollapseDone: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() expandCollapseChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('template', { static: true }) template: TemplateRef<any>;

    get borderColour() {
        return this.disabled ? '' : this.colour;
    }

    toggleItem() {
        if (this.disabled) {
            return;
        }

        this.expanded = !this.expanded;
        this.expandCollapseChange.emit();
    }

    onExpandCollapseDone() {
        this.expandCollapseDone.emit(this.expanded);
    }
}
