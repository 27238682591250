import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, map, skipWhile, switchMap } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import { ConfigurationService } from 'core/configuration.service';
import { EmitEvent, EventService, EventType } from 'core/event.service';
import { CartConfig } from 'core/models/navigation.model';
import { ProfileUpdateResult } from 'core/models/profile.model';
import { CartPanelDisplayInfo, ProviderIntegrationMessage, SelectionBasic, SelectionPeriod } from 'core/selection.model';
import { TestingModeService } from 'core/testing-mode.service';

export interface ProviderIntegrationMessageSubscription {
    subscription: Subscription;
    message: ProviderIntegrationMessage;
}

@Injectable()
export class SelectionService {
    selectionPeriods = new BehaviorSubject<SelectionPeriod[]>([]);
    hasPostSelection: boolean = false;

    private messagePollingSubscriptionsMap = new Map<string, ProviderIntegrationMessageSubscription>();

    constructor(
        private httpClient: ApiHttpClient,
        private configurationService: ConfigurationService,
        private eventService: EventService,
        private testingModeService: TestingModeService
    ) {}

    getTempSelectionsCountAndSetHasPostSelection(): Observable<number> {
        let getSelectionObservable = this.httpClient.get<SelectionBasic>('/Selection/GetSelection');

        if (this.testingModeService.isInTestingMode) {
            getSelectionObservable = this.httpClient.get<SelectionBasic>('/Selection/GetSelectionForSpecifiedDate', [
                { key: 'specifiedCurrentPeriodDate', value: this.testingModeService.specifiedCurrentPeriodDate.toISOString() }
            ]);
        }

        return getSelectionObservable.pipe(
            map(selection => {
                this.selectionPeriods.next(selection.selectionPeriods);
                let total = 0;
                selection.selectableBenefits.forEach(x => (total = total + x.selectionBenefitEffectives.filter(e => e.inTemporarySelection).length));

                const isPostSelectionPageEnabled: boolean = this.configurationService.configuration.pageConfig.benefitManager.isPostSelectionEnabled;

                this.hasPostSelection = isPostSelectionPageEnabled && selection.postSelectionModels?.some(x => x.isEnabled);
                this.testingModeService.setMinDate(selection);

                return total;
            })
        );
    }

    loadProviderIntegrationMessages() {
        return this.httpClient.get<ProviderIntegrationMessage[]>('/Selection/GetProviderIntegrationMessages').pipe(
            map(messages => {
                this.setUpMessagePolling(messages);
            })
        );
    }

    updateProfilePreSelectionFields(fields: { [id: string]: any }): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Selection/UpdatePreSelectionFields', fields);
    }

    updateDependantPreSelectionFields(dependantId: string, updatedFields: { [id: string]: any }): Observable<ProfileUpdateResult> {
        return this.httpClient.post<ProfileUpdateResult>('/Selection/UpdateDependantPreSelectionFields', updatedFields, [
            { key: 'dependantID', value: dependantId }
        ]);
    }

    startMessagePolling(message: ProviderIntegrationMessage) {
        if (this.messagePollingSubscriptionsMap.has(message.id)) {
            return;
        }

        const subscription: Subscription = this.isSSOSelectionPending(message.id).subscribe(() => {
            this.loadBenefitDisplayInfo(message.benefitID, message.selectionEffectiveDate).subscribe(displayInfo => {
                this.messagePollingSubscriptionsMap.get(message.id).subscription.unsubscribe();

                this.openToastValidation(displayInfo, false);

                this.eventService.triggerEvent(new EmitEvent(EventType.SSOSelectionReceived));
            });
        });

        this.messagePollingSubscriptionsMap.set(message.id, <ProviderIntegrationMessageSubscription>{
            message: message,
            subscription: subscription
        });
    }

    private setUpMessagePolling(providerIntegrationMessages: ProviderIntegrationMessage[]) {
        providerIntegrationMessages.forEach(message => {
            if (message.isThirdParty) {
                return;
            }
            this.startMessagePolling(message);
        });
    }

    private isSSOSelectionPending(id: string) {
        return interval(5000).pipe(
            switchMap(() => {
                return this.httpClient
                    .get<boolean>('/Selection/DoesProviderIntegrationMessageExist', [{ key: 'id', value: id }], true)
                    .pipe(catchError(() => of(true)));
            }),
            skipWhile(val => val)
        );
    }

    private loadBenefitDisplayInfo(templateID: string, selectionEffectiveDate: Date) {
        return this.httpClient.get<CartPanelDisplayInfo>('/Selection/GetCartPanelDisplayInfo', [
            { key: 'templateID', value: templateID },
            { key: 'selectionEffectiveDate', value: selectionEffectiveDate }
        ]);
    }

    private openToastValidation(displayInfo: CartPanelDisplayInfo, isDiscontinue: boolean) {
        const isPostSelectionEnabled: boolean = this.configurationService.configuration.pageConfig.benefitManager.isPostSelectionEnabled;

        this.eventService.triggerEvent(
            new EmitEvent(EventType.UpdateCart, {
                tempSelectionsCount: 1,
                isUpdateNotification: true,
                displayText: displayInfo.displayName,
                systemIcon: displayInfo.systemIcon,
                isDiscontinue: isDiscontinue,
                isPostSelectionNextStep: isPostSelectionEnabled && displayInfo.hasPostSelectionModels,
                showMenu: true
            } as CartConfig)
        );
    }
}
