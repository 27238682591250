import { ComponentType } from '@angular/cdk/portal';
import { ElementRef } from '@angular/core';

export interface TooltipData {
    content: string | ComponentType<{}>;
    inputs?: {};
    position?: TooltipPosition;
    contentPadding?: number;
    useAppText?: boolean;
    contentMaxWidth?: number;
}

export interface TooltipPortalData extends TooltipData {
    elementRef: ElementRef;
    position: TooltipPosition;
}

export enum TooltipPosition {
    left = 1,
    right = 2,
    top = 3,
    bottom = 4
}
