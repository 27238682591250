<ng-template #template>
    <li
        class="dropdown-option label-14-r"
        [ngClass]="contextType"
        [class.selected]="selectionFocus"
        [id]="activeDescendantElementId"
        [class.active]="isActive"
        role="option"
        (click)="selectOption()"
        #optionElement>
        <ng-content></ng-content>
    </li>
</ng-template>
