import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from 'core/format.service';

@Pipe({
    name: 'formatString',
    pure: false
})
export class FormatString implements PipeTransform {
    constructor(private formatService: FormatService) {}

    transform(formattedText: string, replacements: string[]) {
        return this.formatService.formatString(formattedText, replacements);
    }
}
