import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsService } from 'core/analytics.service';
import { ApplicationInsightsService } from 'core/application-insights.service';
import { ConfigurationService } from 'core/configuration.service';
import { CookieConsentService } from 'core/cookie-consent.service';
import { DynamicStyleLoaderService } from 'core/dynamic-style-loader.service';
import { EmployeeService } from 'core/employee.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';
import { EmitEvent, EventService, EventType } from 'core/event.service';
import { FeedbackService } from 'core/feedback.service';
import { FontLoaderService } from 'core/font-loader.service';
import { FormatService } from 'core/format.service';
import { IconProviderService } from 'core/icon-provider.service';
import { LoadingScreenService } from 'core/loadingScreen/loading-screen.service';
import { CartConfig } from 'core/models/navigation.model';
import { PrivacyService } from 'core/privacy.service';
import { SelectionService } from 'core/selection.service';
import { TextService } from 'core/text.service';
import { ThirdPartyLockService } from 'core/third-party-lock.service';
import { WindowService } from 'core/window.service';

@Injectable()
export class ApplicationGuard implements CanActivate {
    private loaded: boolean;

    constructor(
        private iconProviderService: IconProviderService,
        private textService: TextService,
        private formatService: FormatService,
        private employeeService: EmployeeService,
        private configurationService: ConfigurationService,
        private dynamicStyleLoaderService: DynamicStyleLoaderService,
        private environmentConfigurationDataService: EnvironmentConfigurationDataService,
        private feedbackService: FeedbackService,
        private eventService: EventService,
        private privacyService: PrivacyService,
        private loadingScreenService: LoadingScreenService,
        private applicationInsightsService: ApplicationInsightsService,
        private analyticsService: AnalyticsService,
        private selectionService: SelectionService,
        private cookieConsentService: CookieConsentService,
        private windowService: WindowService,
        private fontLoaderService: FontLoaderService,
        private thirdPartyLockService: ThirdPartyLockService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.loaded) {
            this.loadingScreenService.show();
            return forkJoin(
                this.configurationService.loadApplicationConfiguration(),
                this.iconProviderService.loadIconSet(),
                this.employeeService.load(),
                this.dynamicStyleLoaderService.run()
            ).pipe(
                map(([config]) => {
                    this.initialiseEvents();
                    this.fontLoaderService.init(config.configuration.styling.fontFamily);
                    this.textService.rewardCentreText = config.text;
                    this.environmentConfigurationDataService.environmentConfigurationData = config.environmentConfigurationData;
                    this.loaded = true;
                    this.feedbackService.init();
                    this.formatService.init();
                    this.privacyService.init(this.configurationService.configuration.options.defaultPrivacyValue);
                    this.windowService.init();
                    this.applicationInsightsService.register();
                    this.analyticsService.register();
                    this.cookieConsentService.init();
                    if (config.configuration.pageConfig.benefitManager.isEnabled) {
                        this.selectionService.loadProviderIntegrationMessages().subscribe();
                        this.thirdPartyLockService.init();
                    }

                    this.loadingScreenService.hide();

                    return true;
                })
            );
        } else {
            return of(true);
        }
    }

    private initialiseEvents() {
        this.eventService.initialiseEvent(new EmitEvent(EventType.UpdateCart, new CartConfig()));
        this.eventService.initialiseEvent(new EmitEvent(EventType.PrivacyToggle, this.privacyService.inPrivateModeFlag));
        this.eventService.initialiseEvent(new EmitEvent(EventType.UpdateMoreItemsMenu));
        this.eventService.initialiseEvent(new EmitEvent(EventType.SSOSelectionReceived), true);
        this.eventService.initialiseEvent(new EmitEvent(EventType.DisplayChange));
    }
}
