import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';

@Injectable()
export class OverlayPriorityHelper {
    private closeSubject: Subject<boolean>;
    private numberOfRootOverlaysOpen: number = 0;

    constructor() {
        this.closeSubject = new Subject<boolean>();
    }

    openOverlay(fromRoot: boolean) {
        if (!fromRoot && this.numberOfRootOverlaysOpen > 0) {
            return this.closeSubject.asObservable();
        } else {
            if (fromRoot) {
                this.numberOfRootOverlaysOpen++;
            }

            return of(true);
        }
    }

    closeOverlay(fromRoot: boolean) {
        if (!fromRoot) {
            return;
        }

        this.numberOfRootOverlaysOpen--;

        if (this.numberOfRootOverlaysOpen === 0) {
            this.closeSubject.next(true);
            this.closeSubject.complete();
        }
    }
}
