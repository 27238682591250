import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import {
    AggregatedLanguage,
    ApplicationConfiguration,
    Configuration,
    FeatureFlag,
    LoginConfiguration,
    LoginInformation,
    SchemeContent,
    TintedAndShadedClientColours
} from 'core/models/configuration.model';
import { EmployeeConfiguration } from 'core/models/employee.model';
import { SessionStorageService } from 'core/session-storage.service';

@Injectable()
export class ConfigurationService {
    configuration: Configuration;
    loginConfiguration: LoginConfiguration;
    aggregatedLanguages: AggregatedLanguage[];
    selectedLanguage: AggregatedLanguage;
    loginInformation: LoginInformation;
    defaultUrl: string = '';
    pageTitle: string = '';
    countryLanguage: string;
    openWelcomeOverlay$: Observable<boolean>;
    togglePrivacyPopUp$: Observable<boolean>;

    private openWelcomeOverlaySubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private togglePrivacyPopUpSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private selectedTintedAndShadedClientColours: TintedAndShadedClientColours;
    private privacyToggleSeen: boolean;
    private featureFlagsEnabled: FeatureFlag[];

    constructor(private httpClient: ApiHttpClient, private sessionStorageService: SessionStorageService) {
        this.openWelcomeOverlay$ = this.openWelcomeOverlaySubject.asObservable();
        this.togglePrivacyPopUp$ = this.togglePrivacyPopUpSubject.asObservable();
    }

    dnaPageEnabled(): Observable<boolean> {
        return this.httpClient.unsecureGet<boolean>('/Configuration/GetDNAPageEnabled');
    }

    loadApplicationConfiguration(): Observable<ApplicationConfiguration> {
        return this.httpClient.get<ApplicationConfiguration>('/Configuration/GetApplicationConfiguration').pipe(
            map((applicationConfiguration: ApplicationConfiguration) => {
                this.configuration = applicationConfiguration.configuration;
                this.aggregatedLanguages = this.configuration.aggregatedLanguages;
                this.selectedLanguage = this.getSelectedLanguage(this.configuration.language);
                this.pageTitle = applicationConfiguration.configuration.options.pageTitle;
                this.countryLanguage = applicationConfiguration.configuration.language.defaultCulture;
                this.featureFlagsEnabled = applicationConfiguration.featureFlagsEnabled;
                return applicationConfiguration;
            })
        );
    }

    loadLoginInformation(externalIdentifier: string, selectedLanguageID: number): Observable<LoginInformation> {
        return this.httpClient
            .unsecureGet<LoginInformation>('/Configuration/GetLoginInformation', [
                { key: 'externalIdentifier', value: externalIdentifier },
                { key: 'selectedLanguageID', value: selectedLanguageID }
            ])
            .pipe(
                map((loginInformation: LoginInformation) => {
                    this.loginInformation = loginInformation;
                    this.loginConfiguration = loginInformation.loginConfiguration;
                    this.aggregatedLanguages = JSON.parse(JSON.stringify(this.loginConfiguration.supportedLanguages));
                    this.selectedLanguage = this.getSelectedLanguage(this.loginConfiguration.language);

                    return this.loginInformation;
                })
            );
    }

    showCookiesPolicy(): Observable<boolean> {
        return this.httpClient.unsecureGet<boolean>('/Configuration/ShowCookiesPolicy').pipe(
            map(resp => {
                return resp;
            })
        );
    }

    setCookiesPolicyCookie(): Observable<boolean> {
        return this.httpClient.unsecureGet<boolean>('/Configuration/SetCookiesPolicyCookie').pipe(
            map(() => {
                return true;
            })
        );
    }

    getClientColour(clientColourNumber: number): string {
        switch (clientColourNumber) {
            case 2:
                return this.configuration.styling.clientColour2;
            case 3:
                return this.configuration.styling.clientColour3;
            case 4:
                return this.configuration.styling.clientColour4;
            case 5:
                return this.configuration.styling.clientColour5;
            case 6:
                return this.configuration.styling.clientColour6;
            case 7:
                return this.configuration.styling.clientColour7;
            case 8:
                return this.configuration.styling.clientColour8;
            case 9:
                return this.configuration.styling.clientColour9;
            case 10:
                return this.configuration.styling.clientColour10;
            default:
                return this.configuration.styling.clientColour1;
        }
    }

    getTintedShadedClientColour(clientColourIndex: number, tintedShadedColourIndex: number) {
        this.selectedTintedAndShadedClientColours = this.getTintedAndShadedColours(clientColourIndex);

        return this.getTintedAndShadedColour(tintedShadedColourIndex);
    }

    updateEmployeeConfiguration(employeeConfiguration: EmployeeConfiguration) {
        return this.httpClient.post<EmployeeConfiguration>('/Configuration/UpdateEmployeeConfiguration', employeeConfiguration).pipe(
            map(response => {
                return response;
            })
        );
    }

    getSchemeName(): Observable<string> {
        return this.httpClient.unsecureGet<SchemeContent>('/Configuration/GetSchemeName').pipe(
            map((response: SchemeContent) => {
                return response.schemeName;
            })
        );
    }

    checkWelcomeOverlay() {
        this.openWelcomeOverlaySubject.next(true);
    }

    showPrivacyToggle(): Observable<void> {
        return this.httpClient.unsecureGet<boolean>('/Configuration/ShowPrivacyToggleCookie').pipe(
            map(response => {
                if (response !== undefined) {
                    this.privacyToggleSeen = response;
                    this.togglePrivacyPopUp(response);
                }
            })
        );
    }

    setPrivacyToggle() {
        return this.httpClient.unsecureGet<boolean>('/Configuration/SetPrivacyToggleCookie').pipe(
            map(() => {
                this.togglePrivacyPopUp(true);
            })
        );
    }

    togglePrivacyPopUp(openPopUp: boolean) {
        const privacyToggleSeenItem = this.sessionStorageService.getItem('privacyToggleSeen');

        if (!this.privacyToggleSeen) {
            if (privacyToggleSeenItem) {
                this.togglePrivacyPopUpSubject.next(privacyToggleSeenItem);
            } else {
                this.togglePrivacyPopUpSubject.next(openPopUp);
            }
        } else {
            this.togglePrivacyPopUpSubject.next(this.privacyToggleSeen);
        }
    }

    isFeatureFlagEnabled(featureFlag: FeatureFlag): boolean {
        return this.featureFlagsEnabled.length > 0 && this.featureFlagsEnabled.indexOf(featureFlag) > -1;
    }

    private getTintedAndShadedColours(clientColourIndex: number) {
        switch (clientColourIndex) {
            case 2:
                return this.configuration.styling.clientColour2TintedAndShadedColours;
            case 3:
                return this.configuration.styling.clientColour3TintedAndShadedColours;
            default:
                return this.configuration.styling.clientColour1TintedAndShadedColours;
        }
    }

    private getTintedAndShadedColour(tintedShadedColourIndex: number): string {
        switch (tintedShadedColourIndex) {
            case 0:
                return this.selectedTintedAndShadedClientColours.clientColour100;
            case 1:
                return this.selectedTintedAndShadedClientColours.clientColour200;
            case 2:
                return this.selectedTintedAndShadedClientColours.clientColour300;
            case 3:
                return this.selectedTintedAndShadedClientColours.clientColour400;
            case 4:
                return this.selectedTintedAndShadedClientColours.clientColour450;
            case 5:
                return this.selectedTintedAndShadedClientColours.clientColour500;
            case 6:
                return this.selectedTintedAndShadedClientColours.clientColour600;
            case 7:
                return this.selectedTintedAndShadedClientColours.clientColour700;
            case 8:
                return this.selectedTintedAndShadedClientColours.clientColour800;
            case 9:
                return this.selectedTintedAndShadedClientColours.clientColour900;
        }
    }

    private getSelectedLanguage(language: any): AggregatedLanguage {
        const selectedLanguage = this.sessionStorageService.getItem('selectedLanguage');

        return selectedLanguage ? selectedLanguage : language;
    }
}
