import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { ApprovalService } from 'core/approval.service';
import { ConfigurationService } from 'core/configuration.service';
import { EmployeeService } from 'core/employee.service';
import { EventService, EventType } from 'core/event.service';
import { MenuNavigationService } from 'core/layout/core/menu-navigation.service';
import { AggregatedLanguage } from 'core/models/configuration.model';
import { DisplaySize } from 'core/models/display.model';
import { EmployeeBasic } from 'core/models/employee.model';
import { EmployeeManagerStatsCount } from 'core/models/line-manager.model';
import { MenuItems, MenuType, NavMenuItem } from 'core/models/navigation.model';
import { NavigationService } from 'core/navigation.service';
import { SecurityService } from 'core/security.service';
import { SessionStorageService } from 'core/session-storage.service';
import { TextService } from 'core/text.service';
import { WindowService } from 'core/window.service';

import { SelectContextType } from 'shared/components/darwin-select/darwin-select.model';
import { TooltipPosition } from 'shared/tooltip/tooltip.model';

@Component({
    selector: 'primary-navigation',
    templateUrl: 'primary-navigation.component.html',
    styleUrls: ['primary-navigation.component.less']
})
export class PrimaryNavigationComponent implements OnInit, OnDestroy, AfterContentInit {
    @ViewChild('logoutButton') logoutButton: ElementRef;

    employee: EmployeeBasic;
    menuItems: MenuItems;
    logoUrl: string;
    returnHomeLabel: string = '';
    tempSelectionsCount: number;
    totalPendingCountForManagerApproval: number;
    maxWidthPrimaryMenu: number = 0;

    supportedLanguages: AggregatedLanguage[];
    selectedLanguage: AggregatedLanguage;
    hasMultiLanguage: boolean;

    invertedNavigationBarColor: boolean = false;
    isMobileOrTablet: boolean = false;
    isMobileDevice: boolean = false;
    isTabletDevice: boolean = false;
    isPostSelectionNextStep: boolean = false;

    showHelpMenu: boolean = false;
    showCartMenu: boolean = false;
    showProfileMenu: boolean = false;
    showFullWidthMenu: boolean = false;
    showPrivacyPopUp: boolean = true;
    mobileTabletMenuExpanded: boolean = false;

    isHelpVisible: boolean = false;
    isProfileVisible: boolean = false;
    isCartVisible: boolean = false;
    isLanguageSwitcherVisible: boolean = false;
    isHelpSelected: boolean = false;
    isProfileSelected: boolean = false;
    isCartMenuSelected: boolean = false;
    showLanguageSwitcherMenu: boolean = false;
    isLanguageDropdownOpen: boolean = false;
    preventDefaultOnDropDownClick: boolean = false;
    contentLoaded: boolean = false;
    imageLoaded: boolean = false;
    hasLogo: boolean = false;
    isLanguageAndLogoutRowLayout = false;

    helpNavMenuItems: NavMenuItem[] = [];
    profileNavMenuItems: NavMenuItem[] = [];
    languageSwitcherNavMenuItems: NavMenuItem[] = [];

    contextType = SelectContextType;
    TooltipPosition = TooltipPosition;

    private profileItemWithApprovalID: string = 'profileItemWithApproval';
    private subscriptions: Subscription[] = [];
    private isTouchDevice: boolean = false;
    private hasManagerApproval: boolean = false;
    private logoutButtonMaxWidth = 100;

    constructor(
        private securityService: SecurityService,
        private employeeService: EmployeeService,
        private router: Router,
        private menuNavigationService: MenuNavigationService,
        private windowService: WindowService,
        private configurationService: ConfigurationService,
        private coreApprovalService: ApprovalService,
        private eventService: EventService,
        private sessionStorageService: SessionStorageService,
        private textService: TextService,
        private cdRef: ChangeDetectorRef,
        private navigationService: NavigationService
    ) {}

    ngOnInit(): void {
        this.employee = this.employeeService.employee;
        this.menuItems = this.menuNavigationService.menuItems;
        this.menuItems.navigationItems = [...this.menuItems.navigationItems];
        this.hasManagerApproval = this.configurationService.configuration.pageConfig.lineManager.isEnabled;

        this.isMobileDevice = this.windowService.isMobileDevice();
        this.isTabletDevice = this.windowService.isTabletDevice();
        this.isMobileOrTablet = this.isMobileDevice || this.isTabletDevice;
        this.isTouchDevice = this.windowService.isTouchDevice();

        this.invertedNavigationBarColor = this.configurationService.configuration.options.invertedNavigationBarColor;

        this.hasLogo = !!this.configurationService.configuration.options.logo;
        if (this.hasLogo) {
            this.logoUrl = this.configurationService.configuration.options.logo.filePath;
        }

        this.setReturnHomeLogoLinkLabel();
        this.initMenuItems();
        this.onDisplayChangeEvent();
        this.initMenusVisibility();
        this.initRightMenuSubscriptions();
    }

    ngAfterContentInit() {
        this.contentLoaded = true;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    logout() {
        this.securityService.logout();
    }

    navigateToHome() {
        this.showFullWidthMenu = null;
        this.router.navigate(['/']);
    }

    openHelpMenu() {
        this.closeMenus();

        if (this.isTouchDevice) {
            return;
        }

        this.showHelpMenu = true;
    }

    closeHelpMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent?.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.showHelpMenu = false;

        this.setMenuIsSelected();
    }

    openCartMenu() {
        this.closeMenus();

        if (this.isTouchDevice || this.isMobileOrTablet) {
            return;
        }

        this.showFullWidthMenu = false;
        this.showCartMenu = true;
    }

    closeCartMenu(menuFocusOutEvent) {
        if ((this.isMobileOrTablet && menuFocusOutEvent.type === 'mouseleave') || menuFocusOutEvent?.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.showCartMenu = false;
        this.isCartMenuSelected = false;
    }

    openProfileMenu() {
        this.closeMenus();

        if (this.isTouchDevice) {
            return;
        }

        this.showProfileMenu = true;

        setTimeout(() => {
            const buttonWidth = this.getElementWidth(<HTMLElement>this.logoutButton?.nativeElement);
            this.isLanguageAndLogoutRowLayout = buttonWidth > this.logoutButtonMaxWidth;
        });
    }

    closeProfileMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent?.currentTarget.contains(menuFocusOutEvent.relatedTarget) || this.preventDefaultOnDropDownClick) {
            this.preventDefaultOnDropDownClick = false;
            return;
        }

        this.showProfileMenu = false;

        this.setMenuIsSelected();
        this.enableScroll();
    }

    openLanguageSwitcher() {
        this.closeMenus();

        if (this.isTouchDevice) {
            return;
        }

        this.showLanguageSwitcherMenu = true;
    }

    closeLanguageMenu(menuFocusOutEvent) {
        if (menuFocusOutEvent?.currentTarget.contains(menuFocusOutEvent.relatedTarget)) {
            return;
        }

        this.showLanguageSwitcherMenu = false;
    }

    changeLanguage(languageId: number) {
        if (this.selectedLanguage.id === languageId) {
            return;
        }

        this.sessionStorageService.setItem(
            'selectedLanguage',
            this.supportedLanguages.find(language => language.id === languageId)
        );

        this.employeeService.updateSelectedLanguage(languageId as number).subscribe(() => {
            this.securityService.removeAccessToken();
            this.windowService.reloadPage();
        });
    }

    onClose() {
        this.showPrivacyPopUp = true;
    }

    triggerAction(navMenuItem: NavMenuItem) {
        this.changeLanguage(Number(navMenuItem.id));
    }

    private closeMenus() {
        this.showProfileMenu = false;
        this.showProfileMenu = false;
        this.showHelpMenu = false;
        this.showLanguageSwitcherMenu = false;
    }

    private initRightMenuSubscriptions() {
        this.subscriptions.push(
            this.router.events.subscribe(routerEvent => {
                if (routerEvent instanceof NavigationEnd) {
                    this.setMenuIsSelected();
                }
            })
        );

        if (this.isProfileVisible && this.hasManagerApproval) {
            this.subscriptions.push(this.eventService.subscribeToEvent(EventType.ManagerApproval).subscribe(() => this.updateAllCounters()));

            this.subscriptions.push(
                this.coreApprovalService.managerApprovalCounterUpdate$.subscribe(() => {
                    this.totalPendingCountForManagerApproval = this.coreApprovalService.getTotalPendingCount();
                    this.profileNavMenuItems.filter(x => x.id === this.profileItemWithApprovalID)[0].count = this.totalPendingCountForManagerApproval;
                })
            );
        }

        this.configurationService.showPrivacyToggle().subscribe();

        this.subscriptions.push(
            this.configurationService.togglePrivacyPopUp$.subscribe(showPrivacyToggle => {
                let inPrivateMode = this.sessionStorageService.getItem('inPrivateMode');

                if (inPrivateMode == null) {
                    inPrivateMode = true;
                }

                this.showPrivacyPopUp = !inPrivateMode || showPrivacyToggle;

                this.cdRef.detectChanges();
            })
        );
    }

    private updateAllCounters() {
        forkJoin([this.coreApprovalService.detailManagerClaimsStatistics(), this.coreApprovalService.countManagerEffectiveReceivedPendingRequest()]).subscribe(
            ([approvalStats, pendingRequestCount]: [EmployeeManagerStatsCount, number]) => {
                this.coreApprovalService.setApprovalStatistics(approvalStats);
                this.coreApprovalService.setPendingRequestCount(pendingRequestCount);
            }
        );
    }

    private setReturnHomeLogoLinkLabel() {
        const pageTitle: string = this.configurationService.configuration.options.pageTitle;
        const navMenuHomeKey: string = this.textService.getText('Nav_Menu_Home');

        if (pageTitle) {
            this.returnHomeLabel = `${pageTitle} ${navMenuHomeKey}`;
        } else {
            this.returnHomeLabel = `Reward Centre ${navMenuHomeKey}`;
        }
    }

    private initMenuItems(): void {
        const mainMenuItems = this.navigationService.buildMenu(this.menuItems, this.hasManagerApproval, this.totalPendingCountForManagerApproval, [], []);
        this.helpNavMenuItems = this.navigationService.getMenu(mainMenuItems, MenuType.HelpAndSupport)?.children ?? [];
        this.profileNavMenuItems = this.navigationService.getMenu(mainMenuItems, MenuType.Profile)?.children ?? [];

        this.supportedLanguages = this.configurationService.aggregatedLanguages;
        this.selectedLanguage = this.configurationService.aggregatedLanguages.filter(x => x.id === this.configurationService.selectedLanguage.id)[0];
        this.hasMultiLanguage = this.supportedLanguages.length > 1;
        this.languageSwitcherNavMenuItems = this.navigationService.initialiseLanguageSwitcherMenu(this.supportedLanguages, this.selectedLanguage.id);

        this.setMenuIsSelected();
    }

    private onDisplayChangeEvent() {
        this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe((displaySize: DisplaySize) => {
            this.isMobileDevice = displaySize === DisplaySize.mobile;
            this.isTabletDevice = displaySize === DisplaySize.tablet;
            this.isMobileOrTablet = this.isMobileDevice || this.isTabletDevice;

            this.initMenusVisibility();

            this.cdRef.detectChanges();
        });
    }

    private initMenusVisibility() {
        if (this.isMobileDevice) {
            this.showPrivacyPopUp = false;
        }

        this.isHelpVisible =
            !this.isMobileOrTablet &&
            this.configurationService.configuration.pageConfig.help.isEnabled &&
            (this.configurationService.configuration.pageConfig.help.benefitInformationPageEnabled ||
                this.configurationService.configuration.pageConfig.help.contactUsPageEnabled ||
                this.configurationService.configuration.pageConfig.help.faqsPageEnabled);

        this.isProfileVisible = !this.isMobileOrTablet;

        this.isCartVisible = this.configurationService.configuration.pageConfig.benefitManager.isEnabled;

        this.isLanguageSwitcherVisible =
            !this.isMobileOrTablet && this.hasMultiLanguage && this.configurationService.configuration.options.displayLanguageSwitcherInMainNavigation;
    }

    private setMenuIsSelected() {
        this.isProfileSelected = this.router.url.startsWith('/profile') || this.router.url.startsWith('/manager');
        this.isHelpSelected = this.router.url.startsWith('/help');
    }

    private enableScroll() {
        // Scroll is disabled when dropdown is opened, we need this work around in order to re-enable it if dropdown is never closed.
        window.onscroll = () => void {};
    }

    private getElementWidth(el: HTMLElement): number {
        return el?.getBoundingClientRect()?.width ?? 0;
    }
}
