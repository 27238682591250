import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ConfigurationService } from 'core/configuration.service';
import { CookieConsentService } from 'core/cookie-consent.service';
import { EmployeeService } from 'core/employee.service';
import { LoadingScreenService } from 'core/loadingScreen/loading-screen.service';
import { EmployeeBasic, EmployeeConfiguration } from 'core/models/employee.model';
import { PolicyService } from 'core/policy.service';
import { SecurityService } from 'core/security.service';
import { TextService } from 'core/text.service';

import { IOverlayContent } from 'shared/overlay/overlay.model';

@Component({
    templateUrl: 'welcome-overlay.component.html',
    styleUrls: ['./welcome-overlay.component.less']
})
export class WelcomeOverlayComponent implements OnInit, OnDestroy, IOverlayContent {
    @Input() employeeConfiguration: EmployeeConfiguration;
    @Input() schemeName: string;
    close: EventEmitter<void>;

    formGroup: FormGroup;
    hasMultiLanguage: boolean;
    replacements: any[] = [];
    agreeToTermsAndConditionsText: string;
    termsAndConditionsText: string;
    privacyPolicyText: string;
    cookiesPolicyText: string;
    footerText: string;
    validationMessage: string;
    employee: EmployeeBasic;
    agreedToTermsAndConditions: boolean;
    showCookiesPolicy: boolean = false;

    private securityId: string = 'security';
    private privacyPolicyID: string = 'privacy-policy';
    private agreeToTermsAndConditionsId: string = 'agree-terms-and-conditions';
    private cookieConsentSubscription: Subscription = Subscription.EMPTY;

    constructor(
        private textService: TextService,
        private policyService: PolicyService,
        private configurationService: ConfigurationService,
        private employeeService: EmployeeService,
        private securityService: SecurityService,
        private loadingScreenService: LoadingScreenService,
        private fb: FormBuilder,
        private cookieConsentService: CookieConsentService
    ) {}

    ngOnInit() {
        this.cookieConsentSubscription = this.cookieConsentService.trustArcStateObservable.subscribe((state: boolean) => {
            this.showCookiesPolicy = !state;
        });

        this.agreedToTermsAndConditions = this.employeeConfiguration ? this.employeeConfiguration.agreedToTermsAndConditions : false;
        this.employee = this.employeeService.employee;
        this.initForm();
        this.setupPoliciesTexts();
        this.hasMultiLanguage = this.configurationService.aggregatedLanguages.length > 1;
    }

    ngOnDestroy() {
        this.cookieConsentSubscription.unsubscribe();
    }

    nagivateTo(event) {
        switch (event.target.id) {
            case this.agreeToTermsAndConditionsId:
                this.policyService.showTermsAndConditions(true);
                break;
            case this.securityId:
                this.policyService.showSecurityPolicy(true);
                break;
            case this.privacyPolicyID:
                this.policyService.showPrivacyPolicy(true);
                break;
        }
    }

    submit() {
        this.setFieldErrorMessage();

        if (this.formGroup.valid) {
            const agreedToTermsAndConditions = this.formGroup.get('agreedToTermsAndConditions').value;
            const hideWelcomeOverlay = this.formGroup.get('hideWelcomeOverlay').value;

            const employeeConfiguration = <EmployeeConfiguration>{
                employeeID: this.employee.id,
                schemeID: this.employee.schemeID,
                agreedToTermsAndConditions: agreedToTermsAndConditions,
                hideWelcomeOverlay: hideWelcomeOverlay
            };

            this.loadingScreenService.show();

            this.configurationService.updateEmployeeConfiguration(employeeConfiguration).subscribe(() => {
                this.loadingScreenService.hide();
                this.close.emit();
            });
        }
    }

    onChange() {
        this.setFieldErrorMessage();
    }

    logout() {
        this.securityService.logout();
    }

    private initForm() {
        this.formGroup = this.fb.group({
            agreedToTermsAndConditions: [this.agreedToTermsAndConditions, Validators.requiredTrue],
            hideWelcomeOverlay: [false]
        });
    }

    private setupPoliciesTexts() {
        this.agreeToTermsAndConditionsText = this.textService
            .getText('WelcomeOverlay_AgreeToTermAndConditions')
            .replace('[AgreeToTermAndConditions]', this.setDynamicLink(this.agreeToTermsAndConditionsId, 'Global_TermsAndConditions'));

        this.termsAndConditionsText = this.textService
            .getText('Global_TermsAndConditions_Description')
            .replace('[PrivacyPolicy]', '<strong>' + this.textService.getText('Global_PrivacyPolicy') + '</strong>');
        this.privacyPolicyText = this.textService
            .getText('Global_PrivacyPolicy_Description')
            .replace('[TermsAndConditions]', '<strong>' + this.textService.getText('Global_TermsAndConditions') + '</strong>');
        this.cookiesPolicyText = this.textService
            .getText('Global_CookiesPolicy_Description')
            .replace('[CookiesPolicy]', '<strong>' + this.textService.getText('Global_CookiesPolicy') + '</strong>');
        this.footerText = this.textService
            .getText('WelcomeOverlay_FooterText')
            .replace('[Security]', this.setDynamicLink(this.securityId, 'Global_Security'))
            .replace('[PrivacyPolicy]', this.setDynamicLink(this.privacyPolicyID, 'Global_PrivacyPolicy'));
    }

    private setDynamicLink(id, textKey) {
        return `<a href="javascript:void(0)" id="${id}">${this.textService.getText(textKey)}</a>`;
    }

    private setFieldErrorMessage() {
        const agreedToTermsAndConditionsControl = this.formGroup.get('agreedToTermsAndConditions');
        if (!agreedToTermsAndConditionsControl.errors) {
            this.validationMessage = null;
        } else {
            this.validationMessage = this.textService.getText('Global_Validation_RequiredFieldError');
        }
    }
}
