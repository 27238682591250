export interface Option {
    value: any;
    text: string;
    contextType: SelectContextType;
}

export enum SelectContextType {
    Default = '',
    Menu = 'menu',
    MenuInverted = 'menu-inverted'
}
