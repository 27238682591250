import { Component, Injector } from '@angular/core';

import { LOADINGSCREEN_DATA, LoadingSpinnerSize } from 'core/loadingScreen/loading-screen.model';

@Component({
    selector: 'loading-screen',
    templateUrl: 'loading-screen.component.html',
    styleUrls: ['loading-screen.component.less']
})
export class LoadingScreenComponent {
    isFullScreen: boolean;
    size: LoadingSpinnerSize;

    loadingSpinnerSize = LoadingSpinnerSize;

    constructor(injector: Injector) {
        const data = injector.get(LOADINGSCREEN_DATA);
        this.isFullScreen = data.fullScreen;
        this.size = data.size;
    }
}
