import {
    AfterContentChecked,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { EventService, EventType } from 'core/event.service';
import { NavMenuItem, NavMenuType } from 'core/models/navigation.model';
import { NavigationService } from 'core/navigation.service';

import { TooltipPosition } from 'shared/tooltip/tooltip.model';

@Component({
    selector: 'drop-down-menu',
    templateUrl: 'drop-down-menu.component.html',
    styleUrls: ['drop-down-menu.component.less']
})
export class DropDownMenuComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {
    @ViewChild('outerContainer', { static: true }) outerContainerEl: ElementRef;
    @ContentChild('itemTemplate', { static: false }) itemTemplateRef: TemplateRef<any>;

    @Input() showMenu: boolean = false;
    @Input() id: string;
    @Input() title: string;
    @Input() navMenuItems: NavMenuItem[] = [];
    @Input() invertedNavigationBarColor: boolean = false;

    @Output() showMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() triggerAction: EventEmitter<NavMenuItem> = new EventEmitter<NavMenuItem>();

    maxHeight: number = null;
    overflowStyle: string = 'unset';
    TooltipPosition = TooltipPosition;

    private bottomPadding: number = 34;
    private windowResizeSubscription: Subscription;

    constructor(private navigationService: NavigationService, private route: ActivatedRoute, private eventService: EventService) {}

    ngOnInit() {
        this.setOverflowStyle();

        this.windowResizeSubscription = this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe(() => {
            this.setOverflowStyle();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['showMenu'] && changes['showMenu'].currentValue) {
            const navMenuItems = [<NavMenuItem>{ children: this.navMenuItems }];
            this.navigationService.setActiveMenuItems(this.route.snapshot['_routerState'].url, navMenuItems);
        }
    }

    ngAfterContentChecked() {
        this.setOverflowStyle();
    }

    ngOnDestroy() {
        this.windowResizeSubscription.unsubscribe();
    }

    triggerMenuAction(menuItem: NavMenuItem) {
        if (menuItem.type === NavMenuType.Action) {
            this.triggerAction.emit(menuItem);
        } else {
            this.navigationService.triggerLinkAction(menuItem);
            this.showMenuChange.emit(false);
        }
    }

    private setOverflowStyle() {
        const componentDOMRect: DOMRect = (<HTMLElement>this.outerContainerEl.nativeElement).getBoundingClientRect();
        this.maxHeight = window.innerHeight - componentDOMRect.top - this.bottomPadding;

        if (componentDOMRect.bottom >= window.innerHeight - this.bottomPadding) {
            this.overflowStyle = 'auto';
        } else {
            this.overflowStyle = 'unset';
        }
    }
}
