import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { MenuNavigationService } from 'core/layout/core/menu-navigation.service';
import { MenuPopupService } from 'core/layout/core/menu-popup.service';
import { HelpMenuComponent } from 'core/layout/header/help-menu/help-menu.component';
import { ProfileMenuComponent } from 'core/layout/header/profile-menu/profile-menu.component';
import { LandingPageComponent } from 'core/layout/landing-page.component';
import { LayoutComponent } from 'core/layout/layout.component';
import { LayoutService } from 'core/layout/layout.service';
import { MegaMenuComponent } from 'core/layout/mega-menu/mega-menu.component';
import { CartMenuComponent } from 'core/layout/primary-navigation/cart-menu/cart-menu.component';
import { DesktopMenuComponent } from 'core/layout/primary-navigation/desktop-menu/desktop-menu.component';
import { FullWidthMenuComponent } from 'core/layout/primary-navigation/full-width-menu/full-width-menu.component';
import { PrimaryNavigationComponent } from 'core/layout/primary-navigation/primary-navigation.component';
import { OVERLAY_CONTAINER_DATA } from 'core/overlay.token';

import { SharedModule } from 'shared/shared.module';

@NgModule({
    declarations: [
        LayoutComponent,
        LandingPageComponent,
        ProfileMenuComponent,
        CartMenuComponent,
        FullWidthMenuComponent,
        HelpMenuComponent,
        PrimaryNavigationComponent,
        DesktopMenuComponent,
        MegaMenuComponent
    ],
    imports: [CommonModule, FormsModule, HttpClientModule, RouterModule, SharedModule.forRoot(true)],
    providers: [MenuNavigationService, MenuPopupService, LayoutService, { provide: OVERLAY_CONTAINER_DATA, useValue: { inputs: null } }, Title]
})
export class LayoutModule {}
