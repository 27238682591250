import { Injectable } from '@angular/core';

import { ConfigurationService } from 'core/configuration.service';
import { AggregatedLanguage, CurrencySymbolPosition, SupportedCurrency } from 'core/models/configuration.model';
import { CurrencyConversionInformation } from 'core/models/core.model';
import { CustomDateTimeFormatOptions, DayFormatOptions, MonthFormatOptions, YearFormatOptions } from 'core/models/custom-date-time-format-options.model';
import { Message, MessageBase, ValidationMessage } from 'core/models/validation.model';
import { TextService } from 'core/text.service';

@Injectable()
export class FormatService {
    private defaultCurrencyMaskedValue: string;
    private supportedCurrenciesMaskedValues: { [id: string]: string } = {};
    private aggregatedLanguage: AggregatedLanguage;
    private defaultCurrency: SupportedCurrency;

    private numberFormat: Intl.NumberFormat;
    private currencyFormat: Intl.NumberFormat;
    private currencyCulture: string;
    private dateFormat: Intl.DateTimeFormat;
    private percentFormat: Intl.NumberFormat;

    private thousandDelimiter: string;
    private decimalDelimiter: string;
    private numberPattern: RegExp;

    private maximumExchangeRateDecimalPlaces: number = 5;
    private defaultExchangeRateValue: number = 1;

    constructor(private textService: TextService, private configurationService: ConfigurationService) {}

    init() {
        this.aggregatedLanguage = this.configurationService.selectedLanguage;
        this.currencyCulture = this.aggregatedLanguage.currencyCulture || this.aggregatedLanguage.defaultCulture;

        this.numberFormat = Intl.NumberFormat(this.aggregatedLanguage.numberCulture || this.aggregatedLanguage.defaultCulture);
        this.percentFormat = Intl.NumberFormat(this.aggregatedLanguage.numberCulture || this.aggregatedLanguage.defaultCulture, {
            style: 'percent',
            maximumFractionDigits: this.numberFormat.resolvedOptions().maximumFractionDigits
        });
        this.dateFormat = Intl.DateTimeFormat(this.aggregatedLanguage.dateCulture || this.aggregatedLanguage.defaultCulture, {
            timeZone: 'UTC'
        } as Intl.DateTimeFormatOptions);

        [this.thousandDelimiter, this.decimalDelimiter] = this.numberFormat.format(11111.1).replace(/1/g, '');
        this.numberPattern = new RegExp(`(?:-?\\d[\\d\\${this.thousandDelimiter}]*(?:\\${this.decimalDelimiter}?\\d*)?)|(?:^-)`, 'g');

        this.currencyFormat = Intl.NumberFormat(this.currencyCulture, {
            minimumFractionDigits: this.aggregatedLanguage.currencyDecimalPlaces,
            maximumFractionDigits: this.aggregatedLanguage.currencyDecimalPlaces
        });

        this.setDefaultCurrency();
        this.generateCurrenciesMaskedValues(this.aggregatedLanguage.supportedCurrencies);
    }

    maskedCurrencyValue(customCurrencyID: string = null): string {
        if (!customCurrencyID) {
            return this.defaultCurrencyMaskedValue;
        }

        return this.supportedCurrenciesMaskedValues[customCurrencyID] ?? this.defaultCurrencyMaskedValue;
    }

    getDateFormat(): string {
        const options = this.dateFormat.resolvedOptions();
        const sampleDate = new Intl.DateTimeFormat(options.locale).format(new Date(3333, 10, 22, 0, 0, 0, 0));

        return sampleDate.replace(/3{1}/g, 'Y').replace(/1{1}/g, 'M').replace(/2{1}/g, 'D');
    }

    getDefaultCurrency(): SupportedCurrency {
        return this.defaultCurrency;
    }

    getSupportedCurrencies(): SupportedCurrency[] {
        return this.aggregatedLanguage.supportedCurrencies;
    }

    updateDefaultCurrency(currencySymbol: string) {
        this.defaultCurrency.currencySymbol = currencySymbol;
    }

    getSupportedCurrency(currencyID: string): SupportedCurrency {
        return this.aggregatedLanguage.supportedCurrencies?.find(x => x.currencyID === currencyID);
    }

    /**
     * Formats a numerical value as a currency string
     * @method formatCurrency
     * @param {number} value the number to be formatted
     * @param {CurrencyConversionInformation} customCurrency the currency info used for formatting
     * @return {string} a currency formatted number
     */
    formatCurrency(value: number, customCurrency: CurrencyConversionInformation = null): string {
        if (!customCurrency) {
            return this.formatDefaultCurrency(value);
        }

        return this.formatCustomCurrency(value, customCurrency);
    }

    /**
     * Formats a numerical value as a currency value string
     * @method formatExchangeRate
     * @param {string} currencyID the ID of the currency to use for formatting
     * @param {number} exchangeRateValue the exchange rate to be formatted
     * @param {boolean} inverse to get the inverse exchange rate
     * @return {string} a formatted exchange rate
     */
    formatExchangeRate(currencyID: string, exchangeRateValue: number = this.defaultExchangeRateValue, inverse: boolean = false): string {
        if (inverse) {
            exchangeRateValue = this.defaultExchangeRateValue / exchangeRateValue;
        }

        const currencyConversionInformation: CurrencyConversionInformation = {
            currencyID: currencyID,
            decimalPlaces: this.countDecimalsForExchangeRate(exchangeRateValue)
        };

        return this.formatCurrency(exchangeRateValue, currencyConversionInformation);
    }

    /**
     * Formats a numerical value as a string
     * @method formatNumber
     * @param {number} value the number to be formatted
     * @return {string} a formatted number
     */
    formatNumber(value: number): string {
        return this.numberFormat.format(value);
    }

    parseNumber(value: string): number {
        return parseFloat(this.trimNumber(value));
    }

    cleanNumberInput(value: string): string {
        const trimmedNumber = this.trimNumber(value);

        if (trimmedNumber === '' || trimmedNumber === '-') {
            return trimmedNumber;
        }

        let result = this.formatNumber(parseFloat(trimmedNumber));
        result += this.getEmptyDecimals(trimmedNumber.split('.')[1]);

        return result;
    }

    /**
     * Formats a numerical value as a string using the same decimal places as defined for the number format
     * @method formatPercent
     * @param {number} value the number to be formatted
     * @return {string} a currency formatted number
     */
    formatPercent(value: number): string {
        return this.percentFormat.format(value / 100);
    }

    /**
     * Formats a date using the specified language date format
     * @method formatDate
     * @param {Date} value the date to be formatted
     * @return {string} a currency formatted number
     */
    formatDate(value: Date | string, customDateTimeFormatOptions: CustomDateTimeFormatOptions = null): string {
        if (value === null) {
            return '';
        }

        const dateTimeFormatOptions: Intl.DateTimeFormatOptions = this.getDateTimeFormatOptions(customDateTimeFormatOptions);
        const utcDate = this.parseDateUTC(value);

        if (dateTimeFormatOptions) {
            const customDateFormat = Intl.DateTimeFormat(this.aggregatedLanguage.dateCulture || this.aggregatedLanguage.defaultCulture, dateTimeFormatOptions);

            return customDateFormat.format(new Date(Date.UTC(utcDate.year, utcDate.month - 1, utcDate.date)));
        }
        return this.dateFormat.format(new Date(Date.UTC(utcDate.year, utcDate.month - 1, utcDate.date)));
    }

    /**
     * Formats a datetime using the specified language date format
     * @method formatDateTime
     * @param {Date} value the date to be formatted
     * @return {string} a currency formatted number
     */
    formatDateTime(value: Date | string, customDateTimeFormatOptions: CustomDateTimeFormatOptions = null): string {
        if (value === null) {
            return '';
        }

        const dateTimeFormatOptions: Intl.DateTimeFormatOptions = this.getDateTimeFormatOptions(customDateTimeFormatOptions);
        const utcDate = this.parseDateUTC(value);

        if (dateTimeFormatOptions) {
            const customDateFormat = Intl.DateTimeFormat(this.aggregatedLanguage.dateCulture || this.aggregatedLanguage.defaultCulture, dateTimeFormatOptions);

            return customDateFormat.format(new Date(Date.UTC(utcDate.year, utcDate.month - 1, utcDate.date, utcDate.hour, utcDate.minute, utcDate.second)));
        }
        return this.dateFormat.format(new Date(Date.UTC(utcDate.year, utcDate.month - 1, utcDate.date, utcDate.hour, utcDate.minute, utcDate.second)));
    }

    parseDateUTC(value): UTCDate {
        if (typeof value === 'string') {
            const date = new Date(value);
            return {
                year: date.getUTCFullYear(),
                month: date.getUTCMonth() + 1,
                date: date.getUTCDate(),
                hour: date.getUTCHours(),
                minute: date.getUTCMinutes(),
                second: date.getUTCSeconds()
            };
        } else {
            return {
                year: value.getFullYear(),
                month: value.getMonth() + 1,
                date: value.getDate(),
                hour: value.getHours(),
                minute: value.getMinutes(),
                second: value.getSeconds()
            };
        }
    }

    formatString(format: string, args: string[]): string {
        if (!args || !args.length || !format) {
            return format;
        }

        for (let i = 0; i < args.length; i++) {
            format = format.split('{' + i + '}').join(args[i]);
        }

        return format;
    }

    isNullOrWhitespace(value: string) {
        return !value || value.trim().length === 0;
    }

    formatMessage(messageBase: MessageBase) {
        let formattedMessage: string;

        if (this.isValidationMessage(messageBase)) {
            const validationMessage = messageBase as ValidationMessage;
            formattedMessage = this.textService.getText(validationMessage.message);
            if (formattedMessage === 'key_' + validationMessage.message + '_missing') {
                formattedMessage = validationMessage.message;
            }
        } else {
            const message = messageBase as Message;
            formattedMessage = this.textService.getText(message.text);

            if (formattedMessage === 'key_' + message.text + '_missing') {
                formattedMessage = message.text;
            }
        }

        if (messageBase.replacements && messageBase.replacements.length) {
            messageBase.replacements.forEach(x => {
                formattedMessage = formattedMessage.replace(`{${x.key}}`, x.value);
            });
        }

        if (messageBase.property) {
            formattedMessage = `${messageBase.property}: ${formattedMessage}`;
        }

        return formattedMessage;
    }

    private isValidationMessage(validationMessage: MessageBase): boolean {
        return (validationMessage as ValidationMessage).message !== undefined;
    }

    private formatDefaultCurrency(value: number): string {
        const formattedValue = this.currencyFormat.format(value);

        if (this.defaultCurrency.currencySymbol) {
            return this.formatCurrencyPosition(formattedValue, this.defaultCurrency.currencySymbol, this.defaultCurrency.currencySymbolPosition);
        }

        return formattedValue;
    }

    private formatCustomCurrency(value: number, customCurrency: CurrencyConversionInformation): string {
        const formattedValue = value.toFixed(customCurrency.decimalPlaces);

        const supportedCurrency: SupportedCurrency = this.aggregatedLanguage.supportedCurrencies?.find(x => x.currencyID === customCurrency.currencyID);

        if (supportedCurrency) {
            return this.formatCurrencyPosition(formattedValue, supportedCurrency.currencySymbol, supportedCurrency.currencySymbolPosition);
        }

        return formattedValue;
    }

    private setDefaultCurrency() {
        this.defaultCurrency = this.aggregatedLanguage.supportedCurrencies ? this.aggregatedLanguage.supportedCurrencies.find(x => x.isDefault) : null;

        if (!this.defaultCurrency) {
            this.defaultCurrency = <SupportedCurrency>{
                currencySymbol: this.aggregatedLanguage.currencySymbol,
                currencySymbolPosition: this.aggregatedLanguage.currencySymbolPosition,
                name: '',
                displayText: '',
                isDefault: true,
                decimalPlaces: this.aggregatedLanguage.currencyDecimalPlaces
            };
        }
    }

    private generateCurrenciesMaskedValues(supportedCurrencies: SupportedCurrency[]) {
        this.defaultCurrencyMaskedValue = this.generateMaskedValue(this.defaultCurrency.currencySymbol, this.defaultCurrency.currencySymbolPosition);

        supportedCurrencies?.forEach(supportedCurrency => {
            this.supportedCurrenciesMaskedValues[supportedCurrency.currencyID] = this.generateMaskedValue(
                supportedCurrency.currencySymbol,
                supportedCurrency.currencySymbolPosition
            );
        });
    }

    private generateMaskedValue(currencySymbol: string, currencySymbolPosition: CurrencySymbolPosition): string {
        const formattedCurrency = this.formatCurrencyPosition(
            Intl.NumberFormat(this.currencyCulture, { minimumFractionDigits: 0 }).format(0),
            currencySymbol,
            currencySymbolPosition
        );
        return formattedCurrency.replace('0', this.textService.getText('Global_HiddenMonetaryValue'));
    }

    private formatCurrencyPosition(value: string, currencySymbol: string, currencySymbolPosition: CurrencySymbolPosition): string {
        switch (currencySymbolPosition) {
            case CurrencySymbolPosition.Prefixed:
                return `${currencySymbol}${value}`;
            case CurrencySymbolPosition.PrefixedWithSpace:
                return `${currencySymbol} ${value}`;
            case CurrencySymbolPosition.Suffixed:
                return `${value}${currencySymbol}`;
            case CurrencySymbolPosition.SuffixedWithSpace:
                return `${value} ${currencySymbol}`;
            case CurrencySymbolPosition.None:
                return '';
            default:
                throw new Error('NotImplementedException');
        }
    }

    private trimNumber(value: string): string {
        const regexMatch = value.match(this.numberPattern);

        if (!regexMatch) {
            return '';
        }

        const result = regexMatch
            .join('')
            .replace(new RegExp(`\\${this.thousandDelimiter}`, 'g'), '')
            .replace(new RegExp(`\\${this.decimalDelimiter}`), '.');

        return result;
    }

    private getEmptyDecimals(decimals: string): string {
        if (decimals === '') {
            return this.decimalDelimiter;
        }

        let result = '';

        const emptyDecimals = /0+$/g.exec(decimals);
        if (emptyDecimals) {
            if (decimals.length === emptyDecimals[0].length) {
                result += this.decimalDelimiter;
            }

            result += emptyDecimals;
        }

        return result;
    }

    private getDateTimeFormatOptions(customDateTimeFormatOptions: CustomDateTimeFormatOptions): Intl.DateTimeFormatOptions {
        if (!customDateTimeFormatOptions) {
            return null;
        }

        const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {};
        dateTimeFormatOptions.timeZone = 'UTC';

        if (customDateTimeFormatOptions.weekdayFormatOptions) {
            dateTimeFormatOptions.weekday = customDateTimeFormatOptions.weekdayFormatOptions;
        }

        if (customDateTimeFormatOptions.dayFormatOptions) {
            dateTimeFormatOptions.day = customDateTimeFormatOptions.dayFormatOptions;
        } else if (customDateTimeFormatOptions.dayFormatOptions !== DayFormatOptions.None) {
            dateTimeFormatOptions.day = 'numeric';
        }

        if (customDateTimeFormatOptions.monthFormatOptions) {
            dateTimeFormatOptions.month = customDateTimeFormatOptions.monthFormatOptions;
        } else if (customDateTimeFormatOptions.monthFormatOptions !== MonthFormatOptions.None) {
            dateTimeFormatOptions.month = 'numeric';
        }

        if (customDateTimeFormatOptions.yearFormatOptions) {
            dateTimeFormatOptions.year = customDateTimeFormatOptions.yearFormatOptions;
        } else if (customDateTimeFormatOptions.yearFormatOptions !== YearFormatOptions.None) {
            dateTimeFormatOptions.year = 'numeric';
        }

        if (customDateTimeFormatOptions.secondFormatOptions) {
            dateTimeFormatOptions.second = customDateTimeFormatOptions.secondFormatOptions;
        }

        if (customDateTimeFormatOptions.minuteFormatOptions) {
            dateTimeFormatOptions.minute = customDateTimeFormatOptions.minuteFormatOptions;
        }

        if (customDateTimeFormatOptions.hourFormatOptions) {
            dateTimeFormatOptions.hour = customDateTimeFormatOptions.hourFormatOptions;
        }

        return dateTimeFormatOptions;
    }

    private countDecimalsForExchangeRate(value: number): number {
        if (Math.floor(value) === value || value === this.defaultExchangeRateValue) {
            return 0;
        }

        const numberOfDecimals: number = value.toString().split('.')[1].length || 0;

        if (numberOfDecimals < this.maximumExchangeRateDecimalPlaces) {
            return numberOfDecimals;
        }

        return this.maximumExchangeRateDecimalPlaces;
    }
}

export interface UTCDate {
    year: number;
    month: number;
    date: number;
    hour: number;
    minute: number;
    second: number;
}
