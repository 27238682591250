import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { AnimationState, flyInOut } from 'shared/animations/overlay.animation';
import { IOverlayBase } from 'shared/overlay/overlay.model';

@Component({
    selector: 'dialog-container',
    templateUrl: 'dialog-container.component.html',
    animations: [flyInOut]
})
export class DialogContainerComponent<T> implements IOverlayBase<T>, OnInit, AfterViewInit {
    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;
    @ViewChild('closeIcon') closeIconEl: ElementRef;
    @ViewChild('containerFocus', { static: true }) containerFocusEl: ElementRef;

    disposeOverlay: EventEmitter<T>;
    actions: { text: string; value: T; class?: string }[];
    ariaLabel: string = '';
    headerText: string = '';
    showIcon: boolean = true;
    hideCross: boolean = false;

    close = new EventEmitter<void>();
    animate = AnimationState.void;

    private inAnimationState: AnimationState = AnimationState.dialogIn;
    private outAnimationState: AnimationState = AnimationState.dialogOut;

    private selectedAction: T;

    constructor() {}

    ngOnInit() {
        this.animate = this.inAnimationState;

        this.close.pipe(take(1)).subscribe(() => {
            this.closeOverlay();
            this.close.complete();
        });

        if (this.hideCross) {
            this.setFocusOnDialogContainer();
        }
    }

    ngAfterViewInit() {
        if (!this.hideCross && this.closeIconEl) {
            this.setFocusOnCloseIcon();
        }
    }

    onAnimationDone() {
        if (this.animate === this.outAnimationState) {
            if (!this.actions) {
                this.disposeOverlay.emit();
            } else if (this.selectedAction !== undefined) {
                this.disposeOverlay.emit(this.selectedAction);
            } else {
                this.disposeOverlay.emit(this.actions[0].value);
            }
        }
    }

    closeOverlay() {
        this.animate = this.outAnimationState;
    }

    selectAction(option) {
        this.selectedAction = option;
        this.closeOverlay();
    }

    private setFocusOnCloseIcon() {
        this.closeIconEl.nativeElement.focus();
    }

    private setFocusOnDialogContainer() {
        this.containerFocusEl.nativeElement.focus();
    }
}
