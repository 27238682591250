<div class="privacy-toggle-content-component">
    <div class="privacy-toggle-wrapper">
        <div #privacyToggle class="privacy-toggle-content">
            <div class="privacy-toggle-container" [ngClass]="{ 'brand-colour': useBrandColour }">
                <toggle
                    [isActive]="!inPrivateMode"
                    [brandColour]="useBrandColour"
                    [showToolTip]="false"
                    label="{{ 'Engager_Accessibility_TogglePrivacyMode' | appText }}">
                    <off-label
                        ><span class="slashed">{{ currencySymbol }}</span></off-label
                    >
                    <on-label
                        ><span>{{ currencySymbol }}</span></on-label
                    >
                </toggle>
                <div class="hand-icon" [class.active]="!inPrivateMode"><icon iconId="hand-point" class="icon-xxl"></icon></div>
            </div>
            <h4 class="user-credit heading-18-b">{{ currencySymbol }}{{ amountText }}</h4>
        </div>
    </div>
    <div class="privacy-toggle-form-container">
        <div class="turn-on-message label-14-b">{{ 'Privacy_Toggle_Turn_On_Toggle' | appText }}</div>
        <div class="form-area" [formGroup]="formGroup">
            <div class="form-checkbox">
                <input type="checkbox" formControlName="hidePrivacyToggle" id="hide-privacy-toggle" />
                <label for="hide-privacy-toggle" class="checkbox-text" appText="Privacy_Toggle_Dont_Show_Again"></label>
            </div>
            <div>
                <button class="btn-primary" type="submit" appText="Global_Action_Ok" (click)="submit()"></button>
            </div>
        </div>
    </div>
</div>
