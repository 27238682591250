import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Subject, Subscription } from 'rxjs';

import { TextService } from 'core/text.service';

import { fadeIn } from 'shared/animations/fade-in.animation';
import { rotateArrow } from 'shared/animations/rotate-arrow.animation';
import { SelectContextType } from 'shared/components/darwin-select/darwin-select.model';
import { DarwinSelectOptionComponent } from 'shared/components/darwin-select/darwin-select-option.component';
import { KeyCodes } from 'shared/shared.model';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DarwinSelectComponent),
    multi: true
};

@Component({
    selector: 'darwin-select',
    templateUrl: 'darwin-select.component.html',
    styleUrls: ['./darwin-select.component.less'],
    animations: [rotateArrow, fadeIn],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DarwinSelectComponent implements OnInit, OnChanges, AfterContentInit, ControlValueAccessor, OnDestroy, AfterViewInit {
    @ContentChildren(DarwinSelectOptionComponent) options: QueryList<DarwinSelectOptionComponent>;
    @ViewChild('selectInput', { static: true }) selectInput: ElementRef;
    @ViewChild('optionsContainer', { static: true }) optionsContainer: ElementRef;
    @ViewChild('optionsListElement', { static: false }) optionsListElement: ElementRef;
    @ViewChild('optionsListElementDummy', { static: true }) optionsListElementDummy: ElementRef;

    @Input()
    set id(value: string) {
        this._id = value;
        this.externalID = null;
    }
    get id() {
        return this._id;
    }

    @Input() disabled?: boolean = false;
    @Input() isRequired?: boolean = false;
    @Input() selectPlaceholder: string = '';
    @Input() initAccessibilityFocus: boolean = false;
    @Input() iconId: string;
    @Input() contextType: SelectContextType = SelectContextType.Default;
    @Input() disableTooltip: boolean = false;

    @Input() set currentValue(value: any) {
        this._currentValue = value;
        this.onChangeCallback(value);

        if (!this.initialLoad) {
            this.currentValueChange.emit(this._currentValue);
        }

        if (this._currentValue) {
            this.showErrors = false;
        }

        this.initialLoad = false;
    }

    get currentValue() {
        return this._currentValue;
    }

    @Output() currentValueChange: EventEmitter<any> = new EventEmitter();

    @HostBinding('attr.id') externalID: string = '';

    currentIndex: number = -1;
    expanded: boolean = false;
    selectedOption: string;
    showErrors: boolean = false;
    isTouched: boolean = false;
    showTop: boolean = false;
    searchingText: string = '';
    focusSearchingState: { [optionId: string]: boolean } = {};

    private _id: string = '';
    private _currentValue: any;
    private optionHeight: number = 38;
    private initialLoad: boolean = true;
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;
    private selectContainerListener: () => void;
    private searchingTextChangeSubject: Subject<string> = new Subject<string>();
    private searchingTextChangeSubscription: Subscription = Subscription.EMPTY;
    private keyManager: ActiveDescendantKeyManager<DarwinSelectOptionComponent>;

    constructor(private textService: TextService, private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

    @HostListener('document:click', ['$event'])
    onInteractBodyElement(event: Event) {
        if (!(<HTMLElement>event.target).closest('.darwin-select-container')) {
            this.expanded = false;
            this.enableScroll();
        }
    }

    ngOnInit() {
        this.selectPlaceholder = this.selectPlaceholder.length > 0 ? this.selectPlaceholder : this.textService.getText('Global_Action_DarwinSelect');

        this.searchingTextChangeSubscription = this.searchingTextChangeSubject.subscribe(() => {
            this.handleSearchingFromKeyboard();
            this.searchingText = '';
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        const currentValue = changes['currentValue'];

        if (currentValue) {
            this.currentIndex = this.options?.length > 0 ? this.options.toArray().findIndex(x => x.value === this.currentValue) : -1;
            if (this.currentIndex > -1) {
                const option = this.options.toArray()[this.currentIndex];

                this.selectedOption = option.optionEl.nativeElement.innerText;

                this.setOptionsSelectedState(option.value);
            }
        }
    }

    ngAfterContentInit() {
        this.keyManager = new ActiveDescendantKeyManager(this.options);

        this.currentIndex = this.options.length > 0 ? this.options.toArray().findIndex(x => x.value === this.currentValue) : -1;

        if (this.currentIndex > -1) {
            this.options.toArray()[this.currentIndex].selectionFocus = true;
        }

        this.options.forEach(item => {
            item.onSelectOption.subscribe(option => {
                this.currentValue = option.value;
                this.selectedOption = option.text;
                this.contextType = option.contextType;
                this.setOptionsSelectedState(option.value);
                this.selectInput.nativeElement.focus();
            });

            item.onInitOption.subscribe(option => {
                this.optionsListElementDummy.nativeElement.innerHTML = this.optionsListElement.nativeElement.innerHTML;
                this.selectedOption = option.text;
                this.contextType = option.contextType;
                this.cdr.detectChanges();
            });

            item.onSelectedOption.subscribe(option => {
                this.currentValue = option.value;
                this.selectedOption = option.text;
                this.contextType = option.contextType;
                this.setOptionsSelectedState(option.value);
                this.currentIndex = this.options.toArray().findIndex(x => x.value === this.currentValue);
                this.cdr.detectChanges();
            });
        });

        this.selectContainerListener = this.renderer.listen(this.selectInput.nativeElement, 'keydown', event => {
            this.handleKeyboardEvents(event);
            this.cdr.detectChanges();
            this.enableScroll();
        });

        this.setAccessibilityFocus();
        this.setAccessibilityActiveDescendantIds();
    }

    ngAfterViewInit() {
        if (this.options.length === 0 || this.currentIndex < 0) {
            this.cdr.detectChanges();
            return;
        }
    }

    ngOnDestroy() {
        this.selectContainerListener();
        this.searchingTextChangeSubscription.unsubscribe();
    }

    toggleItem() {
        this.expanded = !this.expanded;
        if (this.expanded) {
            this.cdr.detectChanges();
            this.setOptionsDropdownDisplayTopBottom();
            this.disableScroll();
        } else {
            this.enableScroll();
        }
    }

    onInputBlur() {
        this.showErrors = this.isRequired && !this.currentValue;
        this.markAsTouched();
    }

    markAsTouched() {
        if (!this.isTouched) {
            this.onTouchedCallback();
            this.isTouched = true;
        }
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        const keyCode = event.keyCode;

        if (this.disabled) {
            return;
        }

        if (event.key.length === 1) {
            const char = event.key;
            this.searchingText += char;

            this.searchingTextChangeSubject.next(this.searchingText.trim());
        }

        if (!this.expanded) {
            this.handleCloseSelect(keyCode);
            return;
        }

        if (this.expanded) {
            this.handleOpenSelect(keyCode);
            return;
        }
    }

    writeValue(value: any) {
        if (!value && this.options) {
            this.resetToDefault();
        } else {
            this._currentValue = value;
            this.initialLoad = false;
        }
    }

    registerOnChange(fn: (_: any) => void) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    private setOptionsDropdownDisplayTopBottom() {
        const clientBoundingRect = this.optionsContainer.nativeElement.getBoundingClientRect();
        clientBoundingRect.height = this.optionsListElement ? this.optionsListElement.nativeElement.clientHeight : this.optionHeight * this.options.length;

        if (clientBoundingRect.y + clientBoundingRect.height > window.innerHeight) {
            this.showTop = true;
        } else {
            this.showTop = false;
        }
    }

    private disableScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = () => {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    private enableScroll() {
        window.onscroll = () => void {};
    }

    private setOptionsSelectedState(optionValue: any) {
        this.options.forEach(item => {
            item.selectionFocus = optionValue === item.value;
        });
    }

    private handleCloseSelect(keyCode: number) {
        let isEventHandled: boolean = false;

        switch (keyCode) {
            case KeyCodes.Home:
                this.keyManager.setFirstItemActive();
                this.currentIndex = 0;
                isEventHandled = true;
                break;
            case KeyCodes.End:
                this.keyManager.setLastItemActive();
                this.currentIndex = this.options.length - 1;
                isEventHandled = true;
                break;
            case KeyCodes.DownArrow:
            case KeyCodes.UpArrow:
            case KeyCodes.Enter:
            case KeyCodes.Space:
                if (this.currentIndex < 0) {
                    this.keyManager.setFirstItemActive();
                } else {
                    this.keyManager.setActiveItem(this.currentIndex);
                }
                isEventHandled = true;
                break;
            default:
                return;
        }

        if (isEventHandled) {
            this.expanded = true;
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private handleOpenSelect(keyCode: number) {
        let isEventHandled: boolean = false;
        const maxIndex = this.options.length - 1;
        const pageUpDownSize = 10;

        switch (keyCode) {
            case KeyCodes.Enter:
            case KeyCodes.Space:
                this.setSelectionFromKeyboard();
                isEventHandled = true;
                this.expanded = false;
                break;
            case KeyCodes.Tab:
                this.setSelectionFromKeyboard();
                this.expanded = false;
                break;
            case KeyCodes.Escape:
                this.expanded = false;
                isEventHandled = true;
                this.resetFocus();
                break;
            case KeyCodes.DownArrow:
                this.handleIndexDownNavigation();
                isEventHandled = true;
                break;
            case KeyCodes.UpArrow:
                this.handleIndexUpNavigation();
                isEventHandled = true;
                break;
            case KeyCodes.Home:
                this.keyManager.setFirstItemActive();
                this.currentIndex = 0;
                isEventHandled = true;
                break;
            case KeyCodes.End:
                this.keyManager.setLastItemActive();
                this.currentIndex = this.options.length - 1;
                isEventHandled = true;
                break;
            case KeyCodes.PageUp:
                this.keyManager.setActiveItem(Math.max(0, this.currentIndex - pageUpDownSize));
                this.currentIndex = Math.max(0, this.currentIndex - pageUpDownSize);
                isEventHandled = true;
                break;
            case KeyCodes.PageDown:
                this.keyManager.setActiveItem(Math.min(maxIndex, this.currentIndex + pageUpDownSize));
                this.currentIndex = Math.min(maxIndex, this.currentIndex + pageUpDownSize);
                isEventHandled = true;
                break;
            default:
                return;
        }

        if (isEventHandled) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private handleIndexDownNavigation() {
        if (this.currentIndex < 0) {
            this.currentIndex = 0;
        } else if (this.currentIndex === this.options.length - 1) {
            return;
        } else if (this.currentIndex < this.options.length - 1) {
            this.currentIndex++;
        }

        this.keyManager.setActiveItem(this.currentIndex);
    }

    private handleIndexUpNavigation() {
        if (this.currentIndex < 1) {
            return;
        } else if (this.currentIndex > 0) {
            this.currentIndex--;
        }

        this.keyManager.setActiveItem(this.currentIndex);
    }

    private handleSearchingFromKeyboard() {
        for (const option of this.options.toArray()) {
            const optionId = option.value;
            const optionName = option.optionEl.nativeElement.innerText.trim().toUpperCase();

            if (this.searchingText && !this.focusSearchingState[optionId]) {
                if (optionName.startsWith(this.searchingText.toUpperCase())) {
                    this.expanded = true;
                    this.currentIndex = this.options.toArray().findIndex(x => x.value === optionId);
                    this.keyManager.setActiveItem(this.currentIndex);
                    this.focusSearchingState[optionId] = true;
                    return;
                }
            }
        }
    }

    private setSelectionFromKeyboard() {
        if (!this.keyManager.activeItem) {
            return;
        }

        this.currentValue = this.keyManager.activeItem.value;
        this.selectedOption = this.keyManager.activeItem.optionEl.nativeElement.innerText;
        this.setOptionsSelectedState(this.keyManager.activeItem.value);
        this.focusSearchingState[this.currentValue] = false;
    }

    private resetFocus() {
        this.options.forEach(option => {
            this.focusSearchingState[option.value] = false;
            option.isActive = false;
        });
    }

    private setAccessibilityFocus() {
        if (this.initAccessibilityFocus) {
            this.selectInput.nativeElement.focus();
        }
    }

    private setAccessibilityActiveDescendantIds() {
        let activeDescendantId: number = 0;
        this.options.forEach(item => {
            item.activeDescendantElementId = `${this.id}-${activeDescendantId++}`;
        });
    }

    private resetToDefault() {
        this._currentValue = null;
        this.selectedOption = '';
        this.showErrors = false;
        this.options.forEach(option => {
            option.selectionFocus = option.selected = false;
        });
    }
}
