export interface FileData {
    fileID: string;
    fileName: string;
    fileSize: number;
    rawFile: File;
    base64Url: string;
    isImageContentType: boolean;
    isImageCompressed: boolean;
    errorType: ErrorType;
    previewUrl: string;
}

export const DefaultMaxFileSize: number = 15728640;
export const DefaultExpectedFileTypes: string[] = ['jpg', 'jpeg', 'pdf', 'png'];
export const CompressableImageFileTypes: string[] = ['jpg', 'jpeg', 'png'];

export enum ErrorType {
    None = 0,
    FileSize = 1,
    FileType = 2
}

export interface FileTypeInfo {
    isXml: boolean;
    isPdf: boolean;
    isImage: boolean;
}
