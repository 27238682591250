import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { AccessibilityService } from 'core/accessibility.service';
import { ConfigurationService } from 'core/configuration.service';
import { CookieConsentService } from 'core/cookie-consent.service';
import { EventService, EventType } from 'core/event.service';
import { SessionStorageService } from 'core/session-storage.service';
import { TextService } from 'core/text.service';
import { WindowService } from 'core/window.service';

import { CookiesOverlayComponent } from 'shared/components/cookies-overlay/cookies-overlay.component';
import { WelcomeOverlayComponent } from 'shared/components/welcome-overlay/welcome-overlay.component';
import { OverlayService } from 'shared/overlay/overlay.service';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {
    isMobileDevice: boolean;
    invertedNavigationBarColor: boolean;
    countryLanguage: string;

    private subscriptions: Subscription[] = [];
    private responses: { text: string; value: boolean }[];
    private welcomeOverlayKey: string = 'welcomeOverlayChecked';

    constructor(
        private configurationService: ConfigurationService,
        private overlayService: OverlayService,
        private textService: TextService,
        private windowService: WindowService,
        private eventService: EventService,
        private sessionStorageService: SessionStorageService,
        private accessibilityService: AccessibilityService,
        private elementRef: ElementRef,
        private cookieConsentService: CookieConsentService,
        private title: Title
    ) {}

    ngOnInit() {
        this.responses = [{ text: this.textService.getText('Global_Action_Ok'), value: true }];

        this.isMobileDevice = this.windowService.isMobileDevice();

        this.subscriptions.push(
            this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe(() => {
                this.isMobileDevice = this.windowService.isMobileDevice();
            })
        );

        this.subscriptions.push(
            this.cookieConsentService.trustArcStateObservable.subscribe((state: boolean) => {
                if (!state) {
                    this.showCookiesPolicy();
                }
            })
        );

        this.subscriptions.push(
            this.configurationService.getSchemeName().subscribe((schemeName: string) => {
                this.subscriptions.push(
                    this.configurationService.openWelcomeOverlay$.subscribe(() => {
                        this.showWelcomeOverlay(schemeName);
                    })
                );
            })
        );

        this.setTitle(this.configurationService.pageTitle);
        this.countryLanguage = this.configurationService.countryLanguage;

        this.invertedNavigationBarColor = this.configurationService.configuration.options.invertedNavigationBarColor;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(item => item.unsubscribe());
    }

    skipToNavigationBar() {
        const navigationMenuElement = this.elementRef.nativeElement.querySelector('#navigation-menu');
        if (this.isMobileDevice) {
            const btnMenu = this.getMobileModeMenuButton();
            if (btnMenu) {
                btnMenu.focus();
            }
            return;
        }
        const focusElement = this.accessibilityService.findFirstTabbableElement(navigationMenuElement);
        if (focusElement) {
            focusElement.focus();
        }
    }

    skipToContent() {
        const firstContentItemID = this.elementRef.nativeElement.querySelector('#main');
        if (firstContentItemID) {
            const focusElement = this.accessibilityService.findFirstTabbableElement(firstContentItemID);
            if (focusElement) {
                focusElement.focus();
            }
        }
    }

    private setTitle(pageTitleText: string) {
        if (pageTitleText) {
            this.title.setTitle(`- ${pageTitleText}`);
        } else {
            this.title.setTitle('- Reward Centre');
        }
    }

    private showCookiesPolicy() {
        this.configurationService.showCookiesPolicy().subscribe(showCookiesPolicy => {
            if (showCookiesPolicy) {
                this.overlayService.openDialog(CookiesOverlayComponent, {}, this.responses, 'cookies-policy', null, false).subscribe(response => {
                    if (response) {
                        this.configurationService.setCookiesPolicyCookie().subscribe();
                    }
                });
            }
        });
    }

    private showWelcomeOverlay(schemeName) {
        if (this.welcomeOverlayIsHidden() && !this.sessionStorageService.getItem(this.welcomeOverlayKey)) {
            this.overlayService
                .openDialog(
                    WelcomeOverlayComponent,
                    { employeeConfiguration: this.configurationService.configuration.employeeConfiguration, schemeName },
                    null,
                    'welcome-overlay',
                    null,
                    null,
                    true,
                    false
                )
                .subscribe(() => {
                    this.sessionStorageService.setItem(this.welcomeOverlayKey, true);
                });
        }
    }

    private welcomeOverlayIsHidden() {
        if (this.configurationService.configuration.employeeConfiguration == null) {
            return true;
        }

        return !this.configurationService.configuration.employeeConfiguration.hideWelcomeOverlay;
    }

    private getMobileModeMenuButton() {
        const btnMenuElement = this.elementRef.nativeElement.offsetParent.querySelector('header-menu .nav-bar-top-right .btn-menu-container:last-child');
        if (!btnMenuElement) {
            return null;
        }

        const ret = this.accessibilityService.findFirstTabbableElement(btnMenuElement);
        return ret;
    }
}
