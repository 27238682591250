import { SchemeContentFile } from 'core/models/configuration.model';
import { SystemIcon } from 'core/models/system-icon.model';

export interface Card {
    width: CardWidth;
    _calculatedWidth?: CardWidth;
}

export enum CardWidth {
    None = 0,
    Quarter = 1,
    Half = 2,
    Full = 4,
    Third = 5
}

export enum CardWidthClass {
    None = 'block-full',
    Quarter = 'block-quarter',
    Third = 'block-third',
    Half = 'block-half',
    Full = 'block-full'
}

export enum ImageFocus {
    None = 0,
    LeftTop = 1,
    LeftCenter = 2,
    LeftBottom = 3,
    RightTop = 4,
    RightCenter = 5,
    RightBottom = 6,
    CenterTop = 7,
    CenterCenter = 8,
    CenterBottom = 9
}

export interface Image {
    file: SchemeContentFile;
    imageFocus: ImageFocus;
    darkenImage: boolean;
}

export interface Icon {
    backgroundColor: string;
    brandColor: string;
    systemIcon: SystemIcon;
    customIcon: SchemeContentFile;
}

export enum CardType {
    None = 0,
    Benefit = 1,
    Engager = 2,
    FundingAccount = 3,
    Icon = 100,
    Image = 101,
    Video = 102
}
