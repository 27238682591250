import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'fallback-error',
    templateUrl: './fallback-error.component.html',
    styleUrls: ['./fallback-error.component.less']
})
export class FallbackErrorComponent {
    constructor(private location: Location) {}

    goBack() {
        this.location.back();
    }
}
