/*DO NOT USE THIS FILE/MAKE CHANGES TO THIS FILE: TALK TO BEN.P*/

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OVERLAY_CONTAINER_DATA } from 'core/overlay.token';

import { KeyCodes } from 'shared/shared.model';

@Injectable()
export class MenuPopupService {
    isOpen$: Observable<boolean>;

    private isOpen = new BehaviorSubject<boolean>(false);
    private lastFocusedElement: HTMLElement;
    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay, private injector: Injector) {
        this.isOpen$ = this.isOpen.asObservable();
    }

    open(component: ComponentType<{}>, inputs?: any) {
        if (document.activeElement != null) {
            this.lastFocusedElement = <HTMLElement>document.activeElement;
        } else {
            this.lastFocusedElement = document.body;
        }

        if (this.overlayRef) {
            this.overlayRef.dispose();
        }

        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop'
        });

        const componentPortal = inputs ? new ComponentPortal(component, null, this.createInjector(inputs)) : new ComponentPortal(component);

        this.overlayRef.attach(componentPortal);

        this.overlayRef.backdropClick().subscribe(() => {
            this.close(true);
        });

        this.overlayRef.keydownEvents().subscribe((event: KeyboardEvent) => {
            if (event.keyCode === KeyCodes.Escape) {
                this.close(true);
            }
        });

        this.isOpen.next(true);
    }

    close(returnFocusToLastFocusedElement: boolean = false) {
        this.overlayRef.dispose();

        if (returnFocusToLastFocusedElement) {
            this.lastFocusedElement.focus();
        }

        this.isOpen.next(false);
    }

    createInjector(dataToPass: any): Injector {
        return Injector.create({
            providers: [{ provide: OVERLAY_CONTAINER_DATA, useValue: dataToPass }],
            parent: this.injector
        });
    }
}
