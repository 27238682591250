import { Injectable } from '@angular/core';

import { ConfigurationService } from 'core/configuration.service';
import { EmployeeService } from 'core/employee.service';
import { EmployeeNameDisplayFormat } from 'core/models/configuration.model';
import { AssigneeType } from 'core/selection.model';

@Injectable()
export class FormatNameService {
    constructor(private employeeService: EmployeeService, private configurationService: ConfigurationService) {}

    format(entity: FormatEntity): string {
        if (entity === undefined) {
            return '';
        }

        if (this.isEmployeeEntity(entity) && this.usePreferredName()) {
            return this.employeeService.employee.preferredName;
        }

        return this.getDefaultDisplayName(entity);
    }

    formatInitials(entity: FormatEntity): string {
        if (entity === undefined) {
            return '';
        }

        const formattedName = this.getDefaultDisplayName(entity);

        return this.getInitials(formattedName);
    }

    private getDefaultDisplayName(entity: FormatEntity): string {
        if (entity.assigneeType === AssigneeType.employeeBeneficiary && entity.name !== null) {
            return `${entity.name}`;
        }

        if (entity.displayName != null) {
            return entity.displayName;
        }

        return `${entity.firstName} ${entity.lastName}`;
    }

    private getInitials(formattedName: string): string {
        const formattedNameComponents: string[] = formattedName.split(' ');
        const initials: string[] = [];

        for (let i = 0; i < formattedNameComponents.length; i++) {
            const nameItem = formattedNameComponents[i].trim();

            if (nameItem !== '') {
                initials.push(nameItem[0].toUpperCase());
            }

            if (initials.length === 3) {
                break;
            }
        }

        return initials.join('');
    }

    private isEmployeeEntity(entity: FormatEntity): boolean {
        const id = entity.id ? entity.id : entity.claimantID;

        return id === this.employeeService.employee.id;
    }

    private usePreferredName() {
        return (
            this.configurationService.configuration.options.employeeNameDisplayFormat == EmployeeNameDisplayFormat.PreferredName &&
            this.employeeService.employee.preferredName
        );
    }
}

export interface FormatEntity {
    id?: string;
    claimantID?: string;
    firstName: string;
    lastName: string;
    displayName?: string;
    name?: string;
    assigneeType?: AssigneeType;
}
