import { Injectable } from '@angular/core';

import { Card, CardWidth, CardWidthClass } from 'core/models/card.model';
import { WindowService } from 'core/window.service';

@Injectable()
export class CardGridService {
    private defaultCarouselCardWidth = CardWidth.Quarter;
    private carouselCardWidth: Map<number, CardWidth> = new Map<number, CardWidth>([
        [0, CardWidth.Full],
        [1, CardWidth.Full],
        [2, CardWidth.Half],
        [3, CardWidth.Third],
        [4, CardWidth.Quarter]
    ]);

    constructor(private windowService: WindowService) {}

    getWidthClass(width: CardWidth): string {
        return CardWidthClass[CardWidth[width]];
    }

    fillCarousel(cards: Card[]) {
        if ((cards || []).length === 0) {
            return;
        }

        const carouselCardWidth = this.carouselCardWidth.get(cards.length) ?? this.defaultCarouselCardWidth;

        cards.forEach(card => {
            card._calculatedWidth = carouselCardWidth;
        });
    }

    fillGrid(cards: Card[]) {
        const isTablet = this.windowService.isTabletDevice();

        if ((cards || []).length === 0) {
            return;
        }

        const cardsCount = cards.length;
        let currentRowCards = [];
        let currentRowWidth = 0;

        for (let i = 0; i < cardsCount; i++) {
            this.setCardCalculatedWidth(cards[i], isTablet);

            const currentCardCausesOverflow = currentRowWidth + cards[i]._calculatedWidth > 4;
            if (currentCardCausesOverflow) {
                this.fillRow(currentRowCards, currentRowWidth);

                currentRowCards = [];
                currentRowWidth = 0;
            }

            currentRowCards.push(cards[i]);
            currentRowWidth += cards[i]._calculatedWidth;

            const isLastCard = i == cardsCount - 1;

            if (currentRowWidth == 4) {
                const halfCardBreaksMiddle = currentRowCards.length == 3 && currentRowCards[1]._calculatedWidth == CardWidth.Half;
                if (halfCardBreaksMiddle) {
                    currentRowCards[0]._calculatedWidth = CardWidth.Half;

                    currentRowCards = [cards[i]];
                    currentRowWidth = cards[i]._calculatedWidth;

                    if (isLastCard) {
                        this.fillRow(currentRowCards, currentRowWidth);
                    }
                } else {
                    currentRowCards = [];
                    currentRowWidth = 0;
                }
            } else if (isLastCard) {
                this.fillRow(currentRowCards, currentRowWidth);
            }
        }
    }

    private fillRow(cards: Card[], rowWidth: number) {
        switch (rowWidth) {
            case 1:
                cards[0]._calculatedWidth = CardWidth.Full;
                break;
            case 2:
                if (cards.length == 1) {
                    cards[0]._calculatedWidth = CardWidth.Full;
                } else {
                    cards[0]._calculatedWidth = cards[1]._calculatedWidth = CardWidth.Half;
                }
                break;
            default:
                if (cards.length == 2) {
                    cards[0]._calculatedWidth = cards[1]._calculatedWidth = CardWidth.Half;
                } else {
                    cards[0]._calculatedWidth = CardWidth.Half;
                }
                break;
        }
    }

    private setCardCalculatedWidth(card: Card, isTablet) {
        card._calculatedWidth = isTablet && card.width === 1 ? 2 : card.width;
    }
}
