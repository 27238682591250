import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationInsightsService } from 'core/application-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    private router: Router;

    constructor(private injector: Injector, private zone: NgZone, private applicationInsightsService: ApplicationInsightsService) {
        super();
    }

    handleError(error: any): void {
        if (!this.router) {
            this.router = this.injector.get(Router);
        }

        super.handleError(error);

        this.applicationInsightsService.logException(error).subscribe(() => {
            this.zone.run(() => this.router.navigate(['/error']));
        });
    }
}
