import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    private readonly storage = localStorage;
    setItem(key: string, object: any) {
        if (!object) {
            return;
        }
        this.storage.setItem(key, JSON.stringify(object));
    }

    clear() {
        this.storage.clear();
    }

    getItem<T>(key: string): T {
        return JSON.parse(this.storage.getItem(key)) as T;
    }

    removeItem(key: string) {
        this.storage.removeItem(key);
    }
}

export enum LocalStorageKey {
    mfaDevicePrint = 'Darwin_Mfa_DevicePrint',
    deviceTokenFso = 'Darwin_Mfa_DeviceTokenFso'
}
