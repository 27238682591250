import { CommonModule, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccessibilityService } from 'core/accessibility.service';
import { AnalyticsService } from 'core/analytics.service';
import { ApiHttpClient } from 'core/api-http-client';
import { ApiRequestInterceptor } from 'core/api-request.interceptor';
import { ApplicationService } from 'core/application.service';
import { ApplicationInsightsService } from 'core/application-insights.service';
import { ApprovalService } from 'core/approval.service';
import { CardGridService } from 'core/card-grid.service';
import { ConfigurationService } from 'core/configuration.service';
import { ContentService } from 'core/content.service';
import { CookieConsentService } from 'core/cookie-consent.service';
import { CoreProfileService } from 'core/core-profile.service';
import { CostPeriodService } from 'core/cost-period.service';
import { DynamicStyleLoaderService } from 'core/dynamic-style-loader.service';
import { EmployeeService } from 'core/employee.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';
import { ErrorModule } from 'core/error/error.module';
import { ErrorHandlerService } from 'core/error-handler.service';
import { EventService } from 'core/event.service';
import { FeedbackService } from 'core/feedback.service';
import { FontLoaderService } from 'core/font-loader.service';
import { FormatService } from 'core/format.service';
import { FormatNameService } from 'core/format-name.service';
import { FundingAccountService } from 'core/funding-account.service';
import { FundingAccountBreakdownHelper } from 'core/funding-account-breakdown.helper';
import { IconProviderService } from 'core/icon-provider.service';
import { LoadingScreenModule } from 'core/loadingScreen/loading-screen.module';
import { LocalStorageService } from 'core/local-storage.service';
import { NavigationService } from 'core/navigation.service';
import { OverlayPriorityHelper } from 'core/overlay-priority.helper';
import { PasswordValidationService } from 'core/password-validation.service';
import { PendoService } from 'core/pendo.service';
import { PolicyService } from 'core/policy.service';
import { PrivacyService } from 'core/privacy.service';
import { ScrollService } from 'core/scroll.service';
import { SecureGuard } from 'core/secure-guard';
import { SecurityService } from 'core/security.service';
import { SelectionService } from 'core/selection.service';
import { DependantEvidenceService } from 'core/services/dependant-evidence.service';
import { SessionStorageService } from 'core/session-storage.service';
import { TestingModeService } from 'core/testing-mode.service';
import { TextService } from 'core/text.service';
import { ThirdPartyLockService } from 'core/third-party-lock.service';
import { TokenService } from 'core/token.service';
import { WindowService } from 'core/window.service';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, LoadingScreenModule, ErrorModule],
    providers: [
        ApiHttpClient,
        ApplicationService,
        CardGridService,
        ConfigurationService,
        CostPeriodService,
        DynamicStyleLoaderService,
        DependantEvidenceService,
        EmployeeService,
        FormatNameService,
        FormatService,
        IconProviderService,
        PrivacyService,
        SecureGuard,
        SecurityService,
        LocalStorageService,
        SessionStorageService,
        TextService,
        ThirdPartyLockService,
        TokenService,
        ScrollService,
        WindowService,
        EnvironmentConfigurationDataService,
        FeedbackService,
        AnalyticsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiRequestInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        SelectionService,
        ApprovalService,
        PolicyService,
        EventService,
        CoreProfileService,
        PasswordValidationService,
        PendoService,
        ContentService,
        ApplicationInsightsService,
        OverlayPriorityHelper,
        AccessibilityService,
        PathLocationStrategy,
        TestingModeService,
        CookieConsentService,
        FontLoaderService,
        NavigationService,
        FundingAccountBreakdownHelper,
        FundingAccountService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
