import { Injectable } from '@angular/core';
import { forkJoin, Observable, Observer, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Guid } from 'core/extensions/guid-extension';
import { LoadingScreenService } from 'core/loadingScreen/loading-screen.service';

import { CompressableImageFileTypes, DefaultExpectedFileTypes, DefaultMaxFileSize, ErrorType, FileData, FileTypeInfo } from 'shared/file-upload/file-upload.model';

@Injectable()
export class FileUploadService {
    private static readonly xmlExtension = 'xml';
    private static readonly pdfExtension = 'pdf';

    constructor(private loadingScreenService: LoadingScreenService) {}

    onFileAdded(fileList: FileList, customMaxFileSize: number, customExpectedFileTypes: string[], compressImages: boolean): Observable<FileData[]> {
        this.loadingScreenService.show();
        const maxFileSize = customMaxFileSize ? customMaxFileSize : DefaultMaxFileSize;
        const expectedFileTypes = customExpectedFileTypes ? customExpectedFileTypes : DefaultExpectedFileTypes;
        let obs = of([]);

        if (fileList && fileList.length > 0) {
            const processFileObservables: Observable<FileData>[] = [];

            for (let i = 0; i < fileList.length; i++) {
                const file = fileList.item(i);
                const extension: string = this.getFileNameExtension(file.name);

                processFileObservables.push(this.processFile(file, maxFileSize, extension, expectedFileTypes, compressImages));
            }

            obs = forkJoin(processFileObservables);
        }

        return obs.pipe(
            tap(() => {
                this.loadingScreenService.hide();
            })
        );
    }

    static getFileTypeInfo(name: string): FileTypeInfo {
        const chunks = name.split('.');
        const extension = chunks[chunks.length - 1].toLowerCase();

        return <FileTypeInfo>{
            isImage: CompressableImageFileTypes.includes(extension),
            isXml: extension == FileUploadService.xmlExtension,
            isPdf: extension == FileUploadService.pdfExtension
        };
    }

    private getFileNameExtension(name) {
        const chunks = name.split('.');
        return chunks[chunks.length - 1];
    }

    private processFile(file: File, maxFileSize: number, extension: string, expectedFileTypes: string[], compressImages: boolean): Observable<FileData> {
        return new Observable<FileData>((obs: Observer<FileData>) => {
            let errorType: ErrorType;
            let fileData: FileData;

            if (compressImages && file.size > maxFileSize && CompressableImageFileTypes.includes(extension.toLowerCase())) {
                const reader = new FileReader();
                const image = new Image();

                reader.onload = (readerEvent: any) => {
                    image.onload = () => {
                        const compressedImage: File = this.compressImage(file, maxFileSize, extension, image);

                        errorType = this.validateFile(extension, compressedImage, maxFileSize, expectedFileTypes);

                        fileData = this.constructFileData(errorType, compressedImage);

                        obs.next(fileData);
                        obs.complete();
                    };
                    image.src = readerEvent.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                errorType = this.validateFile(extension, file, maxFileSize, expectedFileTypes);

                fileData = this.constructFileData(errorType, file);

                obs.next(fileData);
                obs.complete();
            }
        });
    }

    private validateFile(extension: string, file: File, maxFileSize: number, expectedFileTypes: string[]): ErrorType {
        if (expectedFileTypes.indexOf(extension.toLowerCase()) < 0) {
            return ErrorType.FileType;
        }

        if (file.size > maxFileSize) {
            return ErrorType.FileSize;
        }

        return ErrorType.None;
    }

    private constructFileData(errorType: ErrorType, file: File) {
        if (errorType === ErrorType.None) {
            return <FileData>{
                previewUrl: URL.createObjectURL(file),
                fileName: file.name,
                fileID: Guid.newGuid(),
                rawFile: file,
                errorType
            };
        } else {
            return <FileData>{
                fileName: file.name,
                errorType
            };
        }
    }

    private compressImage(file: File, maxFileSize: number, extension: string, image: HTMLImageElement) {
        const canvas = document.createElement('canvas');

        let currentFileSize = file.size;
        let ratio = 1;
        let dataURI;
        const imageContentType = this.getImageContentType(extension);

        while (currentFileSize > maxFileSize) {
            ratio = currentFileSize / maxFileSize >= 2 ? ratio / 2 : ratio - 0.1;

            const ctx = canvas.getContext('2d');

            canvas.width = image.width * ratio;
            canvas.height = image.height * ratio;

            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

            dataURI = canvas.toDataURL(imageContentType);

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            currentFileSize = this.getDataSize(dataURI);
        }

        canvas.remove();
        return this.dataURItoFile(dataURI, file.name);
    }

    private getImageContentType(ext: string) {
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            default:
                return '';
        }
    }

    private getDataSize(dataUri: string) {
        const parts = dataUri.split(';base64,');
        const data = parts[1];

        return Math.round((data.length * 3) / 4);
    }

    private dataURItoFile(dataURI: string, fileName: string) {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i++) {
            ia[i] = bytes.charCodeAt(i);
        }
        const blob = new Blob([ia], { type: mime });
        return new File([blob], fileName, { type: mime });
    }
}
