import { InjectionToken } from '@angular/core';

export const LOADINGSCREEN_DATA = new InjectionToken<LoadingScreenData>('LOADINGSCREEN_DATA');

export interface LoadingScreenData {
    fullScreen: boolean;
    size: LoadingSpinnerSize;
}

export enum LoadingSpinnerSize {
    None = 0,
    Small = 1,
    Large = 2
}
