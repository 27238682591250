import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHttpClient } from 'core/api-http-client';
import { ApplicationService } from 'core/application.service';
import { EnvironmentConfigurationDataService } from 'core/environment-configuration-data.service';
import { Guid } from 'core/extensions/guid-extension';
import { LocalStorageKey, LocalStorageService } from 'core/local-storage.service';
import {
    AccessTokenReturn,
    ActionName,
    AuthResult,
    AuthStatus,
    ChangePasswordCredentials,
    DeviceRequest,
    DeviceTokenData,
    EmployeeSelfRegistrationRequest,
    EmployeeSsoLogin,
    LoginModel,
    LoginResult,
    mfaLoginResult,
    MfaTokenInfo,
    PasswordRequirements,
    RedirectUrl,
    Sso,
    SsoDeepLink,
    SsoDetails,
    SsoOutboundRequest,
    ValidationError
} from 'core/models/security.model';
import { SessionStorageKey, SessionStorageService } from 'core/session-storage.service';
import { TokenService } from 'core/token.service';

@Injectable()
export class SecurityService {
    private changePasswordCredentials: ChangePasswordCredentials;
    private name = 'outBoundSso';
    private interval: any;
    private mfaInterval: any;

    private readonly sessionCheckInterval = 5000;

    constructor(
        private httpClient: ApiHttpClient,
        private tokenService: TokenService,
        private applicationService: ApplicationService,
        private sessionStorageService: SessionStorageService,
        private localStorageService: LocalStorageService,
        private environmentConfigurationDataService: EnvironmentConfigurationDataService,
        private router: Router
    ) {}

    login(loginModel: LoginModel) {
        this.sessionStorageService.clear();

        if (this.environmentConfigurationDataService.environmentConfigurationData.rcMfaEnabled) {
            loginModel.deviceRequest = this.getDeviceRequest();
        }

        return this.httpClient.unsecurePost<AccessTokenReturn>('/Security/Login', loginModel).pipe(
            map(token => {
                const isMfaResult = mfaLoginResult.includes(token.loginResult);

                if (token.loginResult === LoginResult.Success) {
                    this.initializeSession(token);
                }

                if (isMfaResult) {
                    this.updateMfaToken(token);
                    this.updateDeviceTokenFso(token);
                    this.startMfaTokenExpiryCheck();
                }

                if (token.loginResult === LoginResult.TempPassword || token.loginResult === LoginResult.PasswordExpired) {
                    this.setChangePasswordCredentials(loginModel.username, loginModel.password, token.passwordRequirements);
                }

                return token;
            })
        );
    }

    initializeSession(token: AccessTokenReturn) {
        this.tokenService.saveTokens(token.accessToken, token.refreshToken);
        this.sessionStorageService.setItem(SessionStorageKey.rcRewardCentreAccessEnabled, token.rewardCentreAccessEnabled);
        this.startTokenExpiryCheck();
        this.clearMfaSession();
    }

    getChangePasswordCredentials(): ChangePasswordCredentials {
        return this.changePasswordCredentials;
    }

    proxyLogin(tokenKey: string) {
        this.sessionStorageService.clear();

        return this.httpClient.unsecurePost<AccessTokenReturn>('/Security/ProxyLogin', { tokenKey: tokenKey }).pipe(
            map(token => {
                if (token.loginResult === LoginResult.Success) {
                    this.tokenService.saveTokens(token.accessToken, token.refreshToken);
                    this.startTokenExpiryCheck();
                }

                return token.loginResult === LoginResult.Success;
            })
        );
    }

    logout() {
        let url = '';

        const ssoDetails: SsoDetails = this.sessionStorageService.getItem(SessionStorageKey.ssoDetails);

        if (ssoDetails) {
            url = ssoDetails.logoutUrl ? ssoDetails.logoutUrl : `${this.applicationService.loginUrl}/logout`;
        } else {
            url = `${this.applicationService.loginUrl}`;
        }

        this.sessionStorageService.clear();
        window.location.replace(url);
    }

    isLoggedIn(): boolean {
        return !!this.tokenService.getRefreshToken();
    }

    rewardCentreEnabled(): boolean {
        const loginDisabledKey = this.sessionStorageService.getItem(SessionStorageKey.rcRewardCentreAccessEnabled);

        return loginDisabledKey == null || loginDisabledKey;
    }

    ssoAuth(): Observable<AuthResult> {
        const sso: Sso = this.sessionStorageService.getItem(SessionStorageKey.ssoToken);

        const ssoDeepLink: number = sso.parameters.page ? SsoDeepLink[sso.parameters.page] : this.sessionStorageService.getItem(SessionStorageKey.ssoDeepLink);

        this.sessionStorageService.clear();

        if (ssoDeepLink) {
            this.sessionStorageService.setItem(SessionStorageKey.ssoDeepLink, ssoDeepLink);
        }

        switch (sso.parameters.action) {
            case ActionName.SAML2:
                return this.saml2(sso);
            case ActionName.OpenIDStart:
                return this.openIDStart(sso);
            case ActionName.OpenIDComplete:
                return this.openIDComplete(sso);
            default:
                return of(null);
        }
    }

    outboundSso(serviceProviderID: string) {
        this.httpClient
            .get<SsoOutboundRequest>('/Security/GetSsoOutboundRequest', [{ key: 'serviceProviderID', value: serviceProviderID }])
            .subscribe(request => {
                this.handleOutboundSsoRequest(request);
            });
    }

    removeAccessToken() {
        this.tokenService.removeAccessToken();
    }

    requestEmployeeSelfRegistration(employeeSelfRegistrationRequest: EmployeeSelfRegistrationRequest): Observable<ValidationError> {
        return this.httpClient.unsecurePost<ValidationError>('/Security/RequestEmployeeSelfRegistration', employeeSelfRegistrationRequest);
    }

    loginSsoEmployee(employeeSsoLogin: EmployeeSsoLogin): Observable<AuthResult> {
        return this.httpClient
            .unsecurePost<AccessTokenReturn>('/Security/LoginEmployeeSso', employeeSsoLogin)
            .pipe(map(token => this.getSsoAuthCompleteResult(token)));
    }

    handleOutboundSsoRequest(request: SsoOutboundRequest) {
        if (request.url && request.tokenValue) {
            if (!request.errorID) {
                const formID = Guid.newGuid();
                const formEl = document.createElement('form');
                formEl.setAttribute('id', formID);
                formEl.setAttribute('target', this.name);
                formEl.setAttribute('method', 'post');
                formEl.setAttribute('action', request.url);

                const tokenEl = document.createElement('input');
                tokenEl.setAttribute('type', 'hidden');
                tokenEl.setAttribute('name', request.tokenName);
                tokenEl.setAttribute('value', request.tokenValue);
                formEl.appendChild(tokenEl);

                if (request.relayStateURL) {
                    const relayStateUrlEl = document.createElement('input');
                    relayStateUrlEl.setAttribute('type', 'hidden');
                    relayStateUrlEl.setAttribute('name', request.relayStateName);
                    relayStateUrlEl.setAttribute('value', request.relayStateURL);
                    formEl.appendChild(relayStateUrlEl);
                }

                document.body.appendChild(formEl);

                const windowPopup = window.open('', this.name);
                if (windowPopup && !windowPopup.closed) {
                    formEl.submit();
                    formEl.outerHTML = '';
                }
            } else if (request.errorID) {
                this.router.navigate(['/login/sso-error']);
            }
        }
    }

    isMfaScriptRequired() {
        return this.environmentConfigurationDataService.environmentConfigurationData.rcMfaEnabled && !this.getCurrentDevicePrint();
    }

    updateDeviceTokenFso(token: DeviceTokenData) {
        this.localStorageService.setItem(LocalStorageKey.deviceTokenFso, token.deviceTokenFso);
    }

    updateMfaToken(token: MfaTokenInfo) {
        this.tokenService.saveMfaTokens(token.mfaToken);
    }

    getDeviceRequest(): DeviceRequest {
        const deviceRequest: DeviceRequest = {
            devicePrint: this.getDevicePrint(),
            deviceTokenFso: this.localStorageService.getItem(LocalStorageKey.deviceTokenFso)
        };

        return deviceRequest;
    }

    clearDeviceRequest() {
        this.localStorageService.removeItem(LocalStorageKey.deviceTokenFso);
        this.localStorageService.removeItem(LocalStorageKey.mfaDevicePrint);
    }

    clearMfaSession() {
        if (this.mfaInterval) {
            clearInterval(this.mfaInterval);
        }
        this.tokenService.removeMfaAccessToken();
    }

    private getCurrentDevicePrint(): string {
        return this.localStorageService.getItem(LocalStorageKey.mfaDevicePrint);
    }

    private getDevicePrint(): string | null {
        let newDevicePrint: string = this.getCurrentDevicePrint();

        if (newDevicePrint === null) {
            newDevicePrint = this.createDevicePrint();
            if (newDevicePrint != null) {
                this.localStorageService.setItem(LocalStorageKey.mfaDevicePrint, newDevicePrint);
            }
        }

        return newDevicePrint;
    }

    private createDevicePrint() {
        const globalObj = window as any;
        const getdevicePrint = globalObj?.rsa?.encode_deviceprint;

        if (typeof getdevicePrint == 'function') {
            return getdevicePrint();
        }

        return null;
    }

    private startMfaTokenExpiryCheck() {
        this.mfaInterval = setInterval(() => {
            this.validateMfaToken();
        }, this.sessionCheckInterval);
    }

    private validateMfaToken() {
        if (!this.tokenService.getMfaAccessToken()) {
            this.clearMfaSession();
            this.logout();
        }
    }

    private startTokenExpiryCheck() {
        this.interval = setInterval(() => {
            this.validateRefreshToken();
        }, this.sessionCheckInterval);
    }

    private validateRefreshToken() {
        if (!this.tokenService.getRefreshToken()) {
            clearInterval(this.interval);
            this.logout();
        }
    }

    private setChangePasswordCredentials(username: string, currentPassword: string, passwordRequirements: PasswordRequirements) {
        this.changePasswordCredentials = <ChangePasswordCredentials>{
            username: username,
            currentPassword: currentPassword,
            passwordRequirements: passwordRequirements
        };
    }

    private saml2(sso: Sso): Observable<AuthResult> {
        return this.httpClient.unsecurePost<AccessTokenReturn>('/Security/SAML2', sso).pipe(map(token => this.getSsoAuthCompleteResult(token)));
    }

    private openIDStart(sso: Sso): Observable<AuthResult> {
        return this.httpClient.unsecurePost<RedirectUrl>('/Security/OpenIDStart', sso).pipe(
            map((redirectUrl: RedirectUrl) => {
                return <AuthResult>{
                    status: redirectUrl.url ? AuthStatus.Redirect : AuthStatus.Fail,
                    navigateTo: redirectUrl.url || '/login/sso-error'
                };
            })
        );
    }

    private openIDComplete(sso: Sso): Observable<AuthResult> {
        return this.httpClient.unsecurePost<AccessTokenReturn>('/Security/OpenIDComplete', sso).pipe(map(token => this.getSsoAuthCompleteResult(token)));
    }

    private getSsoAuthCompleteResult(token: AccessTokenReturn): AuthResult {
        const ssoDeepLink: number = this.sessionStorageService.getItem(SessionStorageKey.ssoDeepLink);
        this.sessionStorageService.clear();

        if (token.loginResult === LoginResult.SchemeSelection) {
            return {
                status: AuthStatus.SchemeSelection,
                ssoSchemeSelections: token.ssoSchemeSelections,
                ssoValidEmployeeIDsToken: token.accessToken.value
            };
        }

        if (token.loginResult === LoginResult.Success) {
            this.tokenService.saveTokens(token.accessToken, token.refreshToken);
            this.sessionStorageService.setItem(SessionStorageKey.rcRewardCentreAccessEnabled, token.rewardCentreAccessEnabled);
            this.sessionStorageService.setItem(SessionStorageKey.ssoDetails, { logoutUrl: token.logoutUrl });
            this.startTokenExpiryCheck();

            return {
                status: AuthStatus.Success,
                navigateTo: this.getSsoNavigationPath(ssoDeepLink)
            };
        } else {
            return {
                status: AuthStatus.Fail,
                navigateTo: '/login/sso-error'
            };
        }
    }

    private getSsoNavigationPath(ssoDeepLink: SsoDeepLink) {
        switch (ssoDeepLink) {
            case SsoDeepLink.BenefitSelection:
                return '/selection';
            case SsoDeepLink.Profile:
                return '/profile';
            case SsoDeepLink.LifeEvent:
                return '/profile/lifeEvents';
            case SsoDeepLink.TotalRewardStatement:
                return '/engager';
            case SsoDeepLink.Reimbursement:
                return '/reimbursement';
            default:
                return '/';
        }
    }
}
