import { Directive, ElementRef, Input, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { TextService } from 'core/text.service';

@Directive({
    selector: '[appText]'
})
export class AppTextDirective implements OnInit {
    @Input('appText') textKey: string;
    @Input() replacements: (string | number)[];
    @Input() trustHtml: boolean = false;
    @Input() addLineBreak: boolean = false;

    constructor(private el: ElementRef, private textService: TextService, private renderer: Renderer2, private sanitize: DomSanitizer) {}

    ngOnInit(): void {
        const unsafeInnerHtml = this.textService.getText(this.textKey, this.replacements);
        const safeInnerHtml = this.trustHtml ? unsafeInnerHtml : this.sanitize.sanitize(SecurityContext.HTML, unsafeInnerHtml);

        const formattedSafeInnerHtml = this.addLineBreak ? safeInnerHtml.replace('/', '/<wbr>') : safeInnerHtml;

        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', formattedSafeInnerHtml);
    }
}
