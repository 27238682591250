import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ConfigurationService } from 'core/configuration.service';
import { PolicyService } from 'core/policy.service';
import { TextService } from 'core/text.service';

import { IOverlayContent } from 'shared/overlay/overlay.model';

@Component({
    templateUrl: 'cookies-overlay.component.html',
    styleUrls: ['./cookies-overlay.component.less']
})
export class CookiesOverlayComponent implements OnInit, IOverlayContent {
    @Output() hasSeenCookiesPolicy: EventEmitter<void> = new EventEmitter<void>();

    close: EventEmitter<void>;

    replacements: any[] = [];
    hasMultiLanguage: boolean;

    private cookiesPolicyLink: any;

    constructor(private textService: TextService, private policyService: PolicyService, private configurationService: ConfigurationService) {}

    ngOnInit() {
        this.hasMultiLanguage = this.configurationService.aggregatedLanguages.length > 1;
        this.setupLink();
    }

    showCookiesPolicy() {
        this.policyService.showCookiesPolicy(true);
    }

    closeOverlay() {
        this.hasSeenCookiesPolicy.emit();
        this.close.emit();
    }

    private setupLink() {
        this.cookiesPolicyLink = `<a href="javascript:void(0)" title="${this.textService.getText(
            'CookiesPolicy_Title'
        )}" id="cookies-policy">${this.textService.getText('CookiesPolicy_Dialog_LinkLabel')}</a>`;
        this.replacements = [this.cookiesPolicyLink];
    }
}
