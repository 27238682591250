import { Location, PathLocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApplicationService } from 'core/application.service';
import { ConfigurationService } from 'core/configuration.service';
import { EmitEvent, EventService, EventType } from 'core/event.service';
import { LoadingScreenService } from 'core/loadingScreen/loading-screen.service';
import { DisplaySize } from 'core/models/display.model';
import { TextService } from 'core/text.service';
import { TokenService } from 'core/token.service';
import { WindowService } from 'core/window.service';

@Component({
    selector: 'error',
    templateUrl: 'error.component.html',
    styleUrls: ['error.component.less']
})
export class ErrorComponent implements OnInit, OnDestroy {
    darwinLogoUrl: string;
    bannerUrl: string;
    logoUrl: string;
    hasBanner: boolean;
    isMobileDevice: boolean;
    showFallbackErrorComponent: boolean;
    homepageLink: string;

    private subscriptions: Subscription[] = [];

    constructor(
        private applicationService: ApplicationService,
        private configurationService: ConfigurationService,
        private location: Location,
        private windowService: WindowService,
        private eventService: EventService,
        private tokenService: TokenService,
        private loadingScreenService: LoadingScreenService,
        private textService: TextService,
        private pathLocationStrategy: PathLocationStrategy,
        private router: Router
    ) {}

    ngOnInit() {
        try {
            this.setupLink();
            this.loadingScreenService.show();
            this.showFallbackErrorComponent = false;

            const accessToken = this.tokenService.getAccessToken();
            if (!accessToken) {
                this.showFallbackErrorComponent = true;
                return;
            }

            this.isMobileDevice = this.windowService.isMobileDevice();
            this.hasBanner = !!this.configurationService.configuration.options.banner;
            this.darwinLogoUrl = `${this.applicationService.baseUrl}dist/content/DarwinLogo.png`;

            this.eventService.initialiseEvent(new EmitEvent(EventType.DisplayChange));
            this.subscriptions.push(
                this.eventService.subscribeToEvent(EventType.DisplayChange).subscribe((displaySize: DisplaySize) => {
                    this.isMobileDevice = displaySize === DisplaySize.mobile;
                })
            );

            if (this.hasBanner) {
                this.bannerUrl = this.configurationService.configuration.options.banner.filePath;
                this.logoUrl = this.configurationService.configuration.options.logo.filePath;
            }
        } catch (err) {
            this.showFallbackErrorComponent = true;
        } finally {
            this.loadingScreenService.hide();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    goBack() {
        this.location.historyGo(-2);
    }

    goToHomePage() {
        this.router.navigate(['/']);
    }

    private setupLink() {
        this.homepageLink = `<a id="homepageLink" href="${this.pathLocationStrategy.getBaseHref()}"> ${this.textService.getText(
            'Error_Text_Header_HomePage'
        )} </a>`;
    }
}
