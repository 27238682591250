<header id="header-container" class="header-container">
    <nav class="menu">
        <div id="header-menu" [ngClass]="{ inverted: invertedNavigationBarColor }" class="primary-nav-container label-14-r">
            <div class="grid no-vertical-padding primary-nav-sub-container">
                <div class="primary-nav-left-container">
                    <div *ngIf="logoUrl" class="logo-container">
                        <a href="javascript:void(0)" (click)="navigateToHome()">
                            <progressive-image (finishedLoading)="imageLoaded = true" [imageUrl]="logoUrl" [altText]="returnHomeLabel"></progressive-image>
                        </a>
                    </div>
                    <div class="desktop-menu-container" *ngIf="contentLoaded && (!hasLogo || imageLoaded) && !isMobileOrTablet">
                        <desktop-menu></desktop-menu>
                    </div>
                </div>
                <div class="primary-nav-right-container">
                    <div class="primary-nav-right-toggle-container" *ngIf="!isMobileDevice">
                        <privacy-toggle [useBrandColour]="invertedNavigationBarColor" [showValueTextBellow]="true" [toggleSize]="'medium'"></privacy-toggle>
                        <privacy-toggle-popup *ngIf="!showPrivacyPopUp" (close)="onClose()"></privacy-toggle-popup>
                    </div>
                    <div class="primary-nav-right-menu">
                        <ul>
                            <li
                                *ngIf="helpNavMenuItems.length > 0 && isHelpVisible"
                                [ngClass]="{ active: isHelpSelected, inverted: invertedNavigationBarColor }"
                                (focusout)="closeHelpMenu($event)"
                                (mouseleave)="closeHelpMenu($event)">
                                <a
                                    href="javascript:void(0)"
                                    id="menu-help-trigger"
                                    class="menu-trigger label-14-r"
                                    [attr.aria-haspopup]="true"
                                    [attr.aria-expanded]="showHelpMenu"
                                    [attr.aria-label]="'Global_HelpAndSupport' | appText"
                                    (click)="showHelpMenu = !showHelpMenu"
                                    (mouseenter)="openHelpMenu()">
                                    <div class="btn-icon-container">
                                        <div class="icon-container">
                                            <icon class="nav-bar-icon icon-lg" iconId="help"></icon>
                                        </div>
                                        <p class="btn-icon-container-text" *ngIf="!isMobileDevice">
                                            {{ 'Global_Help' | appText }}
                                        </p>
                                    </div>
                                </a>
                                <div class="help-menu-container">
                                    <drop-down-menu
                                        [(showMenu)]="showHelpMenu"
                                        id="menu-help"
                                        buttonId="menu-help-trigger"
                                        title="{{ 'Global_HelpAndSupport' | appText }}"
                                        [invertedNavigationBarColor]="invertedNavigationBarColor"
                                        [navMenuItems]="helpNavMenuItems">
                                    </drop-down-menu>
                                </div>
                            </li>
                            <li
                                *ngIf="isCartVisible"
                                [ngClass]="{ active: isCartMenuSelected, inverted: invertedNavigationBarColor }"
                                (focusout)="closeCartMenu($event)"
                                (mouseleave)="closeCartMenu($event)">
                                <a
                                    href="javascript:void(0)"
                                    id="header-menu-item-cart"
                                    class="menu-trigger"
                                    [attr.aria-haspopup]="true"
                                    [attr.aria-expanded]="showCartMenu"
                                    [attr.aria-label]="'Global_Accessibility_CartMenuLabel' | appText"
                                    (click)="showCartMenu = !showCartMenu; this.showFullWidthMenu = false"
                                    (mouseenter)="openCartMenu()">
                                    <div class="btn-icon-container label-14-r">
                                        <div class="icon-container">
                                            <icon class="nav-bar-icon icon-lg" iconId="empty-cart"></icon>
                                            <div *ngIf="tempSelectionsCount > 0" class="circle-count cart-count label-11-r">
                                                <span>{{ tempSelectionsCount }}</span>
                                            </div>
                                        </div>
                                        <p class="btn-icon-container-text" *ngIf="!isMobileDevice">
                                            {{ 'Header_CartItem' | appText }}
                                        </p>
                                    </div>
                                </a>
                                <cart-menu [(showMenu)]="showCartMenu" (tempSelectionsCount)="tempSelectionsCount = $event"></cart-menu>
                            </li>
                            <li
                                *ngIf="isLanguageSwitcherVisible"
                                [ngClass]="{ inverted: invertedNavigationBarColor }"
                                (focusout)="closeLanguageMenu($event)"
                                (mouseleave)="closeLanguageMenu($event)">
                                <a
                                    href="javascript:void(0)"
                                    id="header-menu-item-language"
                                    class="menu-trigger"
                                    [attr.aria-haspopup]="true"
                                    [attr.aria-expanded]="false"
                                    [attr.aria-label]="'Global_Accessibility_LanguageMenu' | appText"
                                    (click)="showLanguageSwitcherMenu = !showLanguageSwitcherMenu"
                                    (mouseenter)="openLanguageSwitcher()">
                                    <div class="btn-icon-container label-14-r">
                                        <div class="icon-container">
                                            <icon class="nav-bar-icon icon-lg" iconId="globe-language"></icon>
                                        </div>
                                        <p class="btn-icon-container-text" *ngIf="!isMobileDevice">
                                            {{ 'Global_Language' | appText }}
                                        </p>
                                    </div>
                                </a>
                                <div class="language-switcher-menu-container">
                                    <drop-down-menu
                                        [(showMenu)]="showLanguageSwitcherMenu"
                                        id="languageSwitcherMenu"
                                        buttonId="header-menu-item-language"
                                        title="{{ 'Global_SelectLanguage' | appText }}"
                                        (triggerAction)="triggerAction($event)"
                                        [invertedNavigationBarColor]="invertedNavigationBarColor"
                                        [navMenuItems]="languageSwitcherNavMenuItems">
                                    </drop-down-menu>
                                </div>
                            </li>
                            <li
                                *ngIf="profileNavMenuItems.length > 0 && isProfileVisible"
                                [ngClass]="{ active: isProfileSelected, inverted: invertedNavigationBarColor }"
                                (focusout)="closeProfileMenu($event)"
                                (mouseleave)="closeProfileMenu($event)">
                                <a
                                    href="javascript:void(0)"
                                    id="menu-profile-trigger"
                                    class="menu-trigger"
                                    [attr.aria-haspopup]="true"
                                    [attr.aria-expanded]="showProfileMenu"
                                    [attr.aria-label]="'Global_Accessibility_ProfileMenuLabel' | appText"
                                    (click)="showProfileMenu = !showProfileMenu"
                                    (mouseenter)="openProfileMenu()">
                                    <div class="btn-icon-container label-14-r">
                                        <div class="icon-container">
                                            <icon class="nav-bar-icon icon-lg" iconId="user"></icon>
                                            <div *ngIf="totalPendingCountForManagerApproval > 0" class="circle-count task-count label-11-r">
                                                <span>{{ totalPendingCountForManagerApproval }}</span>
                                            </div>
                                        </div>
                                        <p class="btn-icon-container-text" *ngIf="!isMobileDevice">
                                            {{ 'Global_Profile' | appText }}
                                        </p>
                                    </div>
                                </a>
                                <div class="profile-menu-container">
                                    <drop-down-menu
                                        [(showMenu)]="showProfileMenu"
                                        title="{{ employee.firstName }}"
                                        id="menu-profile"
                                        buttonId="menu-profile-trigger"
                                        [invertedNavigationBarColor]="invertedNavigationBarColor"
                                        [navMenuItems]="profileNavMenuItems">
                                        <ng-template #itemTemplate>
                                            <div
                                                class="menu-item-language-dropdown-and-logout"
                                                [class.row-Layout]="isLanguageAndLogoutRowLayout"
                                                (mouseenter)="preventDefaultOnDropDownClick = !preventDefaultOnDropDownClick"
                                                (mouseleave)="preventDefaultOnDropDownClick = !preventDefaultOnDropDownClick">
                                                <li class="language-dropdown-container" *ngIf="hasMultiLanguage && !isLanguageSwitcherVisible">
                                                    <darwin-select
                                                        [contextType]="invertedNavigationBarColor ? contextType.MenuInverted : contextType.Menu"
                                                        iconId="globe-vector"
                                                        [disableTooltip]="true"
                                                        [selectPlaceholder]="selectedLanguage.displayText"
                                                        (currentValueChange)="changeLanguage($event)">
                                                        <darwin-select-option
                                                            *ngFor="let supportedLanguage of supportedLanguages"
                                                            [value]="supportedLanguage.id"
                                                            [contextType]="invertedNavigationBarColor ? contextType.MenuInverted : contextType.Menu"
                                                            [selected]="supportedLanguage.id === selectedLanguage.id">
                                                            {{ supportedLanguage.displayText }}
                                                        </darwin-select-option>
                                                    </darwin-select>
                                                </li>
                                                <li class="logout" [class.logout-only]="!hasMultiLanguage || isLanguageSwitcherVisible">
                                                    <button
                                                        class="btn-primary btn-logout"
                                                        (click)="logout()"
                                                        appText="Global_Action_Logout"
                                                        #logoutButton></button>
                                                </li>
                                            </div>
                                        </ng-template>
                                    </drop-down-menu>
                                </div>
                            </li>
                            <li *ngIf="isMobileOrTablet">
                                <a
                                    class="mobile-tablet-menu-button menu-trigger"
                                    [attr.aria-haspopup]="true"
                                    [attr.aria-expanded]="showFullWidthMenu"
                                    aria-controls="mobile-tablet-menu"
                                    [ngClass]="{ inverted: invertedNavigationBarColor }"
                                    (click)="showFullWidthMenu = !showFullWidthMenu; showCartMenu = false">
                                    <span [ngClass]="{ 'mobile-tablet-menu-expanded': showFullWidthMenu }">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <p class="menu-text">{{ 'Global_Menu_Label' | appText }}</p>
                                </a>
                                <full-width-menu [(showMenu)]="showFullWidthMenu"></full-width-menu>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="primary-nav-bottom-border"></div>
    </nav>
    <div class="primary-nav-partition" *ngIf="isMobileOrTablet"></div>
</header>
