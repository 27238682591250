import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ApiHttpClient } from 'core/api-http-client';
import { ContentService } from 'core/content.service';
import { Guid } from 'core/extensions/guid-extension';

import {
    ContentDocumentNode,
    ContentImageNode,
    ContentLinkNode,
    ContentLinkType,
    ContentNodeItemType,
    ContentTextBlockNode,
    ContentTextNode,
    IContentNode,
    IContentNodeImpl
} from 'shared/components/content-renderer/content-renderer.model';

@Component({
    selector: 'content-renderer',
    templateUrl: 'content-renderer.component.html'
})
export class ContentRendererComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() content: IContentNodeImpl;
    @Input() innerExpander: boolean;

    contentNodeItemType = ContentNodeItemType;
    objectUrl: string;
    safeImageURL: SafeUrl;
    fileName: string;
    showInnerExpanderDetails: boolean = false;
    documentNodeList: IContentNode[] = [];
    externalLinkNodeList: IContentNode[] = [];
    internalLinkNodeList: IContentNode[] = [];

    private blobUrls: string[] = [];

    constructor(
        private contentService: ContentService,
        private apiHttpClient: ApiHttpClient,
        private sanitizationService: DomSanitizer,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        if (this.content.nodeItemType === this.contentNodeItemType.TextBlock) {
            this.parseBlockNodes();
        }

        if (this.content.nodeItemType === this.contentNodeItemType.Image) {
            this.buildInternalImage();
        }
    }

    ngAfterViewInit() {
        if (this.content.nodeItemType !== ContentNodeItemType.Text) {
            return;
        }

        this.addClickEventExternalLinks();
        this.addClickEventDocumentLinks();
        this.addClickEventInternalLinks();
    }

    ngOnDestroy() {
        if (this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
        }

        this.blobUrls.forEach(url => {
            URL.revokeObjectURL(url);
        });
    }

    displayInformation() {
        this.contentService.displayInformation(this.content);
    }

    private parseBlockNodes() {
        const internalLinkIdentifiers = ['hub', 'dependants', 'profile', 'engager', 'reimbursement', 'selection'];
        const textBlockNodes = this.content as ContentTextBlockNode;

        const parsedBlock: ContentTextNode = {
            nodeItemType: ContentNodeItemType.Text,
            text: '',
            _clickable: false
        };

        textBlockNodes.contentNodes.forEach(x => {
            switch (x.nodeItemType) {
                case ContentNodeItemType.Text: {
                    const paragraphNode = x as ContentTextNode;
                    parsedBlock.text += paragraphNode.text;
                    break;
                }
                case ContentNodeItemType.Link: {
                    const linkNode = x as IContentNodeImpl;
                    linkNode.url = this.htmlDecode(linkNode.url);
                    if (internalLinkIdentifiers.some(identifier => linkNode.url.includes(identifier))) {
                        this.addItemToInternalLinkNodeList(linkNode, parsedBlock);
                    } else {
                        this.addItemToExternalLinkNodeList(linkNode, parsedBlock);
                    }

                    break;
                }
                case ContentNodeItemType.Document: {
                    const documentNode = x as ContentDocumentNode;
                    documentNode._id = `documentLink_id_${Guid.newGuid()}`;
                    parsedBlock.text += `<a class="document-link" href="javascript:void(0)" id=\"${documentNode._id}\" aria-label=\"${documentNode.fileName}\">${documentNode.fileName}</a> `;

                    const docExists = this.documentNodeList.find(doc => (doc as ContentDocumentNode).file.fileID === documentNode.file.fileID);

                    if (!docExists) {
                        this.documentNodeList.push(documentNode);
                    }

                    break;
                }
            }
        });

        this.content = parsedBlock as IContentNodeImpl;
    }

    private addItemToExternalLinkNodeList(linkNode: IContentNodeImpl, parsedBlock: ContentTextNode) {
        linkNode._id = `externalLink_id_${Guid.newGuid()}`;

        parsedBlock.text += `<a class="external-link" href="javascript:void(0)" id=\"${linkNode._id}\" aria-label=\"${linkNode.text}\">${linkNode.text}</a> `;

        const externalLinkExists = this.externalLinkNodeList.find(link => (link as ContentLinkNode)._id === linkNode._id);

        if (!externalLinkExists) {
            this.externalLinkNodeList.push(linkNode);
        }
    }

    private addItemToInternalLinkNodeList(linkNode: IContentNodeImpl, parsedBlock: ContentTextNode) {
        linkNode._id = `internalLink_id_${Guid.newGuid()}`;
        linkNode.openInNewWindow = false;
        linkNode.contentLinkType = ContentLinkType.RedirectInternal;
        this.content = linkNode;
        parsedBlock.text += `<a class="internal-link" href=\"${linkNode.url}">${linkNode.text}</a> `;
        const internalLinkExists = this.internalLinkNodeList.find(link => (link as ContentLinkNode)._id === linkNode._id);

        if (!internalLinkExists) {
            this.internalLinkNodeList.push(linkNode);
        }
    }

    private htmlDecode(input) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent;
    }

    private buildInternalImage() {
        const imageNode = this.content as ContentImageNode;

        this.apiHttpClient.getWebMediaFile(imageNode.file.filePath).subscribe(response => {
            const url = URL.createObjectURL(response);
            this.blobUrls.push(url);
            this.safeImageURL = this.sanitizationService.bypassSecurityTrustUrl(url);
            this.fileName = imageNode.fileName;
        });
    }

    private addClickEventExternalLinks() {
        if (this.externalLinkNodeList.length === 0) {
            return;
        }

        const externalLinks = this.elementRef.nativeElement.querySelectorAll(`a.external-link`);
        this.externalLinkNodeList.forEach(node => {
            const externalLinkNode = node as ContentLinkNode;
            externalLinks.forEach(link => {
                if (link.id === externalLinkNode._id) {
                    link.addEventListener('click', () => {
                        this.displayInformationWithContentNode(externalLinkNode);
                    });
                }
            });
        });
    }

    private addClickEventDocumentLinks() {
        if (this.documentNodeList.length === 0) {
            return;
        }

        const documentLinks = this.elementRef.nativeElement.querySelectorAll('a.document-link');
        this.documentNodeList.forEach(node => {
            const documentNode = node as ContentDocumentNode;
            documentLinks.forEach(link => {
                if (link.id === documentNode._id) {
                    link.addEventListener('click', () => {
                        this.displayInformationWithContentNode(node);
                    });
                }
            });
        });
    }

    private addClickEventInternalLinks() {
        if (this.internalLinkNodeList.length === 0) {
            return;
        }

        const internalLinks = this.elementRef.nativeElement.querySelectorAll(`a.internal-link`);
        this.internalLinkNodeList.forEach(node => {
            const internalLinkNode = node as IContentNodeImpl;
            internalLinks.forEach(link => {
                if (link.id === internalLinkNode._id) {
                    link.addEventListener('click', () => {
                        this.content = internalLinkNode;
                        this.displayInformation();
                    });
                }
            });
        });
    }

    private displayInformationWithContentNode(content: IContentNode) {
        this.contentService.displayInformation(content);
    }
}
