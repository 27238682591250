<div class="welcome-overlay">
    <div class="language-area">
        <language-select *ngIf="hasMultiLanguage"></language-select>
    </div>
    <div class="welcome-toggle-area">
        <div class="welcome-text">
            <h4 class="heading-18-b">
                {{ 'WelcomeOverlay_Title' | appText }}
            </h4>
            <div class="welcome-text-content" [innerHtml]="'WelcomeOverlay_Content' | appText | formatString: [employee | formatName, schemeName]"></div>
        </div>
        <div class="accordion-container">
            <accordion-transparent>
                <accordion-transparent-item id="privacy-policy">
                    <span class="accordion-item-title">
                        {{ 'Global_PrivacyPolicy' | appText }}
                    </span>
                    <div class="accordion-item-content">
                        <div [innerHTML]="privacyPolicyText" class="external-html-container"></div>
                    </div>
                </accordion-transparent-item>
                <accordion-transparent-item id="terms-and-conditions">
                    <span class="accordion-item-title">
                        {{ 'Global_TermsAndConditions' | appText }}
                    </span>
                    <div class="accordion-item-content">
                        <div [innerHTML]="termsAndConditionsText" class="external-html-container"></div>
                    </div>
                </accordion-transparent-item>
                <accordion-transparent-item id="cookies-policy" *ngIf="showCookiesPolicy">
                    <span class="accordion-item-title">
                        {{ 'Global_CookiesPolicy' | appText }}
                    </span>
                    <div class="accordion-item-content">
                        <div [innerHTML]="cookiesPolicyText" class="external-html-container"></div>
                    </div>
                </accordion-transparent-item>
            </accordion-transparent>
        </div>
    </div>
    <div class="form-area" [formGroup]="formGroup">
        <div class="agree-to-terms-and-conditions form-checkbox">
            <input
                type="checkbox"
                id="agreedToTermsAndConditions"
                [checked]="agreedToTermsAndConditions"
                formControlName="agreedToTermsAndConditions"
                required
                (change)="onChange()" />
            <label for="agreedToTermsAndConditions" (click)="nagivateTo($event)" [innerHTML]="agreeToTermsAndConditionsText | trustHtml"></label>
            <div class="validation-errors">
                <div *ngIf="validationMessage" class="validation-error">
                    <icon iconId="error-notification-filled" class="icon-md"></icon>
                    <span class="label-14-r">{{ validationMessage }}</span>
                </div>
            </div>
        </div>
        <div class="hide-welcome-overlay form-checkbox">
            <input type="checkbox" id="hideWelcomeOverlay" [checked]="false" formControlName="hideWelcomeOverlay" />
            <label for="hideWelcomeOverlay">{{ 'WelcomeOverlay_HideWelcomeOverlayText' | appText }}</label>
        </div>
        <div class="action-buttons">
            <button class="btn-primary" type="submit" appText="Global_Action_Ok" (click)="submit()"></button>
        </div>
    </div>
    <p class="welcome-footer paragraph-12-r" (click)="nagivateTo($event)" [innerHTML]="footerText | trustHtml"></p>
</div>
