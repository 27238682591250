import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { ConfigurationService } from 'core/configuration.service';
import { EmitEvent, EventService, EventType } from 'core/event.service';

@Injectable()
export class ApplicationLoadResolver implements Resolve<boolean> {
    constructor(private configurationService: ConfigurationService, private eventService: EventService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.configurationService.configuration.pageConfig.lineManager.isEnabled) {
            this.eventService.initialiseEvent(new EmitEvent(EventType.ManagerApproval, true));
        }

        return of(true);
    }
}
