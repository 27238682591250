<div class="app-container" [lang]="countryLanguage">
    <div class="app-inner-container">
        <section id="ghost-element" style="z-index: 2000" tabindex="-1">
            <a
                href="javascript:void(0)"
                (click)="skipToContent()"
                role="link"
                tabindex="0"
                class="skip-link"
                [class.inverse-color]="invertedNavigationBarColor">
                {{ 'Global_Accessibility_SkipToContent' | appText }}
            </a>
            <a
                href="javascript:void(0)"
                (click)="skipToNavigationBar()"
                role="link"
                tabindex="0"
                class="skip-link"
                [class.inverse-color]="invertedNavigationBarColor">
                {{ 'Global_Accessibility_SkipToNavigation' | appText }}
            </a>
        </section>
        <primary-navigation class="app-header"></primary-navigation>
        <main id="main" role="main" class="app-body" [class.app-container-mobile]="isMobileDevice">
            <router-outlet></router-outlet>
            <scrollToTopArrow></scrollToTopArrow>
        </main>
        <footer-details class="app-footer"></footer-details>
    </div>
</div>
