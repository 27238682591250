import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { ApiHttpClient } from 'core/api-http-client';
import { FontFamily } from 'core/models/configuration.model';
import fonts from 'core/scripts/fonts.js';

@Injectable()
export class FontLoaderService {
    constructor(private apiHttpClient: ApiHttpClient) {}

    init(fontFamily: FontFamily) {
        if (!fontFamily) {
            return;
        }

        forkJoin(
            this.apiHttpClient.getWebMediaFile(fontFamily.regular.filePath),
            this.apiHttpClient.getWebMediaFile(fontFamily.bold.filePath),
            this.apiHttpClient.getWebMediaFile(fontFamily.italic.filePath),
            this.apiHttpClient.getWebMediaFile(fontFamily.boldItalic.filePath)
        ).subscribe(blobs => {
            const urls = [];
            blobs.forEach(blob => {
                urls.push(URL.createObjectURL(blob));
            });
            fonts(urls);
        });
    }
}
