import { OverlayModule as CdkOverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InternalSharedModule } from 'shared/internal-shared.module';
import { DialogContainerComponent } from 'shared/overlay/dialog-container.component';
import { OverlayService } from 'shared/overlay/overlay.service';
import { OverlayContainerComponent } from 'shared/overlay/overlay-container.component';
import { OverlayTextContentComponent } from 'shared/overlay/overlay-text-content.component';

@NgModule({
    declarations: [OverlayContainerComponent, OverlayTextContentComponent, DialogContainerComponent],
    imports: [InternalSharedModule, CommonModule, CdkOverlayModule, PortalModule]
})
export class OverlayModule {
    static rootProviders(fromRoot: boolean = false) {
        return [OverlayService, { provide: 'fromRoot', useValue: fromRoot }];
    }
}
