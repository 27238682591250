import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';

import { expandCollapse } from 'shared/animations/expand-collapse.animation';
import { rotateBlob } from 'shared/animations/rotate-blob.animation';

@Component({
    selector: 'accordion-transparent-item',
    templateUrl: 'accordion-transparent-item.component.html',
    styleUrls: ['./accordion-transparent-item.component.less'],
    animations: [expandCollapse, rotateBlob]
})
export class AccordionTransparentItemComponent {
    @ViewChild('template', { static: true }) template: TemplateRef<any>;
    @ContentChildren(AccordionTransparentItemComponent) items: QueryList<AccordionTransparentItemComponent>;

    @Input() id: string;
    @Input() expanded?: boolean = false;
    @Input() disabled?: boolean = false;

    @Output() expandCollapseDone: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() expandCollapseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    customIconClass: string;

    constructor() {}

    toggleItem() {
        this.expanded = !this.expanded;

        this.expandCollapseChange.emit(this.expanded);
    }

    onExpandCollapseDone() {
        this.expandCollapseDone.emit(this.expanded);
    }
}
