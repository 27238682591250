import { Injectable } from '@angular/core';

@Injectable()
export class DatepickerService {
    getUTCMidnightDate(year: number, month: number, day: number): Date {
        return new Date(year, month - 1, day, 0, 0, 0);
    }

    dateToUTCString(date: Date): string {
        const timeOffset: string = 'T00:00:00Z';

        const dateFormat =
            this.formatNumber(date.getFullYear()) + '-' + this.formatNumber(date.getMonth() + 1) + '-' + this.formatNumber(date.getDate()) + timeOffset;

        return dateFormat;
    }

    formatNumber(v: number): string {
        return v < 10 ? `0${v}` : v.toString();
    }

    removeUTCAttributeFromDateString(date: string) {
        return date.replace('Z', '');
    }
}
