import { animate, state, style, transition, trigger } from '@angular/animations';

export const enum AnimationState {
    void = 'void',
    rightIn = 'rightIn',
    rightOut = 'rightOut',
    centerIn = 'centerIn',
    centerOut = 'centerOut',
    dialogIn = 'dialogIn',
    dialogOut = 'dialogOut'
}

export function setAnimationStyle(duration: number) {
    return animate(`${duration}ms ease-in-out`);
}

export const flyInOut = trigger('flyInOut', [
    state(
        `${AnimationState.rightIn}`,
        style({
            transform: 'translate3d(0, 0, 0)'
        })
    ),
    state(
        `${AnimationState.rightOut}`,
        style({
            transform: 'translate3d(100%, 0, 0)'
        })
    ),
    state(
        `${AnimationState.centerIn}, ${AnimationState.dialogIn}`,
        style({
            opacity: 1
        })
    ),
    state(
        `${AnimationState.centerOut}, ${AnimationState.dialogOut}`,
        style({
            opacity: 0
        })
    ),
    transition(`${AnimationState.void} => ${AnimationState.rightIn}`, [style({ transform: 'translate3d(100%, 0, 0)' }), setAnimationStyle(500)]),
    transition(`${AnimationState.rightIn} => ${AnimationState.rightOut}`, setAnimationStyle(700)),
    transition(`${AnimationState.rightOut} => ${AnimationState.rightIn}`, setAnimationStyle(700)),
    transition(`${AnimationState.void} => ${AnimationState.centerIn}`, [style({ opacity: 0 }), setAnimationStyle(600)]),
    transition(`${AnimationState.centerIn} => ${AnimationState.centerOut}`, setAnimationStyle(600)),
    transition(`${AnimationState.void} => ${AnimationState.dialogIn}`, [style({ opacity: 0 }), setAnimationStyle(500)]),
    transition(`${AnimationState.dialogIn} => ${AnimationState.dialogOut}`, setAnimationStyle(200))
]);
