<div class="video-overlay-container">
    <div class="video-overlay" focus-trap>
        <h3 class="video-title heading-22-b" tabindex="0">{{ videoHeader }}</h3>
        <div class="video-player">
            <video-component [internalVideoFile]="internalVideoFile"></video-component>
        </div>
        <div class="video-details">
            <button class="btn-default close-button" (click)="hideOverlay()">
                <span appText="Global_Action_Close"></span>
            </button>
        </div>
    </div>
    <div class="overlay-background"></div>
</div>
