import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class EventService {
    private eventsFired: { [id: number]: ReplaySubject<EmitEvent> | Subject<EmitEvent> } = {};

    initialiseEvent(event: EmitEvent, onlySubscribeToFutureEvents: boolean = false) {
        const eventSubject = onlySubscribeToFutureEvents ? new Subject<EmitEvent>() : new ReplaySubject<EmitEvent>(1);

        this.eventsFired[event.eventType] = eventSubject;

        if (event.value !== null && event.value !== undefined) {
            this.triggerEvent(event);
        }
    }

    subscribeToEvent(eventType: EventType): Observable<any> {
        if (this.eventsFired[eventType]) {
            return this.eventsFired[eventType].pipe(
                filter((event: EmitEvent) => {
                    return event.eventType === eventType;
                }),
                map((event: EmitEvent) => {
                    return event.value;
                })
            );
        } else {
            throw new Error('Event type ' + EventType[eventType] + ' needs to be initialised in the application resolver');
        }
    }

    triggerEvent(event: EmitEvent) {
        if (this.eventsFired[event.eventType]) {
            this.eventsFired[event.eventType].next(event);
        }
    }
}

export class EmitEvent {
    constructor(public eventType: EventType, public value?: any) {}
}

export enum EventType {
    UpdateCart,
    PrivacyToggle,
    UpdateMoreItemsMenu,
    DisplayChange,
    SSOSelectionReceived,
    ManagerApproval
}
