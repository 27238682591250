import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
    setItem(key: string, object: any) {
        sessionStorage.setItem(key, JSON.stringify(object));
    }

    clear() {
        sessionStorage.clear();
    }

    getItem(key: string): any {
        return JSON.parse(sessionStorage.getItem(key));
    }

    removeItem(key: string) {
        sessionStorage.removeItem(key);
    }
}

export enum SessionStorageKey {
    ssoDetails = 'ssoDetails',
    ssoToken = 'ssoToken',
    ssoDeepLink = 'ssoDeepLink',
    rcRewardCentreAccessEnabled = 'rcRewardCentreAccessEnabled',
    hasConfirmedDependants = 'hasConfirmedDependants',
    selectionFlowGuidanceViewed = 'selectionFlowGuidanceViewed',
    mfaAccessToken = 'mfaAccessToken',
    mfaSelectChallengeModel = 'mfaSelectChallengeModel'
}
