import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';

import { IconProviderService } from 'core/icon-provider.service';
import { SystemIcon } from 'core/models/system-icon.model';

@Component({
    selector: 'icon',
    template: '',
    styleUrls: ['icon.component.less'],
    // avoid ViewEncapsulation.None https://space.tobdarwin.com/pages/viewpage.action?pageId=82592378
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit {
    @Input() iconId: string;
    @Input() iconKey: SystemIcon;

    constructor(private iconProviderService: IconProviderService, private element: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        if (this.iconKey) {
            this.iconId = this.getIconId(this.iconKey);
        }

        if (this.iconId) {
            const icon = this.iconProviderService.getIcon(this.iconId);
            __ngRendererProjectNodesHelper(this.renderer, this.element.nativeElement, [icon.cloneNode(true)]);
        }
    }

    private getIconId(systemIcon: SystemIcon): string {
        switch (systemIcon) {
            case SystemIcon.Bike:
                return 'bicycle';
            case SystemIcon.SpendingAccounts:
                return 'spending-account';
            case SystemIcon.Discounts:
                return 'discount';
            case SystemIcon.Benefits:
                return 'benefit';
            case SystemIcon.BenefitInformation:
                return 'benefit-information';
            case SystemIcon.Star:
                return 'star';
            case SystemIcon.Sickness:
                return 'sickness';
            case SystemIcon.Travel:
                return 'travel';
            case SystemIcon.Dependants:
                return 'dependants';
            case SystemIcon.UsefulTools:
                return 'usefulTools';
            case SystemIcon.LifeEventChanges:
                return 'lifeEvents';
            case SystemIcon.Policy:
                return 'policy';
            case SystemIcon.BenefitsModeller:
                return 'benefitsModeller';
            case SystemIcon.PensionsModeller:
                return 'pensionsModeller';
            case SystemIcon.RiskProfiler:
                return 'riskProfiler';
            case SystemIcon.FAQs:
                return 'faq';
            case SystemIcon.Print:
                return 'print';
            case SystemIcon.Call:
                return 'phone';
            case SystemIcon.TotalRewardStatement:
                return 'totalRewardStatement';
            case SystemIcon.BrokenFile:
                return 'broken-file';
            case SystemIcon.Globe:
                return 'globe';
            case SystemIcon.Headphones:
                return 'headphones';
            case SystemIcon.Smile:
                return 'smile';
            case SystemIcon.University:
                return 'university';
            case SystemIcon.People:
                return 'people';
            case SystemIcon.GroupFavoriteHeart:
                return 'group-favorite-heart';
            case SystemIcon.Graduate:
                return 'graduate';
            case SystemIcon.ShoppingCartFull:
                return 'shopping-cart-full';
            case SystemIcon.Safe:
                return 'safe';
            case SystemIcon.BankNotes:
                return 'bank-notes';
            case SystemIcon.MoneyBagDollar:
                return 'money-bag-dollar';
            case SystemIcon.PiggyBank:
                return 'piggy-bank';
            case SystemIcon.CoinBankNote:
                return 'coin-bank-note';
            case SystemIcon.HouseLock:
                return 'house-lock';
            case SystemIcon.Beach:
                return 'beach';
            case SystemIcon.AirplaneDeparture:
                return 'airplane-departure';
            case SystemIcon.FoodDomeServing:
                return 'food-dome-serving';
            case SystemIcon.WeightLifting:
                return 'weight-lifting';
            case SystemIcon.Dumbbell:
                return 'dumbbell';
            case SystemIcon.Stethoscope:
                return 'stethoscope';
            case SystemIcon.Saline:
                return 'saline';
            case SystemIcon.Medicine:
                return 'medicine';
            case SystemIcon.MedicalNotepad:
                return 'medical-notepad';
            case SystemIcon.HeartBeat:
                return 'heart-beat';
            case SystemIcon.Dentist:
                return 'dentist';
            case SystemIcon.DentalShield:
                return 'dental-shield';
            case SystemIcon.Ambulance:
                return 'ambulance';
            case SystemIcon.GlassesRound:
                return 'glasses-round';
            case SystemIcon.MaskDouble:
                return 'mask-double';
            case SystemIcon.HeartCare:
                return 'heart-care';
            case SystemIcon.Car:
                return 'car';
            case SystemIcon.PoundSign:
                return 'pound-sign';
            case SystemIcon.BookFavoriteHeart:
                return 'book-favorite-heart';
            case SystemIcon.BookContact:
                return 'book-contact';
            case SystemIcon.BubbleChatQuestion:
                return 'bubble-chat-question';
            case SystemIcon.Wallet:
                return 'wallet';
            default:
                return null;
        }
    }
}
type AnyDuringRendererMigration = any;

function __ngRendererProjectNodesHelper(renderer: AnyDuringRendererMigration, parent: AnyDuringRendererMigration, nodes: AnyDuringRendererMigration) {
    for (let i = 0; i < nodes.length; i++) {
        renderer.appendChild(parent, nodes[i]);
    }
}
