<div class="language-select">
    <div class="label-container">
        <icon iconId="globe-language" class="globe-language-icon"></icon>
        <label for="change-language-select" id="change-language-label" appText="Global_ChangeLanguage" class="label-text label-14-r"></label>
    </div>
    <darwin-select
        aria-labelledby="change-language-label"
        id="change-language-select"
        class="select-container"
        (currentValueChange)="changeLanguage($event)"
        [initAccessibilityFocus]="initAccessibilityFocus"
        [class.flex-grow]="autoFillContainer"
        #languageSelectElement>
        <darwin-select-option *ngFor="let item of supportedLanguages" [value]="item.id" [selected]="item.id === selectedLanguage.id">{{
            item.displayText
        }}</darwin-select-option>
    </darwin-select>
</div>
