import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ApiHttpClient } from 'core/api-http-client';
import { ConfigurationService } from 'core/configuration.service';
import { LoadingScreenService } from 'core/loadingScreen/loading-screen.service';
import { AccountSettingsState, AggregatedLanguage, HubPageNavigationDetail, QuickLink, QuickLinkGroup } from 'core/models/configuration.model';
import { MenuItem, MenuItems, MenuType, NavMenuItem, NavMenuType } from 'core/models/navigation.model';
import { SecurityService } from 'core/security.service';
import { TextService } from 'core/text.service';
import { WindowService } from 'core/window.service';

@Injectable()
export class NavigationService {
    private quickLinksText: string;
    private managerApprovalTextKey = 'EmployeeLineManager_NavTab_ManagerApproval';
    private globalProfileTextKey: string = 'Global_Profile';
    private globalHelpAndSupportTextKey: string = 'Global_HelpAndSupport';
    private quickLinksTextKey: string = 'Global_QuickLinks';
    private profileItemWithApprovalID: string = 'profileItemWithApproval';

    constructor(
        private windowService: WindowService,
        private securityService: SecurityService,
        private httpClient: ApiHttpClient,
        private loadingScreenService: LoadingScreenService,
        private router: Router,
        private route: ActivatedRoute,
        private textService: TextService,
        private configurationService: ConfigurationService
    ) {}

    convertToNavMenuItems(menuItems: MenuItem[]): NavMenuItem[] {
        if (menuItems.length === 0) {
            return null;
        }

        const navMenuItems: NavMenuItem[] = [];

        menuItems.forEach(x => {
            navMenuItems.push({
                displayText: x.displayName.trim(),
                path: x.routerLink,
                children: [],
                type: NavMenuType.Link,
                active: false,
                iconName: x.iconName,
                quickLink: null,
                count: x.pendingCount
            });
        });

        return navMenuItems;
    }

    convertQuickLinkToNavMenuItems(menuName: string, quickLinks: QuickLink[], groupName?: string): NavMenuItem {
        const navMenuItems: NavMenuItem[] = [];

        quickLinks.forEach(quickLink => {
            let pageName = quickLink.name;

            if (quickLink.isInternalLink) {
                pageName = this.setInternalPageName(quickLink);
            }

            const quickLinkType = this.getQuickLinkType(quickLink);

            navMenuItems.push({
                displayText: pageName.trim(),
                path: quickLink.url,
                type: quickLinkType,
                children: [],
                quickLink: quickLink,
                tooltip: quickLink.description,
                count: 0,
                iconName: this.getIcon(this.getQuickLinkType(quickLink))
            });
        });

        return <NavMenuItem>({ displayText: menuName, children: navMenuItems, id: groupName ?? menuName, active: false } as any);
    }

    triggerLinkAction(menuItem: NavMenuItem) {
        switch (menuItem.type) {
            case NavMenuType.Link:
                this.router.navigate([menuItem.path]);
                break;
            case NavMenuType.QuickLinkInternal:
                this.router.navigate([menuItem.path]);
                break;
            case NavMenuType.QuickLinkExternal:
                this.windowService.navigateTo(menuItem.path, menuItem.quickLink.openInNewTab);
                break;
            case NavMenuType.QuickLinkSso:
                this.outboundSso(menuItem.quickLink.serviceProviderID);
                break;
            case NavMenuType.QuickLinkDocument:
                const quickLinkDocument = menuItem.quickLink;

                if (quickLinkDocument) {
                    this.onFileDownload(quickLinkDocument);
                }
            default:
                break;
        }
    }

    setActiveGroup(path: string, mainMenuItems: NavMenuItem[]) {
        mainMenuItems.forEach(item => {
            if (item.children?.length) {
                item.active = false;

                for (let i = 0; i < item.children.length; i++) {
                    const isCurrentPath = item.children[i].path === path;

                    if (isCurrentPath) {
                        item.active = true;
                        break;
                    }
                }
            }
        });
    }

    setActiveMenuItems(path: string, mainMenuItems: NavMenuItem[]) {
        mainMenuItems.forEach(item => {
            if (item.children?.length) {
                for (let i = 0; i < item.children.length; i++) {
                    if (item.children[i].type === NavMenuType.Action) {
                        return;
                    }

                    const isPathMatch = item.children[i]?.path !== null && item.children[i].path?.includes(path);

                    item.children.forEach(x => {
                        x.active = false;
                    });

                    item.children[i].active = isPathMatch;

                    if (isPathMatch) {
                        break;
                    }
                }
            }
        });
    }

    buildMenu(
        menuItems: MenuItems,
        hasManagerApproval: boolean,
        totalPendingCountForManagerApproval,
        quickLinks: QuickLink[],
        quickLinkGroups: QuickLinkGroup[]
    ): NavMenuItem[] {
        const mainMenuItems: NavMenuItem[] = [];

        const navigationItems = this.convertToNavMenuItems(menuItems.navigationItems);

        navigationItems.forEach(mainMenuItem => {
            mainMenuItems.push(mainMenuItem);
        });

        if (quickLinks?.length) {
            mainMenuItems.push(this.buildQuickLinks(quickLinks));
        }

        if (quickLinkGroups?.length) {
            this.buildQuickLinkGroupMenus(quickLinkGroups).forEach(quickLinkByGroup => {
                mainMenuItems.push(quickLinkByGroup);
            });
        }

        if (menuItems.helpMenuItems?.length) {
            mainMenuItems.push(this.buildHelpMenu(menuItems.helpMenuItems));
        }

        if (menuItems.profileMenuItems?.length) {
            mainMenuItems.push(this.buildProfileMenu(menuItems, hasManagerApproval, totalPendingCountForManagerApproval));
        }

        return mainMenuItems;
    }

    initialiseLanguageSwitcherMenu(supportedLanguages: AggregatedLanguage[], selectedLanguageId: number): NavMenuItem[] {
        const languageSwitcherNavMenuItems: NavMenuItem[] = [];

        if (supportedLanguages.length === 1) {
            return languageSwitcherNavMenuItems;
        }

        supportedLanguages.forEach(language => {
            languageSwitcherNavMenuItems.push(<NavMenuItem>{
                displayText: language.displayText,
                children: null,
                type: NavMenuType.Action,
                path: '',
                id: language.id.toString(),
                active: selectedLanguageId === language.id,
                count: 0
            });
        });

        return languageSwitcherNavMenuItems;
    }

    getMenu(mainMenuItems: NavMenuItem[], menuType: MenuType): NavMenuItem {
        if (menuType === MenuType.Profile) {
            return mainMenuItems.filter(x => {
                return x.id === this.globalProfileTextKey;
            })[0];
        } else if (menuType === MenuType.HelpAndSupport) {
            return mainMenuItems.filter(x => x.id === this.globalHelpAndSupportTextKey)[0];
        } else if (menuType === MenuType.QuickLink) {
            return mainMenuItems.filter(x => x.id === this.quickLinksTextKey)[0];
        }

        return null;
    }

    getRoute(): string {
        return this.route.snapshot['_routerState'].url;
    }

    getIconNameAndSizeList() {
        const iconNameList: Map<number, string> = new Map<number, string>();

        iconNameList[NavMenuType.Link] = ['icon-xxs', 'right'];
        iconNameList[NavMenuType.QuickLinkExternal] = ['icon-md', 'launch'];
        iconNameList[NavMenuType.QuickLinkInternal] = ['icon-xxs', 'right'];
        iconNameList[NavMenuType.QuickLinkDocument] = ['icon-sm', 'download'];
        iconNameList[NavMenuType.QuickLinkSso] = ['icon-md', 'launch'];

        return iconNameList;
    }

    private buildHelpMenu(helpMenuItems: MenuItem[]): NavMenuItem {
        const menuText = this.textService.getText(this.globalHelpAndSupportTextKey);

        return <NavMenuItem>({ displayText: menuText, children: this.convertToNavMenuItems(helpMenuItems), id: this.globalHelpAndSupportTextKey } as any);
    }

    private buildProfileMenu(menuItems: MenuItems, hasManagerApproval: boolean, totalPendingCountForManagerApproval: number): NavMenuItem {
        const profileNavMenuItems = this.convertToNavMenuItems(menuItems.profileMenuItems);

        const profileText = this.textService.getText(this.globalProfileTextKey);

        const managerApprovalText = this.textService.getText(this.managerApprovalTextKey);

        const managerApprovalItem = this.convertToNavMenuItems(menuItems.managerMenuItems).filter(
            x => x.displayText === this.textService.getText(this.managerApprovalTextKey)
        )[0];

        if (hasManagerApproval && managerApprovalItem) {
            profileNavMenuItems.push(this.addManagerApprovalItem(managerApprovalItem, managerApprovalText, totalPendingCountForManagerApproval));
        }

        const profileMenuItem = <NavMenuItem>({
            displayText: profileText,
            children: profileNavMenuItems,
            count: totalPendingCountForManagerApproval,
            active: false,
            hasApproval: hasManagerApproval,
            id: this.globalProfileTextKey
        } as any);

        return profileMenuItem;
    }

    private addManagerApprovalItem(managerMenuItem: NavMenuItem, managerApprovalText: string, totalPendingCountForManagerApproval: number) {
        managerMenuItem.hasApproval = true;
        managerMenuItem.id = this.profileItemWithApprovalID;
        managerMenuItem.hasDivider = true;
        managerMenuItem.count = totalPendingCountForManagerApproval;
        managerMenuItem.displayText = managerApprovalText;

        return managerMenuItem;
    }

    private buildQuickLinks(quickLinks: QuickLink[]): NavMenuItem {
        this.quickLinksText = this.textService.getText(this.quickLinksTextKey);

        return this.convertQuickLinkToNavMenuItems(this.quickLinksText, quickLinks, this.quickLinksTextKey);
    }

    private buildQuickLinkGroupMenus(quickLinkGroups: QuickLinkGroup[]): NavMenuItem[] {
        const navMenuItemByGroup: NavMenuItem[] = [];

        quickLinkGroups.forEach(group => {
            navMenuItemByGroup.push(this.convertQuickLinkToNavMenuItems(group.name, group.quickLinks, group.name));
        });

        return navMenuItemByGroup;
    }

    private getQuickLinkType(quickLink: QuickLink): NavMenuType {
        if (quickLink.isInternalLink) {
            return NavMenuType.QuickLinkInternal;
        }

        if (quickLink.isContentDocument) {
            return NavMenuType.QuickLinkDocument;
        }

        if (quickLink.isOutboundSsoLink) {
            return NavMenuType.QuickLinkSso;
        }

        return NavMenuType.QuickLinkExternal;
    }

    private outboundSso(serviceProviderID: string): void {
        this.securityService.outboundSso(serviceProviderID);
    }

    private getProfileKey(): string {
        return this.configurationService.configuration.options.accountSettingsState !== AccountSettingsState.Hidden
            ? 'Global_Profile_And_Account_Settings'
            : 'Global_Your_Profile';
    }

    private onFileDownload(quickLink: QuickLink): void {
        let obs: Observable<Blob>;
        let fileName: string;

        if (quickLink.prepopulationDocument) {
            obs = this.httpClient.downloadFile(
                '/Prepopulation/PrepopulateContentDocument',
                { documentID: quickLink.prepopulationDocument.prepopulationDocumentID },
                quickLink.prepopulationDocument.fileName
            );
            fileName = quickLink.prepopulationDocument.fileName;
        } else {
            obs = this.httpClient.getWebMediaFile(quickLink.document.filePath);
            fileName = quickLink.document.fileName;
        }

        this.loadingScreenService.show();
        obs.subscribe(response => {
            this.showFile(response, fileName);
            this.loadingScreenService.hide();
        });
    }

    private showFile(response: Blob, fileName: string): void {
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        document.body.appendChild(link);
        link['download'] = fileName!;
        link.click();
        document.body.removeChild(link);
    }

    private setInternalPageName(quickLink: QuickLink) {
        let pageName: string = quickLink.name;
        if (quickLink.pageID !== null) {
            const hubPage: HubPageNavigationDetail = this.configurationService.configuration.navigationMenu.hubPageNavigationDetails?.find(
                page => page.pageID === quickLink.pageID
            );
            if (hubPage) {
                pageName = hubPage.displayName;
            }
        } else {
            const internalStaticLinks: { key: string; textKey: string }[] = this.getInternalStaticLinks();
            const staticLink = internalStaticLinks.find(link => link.key === quickLink.name);
            if (staticLink) {
                pageName = this.textService.getText(staticLink.textKey);
            }
        }

        return pageName;
    }

    private getInternalStaticLinks(): { key: string; textKey: string }[] {
        return [
            { key: 'Selection', textKey: 'Global_Benefits' },
            { key: 'Engager', textKey: 'Global_TotalRewardStatement' },
            { key: 'Reimbursements', textKey: 'Global_Reimbursement' },
            { key: 'Profile', textKey: this.getProfileKey() },
            { key: 'Dependants', textKey: 'Global_Dependants_Details' },
            { key: 'Life Events', textKey: 'Global_LifeEvents' }
        ];
    }

    private getIcon(navMenuType: NavMenuType) {
        switch (navMenuType) {
            case NavMenuType.QuickLinkDocument:
                return 'file-pdf-1';
            case NavMenuType.QuickLinkInternal:
                return 'bookmark-article';
            case NavMenuType.QuickLinkSso:
                return 'globe-vector';
            default:
                return '';
        }
    }
}
