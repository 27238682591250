<div
    id="mega-menu"
    class="mega-menu-cointainer nav-column-{{ categories.length }}"
    [ngClass]="{ inverted: invertedNavigationBarColor, default: !invertedNavigationBarColor }"
    [ngStyle]="{ width: menuWidth }">
    <div class="header-and-menu-container" [hidden]="!showMenu" [class.stacked]="isStacked" [ngStyle]="{ width: menuWidth }">
        <div class="header-row">
            <a *ngIf="bannerUrl" [routerLink]="'/'" tabindex="-1" class="banner">
                <progressive-image [imageUrl]="bannerUrl"></progressive-image>
            </a>
        </div>
        <div class="menu-container">
            <ng-container *ngIf="!isStacked">
                <ul class="menu-column" *ngFor="let items of groupedNavMenuItems" [attr.aria-label]="items[0]?.headerTitle">
                    <ng-container *ngFor="let item of items">
                        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
                    </ng-container>
                </ul>
            </ng-container>
            <ul *ngIf="isStacked" class="menu-column" [ngStyle]="{ 'column-count': maxNumberOfColumnsForScreenSize }" role="presentation">
                <ul *ngFor="let items of groupedNavMenuItems" [attr.aria-label]="isStacked ? items[0]?.headerTitle : ''">
                    <ng-container *ngFor="let item of items">
                        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
                    </ng-container>
                </ul>
            </ul>
        </div>
        <div class="bottom-spacing"></div>
    </div>
</div>
<ng-template #itemTemplate let-item>
    <li class="menu-item-components-wrapper">
        <div *ngIf="item?.headerTitle && showGroupHeaderTitle" class="category">
            <span class="label-14-r">{{ item.headerTitle }}</span>
        </div>
        <a href="javascript:void(0);" (click)="triggerMenuAction(item)" class="menu-item link" [ngStyle]="{ 'min-width': menuItemWidth }">
            <div class="title-and-description label-14-r" [attr.aria-label]="item.displayText">
                <ng-container *ngIf="item.type === NavMenuType.QuickLinkExternal || item.iconName">
                    <favicon
                        [siteUrl]="item.type === NavMenuType.QuickLinkExternal ? item.path : ''"
                        [overrideIcon]="item.iconName"
                        [inverted]="invertedNavigationBarColor"></favicon>
                </ng-container>
                <div class="title-description-and-right-icon">
                    <div class="title-and-icon">
                        <a
                            href="javascript:void(0)"
                            class="text"
                            [routerLinkActive]="'label-14-b'"
                            [routerLink]="item.path"
                            [routerLinkActiveOptions]="{ exact: !item.path?.startsWith('selection') && !item.path?.startsWith('reimbursement') }"
                            [innerHTML]="item.displayText"></a>
                        <span class="icon"
                            >&nbsp;<icon
                                class="menu-icon link-icon {{ iconNameList[item.type][0] }} {{ iconNameList[item.type][1] }}"
                                iconId="{{ iconNameList[item.type][1] }}"></icon>
                        </span>
                    </div>
                    <span class="description paragraph-12-r" *ngIf="item.tooltip?.length > 0">
                        {{ item.tooltip }}
                    </span>
                </div>
            </div>
        </a>
    </li>
</ng-template>
