import { Injectable } from '@angular/core';

import { MonetaryValueExtension } from 'core/extensions/monetary-value.extension';
import { FormatNameService } from 'core/format-name.service';
import { MonetaryValue, MonetaryValueType } from 'core/models/core.model';
import { CostSummary, LatestAccountBalance } from 'core/models/reimbursement.model';
import { TextService } from 'core/text.service';

import {
    EmployeeFundingAccount,
    EmployeeFundingAccountTransactionSummary,
    FundingAccountBreakdown,
    FundingAccountTransactionType
} from 'shared/components/funding-account-breakdown/funding-account-breakdown.model';

@Injectable()
export class FundingAccountBreakdownHelper {
    private otherDeductionsTargetID = 'bd697700-931d-4558-a695-ca5eccddf100';
    private claimsTargetID = '48d9af01-f11b-4a0b-8bc4-2d6e7559ac07';
    private carryOverTargetID = 'b56f0659-897a-47e2-af83-7a996884d92d';
    private assigneeDeductionsTargetID = 'bd697700-931d-4558-a695-ca5eccddf145';

    constructor(private textService: TextService, private formatNameService: FormatNameService) {}

    getFundingAccountBreakdownForSelection(fundingAccount: EmployeeFundingAccount, hasPendingApproval: boolean): FundingAccountBreakdown {
        const totalTypeCreditRule = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(fundingAccount.credit, hasPendingApproval);

        const fundingAccountBreakdown: FundingAccountBreakdown = {
            totalTypeCreditRule: totalTypeCreditRule,
            totalTakenFromClaims: 0,
            totalDeductions: 0,
            otherDeductions: 0,
            totalClaimDebit: 0,
            showExtension: false,
            totalFundingAmount: totalTypeCreditRule.schemePeriodForecast,
            carryoverInitialBalance: 0,
            preResidueSchemePeriodForecast: 0,
            creditSchemePeriodForecast: 0,
            preResidueBalance: MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(fundingAccount.preResidueBalance, hasPendingApproval)
                .schemePeriodForecast,
            hasPendingApproval: hasPendingApproval,
            showFundingAccountHeader: true,
            showExpiryMessage: fundingAccount.daysUntilEndDate <= 7,
            summariesForBenefitsCredited: [],
            summariesForAllDeductions: [],
            summariesForClaimsToShow: [],
            fundingAccount: fundingAccount
        };

        this.setCreditAndDeductionsSummaries(fundingAccount, fundingAccountBreakdown, hasPendingApproval);

        this.setTotalClaimsDebit(fundingAccountBreakdown, false);

        const totalAllowanceDeductionsForBenefits: number = this.getTotalAllowanceDeductionsForBenefits(
            fundingAccountBreakdown.summariesForAllDeductions,
            fundingAccount,
            hasPendingApproval
        );

        this.setPeriodForecast(fundingAccount, hasPendingApproval, fundingAccountBreakdown);

        this.setTotalTakenFromClaims(fundingAccountBreakdown.summariesForClaimsToShow, fundingAccount, fundingAccountBreakdown, hasPendingApproval);

        fundingAccountBreakdown.otherDeductions = +(
            fundingAccountBreakdown.creditSchemePeriodForecast -
            fundingAccountBreakdown.preResidueSchemePeriodForecast -
            totalAllowanceDeductionsForBenefits -
            fundingAccountBreakdown.totalClaimDebit
        ).toFixed(4);

        fundingAccountBreakdown.totalDeductions = MonetaryValueExtension.getMonetaryValueByType(
            MonetaryValueExtension.add(
                fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_BenefitEmployeePreTaxCost_ToBenefit]],
                fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_BenefitEmployeePreTaxCostReclaim_ToBenefit]]
            ),
            MonetaryValueType.SchemePeriodForecast,
            hasPendingApproval
        );

        if (fundingAccountBreakdown.summariesForClaimsToShow.length > 0) {
            fundingAccountBreakdown.totalDeductions += fundingAccountBreakdown.totalClaimDebit;
        }

        if (fundingAccountBreakdown.otherDeductions > 0) {
            fundingAccountBreakdown.totalDeductions += fundingAccountBreakdown.otherDeductions;
        }

        this.setSelectionDeductions(
            fundingAccount,
            fundingAccountBreakdown.summariesForAllDeductions,
            fundingAccountBreakdown.otherDeductions,
            fundingAccountBreakdown.totalClaimDebit,
            hasPendingApproval
        );

        return fundingAccountBreakdown;
    }

    getFundingAccountBreakdownForReimbursement(
        fundingAccount: EmployeeFundingAccount,
        assigneeID: string,
        costSummary: CostSummary,
        claimantID: string,
        claimantName: string
    ): FundingAccountBreakdown {
        const fundingAccountBreakdown: FundingAccountBreakdown = {
            totalTypeCreditRule: null,
            totalTakenFromClaims: 0,
            totalDeductions: 0,
            otherDeductions: 0,
            totalClaimDebit: null,
            showExtension: fundingAccount.extension !== null,
            totalFundingAmount: 0,
            carryoverInitialBalance: 0,
            preResidueSchemePeriodForecast: 0,
            creditSchemePeriodForecast: 0,
            preResidueBalance: 0,
            hasPendingApproval: false,
            showFundingAccountHeader: false,
            showExpiryMessage: fundingAccount.daysUntilEndDate && fundingAccount.daysUntilEndDate <= 7,
            summariesForBenefitsCredited: [],
            summariesForAllDeductions: [],
            summariesForClaimsToShow: [],
            fundingAccount: fundingAccount,
            currentClaimDeduction: 0
        };
        let remainingBalance = 0;

        if (fundingAccount.isAssigneeFundingAccount) {
            fundingAccount.transactionSummaries = fundingAccount.transactionSummaries.filter(x => x.assigneeID == assigneeID);
            const assigneeLedger = fundingAccount.assigneeSubLedgers?.find(x => x.assigneeID == assigneeID);
            if (assigneeLedger) {
                fundingAccount.credit = assigneeLedger.credit;
                fundingAccount.carryover = assigneeLedger.carryover;
                fundingAccount.expiredCarryover = assigneeLedger.expiredCarryover;
                fundingAccount.extension = assigneeLedger.extension;
                fundingAccount.totalTargetDebits = assigneeLedger.totalTargetDebits;
                fundingAccount.totalTypeValues = assigneeLedger.totalTypeValues;
                fundingAccount.preResidueBalance = assigneeLedger.preResidueBalance;
                remainingBalance = assigneeLedger.remainingBalance;

                const assigneeCost = costSummary?.latestAccountBalances?.find(
                    x => x.id == fundingAccount.fundingAccountID && x.isAssigneeFundingAccount && x.assigneeID == assigneeID && x.showCostDifference
                );

                if (assigneeCost) {
                    const claimDescription =
                        assigneeID != claimantID
                            ? this.textService.getText('BenefitSelection_FundingBreakdown_Assignee_Claim_Text', [claimantName])
                            : this.textService.getText('BenefitSelection_FundingBreakdown_Current_Claim_Text');
                    remainingBalance -= assigneeCost.costDifference.periodActualValue;
                    fundingAccountBreakdown.currentClaimDeduction = assigneeCost.costDifference.periodActualValue;
                    this.MapAssigneeAccoutCurrentTransaction(fundingAccount, assigneeCost, claimDescription, assigneeCost.costDifference, true);
                }
            }
        }

        this.setCreditAndDeductionsSummaries(fundingAccount, fundingAccountBreakdown, true);

        this.setTotalClaimsDebit(fundingAccountBreakdown, false);

        fundingAccountBreakdown.totalTypeCreditRule = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(fundingAccount.credit, false);

        if (fundingAccountBreakdown.totalTypeCreditRule) {
            fundingAccountBreakdown.totalFundingAmount = fundingAccountBreakdown.totalTypeCreditRule.schemePeriodForecast;
        }

        fundingAccountBreakdown.carryoverInitialBalance = this.getCarryOverInitialBalance(fundingAccount, null);

        if (fundingAccountBreakdown.carryoverInitialBalance > 0) {
            fundingAccountBreakdown.totalFundingAmount += fundingAccountBreakdown.carryoverInitialBalance;
        }

        if (fundingAccount.schemePeriodID) {
            fundingAccountBreakdown.creditSchemePeriodForecast =
                MonetaryValueExtension.getRecurringPeriodValue(
                    fundingAccount.credit,
                    MonetaryValueType.SchemePeriodForecast,
                    fundingAccount.schemePeriodID,
                    false
                ) + fundingAccountBreakdown.carryoverInitialBalance;
        } else {
            fundingAccountBreakdown.creditSchemePeriodForecast =
                MonetaryValueExtension.getMonetaryValueByType(fundingAccount.credit, MonetaryValueType.SchemePeriodForecast, false) +
                fundingAccountBreakdown.carryoverInitialBalance;
        }

        fundingAccountBreakdown.preResidueSchemePeriodForecast = this.getRemainingBalance(fundingAccount, fundingAccountBreakdown.carryoverInitialBalance);

        this.setDeductions(fundingAccount, fundingAccountBreakdown);

        fundingAccountBreakdown.preResidueBalance = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(
            fundingAccount.preResidueBalance,
            true
        ).schemePeriodForecast;

        this.setDeductionsAndCarryOver(
            fundingAccount,
            fundingAccountBreakdown.summariesForBenefitsCredited,
            fundingAccountBreakdown.summariesForAllDeductions,
            fundingAccountBreakdown.otherDeductions,
            fundingAccountBreakdown.carryoverInitialBalance,
            true
        );

        if (fundingAccount.isAssigneeFundingAccount) {
            fundingAccountBreakdown.preResidueSchemePeriodForecast = remainingBalance;
        }

        return fundingAccountBreakdown;
    }

    mapFundingAccounts(fundingAccounts: EmployeeFundingAccount[]): EmployeeFundingAccount[] {
        const fundingAccountList: EmployeeFundingAccount[] = [];
        fundingAccounts.forEach(fundingAccount => {
            if (fundingAccount.isAssigneeFundingAccount) {
                const accounts = this.mapAssigneeFundingAccount(fundingAccount);
                fundingAccountList.push(...accounts);
            } else {
                fundingAccountList.push(fundingAccount);
            }
        });

        return fundingAccountList;
    }

    mapAssigneeFundingAccount(fundingAccount: EmployeeFundingAccount): EmployeeFundingAccount[] {
        const fundingAccountList: EmployeeFundingAccount[] = [];
        fundingAccount.assigneeLedgers.forEach(assigneeLedger => {
            fundingAccountList.push(<EmployeeFundingAccount>{
                assigneeLedgers: fundingAccount.assigneeLedgers,
                assigneeSubLedgers: fundingAccount.assigneeLedgers,
                carryover: assigneeLedger.carryover,
                credit: assigneeLedger.credit,
                daysUntilEndDate: fundingAccount.daysUntilEndDate,
                endDate: fundingAccount.endDate,
                expiredCarryover: assigneeLedger.expiredCarryover,
                extension: assigneeLedger.extension,
                fundingAccountID: assigneeLedger.id,
                isAssigneeFundingAccount: fundingAccount.isAssigneeFundingAccount,
                name: fundingAccount.name,
                preResidueBalance: assigneeLedger.preResidueBalance,
                schemePeriodID: fundingAccount.schemePeriodID,
                startDate: fundingAccount.startDate,
                totalTargetCredits: assigneeLedger.totalTargetCredits,
                totalTargetDebits: assigneeLedger.totalTargetDebits,
                totalTypeValues: assigneeLedger.totalTypeValues,
                transactionSummaries: fundingAccount.transactionSummaries.filter(x => x.assigneeID == assigneeLedger.assigneeID),
                assigneeID: assigneeLedger.assigneeID,
                assigneeName: this.formatNameService.format(assigneeLedger)
            });
        });

        return fundingAccountList;
    }

    private MapAssigneeAccoutCurrentTransaction(
        fundingAccount: EmployeeFundingAccount,
        assigneeCost: LatestAccountBalance,
        claimDescription: string,
        currentClaimDeduction: MonetaryValue,
        isAssigneeAccountDeducted: boolean
    ) {
        if (!fundingAccount.transactionSummaries.some(summaries => summaries.isAssigneeAccountDeducted)) {
            fundingAccount.transactionSummaries.unshift({
                type: FundingAccountTransactionType.Debit_Claim,
                isHistoricalClaim: null,
                transactionID: this.assigneeDeductionsTargetID,
                targetID: this.assigneeDeductionsTargetID,
                assigneeID: assigneeCost.assigneeID,
                description: claimDescription,
                credit: null,
                debit: currentClaimDeduction,
                _totalDebit: 0,
                _totalCredit: 0,
                isAssigneeAccountDeducted: isAssigneeAccountDeducted
            });
        }
    }

    private setCreditAndDeductionsSummaries(
        fundingAccount: EmployeeFundingAccount,
        fundingAccountBreakdown: FundingAccountBreakdown,
        hasPendingApproval: boolean
    ) {
        this.setDebitAndCreditTransactionSummaries(fundingAccount, fundingAccountBreakdown, hasPendingApproval);

        this.setSummariesForClaimsToShow(fundingAccount, fundingAccountBreakdown, hasPendingApproval);
    }

    private setDebitAndCreditTransactionSummaries(
        fundingAccount: EmployeeFundingAccount,
        fundingAccountBreakdown: FundingAccountBreakdown,
        hasPendingApproval: boolean
    ) {
        fundingAccount.transactionSummaries
            .filter(x => x.type === FundingAccountTransactionType.Debit_BenefitEmployeePreTaxCost_ToBenefit && x.debit !== null)
            .forEach(transactionSummary => {
                if (
                    fundingAccountBreakdown.summariesForAllDeductions.findIndex(
                        x => x.targetID === transactionSummary.targetID && x.transactionID === transactionSummary.transactionID
                    ) === -1
                ) {
                    fundingAccountBreakdown.summariesForAllDeductions.push(transactionSummary);
                }
            });

        fundingAccount.transactionSummaries
            .filter(
                x =>
                    (x.type === FundingAccountTransactionType.Credit_BenefitReturnToFunding_FromBenefit ||
                        x.type === FundingAccountTransactionType.Credit_CarryOver ||
                        x.type === FundingAccountTransactionType.Credit_ResidueToFundingAccount_FromFundingAccount ||
                        x.type === FundingAccountTransactionType.Credit_FundingRule ||
                        x.type === FundingAccountTransactionType.Credit_CreditRule) &&
                    x.credit !== null
            )
            .forEach(transactionSummary => {
                if (
                    fundingAccountBreakdown.summariesForBenefitsCredited.findIndex(
                        x => x.targetID === transactionSummary.targetID && x.transactionID === transactionSummary.transactionID
                    ) === -1
                ) {
                    fundingAccountBreakdown.summariesForBenefitsCredited.push(transactionSummary);

                    if (transactionSummary.type === FundingAccountTransactionType.Credit_CarryOver) {
                        transactionSummary._totalCredit = this.getCarryOverInitialBalance(fundingAccount, transactionSummary.credit);
                    } else if (transactionSummary.type === FundingAccountTransactionType.Credit_CreditRule) {
                        transactionSummary._totalCredit = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(
                            transactionSummary.credit,
                            hasPendingApproval
                        ).schemePeriodForecast;
                    } else {
                        transactionSummary._totalCredit = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(
                            fundingAccount.totalTargetCredits[transactionSummary.targetID],
                            hasPendingApproval
                        ).schemePeriodForecast;
                    }
                }
            });
    }

    private setSummariesForClaimsToShow(fundingAccount: EmployeeFundingAccount, fundingAccountBreakdown: FundingAccountBreakdown, hasPendingApproval: boolean) {
        const claimTransactions: { [transactionID: string]: EmployeeFundingAccountTransactionSummary[] } = {};

        fundingAccount.transactionSummaries.forEach(transactionSummary => {
            if (
                (transactionSummary.type === FundingAccountTransactionType.Debit_Claim && transactionSummary.debit) ||
                (transactionSummary.type === FundingAccountTransactionType.Credit_ClaimCredit && transactionSummary.credit)
            ) {
                let debitMonetaryValue: MonetaryValue = MonetaryValueExtension.getMonetaryValueEmpty();

                if (!claimTransactions[transactionSummary.transactionID]) {
                    claimTransactions[transactionSummary.transactionID] = [];
                }

                claimTransactions[transactionSummary.transactionID].push(transactionSummary);

                const credit = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(transactionSummary.credit, hasPendingApproval);
                const debit = MonetaryValueExtension.getMonetaryValueByPendingApprovalStatus(transactionSummary.debit, hasPendingApproval);

                if (transactionSummary.type === FundingAccountTransactionType.Debit_Claim) {
                    debitMonetaryValue = MonetaryValueExtension.add(debitMonetaryValue, debit);
                } else {
                    debitMonetaryValue = MonetaryValueExtension.subtract(debitMonetaryValue, credit);
                }

                if (debitMonetaryValue.schemePeriodForecast !== 0) {
                    const claimSumm = { ...transactionSummary, debit: debitMonetaryValue };
                    fundingAccountBreakdown.summariesForClaimsToShow.push(claimSumm);

                    if (!transactionSummary.isAssigneeAccountDeducted) {
                        claimSumm.targetID = this.claimsTargetID;
                    }

                    fundingAccountBreakdown.summariesForAllDeductions.push(claimSumm);
                }
            }
        });
    }

    private setDeductionsAndCarryOver(
        fundingAccount: EmployeeFundingAccount,
        summariesForBenefitsCredited: EmployeeFundingAccountTransactionSummary[],
        summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[],
        otherDeductionsAmount: number,
        carryoverInitialBalance: number,
        hasPendingApproval: boolean
    ) {
        this.setOtherDeductionsAndTotalDebit(otherDeductionsAmount, fundingAccount, summariesForAllDeductions, hasPendingApproval);

        this.setCarryOverSummaries(fundingAccount, summariesForBenefitsCredited, carryoverInitialBalance);
    }

    private setSelectionDeductions(
        fundingAccount: EmployeeFundingAccount,
        summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[],
        otherDeductionsAmount: number,
        totalClaimDebit: number,
        hasPendingApproval: boolean
    ) {
        this.setOtherDeductionsAndTotalDebit(otherDeductionsAmount, fundingAccount, summariesForAllDeductions, hasPendingApproval);

        if (!fundingAccount.totalTargetDebits[this.claimsTargetID]) {
            fundingAccount.totalTargetDebits[this.claimsTargetID] = this.getClaimsTotalValue(totalClaimDebit);
        }
    }

    private getCarryOverInitialBalance(fundingAccount: EmployeeFundingAccount, carryOverAmount: MonetaryValue): number {
        if (fundingAccount.carryover) {
            return fundingAccount.carryover.initialBalance;
        }

        if (fundingAccount.expiredCarryover && fundingAccount.expiredCarryover.initialBalance > 0) {
            return fundingAccount.expiredCarryover.initialBalance;
        }

        return carryOverAmount ? MonetaryValueExtension.getMonetaryValueByType(carryOverAmount, MonetaryValueType.SchemePeriodForecast, false) : 0;
    }

    private getRemainingBalance(fundingAccount: EmployeeFundingAccount, carryoverRemainingBalance: number): number {
        if (fundingAccount.extension) {
            return fundingAccount.extension.remainingBalance;
        }

        if (fundingAccount.schemePeriodID) {
            return MonetaryValueExtension.getRecurringPeriodValue(
                fundingAccount.preResidueBalance,
                MonetaryValueType.SchemePeriodForecast,
                fundingAccount.schemePeriodID,
                true
            );
        }

        return (
            MonetaryValueExtension.getMonetaryValueByType(fundingAccount.preResidueBalance, MonetaryValueType.SchemePeriodForecast, true) +
            carryoverRemainingBalance
        );
    }

    private setDeductions(fundingAccount: EmployeeFundingAccount, fundingAccountBreakdown: FundingAccountBreakdown) {
        const totalTakenFromAllowanceFromBenefits = MonetaryValueExtension.getMonetaryValueByType(
            fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_BenefitEmployeePreTaxCost_ToBenefit]],
            MonetaryValueType.SchemePeriodForecast,
            true
        );

        if (fundingAccountBreakdown.summariesForClaimsToShow.length) {
            fundingAccountBreakdown.totalTakenFromClaims = MonetaryValueExtension.getMonetaryValueByType(
                fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_Claim]],
                MonetaryValueType.SchemePeriodForecast,
                false
            );
        }

        fundingAccountBreakdown.otherDeductions = +(
            fundingAccountBreakdown.creditSchemePeriodForecast -
            fundingAccountBreakdown.preResidueSchemePeriodForecast -
            totalTakenFromAllowanceFromBenefits -
            fundingAccountBreakdown.totalTakenFromClaims -
            fundingAccountBreakdown.currentClaimDeduction
        ).toFixed(4);

        fundingAccountBreakdown.totalDeductions = totalTakenFromAllowanceFromBenefits;

        if (fundingAccountBreakdown.summariesForClaimsToShow.length) {
            fundingAccountBreakdown.totalDeductions += fundingAccountBreakdown.totalClaimDebit;

            if (!fundingAccount.totalTargetDebits[this.claimsTargetID]) {
                fundingAccount.totalTargetDebits[this.claimsTargetID] = this.getClaimsTotalValue(fundingAccountBreakdown.totalClaimDebit);
            }
        }

        if (fundingAccountBreakdown.otherDeductions > 0) {
            fundingAccountBreakdown.totalDeductions += fundingAccountBreakdown.otherDeductions;
        }
    }

    private setTotalClaimsDebit(fundingAccountBreakdown: FundingAccountBreakdown, hasPendingApproval: boolean) {
        let totalClaimDebit: number = 0;

        fundingAccountBreakdown.summariesForClaimsToShow.forEach(
            x => (totalClaimDebit += MonetaryValueExtension.getMonetaryValueByType(x.debit, MonetaryValueType.SchemePeriodForecast, hasPendingApproval))
        );

        fundingAccountBreakdown.totalClaimDebit = totalClaimDebit;
    }

    private setOtherDeductionsAndTotalDebit(
        otherDeductionsAmount: number,
        fundingAccount: EmployeeFundingAccount,
        summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[],
        hasPendingApproval: boolean
    ) {
        if (otherDeductionsAmount > 0) {
            const otherDeductions = this.setOtherDeductionsAmount(otherDeductionsAmount);

            this.setOtherDeductionsSummaries(otherDeductions, summariesForAllDeductions);

            fundingAccount.totalTargetDebits[this.otherDeductionsTargetID] = otherDeductions;
        }

        this.setSummariesTotalDebit(summariesForAllDeductions, fundingAccount, hasPendingApproval);
    }

    private setOtherDeductionsSummaries(otherDeductions: MonetaryValue, summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[]) {
        if (!otherDeductions) {
            return;
        }

        if (summariesForAllDeductions.findIndex(x => x.targetID === this.otherDeductionsTargetID) === -1) {
            summariesForAllDeductions.push({
                type: null,
                isHistoricalClaim: null,
                transactionID: null,
                targetID: this.otherDeductionsTargetID,
                assigneeID: null,
                description: this.textService.getText('BenefitSelection_CostBreakdown_OtherDeductions'),
                credit: null,
                debit: otherDeductions,
                _totalDebit: 0,
                _totalCredit: 0
            });
        }
    }

    private setSummariesTotalDebit(
        summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[],
        fundingAccount: EmployeeFundingAccount,
        hasPendingApproval: boolean
    ) {
        summariesForAllDeductions.forEach(transactionSummary => {
            if (
                transactionSummary.type === FundingAccountTransactionType.Debit_Claim ||
                transactionSummary.type === FundingAccountTransactionType.Credit_ClaimCredit
            ) {
                transactionSummary._totalDebit = transactionSummary.debit.schemePeriodForecast;
            } else {
                transactionSummary._totalDebit = MonetaryValueExtension.getMonetaryValueByType(
                    fundingAccount.totalTargetDebits[transactionSummary.targetID],
                    MonetaryValueType.SchemePeriodForecast,
                    hasPendingApproval
                );
            }
        });
    }

    private setCarryOverSummaries(
        fundingAccount: EmployeeFundingAccount,
        summariesForBenefitsCredited: EmployeeFundingAccountTransactionSummary[],
        carryoverInitialBalance: number
    ) {
        if (!fundingAccount.carryover || carryoverInitialBalance < 1) {
            return;
        }

        if (summariesForBenefitsCredited.findIndex(x => x.targetID === this.carryOverTargetID) === -1) {
            summariesForBenefitsCredited.push({
                type: FundingAccountTransactionType.Credit_CarryOver,
                isHistoricalClaim: null,
                transactionID: null,
                targetID: this.carryOverTargetID,
                assigneeID: null,
                description: this.textService.getText('Reimbursement_Account_Label_Carryover'),
                credit: this.getCarryOverValue(carryoverInitialBalance),
                debit: null,
                _totalDebit: 0,
                _totalCredit: carryoverInitialBalance
            });
        }
    }

    private getClaimsTotalValue(totalClaimDebit: number) {
        const claimsTotalValue: MonetaryValue = {
            periodValue: 0,
            periodActualValue: 0,
            annualActualValue: 0,
            annualActualCurrentValue: 0,
            schemePeriodCurrentForecast: totalClaimDebit,
            schemePeriodForecast: totalClaimDebit,
            schemePeriodToDate: 0,
            pendingApprovalValue: null
        };

        return claimsTotalValue;
    }

    private getCarryOverValue(carryoverInitialBalance: number) {
        const carryOverValue: MonetaryValue = {
            periodValue: 0,
            periodActualValue: 0,
            annualActualValue: 0,
            annualActualCurrentValue: 0,
            schemePeriodCurrentForecast: carryoverInitialBalance,
            schemePeriodForecast: carryoverInitialBalance,
            schemePeriodToDate: 0,
            pendingApprovalValue: null
        };

        return carryOverValue;
    }

    private setOtherDeductionsAmount(otherDeductionsAmount: number): MonetaryValue {
        const otherDeductions: MonetaryValue = {
            periodValue: 0,
            periodActualValue: 0,
            annualActualValue: 0,
            annualActualCurrentValue: 0,
            schemePeriodCurrentForecast: otherDeductionsAmount,
            schemePeriodForecast: otherDeductionsAmount,
            schemePeriodToDate: 0
        };

        return otherDeductions;
    }

    private getTotalAllowanceDeductionsForBenefits(
        summariesForAllDeductions: EmployeeFundingAccountTransactionSummary[],
        fundingAccount: EmployeeFundingAccount,
        hasPendingApproval: boolean
    ): number {
        if (summariesForAllDeductions.length) {
            const totalBenefitsDeductions: MonetaryValue = MonetaryValueExtension.add(
                fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_BenefitEmployeePreTaxCost_ToBenefit]],
                fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_BenefitEmployeePreTaxCostReclaim_ToBenefit]]
            );

            if (fundingAccount.schemePeriodID) {
                return MonetaryValueExtension.getRecurringPeriodValue(
                    totalBenefitsDeductions,
                    MonetaryValueType.SchemePeriodForecast,
                    fundingAccount.schemePeriodID,
                    hasPendingApproval
                );
            } else {
                return MonetaryValueExtension.getMonetaryValueByType(totalBenefitsDeductions, MonetaryValueType.SchemePeriodForecast, hasPendingApproval);
            }
        }

        return 0;
    }

    private setTotalTakenFromClaims(
        summariesForClaimsToShow: EmployeeFundingAccountTransactionSummary[],
        fundingAccount: EmployeeFundingAccount,
        fundingAccountBreakdown: FundingAccountBreakdown,
        hasPendingApproval: boolean
    ) {
        if (summariesForClaimsToShow.length) {
            const totalClaimsDeductions: MonetaryValue =
                fundingAccount.totalTypeValues[FundingAccountTransactionType[FundingAccountTransactionType.Debit_Claim]];
            if (fundingAccount.schemePeriodID) {
                fundingAccountBreakdown.totalTakenFromClaims = MonetaryValueExtension.getRecurringPeriodValue(
                    totalClaimsDeductions,
                    MonetaryValueType.SchemePeriodForecast,
                    fundingAccount.schemePeriodID,
                    hasPendingApproval
                );
            } else {
                fundingAccountBreakdown.totalTakenFromClaims = MonetaryValueExtension.getMonetaryValueByType(
                    totalClaimsDeductions,
                    MonetaryValueType.SchemePeriodForecast,
                    hasPendingApproval
                );
            }
        }
    }

    private setPeriodForecast(fundingAccount: EmployeeFundingAccount, hasPendingApproval: boolean, fundingAccountBreakdown: FundingAccountBreakdown) {
        if (fundingAccount.schemePeriodID) {
            fundingAccountBreakdown.creditSchemePeriodForecast = MonetaryValueExtension.getRecurringPeriodValue(
                fundingAccount.credit,
                MonetaryValueType.SchemePeriodForecast,
                fundingAccount.schemePeriodID,
                hasPendingApproval
            );
            fundingAccountBreakdown.preResidueSchemePeriodForecast = MonetaryValueExtension.getRecurringPeriodValue(
                fundingAccount.preResidueBalance,
                MonetaryValueType.SchemePeriodForecast,
                fundingAccount.schemePeriodID,
                hasPendingApproval
            );
        } else {
            fundingAccountBreakdown.creditSchemePeriodForecast = MonetaryValueExtension.getMonetaryValueByType(
                fundingAccount.credit,
                MonetaryValueType.SchemePeriodForecast,
                hasPendingApproval
            );
            fundingAccountBreakdown.preResidueSchemePeriodForecast = MonetaryValueExtension.getMonetaryValueByType(
                fundingAccount.preResidueBalance,
                MonetaryValueType.SchemePeriodForecast,
                hasPendingApproval
            );
        }
    }
}
