import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

import { KeyCodes } from 'shared/shared.model';

@Directive({
    selector: '[focus-trap]'
})
export class FocusTrapDirective implements AfterViewInit {
    private tabbableChildren: Array<HTMLElement> = [];
    private tabDirection: Direction = Direction.none;
    private container: HTMLElement = this.elementRef.nativeElement;
    private currentTabbableElementIndex: number;

    constructor(private elementRef: ElementRef) {}

    @HostListener('window:focusin', ['$event'])
    onFocusIn(event: FocusEvent) {
        const currentFocusedElement: HTMLElement = <HTMLElement>event.target;
        if (
            !this.container.contains(currentFocusedElement) &&
            this.tabbableChildren.length > 0 &&
            (this.currentTabbableElementIndex === 0 ||
                this.currentTabbableElementIndex === this.tabbableChildren.length - 1 ||
                this.tabbableChildren.length === 1)
        ) {
            switch (this.tabDirection) {
                case 1:
                    this.focusOnTabbableChildElementByIndex(this.tabbableChildren.length - 1);
                    break;
                case 2:
                    this.focusOnTabbableChildElementByIndex(0);
                    break;
                default:
                    break;
            }
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleTabbing(event: KeyboardEvent) {
        if (event.keyCode === KeyCodes.Tab) {
            this.tabbableChildren = [];
            this.findAllTabbableChildElements(<HTMLElement>this.elementRef.nativeElement);
            this.currentTabbableElementIndex = this.tabbableChildren.indexOf(<HTMLElement>event.target);

            if (this.tabbableChildren.length === 0) {
                event.preventDefault();
                return;
            }

            if (event.shiftKey) {
                this.tabDirection = Direction.up;
            } else {
                this.tabDirection = Direction.down;
            }
        }
    }

    ngAfterViewInit() {
        const parentElement: Node = this.container.parentNode;
        const tabbableElement: HTMLElement = document.createElement('DIV');
        tabbableElement.setAttribute('tabIndex', '0');
        parentElement.appendChild(tabbableElement);
    }

    private focusOnTabbableChildElementByIndex(index: number) {
        if (index >= 0 && index < this.tabbableChildren.length) {
            this.tabbableChildren[index].focus();
        } else {
            this.tabbableChildren[0].focus();
        }
    }

    private findAllTabbableChildElements(element: HTMLElement) {
        const elementChildren: HTMLCollection = element.children;

        for (let i = 0; i < elementChildren.length; i++) {
            const elementChild: HTMLElement = <HTMLElement>elementChildren[i];

            if (this.tabbable(elementChild)) {
                this.tabbableChildren.push(elementChild);
            }

            if (elementChild.children && elementChild.children.length > 0) {
                this.findAllTabbableChildElements(elementChild);
            }
        }
    }

    private tabbable(element: HTMLElement): boolean {
        const tabIndex: number = this.getElementTabIndex(element);
        const isTabIndexNan: boolean = isNaN(tabIndex);

        return (isTabIndexNan || tabIndex >= 0) && this.focusable(element);
    }

    private focusable(element: HTMLElement): boolean {
        const nodeName: string = element.nodeName.toLowerCase();
        const isTabIndexNotNan: boolean = !isNaN(this.getElementTabIndex(element));

        if ('a' === nodeName) {
            if (element.tagName !== 'A') {
                return false;
            }

            const hasHrefAttribute: boolean = element.hasAttribute('href');
            const tabIndex: string = element.getAttribute('tabIndex');
            const hasFocusableTabIndex: boolean = tabIndex !== null && Number(tabIndex) >= 0;

            return (hasHrefAttribute || hasFocusableTabIndex) && this.visible(element);
        }

        return this.visible(element)
            ? /^(input|select|textarea|button|object)$/.test(nodeName)
                ? element.getAttribute('disabled') === null
                : isTabIndexNotNan
            : false;
    }

    private visible(element: HTMLElement): boolean {
        return (
            element.offsetHeight !== 0 &&
            element.offsetWidth !== 0 &&
            element.style.visibility !== 'hidden' &&
            element.getAttribute('visibility') !== 'hidden' &&
            !element.hidden &&
            window.getComputedStyle(element).visibility !== 'hidden'
        );
    }

    private getElementTabIndex(element: HTMLElement): number {
        const nodeName: string = element.nodeName.toLowerCase();
        if (/^(input|select|textarea|button|object|a|area)$/.test(nodeName)) {
            return element.tabIndex;
        }

        const tabIndex: string = element.getAttribute('tabIndex');
        return tabIndex !== null ? Number(tabIndex) : -1;
    }
}

enum Direction {
    none = 0,
    up = 1,
    down = 2
}
