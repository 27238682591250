<ng-template #template>
    <div id="{{ id }}" class="accordion-item grid no-horizontal-padding">
        <div
            class="header-container"
            [style.border-color]="borderColour"
            [class.disabled]="disabled"
            (click)="toggleItem()"
            tabindex="0"
            (keydown.enter)="toggleItem()"
            (keydown.space)="toggleItem()">
            <div class="expand-collapse-blob" [style.background]="colour" [@rotateBlob]="!expanded">
                <icon class="icon-xxs expand-collapse-icon" iconId="right"></icon>
            </div>
            <ng-content select=".accordion-item-header"></ng-content>
        </div>
        <div class="content-container" [@expandCollapse]="expanded" (@expandCollapse.done)="onExpandCollapseDone()">
            <ng-content select=".accordion-item-content"></ng-content>
        </div>
    </div>
</ng-template>
