import { ChangeDetectionStrategy, Component, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TextService } from 'core/text.service';

import { TooltipPosition } from 'shared/tooltip/tooltip.model';

@Component({
    selector: 'toggle',
    templateUrl: 'toggle.component.html',
    styleUrls: ['toggle.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent implements OnInit {
    @Output() toggleFlag = new EventEmitter<boolean>();

    @Input() size: string;
    @Input() isActive: boolean;
    @Input() brandColour: boolean = true;
    @Input() label: string = '';
    @Input() disabled: boolean;
    @Input() offStateToolTip: string = this.textService.getText('Toggle_ToolTip_Default_Off');
    @Input() onStateToolTip: string = this.textService.getText('Toggle_ToolTip_Default_On');
    @Input() showToolTip: boolean = true;
    @Input() tooltipPosition: TooltipPosition = TooltipPosition.bottom;

    sizeStyleClass: string;
    iconStyleClass: string;

    constructor(private textService: TextService) {}

    ngOnInit() {
        this.setToggleSize();
    }

    onClick() {
        this.isActive = !this.isActive;
        this.toggleFlag.emit(this.isActive);
    }

    private setToggleSize() {
        switch (this.size) {
            case 'small':
                this.sizeStyleClass = 'toggle toggle-sm';
                this.iconStyleClass = 'toggle-icon-sm icon-xxxs';
                break;
            case 'medium':
                this.sizeStyleClass = 'toggle toggle-md';
                this.iconStyleClass = 'toggle-icon-md icon-xxs';
                break;
            default:
                this.sizeStyleClass = 'toggle';
                this.iconStyleClass = 'toggle-icon icon-xxs';
                break;
        }
    }
}

@Directive({ selector: 'off-label' })
export class ToggleComponentOffLabelDirective {}

@Directive({ selector: 'on-label' })
export class ToggleComponentOnLabelDirective {}
