import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InternalSharedModule } from 'shared/internal-shared.module';

@NgModule({
    declarations: [],
    imports: [InternalSharedModule, CommonModule, CdkTableModule, PortalModule],
    exports: [CdkTableModule]
})
export class TableModule {
    static rootProviders() {
        return [];
    }
}
