import { Pipe, PipeTransform } from '@angular/core';

import { FormatEntity, FormatNameService } from 'core/format-name.service';

@Pipe({
    name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {
    constructor(private formatNameService: FormatNameService) {}

    transform(entity: FormatEntity, initialsOnly?: boolean): string {
        if (initialsOnly) {
            return this.formatNameService.formatInitials(entity);
        }

        return this.formatNameService.format(entity);
    }
}
