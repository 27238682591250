<div class="content-renderer-container">
    <div class="content-container" [ngSwitch]="content.nodeItemType">
        <ng-container *ngSwitchCase="contentNodeItemType.Header">
            <h3 class="content-header heading-22-b">{{ content.headerText }}</h3>
        </ng-container>
        <ng-container *ngSwitchCase="contentNodeItemType.Text || contentNodeItemType.TextBlock">
            <div class="content-item external-html-container" [innerHtml]="content.text | trustHtml"></div>
        </ng-container>
        <ng-container *ngSwitchCase="contentNodeItemType.Image">
            <div class="content-item">
                <img [src]="safeImageURL" alt="{{ fileName }}" />
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="contentNodeItemType.Video">
            <video-component class="video-player" [internalVideoFile]="content.file"></video-component>
        </ng-container>
        <ng-container *ngSwitchCase="contentNodeItemType.Expander">
            <accordion *ngIf="!innerExpander">
                <accordion-item [expanded]="content.isExpanded">
                    <div class="accordion-item-header">
                        <div class="category-header">
                            <h4 class="category-name heading-18-r">{{ content.expanderHeader }}</h4>
                        </div>
                    </div>
                    <div class="accordion-item-content">
                        <ng-container *ngFor="let expanderNodes of content.contentNodes">
                            <content-renderer [content]="expanderNodes" [innerExpander]="true"></content-renderer>
                        </ng-container>
                    </div>
                </accordion-item>
            </accordion>
            <accordion-transparent *ngIf="innerExpander">
                <accordion-transparent-item id="content-render-expander" [expanded]="showInnerExpanderDetails">
                    <span class="accordion-item-title">
                        {{ content.expanderHeader }}
                    </span>
                    <span class="accordion-item-content">
                        <ng-container *ngFor="let expanderNodes of content.contentNodes">
                            <content-renderer [content]="expanderNodes"></content-renderer>
                        </ng-container>
                    </span>
                </accordion-transparent-item>
            </accordion-transparent>
        </ng-container>
        <ng-container *ngSwitchCase="contentNodeItemType.Link">
            <div class="content-item">
                <a href="javascript:void(0)" (click)="displayInformation()" role="link">
                    <div class="btn-link-icon">
                        <icon class="icon-sm" [iconId]="content._iconId"></icon>
                        <span>{{ content.text }}</span>
                    </div>
                </a>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="contentNodeItemType.Document">
            <div class="content-item">
                <a href="javascript:void(0)" role="link" (click)="displayInformation()">
                    <div class="btn-link-icon">
                        <icon class="icon-sm" iconId="download"></icon>
                        <span>{{ content.fileName }}</span>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>
</div>
