<div class="notification-message-parent" [class.padding-top]="!hidePaddingTop" *ngIf="validationMessages && validationMessages.length > 0">
    <div *ngFor="let message of validationMessages">
        <div
            class="notification-message row notification-message-without-title"
            *ngIf="message.messageType !== messageType.error || displayValidationErrors"
            [class.notification-success-message]="message.messageType === messageType.success"
            [class.notification-warning-message]="message.messageType === messageType.warning"
            [class.notification-error-message]="message.messageType === messageType.error"
            [class.notification-information-message]="message.messageType === messageType.information">
            <div class="notification-message-header">
                <icon class="notification-message-icon {{ this.iconSize }}" iconId="{{ iconIdOverride ?? iconIds.get(message.messageType) }}"></icon>
            </div>
            <div class="notification-message-text label-14-r">
                <div class="notification-message-title label-14-b" *ngIf="message.title">{{ message.title.key | appText: message.title.replacements }}</div>
                <div class="notification-message-item" [innerHtml]="formatMessage(message) | trustHtml"></div>
            </div>
        </div>
    </div>
</div>
