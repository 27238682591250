<button
    [ngClass]="sizeStyleClass"
    (click)="onClick()"
    class="privacy-toggle-button"
    [class.nav-bar-default-color]="!brandColour"
    [class.active]="isActive"
    role="button"
    [tooltip]="showToolTip ? (isActive ? onStateToolTip : offStateToolTip) : null"
    [tooltipPosition]="tooltipPosition"
    [attr.aria-pressed]="isActive"
    [attr.aria-label]="showToolTip ? (isActive ? label + onStateToolTip : label + offStateToolTip) : label"
    [disabled]="disabled"
    [class.disabled]="disabled">
    <div class="blob" role="presentation">
        <icon *ngIf="!isActive" iconId="cross" [ngClass]="iconStyleClass"></icon>
        <icon *ngIf="isActive" iconId="tick" [ngClass]="iconStyleClass"></icon>
    </div>
    <div *ngIf="!showToolTip" class="off-label-container label-14-r" role="presentation">
        <ng-content select="off-label"></ng-content>
    </div>
    <div *ngIf="!showToolTip" class="on-label-container label-14-r" role="presentation">
        <ng-content select="on-label"></ng-content>
    </div>
</button>
