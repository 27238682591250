import { CdkPortalOutlet } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { AnimationState, flyInOut } from 'shared/animations/overlay.animation';
import { IOverlayBase, OverlayPosition } from 'shared/overlay/overlay.model';

@Component({
    selector: 'overlay-container',
    templateUrl: 'overlay-container.component.html',
    animations: [flyInOut]
})
export class OverlayContainerComponent implements OnInit, AfterViewInit, OnDestroy, IOverlayBase {
    @ViewChild('closeIcon', { static: true }) closeIconEl: ElementRef;
    @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;
    @HostBinding('class.overlay-centre') get isCenter() {
        return this.position === OverlayPosition.Centre;
    }
    @HostBinding('class.overlay-right') get isRight() {
        return this.position === OverlayPosition.Right;
    }

    noOverlayPadding: boolean;
    disposeOverlay: EventEmitter<void>;
    position: OverlayPosition = OverlayPosition.Right;
    autoWidth: boolean;
    checkoutBarOverlay: boolean;
    ariaLabel: string;

    animate = AnimationState.void;
    close = new EventEmitter<void>();

    private inAnimationState: AnimationState;
    private outAnimationState: AnimationState;

    constructor() {}

    ngOnInit() {
        this.setAnimationState();
        this.animate = this.inAnimationState;

        this.close.pipe(take(1)).subscribe(() => {
            this.closeOverlay();
            this.close.complete();
        });
    }

    ngAfterViewInit() {
        this.setFocusOnCloseIcon();
    }

    ngOnDestroy() {
        this.closeOverlay();
        this.close.complete();
    }

    onAnimationDone() {
        if (this.animate === this.outAnimationState) {
            this.disposeOverlay.emit();
        }
    }

    closeOverlay() {
        this.animate = this.outAnimationState;
    }

    private setFocusOnCloseIcon() {
        this.closeIconEl.nativeElement.focus();
    }

    private setAnimationState() {
        switch (this.position) {
            case OverlayPosition.Right:
                this.inAnimationState = AnimationState.rightIn;
                this.outAnimationState = AnimationState.rightOut;
                break;
            case OverlayPosition.Centre:
                this.inAnimationState = AnimationState.centerIn;
                this.outAnimationState = AnimationState.centerOut;
                break;
        }
    }
}
