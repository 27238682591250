<div class="darwin-select-container" [ngClass]="contextType">
    <button
        #selectInput
        [id]="id"
        class="darwin-select-button label-14-r"
        [class.invalid]="showErrors"
        [disabled]="disabled"
        (click)="toggleItem()"
        (blur)="onInputBlur()"
        role="combobox"
        type="button"
        [attr.aria-expanded]="expanded"
        [attr.aria-activedescendant]="id + '-' + currentIndex">
        <span class="darwin-select-header">
            <ng-container *ngIf="selectedOption; else nonSelectedOption">
                <span [textOverflowTooltip]="disableTooltip ? '' : selectedOption" [class.left-icon]="iconId">
                    <icon class="icon-md" *ngIf="iconId" iconId="{{ iconId }}"></icon>
                    <span class="text">{{ selectedOption }}</span>
                </span>
            </ng-container>
            <ng-template #nonSelectedOption>
                <span [class.left-icon]="iconId" *ngIf="iconId">
                    <icon class="icon-md" *ngIf="iconId" iconId="{{ iconId }}"></icon>
                    <span class="text">{{ selectPlaceholder }}</span>
                </span>
                <ng-container *ngIf="!iconId">
                    {{ selectPlaceholder }}
                </ng-container>
            </ng-template>
            <icon class="icon-xxxs" iconId="down" [@rotateArrow]="expanded"></icon>
        </span>
    </button>
</div>
<div class="darwin-options-container" #optionsContainer>
    <ul
        #optionsListElement
        [class.collapsed]="!expanded"
        [@fadeIn]="expanded"
        class="darwin-options"
        [ngClass]="contextType"
        [class.top]="showTop"
        role="listbox">
        <ng-container *ngFor="let option of options" [ngTemplateOutlet]="option.template"></ng-container>
    </ul>
</div>
<div class="darwin-options-container dummy">
    <ul #optionsListElementDummy class="darwin-options"></ul>
</div>
