import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { EventService, EventType } from 'core/event.service';
import { FormatService } from 'core/format.service';
import { PrivacyService } from 'core/privacy.service';

@Component({
    selector: 'privacy-toggle',
    templateUrl: './privacy-toggle.component.html',
    styleUrls: ['./privacy-toggle.component.less']
})
export class PrivacyToggleComponent implements OnInit, OnDestroy {
    @Input() useBrandColour: boolean = true;
    @Input() toggleSize: string = '';
    @Input() showValueTextBellow: boolean = false;

    inPrivateMode: boolean;
    currencySymbol: string;

    labelText: string = 'Toggle_ShowValue';

    private privacySubscription: Subscription = Subscription.EMPTY;

    constructor(private privacyService: PrivacyService, private formatService: FormatService, private eventService: EventService) {}

    ngOnInit() {
        this.privacySubscription = this.eventService.subscribeToEvent(EventType.PrivacyToggle).subscribe(inPrivateMode => (this.inPrivateMode = inPrivateMode));

        this.currencySymbol = this.formatService.getDefaultCurrency().currencySymbol;
    }

    ngOnDestroy() {
        this.privacySubscription.unsubscribe();
    }

    togglePrivateMode(flag: boolean) {
        this.privacyService.setPrivacy(flag);
        this.labelText = flag ? 'Toggle_HideValue' : 'Toggle_ShowValue';
    }
}
