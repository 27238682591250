import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';
import * as PdfJsNamespace from 'pdfjs-dist/types/pdf';

import { ApplicationService } from 'core/application.service';

export type PdfJs = typeof PdfJsNamespace;

@Injectable()
export class PdfViewerService {
    private libSubject = new AsyncSubject<PdfJs>();
    private isLibLoaded = false;
    private isIE11 = false;
    private pdfJsVersion = '2.9.359';
    private pdfJsPath = '';
    private pdfJsWorkerPath = '';

    constructor(applicationService: ApplicationService) {
        this.isIE11 = !window.crypto;

        const path = `${applicationService.webUrl}/assets/pdfjs`;
        this.pdfJsPath = `${path}/pdf.min.js?v=${this.pdfJsVersion}`;
        this.pdfJsWorkerPath = `${path}/pdf.worker.min.js?v=${this.pdfJsVersion}`;
    }

    getPdfJsLib() {
        if (!this.isLibLoaded) {
            this.isLibLoaded = true;
            this.loadPdfJsScript();
        }

        return this.libSubject.asObservable();
    }

    private loadPdfJsScript() {
        if (this.isIE11) {
            this.libSubject.next(undefined);
            this.libSubject.complete();
            return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = true;
        script.src = this.pdfJsPath;
        script.onload = () => {
            const lib: PdfJs = window['pdfjsLib'];
            lib.GlobalWorkerOptions.workerSrc = this.pdfJsWorkerPath;

            this.libSubject.next(lib);
            this.libSubject.complete();
        };

        document.body.appendChild(script);
    }
}
